import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUserFranchiseListings } from '../../state/actions/user';
import BusinessFranchisePlaceholder from '../../common/components/BusinessFranchisePlaceholder';
import FadeIn from 'react-fade-in';
import UserBusinessFranchiseItem from './UserBusinessFranchiseItem';
import { NavLink } from 'react-router-dom';

class UserFranchiseListings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      done: false,
    };
    this.refreshListings = this.refreshListings.bind(this);
  }

  componentDidMount() {
    if (this.props.currentUser.userId) {
      this.props.fetchUserFranchiseListings(this.props.currentUser.userId);
    }
    if (this.props.userFranchiseListings.length > 0) {
      this.setState({ loading: false, done: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentUser.userId !== prevProps.currentUser.userId) {
      this.props.fetchUserFranchiseListings(this.props.currentUser.userId);
    }
    if (this.props.userFranchiseListings !== prevProps.userFranchiseListings) {
      this.setState({ ...this.state, loading: false });
      setTimeout(() => {
        this.setState({ done: true });
      }, 1000);
    }
  }

  refreshListings() {
    this.props.fetchUserFranchiseListings(this.props.currentUser.userId);
  }

  render() {
    const { userFranchiseListings } = this.props;

    let placeholders = [];
    for (let i = 0; i < 6; i++) {
      placeholders.push(
        <FadeIn key={i}>
          <div className="mb-5 mx-2">
            <BusinessFranchisePlaceholder />
          </div>
        </FadeIn>,
      );
    }
    return (
      <>
        <div
          className="d-flex flex-wrap justify-content-center
              justify-content-lg-start justify-content-xl-start"
        >
          {this.state.loading
            ? placeholders
            : userFranchiseListings.map((listing, index) => (
                <FadeIn key={index}>
                  <UserBusinessFranchiseItem
                    key={listing.id}
                    whichListing={'franchise'}
                    listing={listing}
                    refreshListings={this.refreshListings}
                  />
                </FadeIn>
              ))}
        </div>
        {this.state.done &&
          userFranchiseListings &&
          userFranchiseListings.length === 0 && (
            <FadeIn>
              <div className="p-3 m-3 text-center pt-7 min-vh-50 text-danger">
                <p className="mb-3 d-inline mr-3">
                  You have no franchise profile. Why not
                </p>
                <NavLink
                  to="/register-franchise/plans"
                  className="btn btn-outline-white bg-white shadow text-bright-primary rounded-2"
                >
                  list a franchise profile?
                </NavLink>
              </div>
            </FadeIn>
          )}
      </>
    );
  }
}

export default connect(
  state => ({
    userFranchiseListings: state.user.userFranchiseListings,
    currentUser: state.user.currentUser,
  }),
  { fetchUserFranchiseListings },
)(UserFranchiseListings);
