import React from 'react';

const Refunds = () => {
  return (
    <div className="container custom-home py-5">
      <div className="d-flex flex-column align-items-center justify-content-center text-center bg-about mb-5">
        <p className="h1 text-subscribe-light font-weight-bold">
          Refund Policy
        </p>
      </div>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        COMPEXAFRICA will refund full cost of your order if we fail to deliver
        our services within the stipulated amount of time as provided in the
        chart below:
      </p>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        Services
      </p>
      <p className="h7 line-height-5 mb-4">
        Business/Franchise Profile Plans, Investor/Buyer Profile Plans, and
        Advisor Profile Plans..
      </p>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        Stipulated time
      </p>
      <p className="h7 line-height-5 mb-4">
        Profile to be activated within 5 business days, provided complete
        information and verification details are provided by the user. Refund
        not applicable if the user provides wrong information or delays
        providing required information to complete the profile.
      </p>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        Services
      </p>
      <p className="h7 line-height-5 mb-4">Introduction Credits</p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        Stipulated time
      </p>
      <p className="h7 line-height-5 mb-4">
        Business introductions which do not respond to you or are unavailable
        can be reported on our website or over email. After validating the same,
        these introduction credits will be re-credited back to your account for
        connecting with alternate businesses.
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        Services
      </p>
      <p className="h7 line-height-5 mb-4">
        Information Memorandum and Business Valuation Model
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        Stipulated time
      </p>
      <p className="h7 line-height-5 mb-4">
        First version of Report to be submitted to the client within 25 business
        days from date of receiving complete information and payment from the
        client. Refund not applicable if the client provides wrong information
        or delays providing required information to complete the process.
        <br></br>
        <br></br>
        COMPEXAFRICA will refund your order post a 5% deduction towards
        transaction charges under the following circumstances:
        <br></br>
        <br></br>
        COMPEXAFRICA reserves the right to reject, suspend, edit, or delete a
        profile even after submission of the required verification documents by
        the user, if we believe, in our opinion, that such steps will improve
        the quality of the website.
        <br></br>
        <br></br>
        In such cases, where COMPEXAFRICA is unable to approve your profile or
        retain your profile on our website, we provide a refund on a pro-rata
        basis post deduction of a 5% transaction charge.
        <br></br>
        <br></br>
        We apologize for any inconvenience, but we will not be providing a
        refund under the following circumstances:
        <br></br>
        <br></br>- COMPEXAFRICA neither guarantee introductions nor deal
        closures and we do not issue refunds for the same. We recommend you to
        user our free plans and go through our services and processes, as and
        when available, to try our services before subscribing to a paid plan.
        <br></br>
        <br></br>- COMPEXAFRICA will also not be providing any refunds if user
        changes their mind or does not use their profile or fails to furnish
        verification proof to back its claims.
        <br></br>
        <br></br>- COMPEXAFRICA may terminate an account if we, in our opinion,
        believe that the user is abusing COMPEXAFRICA in any way, intentionally
        furnishes false information, or has previously failed to pay Finder’s
        Fee to COMPEXAFRICA.
        <br></br>
        <br></br>
        In such a situation, COMPEXAFRICA shall be entitled to retain the entire
        fees paid the user towards damages and user shall not be entitled to
        seek a refund of the same.
        <br></br>
        <br></br>
        If you are eligible for a refund based on the above terms, kindly follow
        the below process to raise a refund request:
        <br></br>
        <br></br>
        All refund requests need to be sent over an email to
        info@COMPEXAFRICA.com from the registered email address of the user.
        Please state the invoice number, payment date, amount and reason for the
        refund request. We will review the case in 3 business days and issue a
        refund, if applicable, as per our refund policy. Depending on the mode
        of payment it may take between 5-15 business days for the credit to
        reflect in your card or account.
      </p>
    </div>
  );
};

export default Refunds;
