import React from 'react';
import { Link } from 'react-router-dom';
import Tick from './../../assets/img/tick.svg';

const RequestPasswordSuccess = props => {
  return (
    <div>
      <div className="d-flex flex-column align-items-center container pb-5">
        <div className="rounded-circle bg-primary p-3 w-25 col-lg-1 my-5">
          <img src={Tick} alt="..."></img>
        </div>
        <h1 className="h3 text-verify-dark text-center">
          Password reset link sent successfully
        </h1>
        <div className="card bg-white shadow rounded mb-3 col-lg-6 my-5">
          <div className="card-body px-5 py-5">
            <p className="card-text h5 text-verify-light font-weight-light text-center">
              A password reset link has been sent to your email. <br />
              Please check your email to continue.
            </p>
          </div>
        </div>
        <div className="pb-5">
          <Link to="/" className="btn btn-primary" role="button">
            Proceed to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RequestPasswordSuccess;
