import React from 'react';
import UserBusinessListings from './UserBusinessListings';
import InvestorListingItemPage from '../investors/InvestorListingItemPage';
import AdvisorListingItemPage from '../advisors/AdvisorListingItemPage';
import FranchiseListingItemPage from '../franchise/FranchiseListingItemPage';
import ProposalsPage from './UserProposalsPage';
import { Route, Switch } from 'react-router-dom';
import UserFranchiseListings from './UserFranchiseListings';
import UserAdvisorListings from './UserAdvisorListings';
import UserInvestorListings from './UserInvestorListings';
import UserSettings from './UserSettings';
import ContactedListingsPage from './ContactedListingsPage';
import BusinessListingItemPage from '../businesses/BusinessListingItemPage';
import UserSubscriptions from './UserSubscriptions';
import SubscriptionDetail from './SubscriptionDetail';
import UpgradeInvestorPlan from '../investors/UpgradeInvestorPlan';
import UpgradeAdvisorPlan from '../advisors/UpgradeAdvisorPlan';
import UpgradeBusinessPlan from '../businesses/UpgradeBusinessPlan';
import UpgradeFranchisePlan from '../franchise/UpgradeFranchisePlan';
import DashboardLanding from './DashboardLanding';

const UserRoutes = props => {
  const { match, currentUser, userProposals } = props;
  return (
    <Switch>
      <Route path={`${match.path}/dashboard`} component={DashboardLanding} />
      <Route
        path={`${match.path}/contacts`}
        component={ContactedListingsPage}
      />
      <Route
        path={`${match.path}/businesses/:id/upgrade`}
        component={UpgradeBusinessPlan}
      />
      <Route
        path={`${match.path}/businesses/:id`}
        component={BusinessListingItemPage}
      />
      <Route
        exact
        path={`${match.path}/businesses`}
        render={props => <UserBusinessListings {...props} />}
      />
      <Route
        path={`${match.path}/investors/:id/upgrade`}
        component={UpgradeInvestorPlan}
      />
      <Route
        path={`${match.path}/investors/:id`}
        component={InvestorListingItemPage}
      />
      <Route
        path={`${match.path}/investors`}
        render={props => (
          <UserInvestorListings {...props} whichListing="investor" />
        )}
      />
      <Route
        path={`${match.path}/inbox/proposals`}
        render={props => (
          <ProposalsPage
            {...props}
            userProposals={userProposals}
            currentUser={currentUser}
          />
        )}
      />

      <Route
        path={`${match.path}/sent/proposals`}
        render={props => (
          <ProposalsPage
            {...props}
            userProposals={userProposals}
            currentUser={currentUser}
          />
        )}
      />

      <Route
        path={`${match.path}/advisors/:id/upgrade`}
        component={UpgradeAdvisorPlan}
      />
      <Route
        path={`${match.path}/advisors/:id`}
        component={AdvisorListingItemPage}
      />

      <Route
        path={`${match.path}/advisors`}
        render={props => (
          <UserAdvisorListings {...props} whichListing="advisor" />
        )}
      />

      <Route
        path={`${match.path}/franchises/:id/upgrade`}
        component={UpgradeFranchisePlan}
      />
      <Route
        path={`${match.path}/franchises/:id`}
        component={FranchiseListingItemPage}
      />
      <Route
        path={`${match.path}/franchises`}
        render={props => <UserFranchiseListings {...props} />}
      />
      <Route path={`${match.path}/settings`} component={UserSettings} />
      <Route
        path={`${match.path}/subscriptions/:id`}
        component={SubscriptionDetail}
      />
      <Route
        path={`${match.path}/subscriptions`}
        component={UserSubscriptions}
      />
    </Switch>
  );
};

export default UserRoutes;
