import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setHeaders, resetHeaders } from './../../state/actions/layout';
import { withRouter } from 'react-router-dom';

const About = props => {
  //let reduxStore = useSelector(state => state);
  const description =
    'CompexAfrica is all about making investment accessible to small and medium enterprises, start-ups, franchises and providing opportunity for industry advisors to provide services. COMPEXAFRICA is a Public Market Network for Businesses, Investors, Acquirers, Lenders, M&A Advisors, start-ups and franchises.';
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setHeaders({
        title: 'About Compex Africa',
        url: props.history.location.pathname,
        description,
      }),
    );

    return function cleanup() {
      dispatch(resetHeaders());
    };
  }, [description, dispatch, props.history.location.pathname]);

  return (
    <div className="container custom-home py-5">
      <div className="d-flex flex-column align-items-center justify-content-center text-center bg-about mb-5">
        <p className="h1 text-subscribe-light font-weight-bold">
          About Compex Africa
        </p>
      </div>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        CompexAfrica is all about making investment accessible to small and
        medium enterprises, start-ups, franchises and providing opportunity for
        industry advisors to provide services.
      </p>
      <p className="h7 line-height-5 text-about mb-4">
        COMPEXAFRICA is a Public Market Network for Businesses, Investors,
        Acquirers, Lenders, M&A Advisors, start-ups and franchises. Our platform
        automates deal origination, valuation, matching and introduction of
        businesses and investors across Africa. With the click of a button
        members can access services such as Mergers and Acquisitions, Fund
        Raising, Joint Ventures & Debt transactions, Capital raise.
      </p>
      <p className="h7 line-height-5 text-about mb-4">
        Business owners, Entrepreneurs, CEOs, Investors, Acquirers, Business
        Buyers, Lenders, Advisors, and Founders from across Africa discover each
        other and connect almost instantly to pursue financial transactions.
        Whether it is a SME business, start-up or a large established company,
        COMPEXAFRICA has access to the right network of members to help close
        successful transactions.
      </p>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        Who uses COMPEXAFRICA?
      </p>
      <ul className="h7 line-height-5 text-about mb-4">
        <li>Businesses For Sale</li>
        <li>Companies/start-ups Seeking Capital</li>
        <li>Franchise Brands</li>
        <li>M&A Advisors</li>
        <li>Business Brokers/agents</li>
        <li>Private Investors</li>
        <li>Corporate Acquirers</li>
        <li>Lenders</li>
        <li>PE / VC Funds</li>
        <li>Deal Professionals</li>
        <li>Financial advisors</li>
      </ul>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        Why COMPEXAFRICA?
      </p>
      <ul className="h7 line-height-5 text-about mb-4">
        <li>Simple and straight forward</li>
        <li>Pre-approved profiles only</li>
        <li>Confidential “no name” profiles till introduction is made</li>
        <li>Automatic matching of profiles</li>
        <li>Insights to improve your profile</li>
        <li>Ratings to sort good profiles</li>
        <li>Easy Messaging and Document Sharing</li>
        <li>
          Thousands of business owners, investors, lenders and advisors trust
          COMPEXAFRICA.
        </li>
      </ul>
    </div>
  );
};

export default withRouter(About);
