import React, { Component } from 'react';
import Joi from 'joi-browser';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import authService from '../../common/services/authService';
import {
  hideNavbar,
  hideFooter,
  showNavbar,
  showFooter,
} from './../../state/actions/layout';
import { CircularProgress } from '@material-ui/core';
import Swal from 'sweetalert2';
import FormAlertMessage from '../../common/components/FormAlertMessage';
import GoogleLoginButton from '../../common/components/GoogleLoginButton';
import FacebookLoginButton from './../../common/components/FacebookLoginButton';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {
        email: '',
        password: '',
      },
      errors: {},
      loading: undefined,
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.props.hideNavbar();
    this.props.hideFooter();
  }

  schema = {
    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .label('Email'),
    password: Joi.string()
      .min(6)
      .required()
      .label('Password'),
  };

  validate() {
    const { error } = Joi.validate(this.state.account, this.schema, {
      abortEarly: false,
    });
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  componentWillUnmount() {
    this.props.showNavbar();
    this.props.showFooter();
  }

  onChange = e => {
    const { name, value } = e.target;
    const account = { ...this.state.account };
    account[name] = value;
    const errors = { ...this.state.errors };
    errors[name] = '';
    this.setState({ account, errors });
  };

  resendConfirmationEmail = async e => {
    e.preventDefault();
    try {
      const { email } = this.state.account;
      this.setState({ loading: true });
      const res = await authService.resendConfirmationEmail(email);
      if (res) {
        Swal.fire(
          'Email Resent Successfully',
          `A new verification email has been sent to ${email}. Didn't get an email after a few minutes? Please check you spam, promotions, social or junk folder.`,
          'success',
        );
        this.setState({ errorMessage: '', loading: false });
      } else {
        Swal.fire(
          'Action failed',
          `Unable to resend email. Try again`,
          'error',
        );
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  onSubmit = async e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });

    try {
      if (errors) return;
      this.setState({ ...this.state, loading: true });
      const result = await authService.login({ ...this.state.account });
      if (result) {
        this.setState({ ...this.state, loading: false });
        const { state } = this.props.location;
        const redirectPath = state ? state.from.pathname : '/user/dashboard';
        this.props.history.replace(redirectPath);
      } else {
        this.setState({ loading: false });
      }
    } catch (err) {
      this.setState({ loading: false });

      const { response } = err;

      if (response && response.data && response.data.title) {
        let message = response.data.title;

        if (message.includes('email has not been verified')) {
          message = (
            <span>
              Your email address has not been verified. Please kindly click the
              link that was sent to your email to verify your account and have
              access to your dashboard.{' '}
              <button
                className="button-as-link text-primary"
                onClick={this.resendConfirmationEmail}
              >
                Resend Verification Email.
              </button>{' '}
              <br />
              <br />
              <span className="font-weight-bold">
                Didn't get an email after a few minutes?
              </span>
              <br />
              Please check you spam, promotions, social or junk folder.
              <br />
            </span>
          );
        } else if (message.includes('You have not set a password')) {
          message = (
            <span>
              You must change your password to login. <br />
              <Link to="/forgot-password">Change password</Link>
            </span>
          );
        }

        this.setState({ errorMessage: message });
      }
    }
  };

  render() {
    return (
      <div className="p-3 custom-home">
        <div className="d-flex justify-content-center fixed-top justify-content-md-between align-items-center px-5 pt-3 mb-3 bg-body">
          <Link to="/" className="nav-link">
            <img
              className="compex-logo"
              src={require('../../assets/img/compex-logo.svg')}
              alt=""
            />
          </Link>
          <div className="d-none d-md-block">
            <span>Don't have an account?</span>
            <Link to="/signup">
              <span className="text-primary font-weight-bold px-2">
                Sign up
              </span>
            </Link>
          </div>
        </div>
        <div className="min-vh-100">
          <div className="text-center mb-5">
            <h3 className="text-primary">Welcome back!</h3>
            <h6>Please sign in</h6>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="bg-white shadow p-5 rounded ">
                <form
                  onSubmit={this.onSubmit}
                  method="POST"
                  className="font-weight-bold small"
                  noValidate
                >
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={this.state.account.email}
                        onChange={this.onChange}
                        className={
                          this.state.errors['email']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['email']}
                      </div>
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="lastname">Password</label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        value={this.state.account.password}
                        onChange={this.onChange}
                        className={
                          this.state.errors['password']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['password']}
                      </div>
                    </div>
                  </div>
                  {this.state.errorMessage && (
                    <FormAlertMessage message={this.state.errorMessage} />
                  )}
                  <div className="row justify-content-center py-3">
                    <div className="col-md-6">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block bg-loader"
                      >
                        {this.state.loading ? (
                          <CircularProgress color="inherit" size={'1rem'} />
                        ) : (
                          'Sign in'
                        )}
                      </button>
                    </div>
                  </div>
                </form>

                <div className="d-flex flex-column justify-content-center mt-3">
                  <div className="mx-2 my-2 text-center">
                    <GoogleLoginButton
                      displayText={'Sign in with Google'}
                      {...this.props}
                    />
                  </div>

                  <div className="mx-2 my-2 text-center">
                    <FacebookLoginButton
                      displayText={'Sign in with Facebook'}
                      {...this.props}
                    />
                  </div>
                </div>
              </div>
              <Link
                to="/forgot-password"
                className="text-right text-primary mr-3 mt-3 font-weight-bold"
              >
                Forgot password?
              </Link>

              <div className="d-block d-md-none">
                <p className="text-center my-5">
                  New to Compex Africa?{' '}
                  <Link className="text-primary font-weight-bold" to="/signup">
                    Sign up
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  hideNavbar,
  hideFooter,
  showFooter,
  showNavbar,
})(Login);
