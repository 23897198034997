import http from './httpService';
import baseApiUrl from '../../config';

const getAdminStartups = (pageNumber = 1) => {
  return http.get(`${baseApiUrl}startups?all=true&pageNumber=${pageNumber}`);
};

const searchAdminStartups = (
  startDate,
  endDate,
  searchQuery,
  status,
  byId = false,
  pageNumber = 1,
) => {
  try {
    startDate = startDate && startDate.toISOString();
    endDate = endDate && endDate.toISOString();
    if (startDate && endDate)
      return http.get(
        `${baseApiUrl}startups?all=true&startDate=${startDate}&endDate=${endDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else if (startDate)
      return http.get(
        `${baseApiUrl}startups?all=true&startDate=${startDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else if (endDate)
      return http.get(
        `${baseApiUrl}startups?all=true&endDate=${endDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else
      return http.get(
        `${baseApiUrl}startups?all=true&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
  } catch (error) {
    console.log(error);
  }
};

const approveStartup = async id => {
  const { status } = await http.post(`${baseApiUrl}startups/${id}/approve`);
  if (status === 200) return true;
};

const disapproveStartup = async id => {
  const { status } = await http.post(`${baseApiUrl}startups/${id}/disapprove`);
  if (status === 200) return true;
};

export default {
  getAdminStartups,
  disapproveStartup,
  approveStartup,
  searchAdminStartups,
};
