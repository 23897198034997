import {
  FETCH_ADMIN_STARTUPS,
  FETCH_ADMIN_STARTUPS_STARTED,
  FETCH_STARTUP_POSTS,
  FETCH_STARTUP_POSTS_STARTED,
  FETCH_POST_DETAILS,
  FETCH_POST_DETAILS_STARTED,
} from '../actions/types';

const initialState = {
  posts: [],
  post: {},
  loadingPosts: false,
  loadingPostDetail: false,
  postPageNumber: 1,
  postPageSize: 50,
  postTotalCount: 0,
  startupListings: [],
  loadingStartups: false,
  adminStartupListings: [],
  pageNumber: 1,
  pageSize: 50,
  totalCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_STARTUPS:
      return {
        ...state,
        adminStartupListings: action.payload.data,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount,
        loadingStartups: false,
      };
    case FETCH_ADMIN_STARTUPS_STARTED:
      return {
        ...state,
        loadingStartups: true,
      };
    case FETCH_STARTUP_POSTS:
      return {
        ...state,
        posts: action.payload.data,
        postPageNumber: action.payload.pageNumber,
        postPageSize: action.payload.pageSize,
        postTotalCount: action.payload.totalCount,
        loadingPosts: false,
      };
    case FETCH_STARTUP_POSTS_STARTED:
      return {
        ...state,
        loadingPosts: true,
      };
    case FETCH_POST_DETAILS:
      return {
        ...state,
        post: action.payload,
        loadingPostDetail: false,
      };
    case FETCH_POST_DETAILS_STARTED:
      return {
        ...state,
        loadingPostDetail: true,
      };
    default:
      return state;
  }
};
