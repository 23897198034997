import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchInvestorListings } from '../../state/actions/investor';
import AdvisorInvestorListingItem from '../../common/components/AdvisorInvestorListingItem';
import AdvisorInvestorPlaceholder from '../../common/components/AdvisorInvestorPlaceholder';
import FadeIn from 'react-fade-in';
import Pagination from '../../common/components/Pagination';
import randomstring from 'randomstring';
import { setHeaders, resetHeaders } from './../../state/actions/layout';

class InvestorListings extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, interests: '' };
  }

  componentDidMount() {
    const { pathname } = this.props.location;

    if (pathname === '/lenders') {
      this.setState({ interests: 'lending' });
      this.props.fetchInvestorListings('lending');
    } else {
      this.props.fetchInvestorListings();
    }

    this.props.setHeaders({
      title: 'Investors - Business buyers and investors | CompexAfrica',
      url: this.props.history.location.pathname,
      description:
        'Investors in Nigeria, Africa and Overseas: Invest in a business, get Loans, reachout and contact Investors in Nigeria, Africa and Overseas',
      keywords:
        'Invest in a Verified Business in Nigeria, Africa and overseas, Get Loans, Connect with Verified Lender',
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const { pathname } = this.props.location;

      if (pathname === '/lenders') {
        this.setState({ interests: 'lending' });
        this.props.fetchInvestorListings('lending');
      } else {
        this.props.fetchInvestorListings();
      }
    }

    if (this.props.investorListings !== prevProps.investorListings) {
      this.setState({ ...this.state, loading: false });
    }
  }

  componentWillUnmount() {
    this.props.resetHeaders();
  }

  onPageChange = page => {
    if (typeof page === 'number') {
      this.setState({ loading: true });
      this.props.fetchInvestorListings(this.state.interests, page);
    }
  };

  onPreviousPage = () => {
    this.setState({ loading: true });
    this.props.fetchInvestorListings(
      this.state.interests,
      this.props.pageNumber - 1,
    );
  };

  onNextPage = () => {
    this.setState({ loading: true });
    this.props.fetchInvestorListings(
      this.state.interests,
      this.props.pageNumber + 1,
    );
  };

  render() {
    const { investorListings, pageNumber, pageSize, totalCount } = this.props;

    let placeholders = [];
    for (let i = 0; i < 9; i++) {
      placeholders.push(
        <FadeIn key={randomstring.generate()}>
          <AdvisorInvestorPlaceholder />
        </FadeIn>,
      );
    }

    return (
      <div className="container max-w-rem-sm-42 max-w-rem-lg-60 max-w-rem-xxl-80 max-w-rem-5xl-100">
        <div className="d-flex flex-wrap justify-content-between mb-5">
          <p className="h3 font-weight-bold">Business buyers and investors</p>
          <div className="input-group mb-3 w-30 d-none">
            <div className="input-group-prepend">
              <button className="btn btn-outline-secondary" type="button">
                Sort by
              </button>
            </div>
            <select className="custom-select" id="inputGroupSelect03">
              <option defaultValue>Choose...</option>
              <option value="mostViewed">Most viewed</option>
            </select>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center justify-content-sm-start">
          {this.state.loading
            ? placeholders
            : investorListings.map(investor => (
                <FadeIn key={randomstring.generate()}>
                  <AdvisorInvestorListingItem
                    listing={investor}
                    currentUser={this.props.currentUser}
                    className="fixed-width-height"
                    whichListingType="investor"
                  ></AdvisorInvestorListingItem>
                </FadeIn>
              ))}
        </div>
        {investorListings.length > 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={pageNumber}
              pageSize={pageSize}
              totalItem={totalCount}
              onPageChange={this.onPageChange}
              onPreviousPage={this.onPreviousPage}
              onNextPage={this.onNextPage}
            />
          </div>
        )}
        {!this.state.loading && investorListings.length === 0 && (
          <FadeIn>
            <div className="text-center py-5">
              <p className="text-danger">No Result Found</p>
            </div>
          </FadeIn>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    investorListings: state.investor.investorListings,
    pageNumber: state.investor.pageNumber,
    pageSize: state.investor.pageSize,
    totalCount: state.investor.totalCount,
    currentUser: state.user.currentUser,
  }),
  { fetchInvestorListings, setHeaders, resetHeaders },
)(InvestorListings);
