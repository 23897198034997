import React, { Component } from 'react';
import { Slider } from '@material-ui/core';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import SearchForm from './SearchForm';

class FilterForm extends Component {
  state = {
    country: '',
    region: '',
    sector: '',
  };

  onChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value }, function() {
      this.props.onFilter(this.state);
    });
  };

  selectCountry(val) {
    this.setState({ country: val }, function() {
      this.props.onFilter(this.state);
    });
  }

  selectRegion(val) {
    this.setState({ region: val }, function() {
      this.props.onFilter(this.state);
    });
  }

  render() {
    const { removeFilter, onSearch, searchListings } = this.props;
    return (
      <>
        <div className="d-none d-lg-block d-xl-block">
          <div className="bg-nav d-flex flex-wrap justify-content-around w-100 align-items-center my-2">
            <div className="w-100 col-lg-3 d-none">
              <Slider />
            </div>
            <div className="form-group my-2 col-4 col-lg-2 px-0">
              <select
                onChange={this.onChange}
                value={this.state.sector}
                name="sector"
                className="form-control border-0 shadow-sm font-weight-bold"
                id="sectorSelectDesktop"
              >
                <option value="">Sector</option>
                <option value="Agribusiness">Agribusiness</option>
                <option value="Banking">Banking</option>
                <option value="E-commerce">E-commerce</option>
                <option value="Education">Education</option>
                <option value="Financial Services">Financial Services</option>
                <option value="Food  and Beverage">Food and Beverage</option>
                <option value="Franchise Releases">Franchise Releases</option>
                <option value="Health services">Health services</option>
                <option value="Hospitality - Hotel">Hospitality - Hotel</option>
                <option value="Import and Export">Import and Export</option>
                <option value="Insurance">Insurance</option>
                <option value="Internet">Internet</option>
                <option value="Leisure">Leisure</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Oil and Gas">Oil and Gas</option>
                <option value="Pharmaceuticals">Pharmaceuticals</option>
                <option value="Real Estate">Real Estate</option>
                <option value="Restaurant and Bar">Restaurant and Bar</option>
                <option value="Retail">Retail</option>
                <option value="Services">Services</option>
                <option value="Technology and Media">
                  Technology and Media
                </option>
                <option value="Waste Recycling">Waste Recycling</option>
                <option value="Wholesale and Distribution">
                  Wholesale and Distribution
                </option>
              </select>
            </div>

            <div className="form-group my-2 col-4 col-lg-2 px-0">
              <CountryDropdown
                classes="form-control border-0 shadow-sm font-weight-bold"
                defaultOptionLabel="country"
                value={this.state.country}
                onChange={val => this.selectCountry(val)}
              />
            </div>

            <div className="form-group my-2 col-4 col-lg-2 px-0">
              <RegionDropdown
                classes="form-control border-0 shadow-sm font-weight-bold"
                country={this.state.country}
                defaultOptionLabel="city"
                value={this.state.region}
                onChange={val => this.selectRegion(val)}
              />
            </div>
            <div className="d-none d-lg-block d-xl-block align-self-stretch border border-primary"></div>
            <button
              onClick={removeFilter}
              className="btn btn-primary mb-1 d-none d-lg-block d-xl-block"
            >
              Close filter &nbsp; <i className="fas fa-times text-white"></i>
            </button>
            <button className="btn btn-outline-primary mb-1 d-none">
              Request a business
            </button>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-between d-lg-none d-xl-none">
          <div className="my-4">
            <SearchForm
              onSearch={onSearch}
              searchListings={searchListings}
            ></SearchForm>
          </div>
          <div className="w-100 mb-4 d-none">
            <Slider />
          </div>
          <div className="form-group mb-5 px-0">
            <select
              onChange={this.onChange}
              value={this.state.sector}
              name="sector"
              className="form-control border-0 shadow-sm font-weight-bold"
              id="sectorSelectMobile"
            >
              <option value="">Sector</option>
              <option value="Agribusiness">Agribusiness</option>
              <option value="Banking">Banking</option>
              <option value="E-commerce">E-commerce</option>
              <option value="Education">Education</option>
              <option value="Financial Services">Financial Services</option>
              <option value="Food  and Beverage">Food and Beverage</option>
              <option value="Franchise Releases">Franchise Releases</option>
              <option value="Health services">Health services</option>
              <option value="Hospitality - Hotel">Hospitality - Hotel</option>
              <option value="Import and Export">Import and Export</option>
              <option value="Insurance">Insurance</option>
              <option value="Internet">Internet</option>
              <option value="Leisure">Leisure</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="Oil and Gas">Oil and Gas</option>
              <option value="Pharmaceuticals">Pharmaceuticals</option>
              <option value="Real Estate">Real Estate</option>
              <option value="Restaurant and Bar">Restaurant and Bar</option>
              <option value="Retail">Retail</option>
              <option value="Services">Services</option>
              <option value="Technology and Media">Technology and Media</option>
              <option value="Waste Recycling">Waste Recycling</option>
              <option value="Wholesale and Distribution">
                Wholesale and Distribution
              </option>
            </select>
          </div>
          <div className="form-group mb-5 px-0">
            <CountryDropdown
              classes="form-control border-0 shadow-sm font-weight-bold"
              defaultOptionLabel="country"
              value={this.state.country}
              onChange={val => this.selectCountry(val)}
            />
          </div>

          <div className="form-group mb-5 px-0">
            <RegionDropdown
              classes="form-control border-0 shadow-sm font-weight-bold"
              country={this.state.country}
              defaultOptionLabel="city"
              value={this.state.region}
              onChange={val => this.selectRegion(val)}
            />
          </div>
        </div>
      </>
    );
  }
}

export default FilterForm;
