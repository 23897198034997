import React, { Component } from 'react';
import Joi from 'joi-browser';
import { connect } from 'react-redux';
import equal from 'fast-deep-equal';
import queryString from 'query-string';
import Swal from 'sweetalert2';
import advisorService from '../../common/services/advisorService';
import { editAdvisor, resetForm } from '../../state/actions/advisor';
import planTypes from '../../common/utils/planTypes';
import Select from 'react-select';
import withReactContent from 'sweetalert2-react-content';
import {
  CountryInput,
  RegionInput,
  RegionSelect,
} from '../../common/components/LocationInput';
import SubscriptionModal from '../../common/components/SubscriptionModal';
import subscriptionService from '../../common/services/subscriptionService';
import { setHeaders, resetHeaders } from './../../state/actions/layout';
import NumberFormat from 'react-number-format';
import { CircularProgress } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { scrollToRef } from '../../common/utils/helpers';
import AdvisorPlans from './AdvisorPlans';
import profileStatus from './../../common/utils/profileStatus';
import profileType from './../../common/utils/profileType';
import businessSectors from '../../common/utils/businessSectors';

class RegisterAdvisorForm extends Component {
  state = {
    formdata: {
      firstName: '',
      lastName: '',
      workEmail: '',
      phoneNumber: '',
      whoYouAre: '',
      industries: '',
      locations: '',
      investmentRangeFrom: '',
      investmentRangeTo: '',
      currentLocation: '',
      yourCompany: '',
      yourDesignation: '',
      companyWebsite: '',
      companySector: '',
      lookingFactors: '',
      aboutYourself: '',
      agreement: '',
      couponCode: '',
      currCountry: '',
      currRegion: '',
    },
    interests: [],
    logo: {},
    picture: {},
    proofDocsFiles: [],
    corporateDocs: [],
    errors: {},
    previousData: {},
    id: '',
    selectedPlan: '',
    industries: null,
    country: '',
    currCountry: '',
    currRegion: '',
    subscriptionPlans: [],
    isValidCode: '',
    isSaving: false,
    isSubmitting: false,
    hasSubscribed: false,
    isValidating: false,
    reApprovalWarning: {},
    reApprovalRequiredFields: [
      'yourCompany',
      'yourDesignation',
      'companyWebsite',
      'lookingFactors',
      'aboutYourself',
    ],
  };

  async componentDidMount() {
    const { id } = queryString.parse(this.props.location.search);
    // editing
    if (id) this.props.editAdvisor(id);

    this.autofillFormFields();

    const subscriptionPlans = await subscriptionService.getSubscriptionPlans();
    this.setState({ subscriptionPlans });

    this.props.setHeaders({
      title: 'Register advisor profile',
      url: this.props.history.location.pathname,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!equal(this.props.currentUser, prevProps.currentUser)) {
      this.autofillFormFields();
    }
    if (!equal(this.props.formdata, prevProps.formdata)) {
      this.autofillFormFields();
    }
    if (!equal(this.state, prevState)) {
      this.getMultiSelectValue();
    }
  }

  componentWillUnmount() {
    this.props.resetHeaders();
    this.props.resetForm();
  }

  multiSelectOptions = {
    industriesOptions: [
      { value: 'Agriculture', label: 'Agriculture' },
      {
        value: 'Construction/ Real Estate',
        label: 'Construction/ Real Estate',
      },
      { value: 'Consumer Goods', label: 'Consumer Goods' },
      { value: 'Financial Services', label: 'Financial Services' },
      { value: 'Healthcare', label: 'Healthcare' },
      { value: 'Industrial Goods', label: 'Industrial Goods' },
      {
        value: 'Information & Communications Technology',
        label: 'Information & Communications Technology',
      },
      { value: 'Natural Resources', label: 'Natural Resources' },
      { value: 'Oil & Gas', label: 'Oil & Gas' },
      { value: 'Services', label: 'Services' },
      { value: 'Utilities', label: 'Utilities' },
      { value: 'Conglomerates', label: 'Conglomerates' },
    ],
  };

  autofillFormFields() {
    const { formdata, currentUser } = this.props;

    if (formdata && formdata.id) {
      const form = { ...this.state.formdata };
      form.firstName = formdata.firstName;
      form.lastName = formdata.lastName;
      form.workEmail = formdata.workEmail;
      form.phoneNumber = formdata.phoneNumber || '';
      form.whoYouAre = formdata.whoYouAre;
      form.industries = formdata.industries;
      form.locations = formdata.locations;
      form.investmentRangeFrom = formdata.investmentFrom;
      form.investmentRangeTo = formdata.investmentTo;
      form.currentLocation = formdata.currentLocation;
      form.yourCompany = formdata.placeOfWork;
      form.yourDesignation = formdata.positionInCompany;
      form.companyWebsite = formdata.companyWebsite;
      form.companySector = formdata.companySector;
      form.lookingFactors = formdata.factorsLookingFor;
      form.aboutYourself = formdata.aboutYourself;
      form.agreement = 'true';
      this['agreement'].checked = true;

      const currCountry = form.currentLocation.split(', ')[1];
      const currRegion = form.currentLocation.split(', ')[0];
      form.currCountry = currCountry;
      form.currRegion = currRegion;

      if (formdata.interests && typeof formdata.interests === 'string') {
        const interests = formdata.interests.split(',').filter(x => x !== '');
        interests.forEach(interest => {
          this[interest + 'Checkbox'].checked = true;
        });
        form.interests = interests;
        this.setState({ interests: interests });
      }

      let logoServerFiles = formdata.files.filter(
        file => file.fileGroup === 'Logo',
      );

      let profilePictureServerFiles = formdata.files.filter(
        file => file.fileGroup === 'ProfilePicture',
      );
      let proofOfBusinessServerFiles = formdata.files.filter(
        file => file.fileGroup === 'ProofOfBusiness',
      );
      let corpDocumentServerFiles = formdata.files.filter(
        file => file.fileGroup === 'CorpDocument',
      );

      let logo =
        (logoServerFiles &&
          logoServerFiles.map(file => {
            return {
              name: file.name,
              type: file.type,
              size: file.size,
              base64Url: file.fileUrl,
            };
          }))[0] || {};

      let picture =
        (profilePictureServerFiles &&
          profilePictureServerFiles.map(file => {
            return {
              name: file.name,
              type: file.type,
              size: file.size,
              base64Url: file.fileUrl,
            };
          }))[0] || {};

      let proofDocsFiles = proofOfBusinessServerFiles.map(file => {
        return {
          name: file.name,
          type: file.type,
          size: file.size,
          base64Url: file.fileUrl,
        };
      });

      let corporateDocs = corpDocumentServerFiles.map(file => {
        return {
          name: file.name,
          type: file.type,
          size: file.size,
          base64Url: file.fileUrl,
        };
      });

      const { subscriptions } = formdata;
      let subscriptionPlan = '';
      let hasSubscribed = false;

      if (subscriptions && subscriptions.length > 0) {
        subscriptionPlan = subscriptions[0].planType;
        hasSubscribed = true;
      }

      this.setState({
        formdata: form,
        logo,
        picture,
        proofDocsFiles,
        corporateDocs,
        id: formdata.id,
        selectedPlan: subscriptionPlan,
        hasSubscribed,
        previousData: formdata,
        currCountry: currCountry,
        currRegion: currRegion,
      });
    } else if (currentUser && currentUser.userId) {
      const form = { ...this.state.formdata };
      form.firstName = currentUser.firstname;
      form.lastName = currentUser.surname;
      form.workEmail = currentUser.email;
      form.phoneNumber = currentUser.phoneNumber || '';
      this.setState({ formdata: form, selectedPlan: this.props.selectedPlan });
    }
  }

  schema = {
    firstName: Joi.string()
      .required()
      .label('First Name'),
    lastName: Joi.string()
      .required()
      .label('Last Name'),
    workEmail: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .label('Work Email'),
    phoneNumber: Joi.string()
      .required()
      .min(3)
      .label('Phone Number'),
    interests: Joi.array()
      .required()
      .items(Joi.string().required())
      .label('Interests'),
    whoYouAre: Joi.string()
      .required()
      .label('Who Am I'),
    industries: Joi.string()
      .required()
      .label('Industries'),
    locations: Joi.string()
      .required()
      .label('Locations'),
    country: Joi.string()
      .optional()
      .label('Country'),
    region: Joi.string()
      .optional()
      .label('Country'),
    investmentRangeFrom: Joi.number()
      .integer()
      .required()
      .label('Investment Range From'),
    investmentRangeTo: Joi.number()
      .integer()
      .required()
      .label('Investment Range To'),
    currentLocation: Joi.string()
      .required()
      .label('Current Location'),
    yourCompany: Joi.string()
      .required()
      .label('Your Company'),
    yourDesignation: Joi.string()
      .required()
      .label('Your Designation'),
    companyWebsite: Joi.string()
      .required()
      .label('Company Website'),
    companySector: Joi.string()
      .required()
      .label('Company Sector'),
    lookingFactors: Joi.string()
      .required()
      .label('Looking Factors'),
    aboutYourself: Joi.string()
      .required()
      .label('About Yourself'),
    agreement: Joi.string()
      .required()
      .valid('true')
      .label('Agreement'),
    couponCode: Joi.optional(),
    currCountry: Joi.string()
      .required()
      .label('Country'),
    currRegion: Joi.string()
      .required()
      .label('Region'),
  };

  isValidData() {
    const { error } = Joi.validate(this.state.formdata, this.schema, {
      abortEarly: false,
    });
    if (!error) return true;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    this.setState({ errors: errors || {} });

    if (errors) {
      const firstError = this[Object.keys(errors)[0]];
      scrollToRef(firstError);
      firstError.focus({ preventScroll: true });
      return false;
    } else {
      return true;
    }
  }

  onChange = (e, isFormatted = false) => {
    const { name, value } = e.target;
    const formdata = { ...this.state.formdata };
    formdata[name] = isFormatted
      ? parseInt(value.replace(/,/g, ''), 10)
      : value;
    const errors = { ...this.state.errors };
    errors[name] = '';

    // reset selectedPlan if coupon code is changing
    let selectedPlan = this.state.selectedPlan;
    if (formdata[name] === 'couponCode') selectedPlan = '';

    this.setState({ formdata, selectedPlan, errors });
  };

  onFocusTextFields = e => {
    const { previousData: advisor, reApprovalRequiredFields } = this.state;
    if (advisor.isApproved) {
      const { name } = e.target;
      if (reApprovalRequiredFields.some(x => x === name)) {
        const { reApprovalWarning: oldData } = this.state;
        const reApprovalWarning = { ...oldData };
        reApprovalWarning[name] =
          'Editing this field will require Admin reapproval';
        this.setState({ reApprovalWarning });
      }
    }
  };

  onBlurTextFields = e => {
    const { previousData: advisor, reApprovalRequiredFields } = this.state;
    if (advisor.isApproved) {
      const { name, value } = e.target;
      if (reApprovalRequiredFields.some(x => x === name)) {
        if (
          value === advisor[name] ||
          value === advisor['placeOfWork'] ||
          value === advisor['positionInCompany'] ||
          value === advisor['factorsLookingFor']
        ) {
          const { reApprovalWarning: oldData } = this.state;
          const reApprovalWarning = { ...oldData };
          reApprovalWarning[name] = '';
          this.setState({ reApprovalWarning });
        }
      }
    }
  };

  onAgreementChange = e => {
    const { name } = e.target;
    const formdata = { ...this.state.formdata };
    formdata[name] = e.target.checked ? 'true' : 'false';
    const errors = { ...this.state.errors };
    errors[name] = '';
    this.setState({ formdata, errors });
  };

  onPhoneChange = phone => {
    const formdata = { ...this.state.formdata };
    formdata['phoneNumber'] = phone;
    const errors = { ...this.state.errors };
    errors['phoneNumber'] = '';
    this.setState({ formdata, errors });
  };

  validateCouponCode = async e => {
    const { couponCode } = this.state.formdata;

    if (couponCode) {
      // validate coupon code
      this.setState({ isValidating: true });
      const res = await subscriptionService.validateCouponCode(
        couponCode,
        'Advisor',
      );
      if (res) {
        this.setState({
          selectedPlan: planTypes.coupon,
          isValidCode: true,
          isValidating: false,
        });
      } else {
        this.setState({
          selectedPlan: '',
          isValidCode: false,
          isValidating: false,
        });
      }
    }
  };

  getMultiSelectValue = () => {
    const fields = ['industries'];
    const state = { ...this.state };
    fields.forEach(field => {
      let options = [];
      const value = this.state.formdata[field].split(',');
      if (value[0] === '') {
        return;
      }
      value.forEach(option => {
        options.push({ value: option.trim(), label: option.trim() });
      });
      state[field] = options;
    });
    this.setState(state);
  };

  onMultiSelect = (selectedOptions, name, maxOptions = 1000) => {
    const state = { ...this.state };
    state[name] = selectedOptions;
    this.setState(state);

    //Dynamically limit selectable options
    if (selectedOptions && selectedOptions.length >= maxOptions) {
      this.multiSelectOptions[name + 'Options'].forEach(option => {
        option['disabled'] = 'yes';
      });
    } else {
      this.multiSelectOptions[name + 'Options'].forEach(option => {
        option['disabled'] = 'no';
      });
    }

    //Stringify selected options
    let value = '';
    if (selectedOptions) {
      selectedOptions.forEach((option, index) => {
        value =
          index === 0 ? value + option.value : value + ', ' + option.value;
      });
    }

    //Update state
    const formdata = { ...this.state.formdata };
    formdata[name] = value;
    const errors = { ...this.state.errors };
    errors[name] = '';
    this.setState({ formdata, errors });
  };

  selectCountry(val, clearRegion = false) {
    const formdata = { ...this.state.formdata };
    formdata.country = val;
    this.setState({ country: val, formdata });
    if (clearRegion) {
      formdata.country = '';
      this.setState({ formdata, country: '', region: '' });
    }
  }

  selectRegion(val) {
    const formdata = { ...this.state.formdata };
    formdata['locations'] =
      val === ''
        ? formdata.locations
        : formdata.locations === ''
        ? val
        : formdata.locations + ', ' + val;
    formdata.region = val;
    this.setState({ formdata, region: val });
  }

  clearLocations(e) {
    e.preventDefault();
    const formdata = { ...this.state.formdata };
    formdata['locations'] = '';
    this.setState({ formdata });
  }

  selectCurrCountry(val, clearRegion = false) {
    const formdata = { ...this.state.formdata };
    formdata.currCountry = val;
    this.setState({ currCountry: val, formdata });
    if (clearRegion) {
      formdata['currentLocation'] = '';
      formdata.currCountry = '';
      this.setState({ formdata, currCountry: '', currRegion: '' });
    }
  }

  selectCurrRegion(val) {
    const formdata = { ...this.state.formdata };
    formdata['currentLocation'] = val + ', ' + this.state.currCountry;
    formdata.currRegion = val;
    this.setState({ formdata, currRegion: val });
  }

  handleInterestSelect = e => {
    const { checked, value } = e.target;
    const interests = [...this.state.interests];
    const formdata = { ...this.state.formdata };
    if (checked) {
      interests.push(value);
      formdata.interests = interests;
      this.setState({ interests, formdata });
    } else {
      const filtered = interests.filter(x => x !== value);
      formdata.interests = filtered;
      this.setState({ interests: filtered, formdata });
    }
  };

  onHandleLogoUpload = async e => {
    const file = e.target.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      Swal.fire('Error', 'Only Jpeg and Png images are allowed', 'error');
    } else {
      if (file && file.size <= 2000000) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = () => {};
        reader.onloadend = () => {
          const base64Url = reader.result;
          if (base64Url) {
            const newFile = {
              name: file.name,
              type: file.type,
              size: file.size,
              base64Url: reader.result,
            };
            this.setState({ logo: newFile });
          }
        };
      } else {
        Swal.fire('Error', 'File is too large', 'error');
      }
    }
  };

  onHandlePictureUpload = async e => {
    const file = e.target.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      Swal.fire('Error', 'Only Jpeg and Png images are allowed', 'error');
    } else {
      if (file && file.size <= 2000000) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = () => {};
        reader.onloadend = () => {
          const base64Url = reader.result;
          if (base64Url) {
            const newFile = {
              name: file.name,
              type: file.type,
              size: file.size,
              base64Url: reader.result,
            };
            this.setState({ picture: newFile });
          }
        };
      } else {
        Swal.fire('Error', 'File is too large', 'error');
      }
    }
  };

  onHandleProofDocsUpload = async e => {
    const file = e.target.files[0];
    if (file && file.size <= 2000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadstart = () => {};
      reader.onloadend = () => {
        const base64Url = reader.result;
        if (base64Url) {
          const newFile = {
            name: file.name,
            type: file.type,
            size: file.size,
            base64Url: reader.result,
          };
          const proofDocsFiles = [...this.state.proofDocsFiles];
          proofDocsFiles.push(newFile);
          this.setState({ proofDocsFiles });
        }
      };
    } else {
      Swal.fire('Error', 'File is too large', 'error');
    }
  };

  onHandleCorporateDocsUpload = async e => {
    const file = e.target.files[0];
    if (file && file.size <= 2000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadstart = () => {};
      reader.onloadend = () => {
        const base64Url = reader.result;
        if (base64Url) {
          const newFile = {
            name: file.name,
            type: file.type,
            size: file.size,
            base64Url: reader.result,
          };
          const corporateDocs = [...this.state.corporateDocs];
          corporateDocs.push(newFile);
          this.setState({ corporateDocs });
        }
      };
    } else {
      Swal.fire('Error', 'File is too large', 'error');
    }
  };

  onDeleteProofDocsFile = file => {
    const files = [...this.state.proofDocsFiles];
    const filteredFiles = files.filter(x => x.name !== file.name);
    this.setState({ proofDocsFiles: filteredFiles });
  };

  onDeleteCorporateDocsFile = file => {
    const files = [...this.state.corporateDocs];
    const filteredFiles = files.filter(x => x.name !== file.name);
    this.setState({ corporateDocs: filteredFiles });
  };

  MySwal = withReactContent(Swal);

  promptPayment = (plan, advisorId, workEmail, isUpgrading = false) => {
    const plans = [...this.state.subscriptionPlans];
    const selectedPlan = plans.find(x => x.planType === plan);

    const { email, phoneNumber } = this.props.currentUser;
    const successMessage = isUpgrading
      ? 'Subscription was successful and your changes was saved'
      : email !== workEmail
      ? `A verification link has been sent to ${workEmail}. You must confirm your email before your listing will be approved.`
      : 'Your request has been sent for approval';

    if (email && selectedPlan && advisorId) {
      this.MySwal.fire({
        customClass: 'bg-transparent w-lg-30 w-md-30 w-90',
        showConfirmButton: false,
        html: (
          <SubscriptionModal
            planId={selectedPlan.planId}
            amount={selectedPlan.amount}
            userEmail={email}
            userPhone={phoneNumber}
            listingId={advisorId}
            listingType={profileType.Advisor}
            redirectTo={`/user/advisors/${advisorId}`}
            successMessage={successMessage}
            history={this.props.history}
          ></SubscriptionModal>
        ),
      });
    }
  };

  onSelectPlan = plan => {
    if (
      this.state.previousData.status &&
      this.state.previousData.status !== profileStatus.Draft
    ) {
      this.upgradePlan(plan);
    } else {
      this.setState({ selectedPlan: plan });
    }
  };

  upgradePlan = async plan => {
    if (!this.isValidData()) return;

    const { id, formdata } = this.state;
    var dto = this.getDtoObject();

    await advisorService.addAdvisor(dto);

    this.promptPayment(plan, id, formdata.workEmail, true);
  };

  save = async e => {
    e.preventDefault();

    var dto = this.getDtoObject();

    if (dto) {
      try {
        this.setState({ isSaving: true });
        const res = await advisorService.addAdvisor(dto);
        if (res && res.id) {
          this.setState({ isSaving: false });
          Swal.fire('Success', 'Changes saved successfully', 'success').then(
            () => {
              this.props.history.replace(`/user/advisors/${res.id}`);
            },
          );
        } else {
          this.setState({ isSaving: false });
          Swal.fire('Error', 'Unable to save changes', 'error');
        }
      } catch (error) {
        this.setState({ isSaving: false });
      }
    }
  };

  getDtoObject() {
    const { formdata, previousData } = this.state;

    previousData.firstName = formdata.firstName;
    previousData.lastName = formdata.lastName;
    previousData.workEmail = formdata.workEmail;
    previousData.phoneNumber = formdata.phoneNumber;
    previousData.whoYouAre = formdata.whoYouAre;
    previousData.industries = formdata.industries;
    previousData.locations = formdata.locations;
    previousData.investmentRangeFrom = formdata.investmentRangeFrom || 0;
    previousData.investmentRangeTo = formdata.investmentRangeTo || 0;
    previousData.currentLocation = formdata.currentLocation;
    previousData.yourCompany = formdata.yourCompany;
    previousData.yourDesignation = formdata.yourDesignation;
    previousData.companyWebsite = formdata.companyWebsite;
    previousData.companySector = formdata.companySector;
    previousData.lookingFactors = formdata.lookingFactors;
    previousData.aboutYourself = formdata.aboutYourself;
    previousData.couponCode = formdata.couponCode;

    previousData.interests = this.state.interests;
    previousData.logo = this.state.logo;
    previousData.picture = this.state.picture;
    previousData.proofDocsFiles = this.state.proofDocsFiles;
    previousData.corporateDocs = this.state.corporateDocs;
    previousData.subscriptionPlan = this.state.selectedPlan || 0;

    let fileGroupCode = [];

    const logo = 1,
      profilePicture = 2,
      proofOfBusiness = 3,
      corpDocument = 4;

    fileGroupCode.push(logo);
    fileGroupCode.push(profilePicture);
    fileGroupCode.push(proofOfBusiness);
    fileGroupCode.push(corpDocument);

    previousData.fileGroupCode = fileGroupCode;

    return previousData;
  }

  onSubmit = async e => {
    e.preventDefault();

    if (!this.isValidData()) return;

    const previousData = this.getDtoObject();
    const { selectedPlan, hasSubscribed } = this.state;

    if (!previousData) return;

    if (!selectedPlan) {
      Swal.fire(
        'Error',
        'You must select a plan or enter a valid subscription code before submitting',
        'error',
      );
      return;
    }

    const workEmail = previousData.workEmail.trim().toLowerCase();

    try {
      this.setState({ isSubmitting: true });

      const res = await advisorService.addAdvisor(previousData);

      if (res && hasSubscribed) {
        this.setState({ isSubmitting: false });
        const message = previousData.isApproved
          ? 'Your changes has been submitted and may require Admin approval'
          : 'Your changes has been submitted successfully';

        Swal.fire('Changes submitted successfully', message, 'success').then(
          () => {
            this.props.history.replace(`user/advisors/${res.id}`);
          },
        );
      } else if (
        res &&
        (selectedPlan === planTypes.activeAdvisor ||
          selectedPlan === planTypes.premiumAdvisor ||
          selectedPlan === planTypes.professionalAdvisor)
      ) {
        this.setState({ isSubmitting: false });
        previousData.id = res.id;
        // redirect to payment platform
        this.promptPayment(selectedPlan, res.id, workEmail);
      } else if (
        res &&
        (selectedPlan === planTypes.basicAdvisor ||
          selectedPlan === planTypes.coupon)
      ) {
        this.setState({ isSubmitting: false });
        previousData.id = res.id;
        const message =
          this.props.currentUser.email !== workEmail
            ? `A verification link has been sent to ${previousData.workEmail}. You must confirm your email before your listing will be approved.`
            : 'Your request has been sent for approval';

        Swal.fire(
          'Advisor profile registered successfully',
          message,
          'success',
        ).then(() => {
          this.props.history.replace(`user/advisors/${res.id}`);
        });
      } else {
        this.setState({ isSubmitting: false });
        Swal.fire('An error occured', '', 'error');
      }
    } catch (error) {
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    const { hasSubscribed, previousData } = this.state;
    var sectors = [];
    for (let [key, obj] of Object.entries(businessSectors)) {
      sectors.push(
        <option key={key} value={obj.value === 'Sector' ? '' : obj.value}>
          {obj.value}
        </option>,
      );
    }

    return (
      <div className="container max-w-rem-100">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="d-flex justify-content-center flex-column text-center mt-3 mb-5">
              <h3 className="text-primary font-weight-bold">
                Register as an advisor
              </h3>
              <p className="text-muted small">
                Connect with businesses either on the sell or buy side of a
                transaction
              </p>
            </div>
            <form
              onSubmit={this.onSubmit}
              method="POST"
              noValidate
              name="register-advisor-form"
            >
              <div className="bg-white shadow rounded-2 py-5 px-3">
                <div className="fieldset border-top border-bottom">
                  <div className="form-row py-2">
                    <div className="col-12 px-md-4 mb-3 pb-2 border-bottom">
                      <h5>Confidential Information</h5>
                      <div className="text-sm text-muted">
                        Information marked{' '}
                        <sup className="font-italic text-danger">*private</sup>{' '}
                        will not be displayed publicly
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="firstName">
                        First Name{' '}
                        <sup className="font-italic text-danger text-small">
                          *private
                        </sup>
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        onChange={this.onChange}
                        value={this.state.formdata.firstName}
                        className={
                          this.state.errors['firstName']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={input => {
                          this.firstName = input;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['firstName']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="lastName">
                        Last Name{' '}
                        <sup className="font-italic text-danger text-small">
                          *private
                        </sup>
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        onChange={this.onChange}
                        value={this.state.formdata.lastName}
                        className={
                          this.state.errors['lastName']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={input => {
                          this.lastName = input;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['lastName']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="workEmail">
                        Work Email{' '}
                        <sup className="font-italic text-danger text-small">
                          *private
                        </sup>{' '}
                        <span className="text-sm text-italic text-muted pl-2">
                          (Improve your rating)
                        </span>
                      </label>
                      <input
                        type="email"
                        id="workEmail"
                        name="workEmail"
                        onChange={this.onChange}
                        value={this.state.formdata.workEmail}
                        className={
                          this.state.errors['workEmail']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={input => {
                          this.workEmail = input;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['workEmail']}
                      </div>
                    </div>

                    <div
                      className="form-group col-md-6 px-md-4 mb-3"
                      ref={input => {
                        this.phoneNumber = input;
                      }}
                    >
                      <label htmlFor="phoneNumber">
                        Phone Number{' '}
                        <sup className="font-italic text-danger text-small">
                          *private
                        </sup>
                        <span className="text-sm text-muted pl-2">
                          (For faster verification)
                        </span>
                      </label>
                      <PhoneInput
                        country={'ng'}
                        value={this.state.formdata.phoneNumber}
                        onChange={this.onPhoneChange}
                        inputClass={
                          this.state.errors['phoneNumber']
                            ? 'form-control w-100 is-invalid'
                            : 'form-control w-100'
                        }
                      />
                      <div
                        className={
                          this.state.errors['phoneNumber'] ? 'is-invalid' : ''
                        }
                      ></div>
                      <div className="invalid-feedback">
                        {this.state.errors['phoneNumber']}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fieldset border-top border-bottom">
                  <div className="form-row py-2">
                    <div className="col-12 px-md-4 mb-3 pb-2 border-bottom">
                      <h5>Your Interest</h5>
                      <div className="text-sm text-muted">
                        Information entered here will be publicly displayed to
                        match you with the right set of businesses. All{' '}
                        <span className="font-italic text-danger text-small">
                          * fields
                        </span>{' '}
                        are required.
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="interests">
                        What are you interested in?{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      <div
                        className={
                          this.state.errors['interests']
                            ? 'row small is-invalid'
                            : 'row small'
                        }
                        ref={formgroup => {
                          this.interests = formgroup;
                        }}
                      >
                        <div className="col-sm-6">
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="buying"
                              name="buying"
                              value="buying"
                              onChange={this.handleInterestSelect}
                              ref={checkbox => {
                                this.buyingCheckbox = checkbox;
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="buying"
                            >
                              Buying a business
                            </label>
                          </div>
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="lending"
                              name="lending"
                              value="lending"
                              onChange={this.handleInterestSelect}
                              ref={checkbox => {
                                this.lendingCheckbox = checkbox;
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="lending"
                            >
                              Lending to a business
                            </label>
                          </div>
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="buyingProperty"
                              name="buyingProperty"
                              value="buyingProperty"
                              onChange={this.handleInterestSelect}
                              ref={checkbox => {
                                this.buyingPropertyCheckbox = checkbox;
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="buyingProperty"
                            >
                              Buying Property, Plants or Machinery
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="investing"
                              name="investing"
                              value="investing"
                              onChange={this.handleInterestSelect}
                              ref={checkbox => {
                                this.investingCheckbox = checkbox;
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="investing"
                            >
                              Investing in a business
                            </label>
                          </div>
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="franchising"
                              name="franchising"
                              value="franchising"
                              onChange={this.handleInterestSelect}
                              ref={checkbox => {
                                this.franchisingCheckbox = checkbox;
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="franchising"
                            >
                              Taking up a Franchise, Distributorship or Sales
                              Agency
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="invalid-feedback">
                        {this.state.errors['interests']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="whoYouAre">
                        I am a(n){' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      <select
                        id="whoYouAre"
                        onChange={this.onChange}
                        value={this.state.formdata.whoYouAre}
                        name="whoYouAre"
                        className={
                          this.state.errors['whoYouAre']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={select => {
                          this.whoYouAre = select;
                        }}
                      >
                        <option value="">select</option>
                        <option value="Accounting firm">Accounting firm</option>
                        <option value="Business Broker">Business Broker</option>
                        <option value="Financial Consultant">
                          Financial Consultant
                        </option>
                        <option value="Investment Bank">Investment Bank</option>
                        <option value="Law Firm">Law Firm</option>
                        <option value="M&A Advisor">M&A Advisor</option>
                        <option value="Merchant Bank">Merchant Bank</option>
                        <option value="Commercial Real Estate Broker">
                          Commercial Real Estate Broker
                        </option>
                      </select>
                      <div className="invalid-feedback">
                        {this.state.errors['whoYouAre']}
                      </div>
                    </div>

                    <div
                      ref={select => {
                        this.industries = select;
                      }}
                      className="form-group col-md-6 px-md-4 mb-3"
                    >
                      <label htmlFor="industries">
                        Select the industries you are interested in{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      <Select
                        options={this.multiSelectOptions.industriesOptions}
                        isOptionDisabled={option => option.disabled === 'yes'}
                        isMulti
                        id="industries"
                        value={this.state.industries}
                        onChange={selectedOptions => {
                          this.onMultiSelect(selectedOptions, 'industries');
                        }}
                        className={
                          this.state.errors['industries'] ? 'is-invalid' : ''
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['industries']}
                      </div>
                    </div>

                    <div
                      className="form-group col-md-6 px-md-4 mb-3"
                      ref={elem => {
                        this.locations = elem;
                      }}
                    >
                      <label htmlFor="locations">
                        Select Locations you are interested in{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      <div
                        className={
                          this.state.errors['locations'] ||
                          this.state.errors.country ||
                          this.state.errors.region
                            ? 'row is-invalid'
                            : 'row'
                        }
                      >
                        <div
                          ref={elem => {
                            this.country = elem;
                          }}
                          className="col-sm-12"
                        >
                          <CountryInput
                            className={
                              this.state.errors['country']
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            value={this.state.country}
                            id={'advisorCountryInterest'}
                            onChange={this.selectCountry.bind(this)}
                          />
                        </div>
                        <div
                          ref={elem => {
                            this.region = elem;
                          }}
                          className="col-sm-12"
                        >
                          <RegionSelect
                            className={
                              this.state.errors['region']
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            country={this.state.country}
                            value={this.state.region}
                            onChange={this.selectRegion.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="invalid-feedback">
                        {this.state.errors['locations'] ||
                          this.state.errors.country ||
                          this.state.errors.region}
                      </div>
                      <div
                        className={
                          this.state.formdata.locations === ''
                            ? 'invisible'
                            : 'bg-primary text-white p-2'
                        }
                      >
                        <button
                          className="button-as-link d-block text-white text-right"
                          onClick={e => this.clearLocations(e)}
                        >
                          Clear my choices
                        </button>
                        {this.state.formdata.locations}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="investmentRange">
                        Kindly select your investment/deal size range (₦){' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      <div className="row">
                        <div className="col-sm-6">
                          <div
                            className="input-group mb-2"
                            ref={elem => {
                              this.investmentRangeFrom = elem;
                            }}
                          >
                            <div className="input-group-prepend">
                              <div className="input-group-text">From</div>
                            </div>
                            <NumberFormat
                              thousandSeparator={true}
                              type="text"
                              id="investmentRangeFrom"
                              name="investmentRangeFrom"
                              onChange={e => this.onChange(e, true)}
                              value={this.state.formdata.investmentRangeFrom}
                              className={
                                this.state.errors['investmentRangeFrom']
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                            <div className="invalid-feedback">
                              {this.state.errors['investmentRangeFrom']}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div
                            className="input-group mb-2"
                            ref={elem => {
                              this.investmentRangeTo = elem;
                            }}
                          >
                            <div className="input-group-prepend">
                              <div className="input-group-text">to</div>
                            </div>
                            <NumberFormat
                              thousandSeparator={true}
                              type="text"
                              id="investmentRangeTo"
                              name="investmentRangeTo"
                              onChange={e => this.onChange(e, true)}
                              value={this.state.formdata.investmentRangeTo}
                              className={
                                this.state.errors['investmentRangeTo']
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                            <div className="invalid-feedback">
                              {this.state.errors['investmentRangeTo']}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="form-group col-md-6 px-md-4 mb-3"
                      ref={elem => {
                        this.currentLocation = elem;
                      }}
                    >
                      <label htmlFor="currentLocation">
                        What is your current location?{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      <div
                        className={
                          this.state.errors['currentLocation'] ||
                          this.state.errors.currCountry ||
                          this.state.errors.currRegion
                            ? 'row is-invalid'
                            : 'row'
                        }
                      >
                        <div
                          className="col-sm-6"
                          ref={elem => {
                            this.currCountry = elem;
                          }}
                        >
                          <CountryInput
                            className={
                              this.state.errors['currCountry']
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            value={this.state.currCountry}
                            id={'advisorCurrentCountry'}
                            onChange={this.selectCurrCountry.bind(this)}
                          />
                        </div>
                        <div
                          className="col-sm-6"
                          ref={elem => {
                            this.currRegion = elem;
                          }}
                        >
                          <RegionInput
                            className={
                              this.state.errors['currRegion']
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            country={this.state.currCountry}
                            value={this.state.currRegion}
                            id={'advisorCurrentRegion'}
                            onChange={this.selectCurrRegion.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="invalid-feedback">
                        {this.state.errors['currentLocation'] ||
                          this.state.errors.currCountry ||
                          this.state.errors.currRegion}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="yourCompany">
                        Company you work at{' '}
                        <span className="text-danger text-small">*</span>
                        {this.state.reApprovalWarning['yourCompany'] && (
                          <div className="text-danger small">
                            <em>
                              Note:{' '}
                              {this.state.reApprovalWarning['yourCompany']}
                            </em>
                          </div>
                        )}
                      </label>
                      <input
                        type="text"
                        id="yourCompany"
                        name="yourCompany"
                        onChange={this.onChange}
                        onFocus={this.onFocusTextFields}
                        onBlur={this.onBlurTextFields}
                        value={this.state.formdata.yourCompany}
                        className={
                          this.state.errors['yourCompany']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.yourCompany = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['yourCompany']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="companyWebsite">
                        Company website or social media profile{' '}
                        <sup className="font-italic text-danger text-small">
                          *private
                        </sup>
                        {this.state.reApprovalWarning['companyWebsite'] && (
                          <div className="text-danger small">
                            <em>
                              Note:{' '}
                              {this.state.reApprovalWarning['companyWebsite']}
                            </em>
                          </div>
                        )}
                      </label>
                      <input
                        type="text"
                        id="companyWebsite"
                        name="companyWebsite"
                        onChange={this.onChange}
                        onFocus={this.onFocusTextFields}
                        onBlur={this.onBlurTextFields}
                        value={this.state.formdata.companyWebsite}
                        className={
                          this.state.errors['companyWebsite']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.companyWebsite = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['companyWebsite']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="yourDesignation">
                        Your position in the company{' '}
                        <span className="text-danger text-small">*</span>
                        {this.state.reApprovalWarning['yourDesignation'] && (
                          <div className="text-danger small">
                            <em>
                              Note:{' '}
                              {this.state.reApprovalWarning['yourDesignation']}
                            </em>
                          </div>
                        )}
                      </label>
                      <input
                        type="email"
                        id="yourDesignation"
                        name="yourDesignation"
                        onChange={this.onChange}
                        onFocus={this.onFocusTextFields}
                        onBlur={this.onBlurTextFields}
                        value={this.state.formdata.yourDesignation}
                        className={
                          this.state.errors['yourDesignation']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={input => {
                          this.yourDesignation = input;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['yourDesignation']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="lookingFactors">
                        Factors you look for in a business{' '}
                        <span className="text-danger text-small">*</span>
                        {this.state.reApprovalWarning['lookingFactors'] && (
                          <div className="text-danger small">
                            <em>
                              Note:{' '}
                              {this.state.reApprovalWarning['lookingFactors']}
                            </em>
                          </div>
                        )}
                      </label>
                      <textarea
                        id="lookingFactors"
                        name="lookingFactors"
                        onChange={this.onChange}
                        onFocus={this.onFocusTextFields}
                        onBlur={this.onBlurTextFields}
                        value={this.state.formdata.lookingFactors}
                        rows="3"
                        className={
                          this.state.errors['lookingFactors']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={textarea => {
                          this.lookingFactors = textarea;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['lookingFactors']}
                      </div>
                      <em className="text-sm-1 text-danger">
                        Note: Do not include your name or contact information
                        here.
                      </em>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="companySector">
                        Your company's sector{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      <select
                        type="text"
                        id="companySector"
                        name="companySector"
                        onChange={this.onChange}
                        value={this.state.formdata.companySector}
                        className={
                          this.state.errors['companySector']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={select => {
                          this.companySector = select;
                        }}
                      >
                        {sectors}
                      </select>
                      <div className="invalid-feedback">
                        {this.state.errors['companySector']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="aboutYourself">
                        About yourself{' '}
                        <span className="text-danger text-small">*</span>
                        {this.state.reApprovalWarning['aboutYourself'] && (
                          <div className="text-danger small">
                            <em>
                              Note:{' '}
                              {this.state.reApprovalWarning['aboutYourself']}
                            </em>
                          </div>
                        )}
                      </label>
                      <textarea
                        id="aboutYourself"
                        name="aboutYourself"
                        onChange={this.onChange}
                        onFocus={this.onFocusTextFields}
                        onBlur={this.onBlurTextFields}
                        value={this.state.formdata.aboutYourself}
                        rows="3"
                        className={
                          this.state.errors['aboutYourself']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={textarea => {
                          this.aboutYourself = textarea;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['aboutYourself']}
                      </div>
                      <em className="text-sm-1 text-danger">
                        Note: Do not include your name or contact information
                        here.
                      </em>
                    </div>

                    <div className="form-group col-md-6 px-md-4">
                      <h5>
                        Company logo{' '}
                        <span className="text-sm-1">(Max file size 2MB)</span>{' '}
                        <span className="text-muted small">optional</span>
                      </h5>
                      <label className="small" htmlFor="">
                        Attach company logo
                      </label>
                      <div>
                        <div className="upload-btn-wrapper">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-lg"
                          >
                            Upload logo
                          </button>
                          <input
                            id="advisorUploadLogo"
                            type="file"
                            name="file"
                            onChange={this.onHandleLogoUpload}
                          />
                        </div>
                      </div>
                      <div>
                        {this.state.logo.name && (
                          <div className="mb-2">
                            {this.state.logo.type === 'application/pdf' ? (
                              <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                            ) : (
                              <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                            )}
                            <span className="small pr-3">
                              <a
                                href={this.state.logo.base64Url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {this.state.logo.name}
                              </a>
                            </span>
                            <i
                              className="far fa-times-circle text-danger cursor-pointer"
                              onClick={() => this.setState({ logo: {} })}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4">
                      <h5>
                        Profile picture{' '}
                        <span className="text-sm-1">(Max file size 2MB)</span>{' '}
                        <span className="text-muted small">optional</span>
                      </h5>
                      <label className="small" htmlFor="">
                        Improve your rating by uploading a profile picture
                      </label>
                      <div>
                        <div className="upload-btn-wrapper">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-lg"
                          >
                            Upload picture
                          </button>
                          <input
                            id={'advisorUploadProfile'}
                            type="file"
                            name="file"
                            onChange={this.onHandlePictureUpload}
                          />
                        </div>
                      </div>
                      <div>
                        {this.state.picture.name && (
                          <div className="mb-2">
                            {this.state.picture.type === 'application/pdf' ? (
                              <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                            ) : (
                              <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                            )}
                            <span className="small pr-3">
                              <a
                                href={this.state.picture.base64Url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {this.state.picture.name}
                              </a>
                            </span>
                            <i
                              className="far fa-times-circle text-danger cursor-pointer"
                              onClick={() => this.setState({ picture: {} })}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4">
                      <h5>
                        Attach proof of business{' '}
                        <span className="text-sm-1">(Max file size 2MB)</span>{' '}
                        <span className="text-muted small">optional</span>
                      </h5>
                      <label className="small" htmlFor="">
                        You can attach government recognized documents such as
                        incorporation certificates, Tax certificates, etc.
                        Uploading this document enables our team review and
                        approve your application faster
                      </label>
                      <div>
                        <div className="upload-btn-wrapper">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-lg"
                          >
                            Upload picture
                          </button>
                          <input
                            id={'advisorUploadProof'}
                            type="file"
                            name="file"
                            onChange={this.onHandleProofDocsUpload}
                          />
                        </div>
                      </div>
                      <div>
                        {this.state.proofDocsFiles.map(file => (
                          <div className="mb-2" key={file.name}>
                            {file.type === 'application/pdf' ? (
                              <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                            ) : (
                              <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                            )}
                            <span className="small pr-3">
                              <a
                                href={file.base64Url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                            </span>
                            <i
                              className="far fa-times-circle text-danger cursor-pointer"
                              onClick={() => this.onDeleteProofDocsFile(file)}
                            ></i>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4">
                      <h5>
                        Corporate documents{' '}
                        <span className="text-sm-1">(Max file size 2MB)</span>{' '}
                        <span className="text-muted small">optional</span>
                      </h5>
                      <label className="small" htmlFor="">
                        Attach Corporate Profile and Terms of Engagement if any
                      </label>
                      <div>
                        <div className="upload-btn-wrapper">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-lg"
                          >
                            Upload docs
                          </button>
                          <input
                            id={'advisorUploadDocs'}
                            type="file"
                            name="file"
                            onChange={this.onHandleCorporateDocsUpload}
                          />
                        </div>
                      </div>
                      <div>
                        {this.state.corporateDocs.map(file => (
                          <div className="mb-2" key={file.name}>
                            {file.type === 'application/pdf' ? (
                              <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                            ) : (
                              <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                            )}
                            <span className="small pr-3">
                              <a
                                href={file.base64Url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                            </span>
                            <i
                              className="far fa-times-circle text-danger cursor-pointer"
                              onClick={() =>
                                this.onDeleteCorporateDocsFile(file)
                              }
                            ></i>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fieldset border-top border-bottom">
                  <div className="py-5 mt-5">
                    <h4 className="text-center text-muted font-weight-bold">
                      Choose a plan
                    </h4>
                  </div>
                  <AdvisorPlans
                    currentPlan={this.state.selectedPlan}
                    planChange={this.onSelectPlan}
                    isDraft={
                      previousData.status &&
                      previousData.status === profileStatus.Draft
                    }
                    isUpgrading={
                      previousData.status &&
                      previousData.status !== profileStatus.Draft
                    }
                    {...this.props}
                  />
                  <div className="mx-5 mb-5">
                    <p>Have a subscription code?</p>
                    <div className="form-row">
                      <div className="col-md-10 mb-3">
                        <input
                          type="text"
                          name="couponCode"
                          onChange={this.onChange}
                          value={this.state.formdata.couponCode}
                          id="couponCode"
                          className="form-control form-control-sm"
                          placeholder="Enter subscription code"
                        />
                      </div>
                      <div className="col-md-2">
                        <button
                          className="btn btn-sm btn-primary w-100"
                          type="button"
                          disabled={hasSubscribed}
                          onClick={
                            !hasSubscribed ? this.validateCouponCode : void 0
                          }
                        >
                          {this.state.isValidating ? (
                            <CircularProgress color="inherit" size={'1rem'} />
                          ) : (
                            'Validate'
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="py-2">
                      {this.state.isValidCode && (
                        <span>
                          <i className="fa fa-check-circle fa-2x text-success"></i>
                          <span className="px-3">Valid Code.</span>
                        </span>
                      )}
                      {this.state.isValidCode === false && (
                        <span>
                          <i className="fa fa-times-circle fa-2x text-danger"></i>
                          <span className="px-3">
                            Code not valid or not usable on this form.
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-12 my-3">
                  <div className="row">
                    <div className="col-1">
                      <div className="text-right">
                        <input
                          type="checkbox"
                          name="agreement"
                          onChange={this.onAgreementChange}
                          value="true"
                          ref={input => {
                            this.agreement = input;
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-11">
                      <p>
                        I agree to the terms and conditions of this platform,
                        including its privacy and refund policies.{' '}
                        <a
                          className="text-primary font-weight-bold"
                          href="/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View terms and conditions.&nbsp;
                        </a>
                        <a
                          className="text-primary font-weight-bold"
                          href="/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View privacy policy.&nbsp;
                        </a>
                        <a
                          className="text-primary font-weight-bold"
                          href="/refunds"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View refunds policy.
                        </a>
                      </p>
                      {this.state.errors['agreement'] && (
                        <small className="text-danger">
                          You must accept agreement before submitting
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center py-5">
                <div className="col-md-8 col-xl-5">
                  <button
                    type="button"
                    onClick={this.save}
                    className="btn btn-outline-primary text-primary bg-white btn-block btn-lg mb-3"
                    disabled={this.state.isSaving}
                  >
                    {this.state.isSaving ? (
                      <CircularProgress color="inherit" size={'1rem'} />
                    ) : (
                      'Save changes and exit'
                    )}
                  </button>
                </div>

                <div className="col-md-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block btn-lg"
                    disabled={this.state.isSubmitting}
                  >
                    {this.state.isSubmitting ? (
                      <CircularProgress color="inherit" size={'1rem'} />
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    formdata: state.advisor.formdata,
    currentUser: state.user.currentUser,
    selectedPlan: state.investor.selectedPlan,
  }),
  { editAdvisor, resetForm, setHeaders, resetHeaders },
)(RegisterAdvisorForm);
