import http from './httpService';
import baseApiUrl from '../../config';

const getBusinessListings = (query = { type: 'all' }, pageNumber = 1) => {
  const { type } = query;

  return http.get(
    `${baseApiUrl}businesses?type=${type}&pageNumber=${pageNumber}`,
  );
};

const getAdminListings = (pageNumber = 1) => {
  return http.get(`${baseApiUrl}businesses?all=true&pageNumber=${pageNumber}`);
};

const searchAdminListings = (
  startDate,
  endDate,
  searchQuery,
  status,
  byId = false,
  pageNumber = 1,
) => {
  try {
    startDate = startDate && startDate.toISOString();
    endDate = endDate && endDate.toISOString();
    if (startDate && endDate)
      return http.get(
        `${baseApiUrl}businesses?all=true&startDate=${startDate}&endDate=${endDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else if (startDate)
      return http.get(
        `${baseApiUrl}businesses?all=true&startDate=${startDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else if (endDate)
      return http.get(
        `${baseApiUrl}businesses?all=true&endDate=${endDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else
      return http.get(
        `${baseApiUrl}businesses?all=true&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
  } catch (error) {
    console.log(error);
  }
};

const getListingDetails = (id, edit) => {
  const queryString = edit ? '?edit=true' : '';
  return http.get(`${baseApiUrl}businesses/${id}${queryString}`);
};

const getFeaturedBusinesses = () => {
  return http.get(`${baseApiUrl}businesses?featured=true`);
};

const approveListing = async id => {
  const { status } = await http.post(`${baseApiUrl}businesses/${id}/approve`);
  if (status === 200) return true;
};

const disapproveListing = async id => {
  const { status } = await http.post(
    `${baseApiUrl}businesses/${id}/disapprove`,
  );
  if (status === 200) return true;
};

const featureListing = async id => {
  const { status } = await http.post(`${baseApiUrl}businesses/${id}/feature`);
  if (status === 200) return true;
};

const unfeatureListing = async id => {
  const { status } = await http.post(`${baseApiUrl}businesses/${id}/unfeature`);
  if (status === 200) return true;
};

const declineListing = async dto => {
  const { status } = await http.post(
    `${baseApiUrl}businesses/${dto.profileId}/decline`,
    {
      reason: dto.reason,
    },
  );
  if (status === 200) return true;
};

const requestListingChange = async dto => {
  const { status } = await http.post(
    `${baseApiUrl}businesses/${dto.profileId}/request-change`,
    {
      reason: dto.reason,
    },
  );
  if (status === 200) return true;
};

const deleteListing = async id => {
  try {
    const { status } = await http.post(`${baseApiUrl}businesses/${id}/delete`);
    if (status === 200) {
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

const register = async listingDto => {
  const { status, data } = await http.post(
    `${baseApiUrl}businesses/register`,
    listingDto,
  );

  if (status === 200) {
  }

  return data;
};

export default {
  getBusinessListings,
  getFeaturedBusinesses,
  getAdminListings,
  searchAdminListings,
  getListingDetails,
  approveListing,
  disapproveListing,
  featureListing,
  unfeatureListing,
  declineListing,
  requestListingChange,
  deleteListing,
  register,
};
