import startupService from '../../common/services/startupService';
import communityService from '../../common/services/communityService';
import {
  FETCH_ADMIN_STARTUPS,
  FETCH_ADMIN_STARTUPS_STARTED,
  FETCH_STARTUP_POSTS,
  FETCH_STARTUP_POSTS_STARTED,
  FETCH_POST_DETAILS,
  FETCH_POST_DETAILS_STARTED,
} from './types';

export const fetchAdminStartups = (pageNumber = 1) => dispatch => {
  dispatch({ type: FETCH_ADMIN_STARTUPS_STARTED });
  startupService.getAdminStartups(pageNumber).then(
    res => {
      dispatch({
        type: FETCH_ADMIN_STARTUPS,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_ADMIN_STARTUPS,
        payload: { data: [] },
      });
    },
  );
};

export const fetchPostDetails = id => dispatch => {
  dispatch({ type: FETCH_POST_DETAILS_STARTED });
  communityService.getPostDetails(id).then(
    res => {
      dispatch({
        type: FETCH_POST_DETAILS,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_POST_DETAILS,
        payload: {},
      });
    },
  );
};

export const fetchStartupPosts = (pageNumber = 1) => dispatch => {
  dispatch({ type: FETCH_STARTUP_POSTS_STARTED });
  communityService.getPosts(pageNumber).then(
    res => {
      dispatch({
        type: FETCH_STARTUP_POSTS,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_STARTUP_POSTS,
        payload: { data: [] },
      });
    },
  );
};

export const searchAdminStartups = (
  startDate,
  endDate,
  searchQuery,
  status,
  byId,
  pageNumber = 1,
) => async dispatch => {
  dispatch({ type: FETCH_ADMIN_STARTUPS_STARTED });
  await startupService
    .searchAdminStartups(
      startDate,
      endDate,
      searchQuery,
      status,
      byId,
      pageNumber,
    )
    .then(
      res => {
        dispatch({
          type: FETCH_ADMIN_STARTUPS,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: FETCH_ADMIN_STARTUPS,
          payload: { data: [] },
        });
      },
    );
};
