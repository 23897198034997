import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { searchListings } from '../../state/actions/common';
import tempAvatar from '../../assets/img/user.svg';
import auth from '../services/authService';
import { useHistory } from 'react-router-dom';
import FilterForm from './FilterForm';
import Notifications from './Notifications';
import ProposalNotification from './ProposalNotification';
import { startupZoneDomain } from '../../config';

const NavBarMobile = props => {
  const { isLoggedIn, currentUser } = props;
  const history = useHistory();

  const onSearch = data => {
    const pathName = window.location.pathname;
    let listingType = '';
    if (pathName.startsWith('/businesses')) listingType = 'business';
    if (pathName.startsWith('/investors')) listingType = 'investor';
    if (pathName.startsWith('/advisors')) listingType = 'advisor';
    if (pathName.startsWith('/franchises')) listingType = 'franchise';

    props.searchListings(listingType, data);
  };

  const logout = () => {
    auth.logOutUser();
    history.replace('/');
  };

  const switchNav = e => {
    const nav = document.getElementById(e.target.dataset.close);
    if (nav.classList.contains('show')) nav.classList.remove('show');
    const parent = document.getElementById('navParent');

    if (e.target.dataset.close === 'navbarMobileFilter') {
      parent.classList.add('bg-landing');
      parent.classList.remove('bg-body');
    } else {
      if (parent.classList.contains('bg-body')) {
        parent.classList.add('bg-landing');
        parent.classList.remove('bg-body');
      } else {
        parent.classList.add('bg-body');
        parent.classList.remove('bg-landing');
      }
    }
  };

  switchNav.bind(this);

  return (
    <div className="d-block d-xl-none d-lg-none navbar-plain">
      <nav
        className="navbar navbar-expand-lg navbar-light fixed-top pl-0 pt-3 bg-landing max-h-100 overflow-auto"
        id="navParent"
      >
        <Link to="/" className="navbar-brand position-relative">
          <img
            className="compex-logo d-lg-none d-xl-none ml-4"
            width="150"
            src={require('../../assets/img/compex-logo.svg')}
            alt=""
            data-toggle="collapse"
            data-target="#navbarMobileMenu"
          />
          <p
            className="m-0 position-absolute h10"
            style={{
              top: '43%',
              right: '-13%',
            }}
          >
            BETA
          </p>
        </Link>
        <div className="d-flex align-items-center">
          <button
            className="navbar-toggler border-0"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMobileFilter"
            aria-controls="navbarMobileFilter"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i
              className="fas fa-search"
              data-close="navbarMobileMenu"
              onClick={e => {
                switchNav(e);
              }}
              style={{ fontSize: '1.2rem' }}
            ></i>
          </button>
          <button
            className="navbar-toggler border-0"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMobileMenu"
            aria-controls="navbarMobileMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span
              className="navbar-toggler-icon"
              data-close="navbarMobileFilter"
              onClick={e => {
                switchNav(e);
              }}
              style={{ fontSize: '1.2rem' }}
            ></span>
          </button>
        </div>

        <div
          className="collapse navbar-collapse flex-wrap mt-lg-4 p-5"
          id="navbarMobileFilter"
        >
          <FilterForm
            onFilter={onSearch}
            onSearch={onSearch}
            searchListings={searchListings}
          ></FilterForm>
        </div>
        <div
          className="collapse navbar-collapse flex-wrap mt-lg-4 p-5"
          id="navbarMobileMenu"
        >
          <ul className="navbar-nav justify-content-between w-100 pr-lg-5 pt-3">
            {isLoggedIn ? (
              <div className="w-90 d-flex justify-content-between align-items-center mt-3 mb-4">
                <img
                  src={currentUser.photoUrl ? currentUser.photoUrl : tempAvatar}
                  alt="..."
                  className="img-fluid rounded-circle p-1"
                  style={{
                    minHeight: '3.2rem',
                    minWidth: '3.2rem',
                    maxHeight: '3.2rem',
                    maxWidth: '3.2rem',
                  }}
                ></img>
                <div className="pl-3 d-flex flex-column">
                  <p className="m- text-primary font-weight-bold m-0">
                    {currentUser.displayName}
                  </p>
                  <p className="m-0">{currentUser.email}</p>
                  <div data-toggle="collapse" data-target="#navbarMobileMenu">
                    <Link
                      to="/user/dashboard"
                      className="text-primary font-weight-bold"
                    >
                      My Dashboard
                    </Link>
                  </div>
                  <div className="d-none">
                    <ProposalNotification history={history} />
                    <Notifications history={history} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-between">
                <li
                  className="nav-item p-0 w-50"
                  data-toggle="collapse"
                  data-target="#navbarMobileMenu"
                >
                  <NavLink to="/login" className="nav-link text-primary">
                    Sign in
                  </NavLink>
                </li>
                <li
                  className="nav-item p-0 w-50"
                  data-toggle="collapse"
                  data-target="#navbarMobileMenu"
                >
                  <NavLink to="/signup" className="nav-link text-primary">
                    Sign up
                  </NavLink>
                </li>
              </div>
            )}
            <div className="dropdown-divider w-100 border-primary my-3"></div>
            {currentUser.isAdmin && (
              <>
                <p className="nav-item text-primary text-left font-weight-bold">
                  Admin
                </p>
                <div className="d-flex justify-content-between flex-wrap">
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/admin/dashboard"
                      className="nav-link text-primary"
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/admin/dashboard/users"
                      className="nav-link text-primary"
                    >
                      Users
                    </NavLink>
                  </li>
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/admin/dashboard/businesses"
                      className="nav-link text-primary"
                    >
                      Business listings
                    </NavLink>
                  </li>
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/admin/dashboard/investors"
                      className="nav-link text-primary"
                    >
                      Investor listings
                    </NavLink>
                  </li>
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/admin/dashboard/advisors"
                      className="nav-link text-primary"
                    >
                      Advisor listings
                    </NavLink>
                  </li>
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/admin/dashboard/franchises"
                      className="nav-link text-primary"
                    >
                      Franchise listings
                    </NavLink>
                  </li>
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/admin/dashboard/startups"
                      className="nav-link text-primary"
                    >
                      Startup listings
                    </NavLink>
                  </li>
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/admin/dashboard/community"
                      className="nav-link text-primary"
                    >
                      Community
                    </NavLink>
                  </li>
                  {currentUser && currentUser.isSuperAdmin && (
                    <li
                      data-toggle="collapse"
                      data-target="#navbarMobileMenu"
                      className="nav-item p-0 d-lg-none d-xl-none w-50"
                    >
                      <NavLink
                        to="/admin/dashboard/coupons"
                        className="nav-link text-primary"
                      >
                        Coupons
                      </NavLink>
                    </li>
                  )}
                </div>
                <div className="dropdown-divider w-100 border-primary my-3"></div>
              </>
            )}
            <div className="d-flex justify-content-between flex-wrap">
              <li
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
                className="nav-item p-0 d-lg-none d-xl-none w-50"
              >
                <NavLink
                  to="/businesses"
                  className="nav-link text-primary font-weight-bold"
                >
                  Businesses for sale
                </NavLink>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
                className="nav-item p-0 d-lg-none d-xl-none w-50"
              >
                <NavLink
                  to="/investors"
                  className="nav-link text-primary font-weight-bold"
                >
                  Investors
                </NavLink>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
                className="nav-item p-0 d-lg-none d-xl-none w-50"
              >
                <Link
                  className="nav-link text-primary font-weight-bold"
                  to={{
                    pathname: '/lenders',
                  }}
                >
                  Lenders
                </Link>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
                className="nav-item p-0 d-lg-none d-xl-none w-50"
              >
                <NavLink
                  to="/advisors"
                  className="nav-link text-primary font-weight-bold"
                >
                  Advisors
                </NavLink>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
                className="nav-item p-0 d-lg-none d-xl-none w-50"
              >
                <NavLink
                  to="/franchises"
                  className="nav-link text-primary font-weight-bold"
                >
                  Franchises
                </NavLink>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
                className="nav-item p-0 d-lg-none d-xl-none w-50"
              >
                <NavLink
                  to="/#pricing"
                  className="nav-link text-primary font-weight-bold"
                >
                  Pricing
                </NavLink>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
                className="nav-item p-0 d-lg-none d-xl-none w-50"
              >
                <a
                  href={`${startupZoneDomain}`}
                  className="nav-link text-primary font-weight-bold"
                >
                  Startup Zone
                </a>
              </li>
            </div>
            <div className="dropdown-divider w-100 border-primary my-3"></div>
            <div className="d-flex justify-content-between flex-wrap">
              <li
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
                className="nav-item p-0 d-lg-none d-xl-none w-50"
              >
                <NavLink
                  to="/register-business/plans"
                  className="nav-link text-primary"
                >
                  Register Businesses
                </NavLink>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
                className="nav-item p-0 d-lg-none d-xl-none w-50"
              >
                <NavLink
                  to="/register-investor/plans"
                  className="nav-link text-primary"
                >
                  Register Investors
                </NavLink>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
                className="nav-item p-0 d-lg-none d-xl-none w-50"
              >
                <NavLink
                  to="/register-advisor/plans"
                  className="nav-link text-primary"
                >
                  Register Advisors
                </NavLink>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
                className="nav-item p-0 d-lg-none d-xl-none w-50"
              >
                <NavLink
                  to="/register-franchise/plans"
                  className="nav-link text-primary"
                >
                  Register Franchises
                </NavLink>
              </li>
            </div>
            <div className="dropdown-divider w-100 border-primary my-3"></div>
            {isLoggedIn ? (
              <>
                <p className="nav-item text-primary text-left font-weight-bold">
                  My Listings
                </p>
                <div className="d-flex justify-content-between flex-wrap">
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/user/contacts"
                      className="nav-link text-primary"
                    >
                      Contacted Listings
                    </NavLink>
                  </li>
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/user/businesses"
                      className="nav-link text-primary"
                    >
                      Business Listings
                    </NavLink>
                  </li>
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/user/investors"
                      className="nav-link text-primary"
                    >
                      Investor Listings
                    </NavLink>
                  </li>
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/user/advisors"
                      className="nav-link text-primary"
                    >
                      Advisor Listings
                    </NavLink>
                  </li>
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/user/franchises"
                      className="nav-link text-primary"
                    >
                      Franchise Listings
                    </NavLink>
                  </li>
                </div>
                <div className="dropdown-divider w-100 border-primary my-3"></div>
                <div className="d-flex justify-content-between flex-wrap mb-3">
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/user/inbox/proposals"
                      className="nav-link text-primary"
                    >
                      Proposals
                    </NavLink>
                  </li>
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/user/notifications"
                      className="nav-link text-primary"
                    >
                      Notifications
                    </NavLink>
                  </li>
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <NavLink
                      to="/user/settings"
                      className="nav-link text-primary"
                    >
                      Settings
                    </NavLink>
                  </li>
                  <li
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                  >
                    <button
                      className="nav-link text-primary border-0 bg-transparent"
                      onClick={logout}
                    >
                      Sign out
                    </button>
                  </li>
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-between flex-wrap mb-3">
                <li
                  data-toggle="collapse"
                  data-target="#navbarMobileMenu"
                  className="nav-item p-0 d-lg-none d-xl-none w-50"
                >
                  <NavLink to="/about" className="nav-link text-primary">
                    About Us
                  </NavLink>
                </li>
                <li
                  data-toggle="collapse"
                  data-target="#navbarMobileMenu"
                  className="nav-item p-0 d-lg-none d-xl-none w-50"
                >
                  <NavLink to="/contact" className="nav-link text-primary">
                    Contact
                  </NavLink>
                </li>
                <li
                  data-toggle="collapse"
                  data-target="#navbarMobileMenu"
                  className="nav-item p-0 d-lg-none d-xl-none w-50"
                >
                  <NavLink to="/careers" className="nav-link text-primary">
                    Careers
                  </NavLink>
                </li>
                <li
                  data-toggle="collapse"
                  data-target="#navbarMobileMenu"
                  className="nav-item p-0 d-lg-none d-xl-none w-50"
                >
                  <NavLink to="/faq" className="nav-link text-primary">
                    F.A.Q
                  </NavLink>
                </li>
              </div>
            )}
            <li
              data-toggle="collapse"
              data-target="#navbarMobileMenu"
              className="nav-item p-0 d-lg-none d-xl-none"
            >
              <span className="flag-icon flag-icon-ng"></span> NGN
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default connect(
  state => ({
    isLoggedIn: state.user.isLoggedIn,
    currentUser: state.user.currentUser,
  }),
  {
    searchListings,
  },
)(NavBarMobile);
