import React, { Component } from 'react';
import CommunityPostTableItem from './CommunityPostTableItem';
import { CircularProgress } from '@material-ui/core';

class CommunityPostTable extends Component {
  render() {
    let { loadingPosts } = this.props;
    return (
      <div className="table-responsive shadow">
        <table className="table">
          <thead className="bg-admin-primary text-white h7">
            <tr>
              <th>Count</th>
              <th>Post Id</th>
              <th>Title</th>
              <th>Date Posted</th>
              <th>Category</th>
              <th>Status</th>
              <th>Posters First Name</th>
              <th>Posters Last Name</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {loadingPosts ? (
              <tr>
                <td>
                  <CircularProgress color="primary" size={'1rem'} />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ) : (
              this.props.posts.map((post, index) => (
                <CommunityPostTableItem
                  key={post.id}
                  post={post}
                  number={this.props.posts.length - index}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CommunityPostTable;
