import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppFooter from './AppFooter';
import Routes from '../routes';
import { connect } from 'react-redux';
import auth from '../common/services/authService';
import NavBar from './NavBar';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export class App extends Component {
  constructor(props) {
    super(props);
    auth.loginUserLocal();
  }

  render() {
    const { headers } = this.props;
    const title = headers.title || 'Compex Africa';
    const description =
      headers.description ||
      'Marketplace for Businesses in Africa. Buy, Sell, Invest or Raise Capital';
    const image = headers.image || 'image url here';
    const url = headers.url
      ? window.location.origin + headers.url
      : window.location.origin;
    const keywords =
      headers.keywords ||
      'Sell a Business, Raise Capital, Loans, Lenders, Buy Franchise';

    return (
      <React.Fragment>
        <Helmet
          title={headers.title || title}
          meta={[
            {
              name: 'description',
              content: headers.description || description,
            },
            { name: 'keywords', content: keywords },
            { property: 'og:title', content: title },
            { property: 'og:url', content: url },
            { property: 'og:image', content: image },
            { property: 'og:image:type', content: 'image/png' },
            { property: 'twitter:image:src', content: image },
            { property: 'twitter:title', content: title },
            { property: 'twitter:description', content: description },
          ]}
        />
        <ToastContainer />
        <div className="body-bg">
          {this.props.showNavbar && <NavBar></NavBar>}
          <main
            className={
              this.props.location.pathname === '/login' ||
              this.props.location.pathname === '/signup' ||
              this.props.location.pathname === '/forgot-password'
                ? 'mt-5 min-vw-100 min-vh-100'
                : this.props.location.pathname === '/'
                ? 'mt-3 min-vw-100 min-vh-100'
                : 'mt-lg-9 mt-xl-9 mt-5 pt-5 min-vw-100 min-vh-100'
            }
          >
            <Routes />
          </main>
          {this.props.showFooter && <AppFooter />}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  showNavbar: state.layout.showNavbar,
  showFooter: state.layout.showFooter,
  isLoggedIn: state.user.isLoggedIn,
  headers: state.layout.headers,
});

export default connect(mapStateToProps)(withRouter(App));
