import NumberFormat from 'react-number-format';
import React, { forwardRef } from 'react';

const handleFileUpload = event => {
  try {
    const file = event.target.files[0];
    if (file && file.size <= 2000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadstart = () => {
        console.log('reading file ...');
      };
      reader.onloadend = () => {
        const base64Url = reader.result.split(',')[1];
        console.log('done reading...');
        if (base64Url) {
          const newFile = {
            name: file.name,
            type: file.type,
            size: file.size,
            base64Url: reader.result,
          };
          console.log(newFile);
          return newFile;
        }
      };
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAbbreviation = price => {
  price = parseInt(price);
  if (price >= 1000000 && price < 1000000000) {
    const decimal = (price % 1000000).toString().substr(0, 2);
    return (
      parseInt(price / 1000000) +
      (decimal === '0' ? '' : `.${decimal}`) +
      ' Million'
    );
  } else if (price >= 1000000000 && price < 1000000000000) {
    const decimal = (price % 1000000000).toString().substr(0, 2);
    return (
      parseInt(price / 1000000000) +
      (decimal === '0' ? '' : `.${decimal}`) +
      ' Billion'
    );
  } else if (price >= 1000000000000) {
    const decimal = (price % 1000000000000).toString().substr(0, 2);
    return (
      parseInt(price / 1000000000000) +
      (decimal === '0' ? '' : `.${decimal}`) +
      ' Trillion'
    );
  } else {
    return (
      <NumberFormat
        value={price}
        displayType={'text'}
        thousandSeparator={true}
      />
    );
  }
};

export const scrollToRef = ref => {
  var rect = ref.getBoundingClientRect();
  window.scrollTo({
    top: rect.top + window.scrollY - 160,
    left: 0,
    behavior: 'smooth',
  });
};

export const numberWithCommas = x => {
  return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const spacePascalWord = (word, firstOnly) => {
  if (typeof word !== 'string') return word;

  const words = word.split(/(?=[A-Z])/);
  if (firstOnly) return words[0];
  else return words.join(' ');
};

export const planActionText = (isUpgrading, planType, currentPlan) => {
  if (!isUpgrading) return 'Select plan';

  const plan = spacePascalWord(planType, true);
  currentPlan = spacePascalWord(currentPlan, true);

  if (currentPlan === plan && plan !== 'Basic') return 'Renew plan';
  else if (currentPlan === 'Basic' && plan !== 'Basic') return 'Upgrade plan';
  else if (
    currentPlan === 'Active' &&
    (plan === 'Premium' || plan === 'Professional')
  )
    return 'Upgrade plan';
  else if (currentPlan === 'Premium' && plan === 'Professional')
    return 'Upgrade plan';
  else return 'Select plan';
};

export const camelPad = str => {
  return (
    str
      // Look for long acronyms and filter out the last letter
      .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
      // Look for lower-case letters followed by upper-case letters
      .replace(/([a-z\d])([A-Z])/g, '$1 $2')
      // Look for lower-case letters followed by numbers
      .replace(/([a-zA-Z])(\d)/g, '$1 $2')
      .replace(/^./, function(str) {
        return str.toUpperCase();
      })
      // Remove any white space left around the word
      .trim()
  );
};

export default {
  handleFileUpload,
};

export const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
  <div className="input-group">
    <div className="input-group-prepend">
      <i
        className="input-group-text fa fa-calendar bd-highlight"
        aria-hidden="true"
      ></i>
    </div>
    <input
      className="form-control w-65"
      onClick={onClick}
      placeholder="Select Date"
      value={value}
      ref={ref}
      readOnly
    />
  </div>
));
