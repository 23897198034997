import React from 'react';
import ReactDOM from 'react-dom';
import App from './layout/App';
import * as serviceWorker from './serviceWorker';
import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../node_modules/bootstrap/dist/js/bootstrap';
import './assets/styles/main.scss';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './state/store';
import ScrollToTop from './common/components/ScrollToTop';
import { HelmetProvider } from 'react-helmet-async';
import GA from './common/utils/googleAnalytics';

if (
  process.env.REACT_APP_CUSTOM_NODE_ENV === 'development' ||
  process.env.REACT_APP_CUSTOM_NODE_ENV === 'staging'
) {
  window.store = store;
}

if (process.env.REACT_APP_CUSTOM_NODE_ENV === 'production') {
  (function(h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 604738, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}

ReactDOM.render(
  <BrowserRouter>
    {GA.init() && <GA.RouteTracker />}
    <Provider store={store}>
      <ScrollToTop />
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
