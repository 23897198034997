import {
  RESETFORMS,
  FETCH_ADVISOR_LISTINGS,
  FETCH_ADVISOR_DETAILS,
  UPDATE_ADVISORS,
  EDITADVISOR,
  FETCH_ADMIN_ADVISOR_LISTINGS,
  FETCH_ADVISOR_LISTINGS_STARTED,
  SELECT_PLAN,
} from '../actions/types';

const initialState = {
  formdata: {},
  advisorListings: [],
  loadingAdvisorListings: false,
  advisorDetails: {},
  adminAdvisorListings: [],
  pageNumber: 1,
  pageSize: 50,
  totalCount: 0,
  selectedPlan: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADVISOR_LISTINGS:
      const { data, pageNumber, pageSize, totalCount } = action.payload;
      return {
        ...state,
        advisorListings: data,
        pageNumber,
        pageSize,
        totalCount,
      };
    case UPDATE_ADVISORS:
      return {
        ...state,
        advisorListings: action.payload.data,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount,
      };
    case EDITADVISOR:
      return {
        ...state,
        formdata: action.payload,
      };
    case FETCH_ADVISOR_DETAILS:
      return {
        ...state,
        advisorDetails: action.payload,
      };
    case RESETFORMS:
      return {
        ...state,
        formdata: {},
        selectedPlan: '',
      };
    case FETCH_ADMIN_ADVISOR_LISTINGS:
      return {
        ...state,
        adminAdvisorListings: action.payload.data,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount,
        loadingAdvisorListings: false,
      };
    case FETCH_ADVISOR_LISTINGS_STARTED:
      return {
        ...state,
        loadingAdvisorListings: true,
      };
    case SELECT_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };
    default:
      return state;
  }
};
