import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchListingDetails, editListing } from '../../state/actions/business';
import ContactBttn from '../../common/components/ContactBttn';
import { Link } from 'react-router-dom';
import CarouselTemp from '../../common/components/CarouselTemp';
import Swal from 'sweetalert2';
import listingsService from '../../common/services/listingsService';
import NumberFormat from 'react-number-format';
import randomstring from 'randomstring';
import { CircularProgress } from '@material-ui/core';
import FadeIn from 'react-fade-in';
import {
  getAbbreviation,
  spacePascalWord,
  camelPad,
} from '../../common/utils/helpers';
import { setHeaders, resetHeaders } from './../../state/actions/layout';
import profileStatus from './../../common/utils/profileStatus';
import businessSectors from './../../common/utils/businessSectors';

class BusinessListingItemPage extends Component {
  state = {
    loading: null,
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.setState({
        loading: true,
      });
      this.props.fetchListingDetails(id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.listing !== prevProps.listing) {
      this.setState({
        loading: false,
      });

      if (this.props.listing) {
        const { listing } = this.props;
        // TODO: we can add image to the meta data, but the images are going to be large
        this.props.setHeaders({
          title: listing.shortDescription,
          url: this.props.history.location.pathname,
        });
      }
    }
  }

  deleteListing = async id => {
    Swal.fire(
      'Are you sure?',
      'Do you want to delete listing?',
      'warning',
    ).then(async ok => {
      if (ok.value) {
        const res = await listingsService.deleteListing(id);
        if (res) {
          Swal.fire('Listing deleted Successfully', '', 'success').then(() => {
            this.props.history.replace('/user/businesses');
          });
        } else {
          Swal.fire('Unable to delete listing', '', 'error');
        }
      }
    });
  };

  render() {
    const { listing, currentUser } = this.props;

    let listingPhotos = [];

    if (listing.photos && listing.photos.length > 0) {
      listing.photos.forEach((imgObj, index) => {
        listingPhotos.push(
          <div className="vh-center-parent" key={randomstring.generate()}>
            <img
              className="vh-center"
              src={imgObj.fileUrl}
              alt={'Slide' + index}
            />
          </div>,
        );
      });
    }

    if (listing.photos && listing.photos.length === 0) {
      listingPhotos.push(
        <div className="vh-center-parent" key={randomstring.generate()}>
          <img
            className="vh-center"
            src={
              businessSectors[listing.sector.replace(/[- ]/g, '')]
                ? businessSectors[listing.sector.replace(/[- ]/g, '')]
                    .defaultImage
                : businessSectors.default.defaultImage
            }
            alt={`${businessSectors[listing.sector.replace(/[- ]/g, '')] ||
              businessSectors.default.value}`}
          />
        </div>,
      );
    }

    console.log(listingPhotos);

    return (
      <div className="custom-center">
        {this.state.loading ? (
          <div
            style={{ minHeight: '60vh' }}
            className="d-flex justify-content-center align-items-center"
          >
            <CircularProgress color="primary" size={'3rem'} />
          </div>
        ) : (
          listing && (
            <FadeIn>
              <div>
                {(listing.isOwner || currentUser.isAdmin) && (
                  <div className="w-lg-94 w-xl-94 w-xs-100 w-sm-100 w-md-100 rounded bg-white shadow-lg d-flex flex-column p-4 pl-5 mb-5 h-rem-lg-details-7">
                    <p className="h8 w-80 text-details min-h-rem-lg-2">
                      Hi, {listing.firstName} {listing.lastName}, welcome to
                      Compex Africa. You can edit your preference using the
                      buttons below.
                    </p>
                    <div className="d-flex justify-content-between flex-wrap">
                      <div
                        className={
                          listing.status === profileStatus.Approved
                            ? 'text-success mb-3'
                            : listing.status === profileStatus.Pending ||
                              listing.status === profileStatus.ChangesRequired
                            ? 'text-warning mb-3'
                            : listing.status === profileStatus.Draft ||
                              listing.status === profileStatus.Declined
                            ? 'text-danger mb-3'
                            : 'mb-3'
                        }
                      >
                        <span>
                          <i className="fas fa-circle"></i>
                        </span>
                        <span className="small mx-2">
                          {spacePascalWord(listing.status)}
                        </span>
                        {!listing.subscription && (
                          <>
                            <i className="fas fa-square text-muted mr-2"></i>
                            <span className="text-muted">No plan</span>
                          </>
                        )}
                        {listing.subscription && (
                          <>
                            <i className="fas fa-square text-success mr-2"></i>
                            <Link
                              to={`/user/subscriptions/${listing.subscription.subscriptionId}`}
                            >
                              {listing.subscription.planType &&
                                spacePascalWord(
                                  listing.subscription.planType,
                                  true,
                                ) + ' plan'}
                            </Link>
                          </>
                        )}
                      </div>

                      {!listing.subscription && (
                        <>
                          <div className="w-100"></div>

                          <div>
                            <Link
                              to={`/register-business/4?id=${listing.id}`}
                              className="btn btn-outline-primary h8 font-weight-bold min-w-20"
                            >
                              Select plan
                            </Link>
                          </div>
                        </>
                      )}

                      {listing.subscription && (
                        <>
                          <div className="w-100"></div>

                          <Link
                            to={`/user/businesses/${listing.id}/upgrade`}
                            className="btn btn-outline-primary h8 font-weight-bold min-w-20"
                          >
                            Change or renew plan
                          </Link>
                        </>
                      )}

                      <Link
                        className="btn btn-outline-primary h8 font-weight-bold min-w-20"
                        to={`/register-business?id=${listing.id}`}
                      >
                        {listing.status === profileStatus.Draft
                          ? 'Complete profile'
                          : 'Edit'}
                      </Link>
                      <button
                        className="btn btn-outline-primary h8 font-weight-bold min-w-20"
                        onClick={() => this.deleteListing(listing.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                )}
                <div className="h-100 d-flex flex-wrap flex-md-nowrap flex-xl-nowrap justify-content-center">
                  <div className="w-100 w-lg-60 d-flex flex-column mb-5 mrp-md-9a">
                    <div className="d-flex flex-wrap">
                      <div className="w-75">
                        <p className="h1z font-weight-bold text-primary">
                          {listing.shortDescription}
                        </p>
                        <div className="d-flex flex-row justify-content-between mb-3 mt-2 w-md-70 w-100">
                          <div className="h7z font-weight-bold">
                            <i className="fas fa-map-marker-alt"></i>
                            &nbsp;
                            <span>{listing.registeredLocation}</span>
                          </div>
                          <div className="h7z font-weight-bold">
                            <i className="fas fa-store-alt"></i>
                            {' ' + listing.listingType}
                          </div>
                        </div>
                      </div>
                      <div className="w-100 d-lg-none d-xl-none d-sm-block"></div>
                      <div className="w-25 d-flex flex-column justify-content-end pb-4">
                        <p className="h1c font-weight-bold pl-0 m-0">
                          ₦
                          {getAbbreviation(
                            listing.askingPrice ||
                              listing.capitalRaised ||
                              listing.partialSaleAskingPrice,
                          )}
                        </p>
                        <p className="h5 m-0"></p>
                      </div>
                    </div>
                    <div
                      className="w-100 rounded-2 bg-darkdash position-relative shadow"
                      style={{ paddingBottom: '62%' }}
                    >
                      <div
                        className="position-absolute"
                        style={{ top: '0', bottom: '0', left: '0', right: '0' }}
                      >
                        <CarouselTemp
                          listings={listingPhotos}
                          itemsPerSlide={1}
                          id={'listingPhotos'}
                          controls={true}
                        ></CarouselTemp>
                      </div>
                    </div>

                    <div className="mb-3 mt-5">
                      <p className="h7 font-weight-bold">Business Overview</p>
                      <p className="h8a font-weight-normal">
                        {listing.businessExplanation}
                      </p>
                    </div>
                    <div className="d-flex flex-wrap mb-3">
                      <div className="w-md-50 pr-3 mb-3">
                        <p className="h7 font-weight-bold">
                          Product and Services Overview
                        </p>
                        <p className="h8a font-weight-normal">
                          {listing.incomeSource}
                        </p>
                      </div>
                      <div className="d-block d-lg-none d-md-none w-100"></div>
                      <div className="w-md-50 pr-3 mb-3">
                        <p className="h7 font-weight-bold">Business website</p>
                        <p className="h8a font-weight-normal">
                          {listing.businessWebsite
                            ? listing.businessWebsite
                            : 'Hidden until contacted'}
                        </p>
                      </div>
                      <div className="d-block d-lg-none d-md-none w-100"></div>
                      <div className="w-md-50 pr-3 mb-3">
                        <p className="h7 font-weight-bold">Reason for sale</p>
                        <p className="h8a font-weight-normal">
                          {listing.whySelling}
                        </p>
                      </div>
                      <div className="d-block d-lg-none d-md-none w-100"></div>
                      <div className="w-md-50 pr-3 mb-3">
                        <p className="h7 font-weight-bold">
                          Business Strengths
                        </p>
                        <p className="h8a font-weight-normal">
                          {listing.businessStrength}
                        </p>
                      </div>
                      <div className="d-block d-lg-none d-md-none w-100"></div>
                      <div className="w-md-50 pr-3 mb-3">
                        <p className="h7 font-weight-bold">
                          Opportunities for new owner
                        </p>
                        <p className="h8a font-weight-normal">
                          {listing.newOwnerOpportunities}
                        </p>
                      </div>
                      <div className="d-block d-lg-none d-md-none w-100"></div>
                      <div className="w-md-50 pr-3 mb-3">
                        <p className="h7 font-weight-bold">
                          Key day-to-day operations
                        </p>
                        <p className="h8a font-weight-normal">
                          {listing.daysOperations}
                        </p>
                      </div>
                      <div className="d-block d-lg-none d-md-none w-100"></div>
                      <div className="w-md-50 pr-3 mb-3">
                        <p className="h7 font-weight-bold">Marketing Tactics</p>
                        <p className="h8a font-weight-normal">
                          {listing.marketing}
                        </p>
                      </div>
                      <div className="d-block d-lg-none d-md-none w-100"></div>
                      <div className="w-md-50 pr-3 mb-3">
                        <p className="h7 font-weight-bold">
                          Post Sale Support Available
                        </p>
                        <p className="h8a font-weight-normal">
                          {listing.postSaleSupport ? 'Yes' : 'No'}
                        </p>
                      </div>
                      <div className="w-md-50 pr-3 mb-3">
                        <p className="h7 font-weight-bold">
                          How can the business be improved?
                        </p>
                        <p className="h8a font-weight-normal">
                          {listing.howToImprove}
                        </p>
                      </div>
                      <div className="w-md-50 pr-3 mb-3">
                        <p className="h7 font-weight-bold">
                          How does the business make money?
                        </p>
                        <p className="h8a font-weight-normal">
                          {listing.howBusinessMakeMoney}
                        </p>
                      </div>
                      <div className="w-md-50 pr-3 mb-3">
                        <p className="h7 font-weight-bold">Facility overview</p>
                        <p className="h8a font-weight-normal">
                          {listing.facilityDescription}
                        </p>
                      </div>
                      <div className="w-md-50 pr-3 mb-3">
                        <p className="h7 font-weight-bold">Company</p>
                        <p className="h8a font-weight-normal">
                          {listing.businessName
                            ? listing.businessName
                            : 'Hidden until contacted'}
                        </p>
                      </div>
                      <div className="w-md-50 pr-3 mb-3">
                        <p className="h7 font-weight-bold">Contact Name</p>
                        <p className="h8a font-weight-normal">
                          {listing.firstName
                            ? `${listing.firstName} ${listing.lastName}`
                            : 'Hidden until contacted'}
                        </p>
                      </div>
                      <div className="w-md-50 pr-3 mb-3">
                        <p className="h7 font-weight-bold">Contact Phone</p>
                        <p className="h8a font-weight-normal">
                          {listing.phoneNumber
                            ? listing.phoneNumber
                            : 'Hidden until contacted'}
                        </p>
                      </div>
                      <div className="w-md-50 pr-3 mb-3">
                        <p className="h7 font-weight-bold">Contact Email</p>
                        <p className="h8a font-weight-normal">
                          {listing.email
                            ? listing.email
                            : 'Hidden until contacted'}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-wrap">
                      <p className="h7 font-weight-bold w-100 mb-3">
                        Documents&nbsp;
                        {!listing.canViewContacts && (
                          <span className="font-weight-normal px-2">
                            <i className="fas fa-lock"></i>
                            <span className="px-2">Hidden until contacted</span>
                          </span>
                        )}
                      </p>

                      {listing.canViewContacts && (
                        <React.Fragment>
                          {listing.documents && listing.documents.length ? (
                            listing.documents.map(document => (
                              <div
                                key={randomstring.generate()}
                                className="w-md-50"
                              >
                                <div className="my-2">
                                  {document.category
                                    ? document.category
                                    : camelPad(document.fileGroup)}
                                </div>
                                <div className="w-md-50 pr-2 d-flex mb-2">
                                  {document.type === 'application/pdf' ? (
                                    <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                                  ) : document.type ===
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                                    <i className="far fa-file-word fa-2x pr-2 text-info"></i>
                                  ) : document.type ===
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
                                    <i className="far fa-file-excel fa-2x pr-2 text-success"></i>
                                  ) : (
                                    <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                                  )}
                                  &nbsp;
                                  <a
                                    href={document.fileUrl}
                                    className="h8a font-weight-normal"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {document.name}
                                  </a>
                                </div>
                                <div className="d-block d-lg-none d-md-none w-100"></div>
                              </div>
                            ))
                          ) : (
                            <p className="text-danger">
                              No document was uploaded
                            </p>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>

                  <div className="d-flex flex-column ml-md-3">
                    {!listing.isOwner && (
                      <div className="mb-4 d-none d-md-block d-lg-block">
                        <ContactBttn
                          sendTo={'business'}
                          businessId={listing.id}
                          history={this.props.history}
                          disable={listing.isOwner || listing.hasContacted}
                          canContact={listing.canContact}
                        ></ContactBttn>
                      </div>
                    )}
                    <div className="d-flex flex-column shadow-lg bg-modal rounded-2 p-4 mb-3 min-h-rem-lg-34 min-w-rem-lg-24">
                      <div className="d-flex flex-column justify-content-between min-h-rem-23 mb-5">
                        <p className="d-flex flex-wrap font-weight-bold h7 text-bright-primary">
                          Financials
                        </p>
                        <p className="d-flex flex-wrap font-weight-bold h7">
                          Reported Revenue:&nbsp;{' '}
                          <span className="font-weight-normal">
                            <NumberFormat
                              value={listing.latestAnnualRevenue}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'₦'}
                            />
                          </span>
                        </p>
                        <p className="d-flex flex-wrap font-weight-bold h7">
                          Run Rate Sales:&nbsp;{' '}
                          <span className="font-weight-normal">
                            <NumberFormat
                              value={listing.averageMonthlySales * 12}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'₦'}
                            />
                          </span>
                        </p>
                        <p className="d-flex flex-wrap font-weight-bold h7">
                          Value of Physical Assets:&nbsp;{' '}
                          <span className="font-weight-normal">
                            <NumberFormat
                              value={listing.physicalAssetsValue}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'₦'}
                            />
                          </span>
                        </p>
                        <p className="d-flex flex-wrap font-weight-bold h7">
                          EBITDA Margin:&nbsp;{' '}
                          <span className="font-weight-normal">
                            {listing.ebitda} %
                          </span>
                        </p>
                        {listing.equity > 0 && (
                          <p className="d-flex flex-wrap font-weight-bold h7">
                            Equity offered:&nbsp;{' '}
                            <span className="font-weight-normal">
                              {listing.equity} %
                            </span>
                          </p>
                        )}
                        <p className="d-flex flex-wrap font-weight-bold h7 mb-5">
                          {listing.listingType + ' amount'}:&nbsp;{' '}
                          <span className="font-weight-normal">
                            <NumberFormat
                              value={
                                listing.askingPrice ||
                                listing.capitalRaised ||
                                listing.partialSaleAskingPrice
                              }
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'₦'}
                            />
                          </span>
                        </p>
                        <p className="d-flex flex-wrap font-weight-bold h7 text-bright-primary">
                          Operatons
                        </p>
                        <p className="d-flex flex-wrap font-weight-bold h7">
                          Year of Establishment:&nbsp;{' '}
                          <span className="font-weight-normal">
                            {String(
                              new Date(
                                listing.beginOperationDate,
                              ).getFullYear(),
                            )}
                          </span>
                        </p>
                        <p className="d-flex flex-wrap font-weight-bold h7">
                          Industries:&nbsp;{' '}
                          <span className="font-weight-normal">
                            {listing.sector}
                          </span>
                        </p>
                        <p className="d-flex flex-wrap font-weight-bold h7">
                          Staff Strength:&nbsp;{' '}
                          <span className="font-weight-normal">
                            {' '}
                            {listing.numberOfStaff}
                          </span>
                        </p>
                        <p className="d-flex flex-wrap font-weight-bold h7">
                          Legal Entity:&nbsp;{' '}
                          <span className="font-weight-normal">
                            {listing.businessLegalType}
                          </span>
                        </p>
                        <p className="d-flex flex-wrap font-weight-bold h7">
                          Listed by:&nbsp;{' '}
                          <span className="font-weight-normal">
                            {listing.whoIAm}
                          </span>
                        </p>
                      </div>
                      <ContactBttn
                        sendTo={'business'}
                        businessId={listing.id}
                        history={this.props.history}
                        disable={listing.isOwner || listing.hasContacted}
                        canContact={listing.canContact}
                        showDocBttn={true}
                      ></ContactBttn>
                    </div>
                  </div>
                </div>
                {!listing.isOwner && (
                  <div className="w-md-25 w-50 mb-3a mt-5 mt-md-0">
                    <ContactBttn
                      sendTo={'business'}
                      businessId={listing.id}
                      history={this.props.history}
                      disable={listing.isOwner || listing.hasContacted}
                      canContact={listing.canContact}
                    ></ContactBttn>
                  </div>
                )}
              </div>
            </FadeIn>
          )
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    listing: state.business.listingDetails,
    currentUser: state.user.currentUser,
  }),
  { fetchListingDetails, editListing, setHeaders, resetHeaders },
)(BusinessListingItemPage);
