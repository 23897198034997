import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdvisorPlans from './AdvisorPlans';
import RegisterAdvisorForm from './RegisterAdvisorForm';

const AddAdvisor = props => {
  return (
    <div>
      <Switch>
        <Route
          path="/register-advisor/plans"
          component={() => {
            return (
              <div className="container">
                <div className="w-100 text-center font-weight-bold text-pricing h2a mb-5">
                  Choose an advisor plan
                </div>
                <AdvisorPlans {...props} />
              </div>
            );
          }}
        />
        <Route path="/" component={RegisterAdvisorForm} />
      </Switch>
    </div>
  );
};

export default AddAdvisor;
