import React, { Component } from 'react';
import Joi from 'joi-browser';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import equal from 'fast-deep-equal';
import queryString from 'query-string';
import Stepper from 'react-stepper-horizontal';
import Swal from 'sweetalert2';
import listing from '../../common/services/listingsService';
import { editListing } from '../../state/actions/business';
import NumberFormat from 'react-number-format';
import { CircularProgress } from '@material-ui/core';
import { scrollToRef } from '../../common/utils/helpers';

class AddBusinessForm3 extends Component {
  constructor(props) {
    super(props);
    this.incorporationSelect = React.createRef();
    this.otherFileSelect = React.createRef();
  }

  state = {
    formdata: {
      latestAnnualRevenue: '',
      averageMonthlySales: '',
      ebitda: '',
      physicalAssetsValue: '',
    },

    incomeStatementFiles: [],
    balanceSheetFiles: [],
    incorporationFiles: [],
    otherFiles: [],
    steps: [{}, {}, {}, {}],
    errors: {},
    previousData: {},
    id: '',
    activeStep: 2,
    isSaving: false,
  };

  componentDidMount() {
    const { id } = queryString.parse(this.props.location.search);

    if (id) {
      this.props.editListing(id);
      this.autofillFormFields();
    } else {
      this.props.history.replace('/register-business');
    }
  }

  componentDidUpdate(prevProps) {
    if (!equal(this.props.formdata, prevProps.formdata)) {
      this.autofillFormFields();
    }
  }

  autofillFormFields() {
    const { formdata } = this.props;
    if (formdata && formdata.id) {
      const form = { ...this.state.formdata };
      form.latestAnnualRevenue = formdata.latestAnnualRevenue || '';
      form.averageMonthlySales = formdata.averageMonthlySales || '';
      form.ebitda = formdata.ebitda || '';
      form.physicalAssetsValue = formdata.physicalAssetsValue || '';

      let incomeServerFiles = formdata.files.filter(
        file => file.fileGroup === 'IncomeStatements',
      );
      let balanceServerFiles = formdata.files.filter(
        file => file.fileGroup === 'BalanceSheets',
      );
      let incorporationServerFiles = formdata.files.filter(
        file => file.fileGroup === 'Incorporation',
      );
      let otherServerFiles = formdata.files.filter(
        file => file.fileGroup === 'Others',
      );

      let incomeStatementFiles = incomeServerFiles.map(file => {
        return {
          name: file.name,
          type: file.type,
          size: file.size,
          base64Url: file.fileUrl,
        };
      });
      let balanceSheetFiles = balanceServerFiles.map(file => {
        return {
          name: file.name,
          type: file.type,
          size: file.size,
          base64Url: file.fileUrl,
        };
      });
      let incorporationFiles = incorporationServerFiles.map(file => {
        return {
          name: file.name,
          type: file.type,
          size: file.size,
          base64Url: file.fileUrl,
        };
      });
      let otherFiles = otherServerFiles.map(file => {
        return {
          name: file.name,
          type: file.type,
          size: file.size,
          base64Url: file.fileUrl,
        };
      });

      this.setState({
        formdata: form,
        incomeStatementFiles,
        balanceSheetFiles,
        incorporationFiles,
        otherFiles,
        id: formdata.id,
        previousData: formdata,
        activeStep: 3,
      });
    }
  }

  schema = {
    latestAnnualRevenue: Joi.number()
      .required()
      .label('Field'),
    averageMonthlySales: Joi.number()
      .required()
      .label('Field'),
    ebitda: Joi.number()
      .required()
      .max(99)
      .label('Field'),
    physicalAssetsValue: Joi.number()
      .required()
      .label('Field'),
  };

  validate() {
    const { error } = Joi.validate(this.state.formdata, this.schema, {
      abortEarly: false,
    });
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  onChange = (e, isFormatted = false) => {
    const { name, value } = e.target;
    const formdata = { ...this.state.formdata };
    formdata[name] = isFormatted
      ? parseInt(value.replace(/,/g, ''), 10)
      : value;
    const errors = { ...this.state.errors };
    errors[name] = '';
    this.setState({ formdata, errors });
  };

  onChangeOptional = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onHandleIncomeFileUpload = e => {
    const file = e.target.files[0];
    if (file && file.size <= 2000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadstart = () => {
        console.log('reading file ...');
      };
      reader.onloadend = () => {
        const base64Url = reader.result.split(',')[1];
        if (base64Url) {
          const newFile = {
            name: file.name,
            type: file.type,
            size: file.size,
            base64Url: reader.result,
          };
          const files = [...this.state.incomeStatementFiles];
          files.push(newFile);
          this.setState({ incomeStatementFiles: files });
        }
      };
    } else {
      Swal.fire('Error', 'File is too large', 'error');
    }
  };

  onDeleteIncomeFile = file => {
    const files = [...this.state.incomeStatementFiles];
    const filteredFiles = files.filter(x => x.name !== file.name);
    this.setState({ incomeStatementFiles: filteredFiles });
  };

  onDeleteBalanceFile = file => {
    const files = [...this.state.balanceSheetFiles];
    const filteredFiles = files.filter(x => x.name !== file.name);
    this.setState({ balanceSheetFiles: filteredFiles });
  };

  onDeleteIncorporationFile = file => {
    const files = [...this.state.incorporationFiles];
    const filteredFiles = files.filter(x => x.name !== file.name);
    this.setState({ incorporationFiles: filteredFiles });
  };

  onDeleteOtherFile = file => {
    const files = [...this.state.otherFiles];
    const filteredFiles = files.filter(x => x.name !== file.name);
    this.setState({ otherFiles: filteredFiles });
  };

  // TODO: refactor to DRY
  onHandleBalanceFileUpload = e => {
    const file = e.target.files[0];
    if (file && file.size <= 2000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadstart = () => {
        console.log('reading file ...');
      };
      reader.onloadend = () => {
        const base64Url = reader.result.split(',')[1];
        if (base64Url) {
          const newFile = {
            name: file.name,
            type: file.type,
            size: file.size,
            base64Url: reader.result,
          };
          const files = [...this.state.balanceSheetFiles];
          files.push(newFile);
          this.setState({ balanceSheetFiles: files });
        }
      };
    } else {
      Swal.fire('Error', 'File is too large', 'error');
    }
  };

  // TODO: refactor to DRY
  onHandleIncorporationFileUpload = e => {
    const file = e.target.files[0];
    if (file && file.size <= 2000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadstart = () => {
        console.log('reading file ...');
      };
      reader.onloadend = () => {
        const base64Url = reader.result.split(',')[1];
        const selectedCategory = this.incorporationSelect.current.value;
        if (base64Url) {
          const newFile = {
            name: file.name,
            type: file.type,
            size: file.size,
            base64Url: reader.result,
            category: selectedCategory,
          };
          const files = [...this.state.incorporationFiles];
          const filtered = files.filter(x => x.category !== selectedCategory);
          filtered.push(newFile);
          this.setState({ incorporationFiles: filtered });
        }
      };
    } else {
      Swal.fire('Error', 'File is too large', 'error');
    }
  };

  // TODO: refactor to DRY
  onHandleOtherFileUpload = e => {
    const file = e.target.files[0];
    if (file && file.size <= 2000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadstart = () => {
        console.log('reading file ...');
      };
      reader.onloadend = () => {
        const base64Url = reader.result.split(',')[1];

        const selectedCategory = this.otherFileSelect.current.value;
        if (base64Url) {
          const newFile = {
            name: file.name,
            type: file.type,
            size: file.size,
            base64Url: reader.result,
            category: selectedCategory,
          };

          const files = [...this.state.otherFiles];
          const filtered = files.filter(x => x.category !== selectedCategory);
          filtered.push(newFile);
          this.setState({ otherFiles: filtered });
        }
      };
    } else {
      Swal.fire('Error', 'File is too large', 'error');
    }
  };

  onSubmit = async e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) {
      const firstError = this[Object.keys(errors)[0]];
      scrollToRef(firstError);
      firstError.focus({ preventScroll: true });
      return;
    }

    // post data to server
    const { formdata, previousData } = this.state;

    previousData.latestAnnualRevenue = formdata.latestAnnualRevenue;
    previousData.averageMonthlySales = formdata.averageMonthlySales;
    previousData.ebitda = formdata.ebitda;
    previousData.physicalAssetsValue = formdata.physicalAssetsValue;

    previousData.incomeStatementFiles = this.state.incomeStatementFiles;
    previousData.balanceSheetFiles = this.state.balanceSheetFiles;
    previousData.incorporationFiles = this.state.incorporationFiles;
    previousData.otherFiles = this.state.otherFiles;

    let fileGroupCode = [];
    const incomeStatements = 2,
      balanceSheets = 3,
      incorporation = 4,
      others = 7;

    fileGroupCode.push(incomeStatements);
    fileGroupCode.push(balanceSheets);
    fileGroupCode.push(incorporation);
    fileGroupCode.push(others);

    previousData.fileGroupCode = fileGroupCode;

    try {
      this.setState({ isSaving: true });
      const res = await listing.register(previousData);

      if (res) {
        this.setState({ isSaving: false });
        this.props.history.push('/register-business/4?id=' + res.id);
      } else {
        this.setState({ isSaving: false });
      }
    } catch (error) {
      this.setState({ isSaving: false });
    }
  };

  render() {
    return (
      <div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="d-flex justify-content-center flex-column text-center my-3">
              <h3 className="text-primary font-weight-bold">
                Register a business
              </h3>
              <p className="text-muted">
                Kindly fill out the following information as accurate as
                possible
              </p>
              <div>
                <Stepper
                  steps={[
                    { href: `/register-business/1?id=${this.state.id}` },
                    { href: `/register-business/2?id=${this.state.id}` },
                    { href: `/register-business/3?id=${this.state.id}` },
                    { href: `/register-business/4?id=${this.state.id}` },
                  ]}
                  activeStep={this.state.activeStep}
                  size={45}
                  circleFontSize={22}
                  titleTop={15}
                  defaultBarColor="#002880"
                  completeBarColor="#002880"
                  activeColor="#002880"
                  completeColor="#002880"
                  defaultColor="#555"
                />
              </div>
              <h3 className="font-weight-bold mt-5 mb-2">
                Financial Information
              </h3>
              <p className="text-muted">
                All fields are required except stated otherwise
              </p>
            </div>

            <form onSubmit={this.onSubmit} method="POST" noValidate>
              <div className="form-row bg-white shadow rounded-2 py-5 px-3">
                <div className="form-row py-2">
                  <div className="form-group col-md-6 px-md-4 mb-3">
                    <label htmlFor="latestAnnualRevenue">
                      Latest reported annual revenue{' '}
                      <span className="text-danger text-small">*</span>
                    </label>
                    <div
                      className="input-group mb-2"
                      ref={elem => {
                        this.latestAnnualRevenue = elem;
                      }}
                    >
                      <div className="input-group-prepend">
                        <div className="input-group-text">₦</div>
                      </div>

                      <NumberFormat
                        thousandSeparator={true}
                        type="text"
                        id="latestAnnualRevenue"
                        name="latestAnnualRevenue"
                        onChange={e => this.onChange(e, true)}
                        value={this.state.formdata.latestAnnualRevenue}
                        className={
                          this.state.errors['latestAnnualRevenue']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />

                      <div className="invalid-feedback">
                        {this.state.errors['latestAnnualRevenue']}
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-6 px-md-4 mb-3">
                    <label htmlFor="averageMonthlySales">
                      Average monthly sales{' '}
                      <span className="text-danger text-small">*</span>
                    </label>
                    <div
                      className="input-group mb-2"
                      ref={elem => {
                        this.averageMonthlySales = elem;
                      }}
                    >
                      <div className="input-group-prepend">
                        <div className="input-group-text">₦</div>
                      </div>

                      <NumberFormat
                        thousandSeparator={true}
                        type="text"
                        id="averageMonthlySales"
                        name="averageMonthlySales"
                        onChange={e => this.onChange(e, true)}
                        value={this.state.formdata.averageMonthlySales}
                        className={
                          this.state.errors['averageMonthlySales']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />

                      <div className="invalid-feedback">
                        {this.state.errors['averageMonthlySales']}
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-6 px-md-4 mb-3">
                    <label htmlFor="ebitda">
                      EBITDA Margin (%){' '}
                      <span className="text-danger text-small">*</span>
                    </label>
                    <input
                      type="text"
                      id="ebitda"
                      name="ebitda"
                      onChange={this.onChange}
                      value={this.state.formdata.ebitda}
                      className={
                        this.state.errors['ebitda']
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      ref={elem => {
                        this.ebitda = elem;
                      }}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors['ebitda']}
                    </div>
                  </div>

                  <div className="form-group col-md-6 px-md-4 mb-3">
                    <label htmlFor="physicalAssetsValue">
                      Value of physical assets{' '}
                      <span className="text-danger text-small">*</span>
                    </label>
                    <div
                      className="input-group mb-2"
                      ref={elem => {
                        this.physicalAssetsValue = elem;
                      }}
                    >
                      <div className="input-group-prepend">
                        <div className="input-group-text">₦</div>
                      </div>

                      <NumberFormat
                        thousandSeparator={true}
                        type="text"
                        id="physicalAssetsValue"
                        name="physicalAssetsValue"
                        onChange={e => this.onChange(e, true)}
                        value={this.state.formdata.physicalAssetsValue}
                        className={
                          this.state.errors['physicalAssetsValue']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />

                      <div className="invalid-feedback">
                        {this.state.errors['physicalAssetsValue']}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row px-2">
                  <div className="col-12 px-md-4 mb-3 pb-2 border-bottom">
                    <h6 className="ml-2 mt-3">
                      Attachments{' '}
                      <span className="text-muted small">(Max 2MB)</span>
                      <span className="text-muted px-2">All optional</span>
                    </h6>
                  </div>
                  <div className="form-group col-md-6 px-md-4">
                    <h6>Income Statements (Profit and Loss) </h6>
                    <label className="small text-muted" htmlFor="">
                      Please ensure this is up to date
                    </label>
                    <div>
                      <div className="upload-btn-wrapper">
                        <button
                          type="button"
                          className="btn btn-outline-primary "
                        >
                          Upload file
                        </button>
                        <input
                          type="file"
                          name="file"
                          onChange={this.onHandleIncomeFileUpload}
                        />
                      </div>
                      <div>
                        {this.state.incomeStatementFiles.map(file => (
                          <div className="mb-2" key={file.name}>
                            {file.type === 'application/pdf' ? (
                              <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                            ) : (
                              <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                            )}
                            <span className="small pr-3">
                              <a
                                href={file.base64Url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                            </span>
                            <i
                              className="far fa-times-circle text-danger cursor-pointer"
                              onClick={() => this.onDeleteIncomeFile(file)}
                            ></i>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-6 px-md-4">
                    <h6>Balance Sheet</h6>
                    <label className="small text-muted" htmlFor="">
                      Please ensure this is up to date
                    </label>
                    <div>
                      <div className="upload-btn-wrapper">
                        <button
                          type="button"
                          className="btn btn-outline-primary "
                        >
                          Upload file
                        </button>
                        <input
                          type="file"
                          name="file"
                          onChange={this.onHandleBalanceFileUpload}
                        />
                      </div>
                      <div>
                        {this.state.balanceSheetFiles.map(file => (
                          <div className="mb-2" key={file.name}>
                            {file.type === 'application/pdf' ? (
                              <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                            ) : (
                              <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                            )}
                            <span className="small pr-3">
                              <a
                                href={file.base64Url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                            </span>
                            <i
                              className="far fa-times-circle text-danger cursor-pointer"
                              onClick={() => this.onDeleteBalanceFile(file)}
                            ></i>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-6 px-md-4 mt-3">
                    <h6>Incorporation Documents </h6>
                    <label className="small text-muted" htmlFor="">
                      Attach copies of your company registration documents
                    </label>
                    <div className="row">
                      <div className="col-8">
                        <select
                          ref={this.incorporationSelect}
                          className="form-control"
                          id="incorporationFiles"
                          name="incorporationFiles"
                        >
                          <option value="Momorandum of articles">
                            Memorandum of articles
                          </option>
                          <option value="Certificate of Incorporation">
                            Certificate of Incorporation
                          </option>
                          <option value="List of Directors">
                            List of Directors (CAC 7)
                          </option>
                        </select>
                      </div>
                      <div className="col-4">
                        <div>
                          <div className="upload-btn-wrapper">
                            <button
                              type="button"
                              className="btn btn-outline-primary "
                            >
                              Upload
                            </button>
                            <input
                              type="file"
                              name="file"
                              onChange={this.onHandleIncorporationFileUpload}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div>
                          {this.state.incorporationFiles.map(file => (
                            <div className="mb-2" key={file.name}>
                              <div className="font-weight-bold">
                                {file.category}
                              </div>
                              {file.type === 'application/pdf' ? (
                                <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                              ) : (
                                <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                              )}
                              <span className="small pr-3">
                                <a
                                  href={file.base64Url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {file.name}
                                </a>
                              </span>
                              <i
                                className="far fa-times-circle text-danger cursor-pointer"
                                onClick={() =>
                                  this.onDeleteIncorporationFile(file)
                                }
                              ></i>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-6 px-md-4 mt-3">
                    <h6>Any other Document </h6>
                    <label className="small text-muted" htmlFor="">
                      Attach any other document. Kindly select from the options
                      listed below
                    </label>
                    <div className="row">
                      <div className="col-8">
                        <select
                          ref={this.otherFileSelect}
                          className="form-control"
                          id="otherFiles"
                          name="otherFiles"
                        >
                          <option value="Investor's pitch">
                            Investor's pitch
                          </option>
                          <option value="Business Plan">Business Plan</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>

                      <div className="col-4">
                        <div>
                          <div className="upload-btn-wrapper">
                            <button
                              type="button"
                              className="btn btn-outline-primary "
                            >
                              Upload
                            </button>
                            <input
                              type="file"
                              name="file"
                              onChange={this.onHandleOtherFileUpload}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div>
                          {this.state.otherFiles.map(file => (
                            <div className="mb-2" key={file.name}>
                              <div className="font-weight-bold">
                                {file.category}
                              </div>
                              {file.type === 'application/pdf' ? (
                                <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                              ) : (
                                <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                              )}
                              <span className="small pr-3">
                                <a
                                  href={file.base64Url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {file.name}
                                </a>
                              </span>
                              <i
                                className="far fa-times-circle text-danger cursor-pointer"
                                onClick={() => this.onDeleteOtherFile(file)}
                              ></i>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-around align-items-around w-100 py-5">
                <Link
                  to={`/register-business/2?id=${this.state.id}`}
                  className="btn btn-lg btn-outline-primary bg-white text-primary w-lg-30 w-sm-60 w-xs-60 mb-3 mb-lg-0 mb-xl-0"
                >
                  Back
                </Link>
                <button
                  type="submit"
                  className="btn btn-primary btn-lg w-lg-30 w-sm-60 w-xs-60"
                >
                  {this.state.isSaving ? (
                    <CircularProgress color="inherit" size={'1rem'} />
                  ) : (
                    'Save and continue'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    formdata: state.business.formdata,
  }),
  { editListing },
)(AddBusinessForm3);
