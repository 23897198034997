import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import bgValuation from '../../assets/img/bg-valuation.png';
import { Switch } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  fetchContactedBusinesses,
  fetchContactedFranchises,
} from '../../state/actions/user';
import BusinessFranchisePlaceholder from '../../common/components/BusinessFranchisePlaceholder';
import FadeIn from 'react-fade-in';
import BusinessFranchiseListingItem from './../../common/components/BusinessFranchiseListingItem';

class ContactedListingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: 'business',
      loadingBusinesses: true,
      loadingFranchises: true,
      doneBusinesses: false,
      doneFranchises: false,
    };
  }

  handleOnSwitchChange = () => {
    let { toggle } = this.state;
    toggle = toggle === 'business' ? 'franchise' : 'business';
    this.setState({
      ...this.state,
      toggle,
    });
  };

  componentDidMount() {
    if (this.props.contactedBusinesses.length === 0) {
      this.props.fetchContactedBusinesses();
    }
    if (this.props.contactedFranchises.length === 0) {
      this.props.fetchContactedFranchises();
    }
    if (this.props.contactedFranchises.length > 0) {
      this.setState({ loadingFranchises: false, doneFranchises: true });
    }
    if (this.props.contactedBusinesses.length > 0) {
      this.setState({ loadingBusinesses: false, doneBusinesses: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.contactedBusinesses !== prevProps.contactedBusinesses) {
      fetchContactedBusinesses();
      this.setState({ ...this.state, loadingBusinesses: false });
      setTimeout(() => {
        this.setState({ doneBusinesses: true });
      }, 1000);
    }
    if (this.props.contactedFranchises !== prevProps.contactedFranchises) {
      fetchContactedFranchises();
      this.setState({ ...this.state, loadingFranchises: false });
      setTimeout(() => {
        this.setState({ doneFranchises: true });
      }, 1000);
    }
  }

  render() {
    const { contactedBusinesses, contactedFranchises } = this.props;

    let placeholders = [];
    for (let i = 0; i < 6; i++) {
      placeholders.push(
        <FadeIn key={i}>
          <div className="mb-5 mx-2">
            <BusinessFranchisePlaceholder />
          </div>
        </FadeIn>,
      );
    }

    return (
      <div>
        <div className="ml-lg-5">
          <h1>Businesses and franchises you contacted</h1>
          <div className="w-50 my-3 py-3">
            <hr></hr>
          </div>
          <div className="pl-2 my-4 h3 font-weight-normal">
            Businesses
            <Switch
              onChange={() => {
                this.handleOnSwitchChange();
              }}
              color="default"
            ></Switch>
            Franchises
          </div>
          <div
            className="d-flex flex-wrap justify-content-center
              justify-content-lg-start justify-content-xl-start"
          >
            {this.state.toggle === 'franchise' &&
              (this.state.loadingFranchises
                ? placeholders
                : contactedFranchises.map((listing, index) => (
                    <FadeIn key={index}>
                      <div>
                        <BusinessFranchiseListingItem
                          key={listing.id}
                          whichListingType={'franchise'}
                          listing={listing}
                          hasContacted={true}
                        />
                      </div>
                    </FadeIn>
                  )))}

            {this.state.toggle === 'business' &&
              (this.state.loadingBusinesses
                ? placeholders
                : contactedBusinesses.map((listing, index) => (
                    <FadeIn key={index}>
                      <div>
                        <BusinessFranchiseListingItem
                          key={listing.id}
                          whichListingType={'business'}
                          listing={listing}
                          hasContacted={true}
                        />
                      </div>
                    </FadeIn>
                  )))}
          </div>
          {this.state.toggle === 'franchise' &&
            this.state.doneFranchises &&
            contactedFranchises &&
            contactedFranchises.length === 0 && (
              <FadeIn>
                <div className="p-3 m-3 text-center pt-7 min-vh-50 text-danger">
                  <p className="mb-3 d-inline mr-3">
                    You have no contacted franchises. Why not
                  </p>
                  <NavLink
                    to="/franchises"
                    className="btn btn-outline-white bg-white shadow text-bright-primary rounded-2"
                  >
                    contact a franchise?
                  </NavLink>
                </div>
              </FadeIn>
            )}
          {this.state.toggle === 'business' &&
            this.state.doneFranchises &&
            contactedFranchises &&
            contactedFranchises.length === 0 && (
              <FadeIn>
                <div className="p-3 m-3 text-center pt-7 min-vh-50 text-danger">
                  <p className="mb-3 d-inline mr-3">
                    You have no contacted businesses. Why not
                  </p>
                  <NavLink
                    to="/businesses"
                    className="btn btn-outline-white bg-white shadow text-bright-primary rounded-2"
                  >
                    contact a business?
                  </NavLink>
                </div>
              </FadeIn>
            )}
          <div className="mt-5 pt-4"></div>
          <NavLink to="/businesses" className="btn btn-primary">
            Find more businesses
          </NavLink>
          <div className="mb-5 pb-4"></div>
          <div className="my-5 py-5">
            <div className="card bg-primary text-white border-0 rounded-0">
              <img
                className="card-img d-none d-lg-block d-xl-block"
                src={bgValuation}
                alt="Card"
              ></img>
              <div className="card-img-overlay d-none d-lg-block d-xl-block align-middle">
                <div className="d-flex h-100 align-items-center">
                  <h2 className="card-text h1a">
                    Online business valuation at your fingertips
                  </h2>
                  <button className="btn btn-primary w-25">Coming soon</button>
                </div>
              </div>
              <div className="card-body d-lg-none d-xl-none">
                <h2 className="card-text">
                  Online business valuation at your fingertips
                </h2>
                <button className="btn btn-outline-light my-3">
                  Coming soon
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    contactedBusinesses: state.user.contactedBusinesses,
    contactedFranchises: state.user.contactedFranchises,
  }),
  { fetchContactedBusinesses, fetchContactedFranchises },
)(ContactedListingsPage);
