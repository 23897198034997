import React, { Component } from 'react';
import tempAvatar from '../../assets/img/user.svg';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import UserRoutes from './UserRoutes';
import {
  fetchUserProposals,
  fetchUserProposalsInbox,
  fetchUserProposalSent,
} from '../../state/actions/user';
import auth from '../../common/services/authService';

class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 10,
    };
  }

  componentDidMount() {
    let { pageNumber, pageSize } = this.state;
    this.props.fetchUserProposalSent(pageNumber, pageSize);
    this.props.fetchUserProposalsInbox(pageNumber, pageSize);
  }

  logout = () => {
    auth.logOutUser();
    this.props.history.replace('/');
  };

  render() {
    const { currentUser, userProposals } = this.props;
    const { match } = this.props;
    let userProposalsInbox = this.props.userProposalsInbox;

    let unreadCount = 0;

    if (userProposalsInbox && userProposalsInbox.length > 0) {
      unreadCount = userProposalsInbox.filter(proposal => !proposal.isRead)
        .length;
    }
    return (
      <div>
        {currentUser && (
          <div className="d-flex flex-wrap mx-lg-5">
            <div className="w-16 d-none d-lg-block d-xl-block flex-column align-items-center">
              <div className="text-center" style={{ minHeight: '13rem' }}>
                <img
                  src={currentUser.photoUrl ? currentUser.photoUrl : tempAvatar}
                  alt="..."
                  className="w-rem-7 h-rem-7 img-fluid rounded-circle p-1 mb-4"
                ></img>
                <p className="text-primary font-weight-bold h5 text-overflow">
                  {currentUser.displayName}
                </p>
                <p className="text-overflow">{currentUser.email}</p>
              </div>
              <div className="sticky-top mb-5">
                <div className="card shadow-lg my-5 rounded-1 border-0">
                  <div className="card-body p-5">
                    <NavLink
                      activeClassName="text-bright-primary"
                      className="d-block   h8a text-darkdash font-weight-bold mb-3"
                      to={`${match.url}/dashboard`}
                    >
                      Dashboard
                    </NavLink>
                    <NavLink
                      activeClassName="text-bright-primary"
                      className="d-block   h8a text-darkdash font-weight-bold mb-3"
                      to={`${match.url}/contacts`}
                    >
                      Contacted profiles
                    </NavLink>

                    <NavLink
                      activeClassName="text-bright-primary"
                      className="d-block h8a text-darkdash font-weight-bold mb-3"
                      to={`${match.url}/businesses`}
                    >
                      Business profiles
                    </NavLink>
                    <NavLink
                      activeClassName="text-bright-primary"
                      className="d-block   h8a text-darkdash font-weight-bold mb-3"
                      to={`${match.url}/investors`}
                    >
                      Investor profiles
                    </NavLink>
                    <NavLink
                      activeClassName="text-bright-primary"
                      className="d-block   h8a text-darkdash font-weight-bold mb-3"
                      to={`${match.url}/advisors`}
                    >
                      Advisor profiles
                    </NavLink>
                    <NavLink
                      activeClassName="text-bright-primary"
                      className="d-block   h8a text-darkdash font-weight-bold mb-3"
                      to={`${match.url}/franchises`}
                    >
                      Franchise profiles
                    </NavLink>
                  </div>
                </div>

                <div className="card mt-5 shadow-lg rounded-1 border-0">
                  <div className="card-body py-5 pl-5">
                    <NavLink
                      activeClassName="text-bright-primary"
                      className="d-block   h8a text-darkdash font-weight-bold mb-3"
                      to={`${match.url}/subscriptions`}
                    >
                      Invoices
                    </NavLink>
                    <p
                      className="d-block h8a font-weight-bold mb-3 cursor-pointer"
                      href="#"
                      style={{ color: '#bfbfbf' }}
                    >
                      Bookmarks and history
                    </p>

                    <NavLink
                      activeClassName="text-bright-primary"
                      className="d-block   h8a text-darkdash font-weight-bold mb-3"
                      to={`${match.url}/inbox/proposals`}
                    >
                      <div className="position-relative max-w-rem-3a">
                        Inbox
                        {unreadCount > 0 && (
                          <div
                            className="counter-wrapper position-absolute"
                            style={{ top: '-2px', right: '0px' }}
                          >
                            <span
                              className="badge badge-danger counter"
                              style={{ fontSize: '.7rem' }}
                            >
                              {unreadCount}
                            </span>
                          </div>
                        )}
                      </div>
                    </NavLink>

                    <p
                      className="d-block h8a font-weight-bold mb-3 cursor-pointer"
                      style={{ color: '#bfbfbf' }}
                    >
                      Notifications
                    </p>
                    <NavLink
                      activeClassName="text-bright-primary"
                      className="d-block   h8a text-darkdash font-weight-bold mb-3"
                      to={`${match.url}/settings`}
                    >
                      Settings
                    </NavLink>
                    <button
                      className="button-as-link d-block h8a text-darkdash font-weight-bold mb-3"
                      onClick={this.logout}
                    >
                      Log out
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-lg-80 w-xl-80 w-md-100 w-xs-100 w-sm-100 container">
              <UserRoutes
                currentUser={currentUser}
                userProposals={userProposals}
                {...this.props}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser,
    userProposals: state.user.userProposals,
    userProposalsInbox: state.user.userProposalsInbox,
    userProposalSent: state.user.userProposalSent,
  };
};

export default connect(mapStateToProps, {
  fetchUserProposals,
  fetchUserProposalsInbox,
  fetchUserProposalSent,
})(UserDashboard);
