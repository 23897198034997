import http from './httpService';
import baseApiUrl from '../../config';

const getAdvisors = (pageNumber = 1) => {
  return http.get(`${baseApiUrl}advisors?pageNumber=${pageNumber}`);
};

const getAdvisor = (id, edit) => {
  const queryString = edit ? '?edit=true' : '';
  return http.get(`${baseApiUrl}advisors/${id}${queryString}`);
};

const deleteAdvisor = async id => {
  try {
    const { status } = await http.post(`${baseApiUrl}advisors/${id}/delete`);
    if (status === 200) {
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

const addAdvisor = async advisorDto => {
  const { data } = await http.post(
    `${baseApiUrl}advisors/register`,
    advisorDto,
  );

  return data;
};

const getAdminAdvisorListings = (pageNumber = 1) => {
  try {
    return http.get(`${baseApiUrl}advisors?all=true&pageNumber=${pageNumber}`);
  } catch (error) {
    console.log(error);
  }
};

const searchAdminAdvisorListings = (
  startDate,
  endDate,
  searchQuery,
  status,
  byId = false,
  pageNumber = 1,
) => {
  try {
    startDate = startDate && startDate.toISOString();
    endDate = endDate && endDate.toISOString();
    if (startDate && endDate)
      return http.get(
        `${baseApiUrl}advisors?all=true&startDate=${startDate}&endDate=${endDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else if (startDate)
      return http.get(
        `${baseApiUrl}advisors?all=true&startDate=${startDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else if (endDate)
      return http.get(
        `${baseApiUrl}advisors?all=true&endDate=${endDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else
      return http.get(
        `${baseApiUrl}advisors?all=true&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
  } catch (error) {
    console.log(error);
  }
};

const approveAdvisorListing = async id => {
  try {
    const { status } = await http.post(`${baseApiUrl}advisors/${id}/approve`);
    if (status === 200) return true;
  } catch (error) {
    console.log(error);
  }
};

const disapproveAdvisorListing = async id => {
  try {
    const { status } = await http.post(
      `${baseApiUrl}advisors/${id}/disapprove`,
    );
    if (status === 200) return true;
  } catch (error) {
    console.log(error);
  }
};

const declineAdvisorListing = async dto => {
  const { status } = await http.post(
    `${baseApiUrl}advisors/${dto.profileId}/decline`,
    {
      reason: dto.reason,
    },
  );
  if (status === 200) return true;
};

const requestAdvisorListingChange = async dto => {
  const { status } = await http.post(
    `${baseApiUrl}advisors/${dto.profileId}/request-change`,
    {
      reason: dto.reason,
    },
  );
  if (status === 200) return true;
};

export default {
  getAdvisors,
  getAdvisor,
  addAdvisor,
  getAdminAdvisorListings,
  searchAdminAdvisorListings,
  approveAdvisorListing,
  disapproveAdvisorListing,
  deleteAdvisor,
  declineAdvisorListing,
  requestAdvisorListingChange,
};
