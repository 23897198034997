import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

const ProposalDetails = ({
  proposals,
  proposalType,
  viewListing,
  resetURL,
  viewButtonLoading,
  viewLinkLoading,
}) => {
  let { id } = useParams();
  id = parseInt(id);
  let proposalThread = proposals.find(mthread => mthread.id === id);
  const senderId = proposalThread && proposalThread['proposals'][0].userFrom;
  let senderDes = '';
  let senderAdvisor = proposalThread && proposalThread['proposals'][0].advisor;
  let senderInvestor =
    proposalThread && proposalThread['proposals'][0].investor;
  let senderBusiness =
    proposalThread && proposalThread['proposals'][0].business;
  let senderFranchise =
    proposalThread && proposalThread['proposals'][0].franchise;
  let senderStartup = proposalThread && proposalThread['proposals'][0].startup;
  if (senderAdvisor) {
    senderDes = senderAdvisor.whoYouAre;
  }
  if (senderInvestor) {
    senderDes = senderInvestor.whoYouAre;
  }
  if (senderBusiness) {
    senderDes = senderBusiness.businessName;
  }
  if (senderFranchise) {
    senderDes = senderFranchise.brandName;
  }
  if (senderStartup) {
    senderDes = senderStartup.startupName;
  }
  const participant =
    proposalThread &&
    proposalThread['proposalParticipants'].find(
      participant => participant.userID === senderId,
    );
  const subject = proposalThread && proposalThread['subject'];

  const advisor = proposalThread && proposalThread['advisor'];
  const investor = proposalThread && proposalThread['investor'];
  let adviorDes = '';
  let investorDes = '';
  let investorID = 0,
    advisorID = 0;
  if (investor) {
    investorDes = investor.whoYouAre;
    investorID = investor.id;
  }
  if (advisor) {
    adviorDes = advisor.whoYouAre;
    advisorID = advisor.id;
  }
  const recipient = investorDes !== '' ? investorDes : adviorDes;
  const proposalBody = proposalThread && proposalThread['proposals'][0].body;
  let sharedListingID = '';
  let sharedListingType = '';
  let listingLink = '';
  if (
    participant &&
    participant.businessID !== null &&
    participant.businessID !== 0
  ) {
    sharedListingID = `/${participant && participant.businessID}`;
    sharedListingType = 'businesses';
  } else if (
    participant &&
    participant.startupID !== null &&
    participant.startupID !== 0
  ) {
    sharedListingID = `/${participant && participant.startupID}`;
    sharedListingType = 'startups';
  } else if (
    participant &&
    participant.advisorID !== null &&
    participant.advisorID !== 0
  ) {
    sharedListingID = `/${participant && participant.advisorID}`;
    sharedListingType = 'advisors';
  } else if (
    participant &&
    participant.franchiseID !== null &&
    participant.franchiseID !== 0
  ) {
    sharedListingID = `/${participant && participant.franchiseID}`;
    sharedListingType = 'franchises';
  } else if (
    participant &&
    participant.investorID !== null &&
    participant.investorID !== 0
  ) {
    sharedListingID = `/${participant && participant.investorID}`;
    sharedListingType = 'investors';
  } else {
    sharedListingID = '#';
    sharedListingType = 'user/inbox/proposals';
  }
  listingLink = `/${sharedListingType}${sharedListingID}`;

  return (
    <div className=" pb-5 pt-2">
      <div className="border-bottom mt-4">
        <div className="">
          <p className="msg-subject font-weight-bold mt-2 mb-2">{subject}</p>
          <div className="mb-3 mt-1">
            To:{' '}
            <Link
              to={
                investorDes !== ''
                  ? `/investors/${investorID}`
                  : `/advisors/${advisorID}`
              }
            >
              <i className="fa fa-user ml-2 mr-1" aria-hidden="true"></i>{' '}
              {recipient}{' '}
            </Link>
            <span className="mx-2">
              {' '}
              from:{' '}
              <button
                id="link"
                className="button-as-link text-primary"
                onClick={e => {
                  viewListing(
                    listingLink,
                    id,
                    proposalType,
                    sharedListingType,
                    e,
                  );
                }}
              >
                <i className="fa fa-user ml-2 mr-1" aria-hidden="true"></i>
                {senderDes}{' '}
              </button>
              {viewLinkLoading && (
                <CircularProgress color="inherit" size={'1rem'} />
              )}
            </span>
            &nbsp;<i className="mdi mdi-account-multiple-plus"></i>
          </div>
        </div>
      </div>
      <div className="p-4 message-content">{proposalBody}</div>
      <div className="border-top pt-4 pb-6">
        <p>
          <button
            id="button"
            type="button"
            onClick={e => {
              viewListing(listingLink, id, proposalType, sharedListingType, e);
            }}
            className="btn btn-primary btn-lg rounded-pill mr-4"
          >
            {viewButtonLoading ? (
              <CircularProgress color="inherit" size={'1rem'} />
            ) : (
              ' View Listing'
            )}
          </button>
          <Link
            to={`/user/inbox/proposals`}
            className={
              proposalType === 'sent'
                ? 'd-none'
                : 'btn btn-secondary btn-lg rounded-pill'
            }
            onClick={() => {
              resetURL();
            }}
          >
            Ignore Listing
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ProposalDetails;
