import {
  HIDE_NAVBAR,
  HIDE_FOOTER,
  SHOW_FOOTER,
  SHOW_NAVBAR,
  SET_HEADERS,
  RESET_HEADERS,
} from './types';

export const hideNavbar = () => dispatch => {
  dispatch({
    type: HIDE_NAVBAR,
  });
};

export const showNavbar = () => dispatch => {
  dispatch({
    type: SHOW_NAVBAR,
  });
};

export const hideFooter = () => dispatch => {
  dispatch({
    type: HIDE_FOOTER,
  });
};

export const showFooter = () => dispatch => {
  dispatch({
    type: SHOW_FOOTER,
  });
};

export const setHeaders = headers => dispatch => {
  dispatch({
    type: SET_HEADERS,
    payload: headers,
  });
};

export const resetHeaders = () => dispatch => {
  dispatch({
    type: RESET_HEADERS,
  });
};
