import {
  SHOW_NAVBAR,
  HIDE_NAVBAR,
  SHOW_FOOTER,
  HIDE_FOOTER,
  SET_HEADERS,
  RESET_HEADERS,
} from '../actions/types';

const initialState = {
  showNavbar: true,
  showFooter: true,
  headers: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HIDE_NAVBAR:
      return {
        ...state,
        showNavbar: false,
      };
    case HIDE_FOOTER:
      return {
        ...state,
        showFooter: false,
      };
    case SHOW_NAVBAR:
      return {
        ...state,
        showNavbar: true,
      };
    case SHOW_FOOTER:
      return {
        ...state,
        showFooter: true,
      };
    case SET_HEADERS:
      return {
        ...state,
        headers: action.payload,
      };
    case RESET_HEADERS:
      return {
        ...state,
        headers: {},
      };
    default:
      return state;
  }
};
