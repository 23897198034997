import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setHeaders, resetHeaders } from './../../state/actions/layout';
import { withRouter } from 'react-router-dom';

const Contact = props => {
  const description = 'You can reach us via call or email';
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setHeaders({
        title: 'Contact',
        url: props.history.location.pathname,
        description,
      }),
    );

    return function cleanup() {
      dispatch(resetHeaders());
    };
  }, [dispatch, description, props.history.location.pathname]);
  return (
    <div className="container custom-home py-5">
      <div className="d-flex flex-column align-items-center justify-content-center text-center bg-contact mb-5">
        <p className="h1 text-subscribe-light font-weight-bold">Contact Us</p>
      </div>
      <div className="d-flex flex-wrap justify-content-between">
        <div className="d-flex flex-column">
          <div className="d-flex mb-5">
            <i className="fas fa-phone-alt text-primary font-size-2 mr-3"></i>
            <div>
              <p className="text-about h4 font-weight-bold">Phone</p>
              <p className="tex-about m-0">+234-817-999-9931</p>
              <p className="tex-about m-0">+234-817-999-9930</p>
            </div>
          </div>
          <div className="d-flex mb-5">
            <i className="fas fa-envelope text-primary font-size-2 mr-3"></i>
            <div>
              <p className="text-about h4 font-weight-bold">Email</p>
              <p className="tex-about m-0">info@compexafrica.com</p>
              <p className="tex-about m-0">hello@compexafrica.com</p>
            </div>
          </div>
          <div className="d-flex mb-5">
            <i className="fas fa-map-marker-alt text-primary font-size-1 mr-3"></i>
            <div>
              <p className="text-about h4 font-weight-bold">Address</p>
              <p className="tex-about m-0">
                Plot 287 Ajose Adeogun Street, Victoria Island. Lagos.
              </p>
            </div>
          </div>
        </div>
        <div className="w-100 d-lg-none d-xl-none"></div>
        <div className="col-lg-6 col-12">
          <iframe
            title="googlemap"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.710314251242!2d3.4343198142967233!3d6.431247126014341!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf523a5e811bf%3A0x113cf719cd01ee97!2s287%20Ajose%20Adeogun%20St%2C%20Victoria%20Island%2023401%2C%20Lagos!5e0!3m2!1sen!2sng!4v1577107927592!5m2!1sen!2sng"
            width="100%"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Contact);
