import userService from '../../common/services/userService';
import {
  SET_CURRENT_USER,
  LOGIN_USER,
  LOGOUT_USER,
  FETCH_USER_LISTINGS,
  ADD_PROPOSAL_TO_INVESTOR_LISTINGS,
  ADD_PROPOSAL_TO_ADVISOR_LISTINGS,
  ADD_TO_SENT_PROPOSALS,
  FETCH_USER_INVESTORS,
  FETCH_USER_ADVISORS,
  FETCH_USER_FRANCHISES,
  FETCH_USER_PROPOSALS,
  FETCH_USER_PROPOSALS_INBOX,
  FETCH_USER_PROPOSALS_SENT,
  FETCH_MORE_SENT_PROPOSALS,
  FETCH_MORE_INBOX_PROPOSALS,
  FETCH_PROPOSALS_INBOX_STARTED,
  FETCH_PROPOSALS_SENT_STARTED,
  FETCH_MORE_SENT_PROPOSALS_STARTED,
  FETCH_MORE_INBOX_PROPOSALS_STARTED,
  FETCH_CONTACTED_BUSINESSES,
  FETCH_CONTACTED_FRANCHISES,
  FETCH_USERS,
  SEARCH_USERS_STARTED,
  FETCH_USER_NOTIFICATIONS,
  ADD_ALL_USER_LISTINGS,
} from './types';

export const fetchUserProposals = (
  pageNumber,
  pageSize,
  searchString = '',
) => dispatch => {
  userService.getUserProposals(pageNumber, pageSize, searchString).then(
    res => {
      dispatch({
        type: FETCH_USER_PROPOSALS,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_USER_PROPOSALS,
        payload: [],
      });
    },
  );
};

export const fetchUserProposalsInbox = (
  pageNumber,
  pageSize,
  searchString = '',
) => async dispatch => {
  dispatch({ type: FETCH_PROPOSALS_INBOX_STARTED });
  await userService
    .getUserProposalsInbox(pageNumber, pageSize, searchString)
    .then(
      res => {
        dispatch({
          type: FETCH_USER_PROPOSALS_INBOX,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: FETCH_USER_PROPOSALS_INBOX,
          payload: {},
        });
      },
    );
};

export const fetchUserProposalSent = (
  pageNumber,
  pageSize,
  searchString = '',
) => async dispatch => {
  dispatch({ type: FETCH_PROPOSALS_SENT_STARTED });
  await userService
    .getUserProposalSent(pageNumber, pageSize, searchString)
    .then(
      res => {
        dispatch({
          type: FETCH_USER_PROPOSALS_SENT,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: FETCH_USER_PROPOSALS_SENT,
          payload: {},
        });
      },
    );
};

export const fetchMoreSentProposals = (
  pageNumber,
  pageSize,
  searchString = '',
) => async dispatch => {
  dispatch({ type: FETCH_MORE_SENT_PROPOSALS_STARTED });
  await userService
    .getUserProposalSent(pageNumber, pageSize, searchString)
    .then(
      res => {
        dispatch({
          type: FETCH_MORE_SENT_PROPOSALS,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: FETCH_MORE_SENT_PROPOSALS,
          payload: {},
        });
      },
    );
};

export const fetchMoreInboxProposals = (
  pageNumber,
  pageSize,
  searchString = '',
) => async dispatch => {
  dispatch({ type: FETCH_MORE_INBOX_PROPOSALS_STARTED });
  await userService
    .getUserProposalsInbox(pageNumber, pageSize, searchString)
    .then(
      res => {
        dispatch({
          type: FETCH_MORE_INBOX_PROPOSALS,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: FETCH_MORE_INBOX_PROPOSALS,
          payload: {},
        });
      },
    );
};

export const fetchUserListings = userId => dispatch => {
  userService.getUserListings(userId).then(
    res => {
      dispatch({
        type: FETCH_USER_LISTINGS,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_USER_LISTINGS,
        payload: { data: [] },
      });
    },
  );
};

export const fetchUserInvestorListings = userId => dispatch => {
  userService.getUserInvestorListings(userId).then(
    res => {
      dispatch({
        type: FETCH_USER_INVESTORS,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_USER_INVESTORS,
        payload: { data: [] },
      });
    },
  );
};

export const fetchUserNotifications = () => dispatch => {
  userService.getNotifications().then(
    res => {
      dispatch({
        type: FETCH_USER_NOTIFICATIONS,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_USER_NOTIFICATIONS,
        payload: [],
      });
    },
  );
};

export const fetchUserAdvisorListings = userId => dispatch => {
  userService.getUserAdvisorListings(userId).then(
    res => {
      dispatch({
        type: FETCH_USER_ADVISORS,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_USER_ADVISORS,
        payload: { data: [] },
      });
    },
  );
};

export const fetchUserFranchiseListings = userId => dispatch => {
  userService.getUserFranchiseListings(userId).then(
    res => {
      dispatch({
        type: FETCH_USER_FRANCHISES,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_USER_FRANCHISES,
        payload: { data: [] },
      });
    },
  );
};

export const setCurrentUser = user => dispatch => {
  dispatch({ type: SET_CURRENT_USER, payload: user });
};

export const loginUser = () => dispatch => {
  dispatch({ type: LOGIN_USER });
};

export const logoutUser = () => dispatch => {
  dispatch({ type: LOGOUT_USER });
};

export const getAllUserListings = () => dispatch => {
  try {
    userService.getProposalListings().then(
      res => {
        dispatch({
          type: ADD_ALL_USER_LISTINGS,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: ADD_ALL_USER_LISTINGS,
          payload: [],
        });
      },
    );
  } catch (error) {}
};

export const addProposalListings = sendTo => dispatch => {
  try {
    userService.getProposalListings(sendTo).then(
      res => {
        switch (sendTo) {
          case 'investor':
            dispatch({
              type: ADD_PROPOSAL_TO_INVESTOR_LISTINGS,
              payload: res.data,
            });
            break;
          case 'advisor':
            dispatch({
              type: ADD_PROPOSAL_TO_ADVISOR_LISTINGS,
              payload: res.data,
            });
            break;
          default:
            break;
        }
      },
      err => {
        switch (sendTo) {
          case 'investor':
            dispatch({
              type: ADD_PROPOSAL_TO_INVESTOR_LISTINGS,
              payload: [],
            });
            break;
          case 'advisor':
            dispatch({
              type: ADD_PROPOSAL_TO_ADVISOR_LISTINGS,
              payload: [],
            });
            break;
          default:
            break;
        }
      },
    );
  } catch (error) {}
};

export const addToSentProposals = proposal => dispatch => {
  dispatch({ type: ADD_TO_SENT_PROPOSALS, payload: proposal });
};

export const fetchContactedBusinesses = () => dispatch => {
  try {
    userService.getContactedBusinesses().then(
      res => {
        dispatch({
          type: FETCH_CONTACTED_BUSINESSES,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: FETCH_CONTACTED_BUSINESSES,
          payload: { data: [] },
        });
      },
    );
  } catch (error) {
    console.error('error fetching contacted businesses: ' + error);
  }
};

export const fetchContactedFranchises = () => dispatch => {
  try {
    userService.getContactedFranchises().then(
      res => {
        dispatch({
          type: FETCH_CONTACTED_FRANCHISES,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: FETCH_CONTACTED_FRANCHISES,
          payload: { data: [] },
        });
      },
    );
  } catch (error) {
    console.error('error fetching contacted franchises: ' + error);
  }
};

export const fetchAdminUserListings = (pageNumber = 1) => dispatch => {
  dispatch({ type: SEARCH_USERS_STARTED });
  userService.getAllUsers(pageNumber).then(
    res => {
      dispatch({
        type: FETCH_USERS,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_USERS,
        payload: { data: [] },
      });
    },
  );
};

export const searchAdminUserListings = (
  startDate,
  endDate,
  searchQuery,
  status,
  byId,
  pageNumber = 1,
) => async dispatch => {
  dispatch({ type: SEARCH_USERS_STARTED });
  await userService
    .searchUsers(startDate, endDate, searchQuery, status, byId, pageNumber)
    .then(
      res => {
        dispatch({
          type: FETCH_USERS,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: FETCH_USERS,
          payload: { data: [] },
        });
      },
    );
};
