import React from 'react';
import tempAvatar from '../../assets/img/user.svg';
import { useHistory } from 'react-router-dom';
import Truncate from 'react-truncate';
import { getAbbreviation } from '../utils/helpers';

const AdvisorInvestorListingItem = props => {
  const history = useHistory();
  const { listing, whichListingType } = props;
  const imgUrl =
    listing.photos && listing.photos.length > 0
      ? listing.photos[0].fileUrl
      : tempAvatar;

  return (
    <div className="investor-listing-item">
      <div
        className={`${props.className}
                d-flex flex-column 
                rounded-2 shadow bg-white
                py-3 px-4 mb-5 mx-2`}
      >
        <div className="d-flex flex-row align-items-start justify-content-between">
          <div className="w-15" href="#">
            <img
              src={imgUrl}
              alt="..."
              className=" img-fluid rounded-circle p-1"
              style={{
                minHeight: '4rem',
                minWidth: '4rem',
                maxHeight: '4rem',
                maxWidth: '4rem',
              }}
            ></img>
          </div>
          <div
            className="rounded-circle bg-success"
            style={{ width: '8px', height: '8px' }}
          ></div>
          <div className="w-70 line-height-2 mb-3">
            <p className="h8 d-inline">{listing.whoYouAre}</p>
            <br></br>
            <p className="h10 d-inline">
              {listing.designation}{' '}
              {listing.locations && ' in ' + listing.locations}
            </p>
          </div>
        </div>
        <div className="w-100 border-top mb-3"></div>
        <div className="d-flex flex-row justify-content-between">
          <div className="h9 line-height-2 font-weight-bold">Interests:</div>
          <div className="h9 line-height-2 w-70 text-right">
            <Truncate lines={1} ellipsis={<span>...</span>}>
              {listing.interests && listing.interests}
            </Truncate>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="h9 line-height-2 font-weight-bold">Background:</div>
          <div className="h9 line-height-2 w-70 text-right">
            <Truncate lines={3} ellipsis={<span>...</span>}>
              {listing.aboutYourself && listing.aboutYourself}
            </Truncate>
          </div>
        </div>
        <div className="w-100 border-top mb-3"></div>
        <div className="d-flex flex-row justify-content-between">
          <div className="h9 line-height-2 font-weight-bold">Locations</div>
          <div className="h9 line-height-2 w-70 text-right">
            {listing.locations && listing.locations}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="h9 line-height-2 font-weight-bold">Industries</div>
          <div className="h9 line-height-2 w-70 text-right">
            <Truncate lines={4} ellipsis={<span>...</span>}>
              {listing.industries && listing.industries}
            </Truncate>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="h9 line-height-2 font-weight-bold">
            Investment size
          </div>
          <div className="h9 line-height-2">
            ₦&nbsp;{getAbbreviation(listing.investmentRangeFrom)}
            &nbsp;-&nbsp; ₦&nbsp;{getAbbreviation(listing.investmentRangeTo)}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-end py-2 mt-auto mb-3">
          {listing &&
            (whichListingType === 'advisor' ? (
              <button
                onClick={e => {
                  e.preventDefault();
                  history.push(`/advisors/${listing.id}`);
                }}
                className="btn btn-outline-primary font-weight-bold py-1 px-2"
              >
                Send Proposal
              </button>
            ) : whichListingType === 'investor' ? (
              <button
                onClick={e => {
                  e.preventDefault();
                  history.push(`/investors/${listing.id}`);
                }}
                className="btn btn-outline-primary font-weight-bold py-1 px-2"
              >
                Send Proposal
              </button>
            ) : (
              <div></div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AdvisorInvestorListingItem;
