const profileStatus = {
  Draft: 'Draft',
  Pending: 'Pending',
  Approved: 'Approved',
  Declined: 'Declined',
  ChangesRequired: 'ChangesRequired',
};

Object.freeze(profileStatus);

export default profileStatus;
