import { combineReducers } from 'redux';
import layoutReducer from './layout';
import userReducer from './user';
import businessReducer from './business';
import investorReducer from './investor';
import advisorReducer from './advisor';
import franchiseReducer from './franchise';
import startupReducer from './startup';

export default combineReducers({
  layout: layoutReducer,
  user: userReducer,
  business: businessReducer,
  investor: investorReducer,
  advisor: advisorReducer,
  franchise: franchiseReducer,
  startup: startupReducer,
});
