import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUserInvestorListings } from '../../state/actions/user';
import AdvisorInvestorPlaceholder from '../../common/components/AdvisorInvestorPlaceholder';
import FadeIn from 'react-fade-in';
import UserAdvisorInvestorItem from './UserAdvisorInvestorItem';
import { NavLink } from 'react-router-dom';

class UserInvestorListings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      done: false,
    };
    this.refreshListings = this.refreshListings.bind(this);
  }

  componentDidMount() {
    if (this.props.currentUser.userId) {
      this.props.fetchUserInvestorListings(this.props.currentUser.userId);
    }
    if (this.props.userInvestorListings.length > 0) {
      this.setState({ loading: false, done: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentUser.userId !== prevProps.currentUser.userId) {
      this.props.fetchUserInvestorListings(this.props.currentUser.userId);
    }
    if (this.props.userInvestorListings !== prevProps.userInvestorListings) {
      this.setState({ ...this.state, loading: false });
      setTimeout(() => {
        this.setState({ done: true });
      }, 1500);
    }
  }

  refreshListings() {
    this.props.fetchUserInvestorListings(this.props.currentUser.userId);
  }

  render() {
    const { userInvestorListings } = this.props;
    let placeholders = [];
    for (let i = 0; i < 6; i++) {
      placeholders.push(
        <FadeIn key={i}>
          <div className="mb-5 mx-2">
            <AdvisorInvestorPlaceholder />
          </div>
        </FadeIn>,
      );
    }

    return (
      <>
        <div
          className="d-flex flex-wrap justify-content-center
              justify-content-lg-start justify-content-xl-start"
        >
          {this.state.loading
            ? placeholders
            : userInvestorListings.map((listing, index) => (
                <FadeIn key={index}>
                  <UserAdvisorInvestorItem
                    key={listing.id}
                    whichListing={'investor'}
                    listing={listing}
                    currentUser={this.props.currentUser}
                    className="fixed-width-height"
                    refreshListings={this.refreshListings}
                  />
                </FadeIn>
              ))}
        </div>
        {this.state.done &&
          userInvestorListings &&
          userInvestorListings.length === 0 && (
            <FadeIn>
              <div className="p-3 m-3 text-center pt-7 min-vh-50 text-danger">
                <p className="mb-3 d-inline mr-3">
                  You have no investor profile. Why not
                </p>
                <NavLink
                  to="/register-investor/plans"
                  className="btn btn-outline-white bg-white shadow text-bright-primary rounded-2"
                >
                  list an investor profile?
                </NavLink>
              </div>
            </FadeIn>
          )}
      </>
    );
  }
}

export default connect(
  state => ({
    userInvestorListings: state.user.userInvestorListings,
    currentUser: state.user.currentUser,
  }),
  { fetchUserInvestorListings },
)(UserInvestorListings);
