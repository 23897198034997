import {
  SET_CURRENT_USER,
  LOGIN_USER,
  LOGOUT_USER,
  ADD_PROPOSAL_TO_INVESTOR_LISTINGS,
  ADD_PROPOSAL_TO_ADVISOR_LISTINGS,
  ADD_TO_SENT_PROPOSALS,
  FETCH_USER_ADVISORS,
  FETCH_USER_FRANCHISES,
  FETCH_USER_LISTINGS,
  FETCH_USER_INVESTORS,
  FETCH_USER_PROPOSALS,
  FETCH_USER_PROPOSALS_INBOX,
  FETCH_USER_PROPOSALS_SENT,
  FETCH_PROPOSALS_INBOX_STARTED,
  FETCH_PROPOSALS_SENT_STARTED,
  FETCH_MORE_SENT_PROPOSALS,
  FETCH_MORE_INBOX_PROPOSALS,
  FETCH_MORE_SENT_PROPOSALS_STARTED,
  FETCH_MORE_INBOX_PROPOSALS_STARTED,
  FETCH_CONTACTED_BUSINESSES,
  FETCH_CONTACTED_FRANCHISES,
  FETCH_USERS,
  SEARCH_USERS_STARTED,
  FETCH_USER_NOTIFICATIONS,
  ADD_ALL_USER_LISTINGS,
} from '../actions/types';

const initialState = {
  userAdvisorListings: [],
  userFranchiseListings: [],
  userInvestorListings: [],
  currentUser: {},
  userProposals: [],
  userProposalsInbox: [],
  userProposalSent: [],
  hasMoreInboxProposals: false,
  hasMoreSentProposals: false,
  isLoggedIn: false,
  loadingSentProposals: false,
  loadingInboxProposals: false,
  loadingMoreSentProposals: false,
  loadingMoreInboxProposals: false,
  proposalToInvestorListings: [],
  proposalToAdvisorListings: [],
  sentProposals: [],
  contactedBusinesses: [],
  contactedFranchises: [],
  adminUserListings: [],
  loadingUserListings: false,
  userNotifications: [],
  userBusinessListings: [],
  pageNumber: 1,
  pageSize: 50,
  totalCount: 0,
  allUserListings: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        isLoggedIn: true,
      };
    case LOGOUT_USER:
      return {
        ...state,
        isLoggedIn: false,
      };
    case FETCH_USER_ADVISORS:
      return {
        ...state,
        userAdvisorListings: action.payload.data,
      };
    case FETCH_USER_INVESTORS:
      return {
        ...state,
        userInvestorListings: action.payload.data,
      };
    case FETCH_USER_NOTIFICATIONS:
      return {
        ...state,
        userNotifications: action.payload,
      };
    case FETCH_USER_FRANCHISES:
      return {
        ...state,
        userFranchiseListings: action.payload.data,
      };
    case FETCH_USER_LISTINGS:
      return {
        ...state,
        userBusinessListings: action.payload.data,
      };
    case FETCH_USER_PROPOSALS:
      return {
        ...state,
        hasMoreProposals: action.payload.hasNext,
        userProposals: action.payload.data,
      };
    case FETCH_USER_PROPOSALS_INBOX:
      return {
        ...state,
        hasMoreInboxProposals: action.payload.hasNext,
        userProposalsInbox: action.payload.data,
        loadingInboxProposals: false,
      };
    case FETCH_MORE_INBOX_PROPOSALS:
      let { userProposalsInbox } = state;
      if (Object.keys(action.payload).length !== 0) {
        action.payload.data.forEach(e => {
          userProposalsInbox.push(e);
        });
      }
      return {
        ...state,
        hasMoreInboxProposals: action.payload.hasNext,
        userProposalsInbox,
        loadingMoreInboxProposals: false,
      };
    case FETCH_USER_PROPOSALS_SENT:
      return {
        ...state,
        hasMoreSentProposals: action.payload.hasNext,
        userProposalSent: action.payload.data,
        loadingSentProposals: false,
      };
    case FETCH_MORE_SENT_PROPOSALS:
      let { userProposalSent } = state;
      if (Object.keys(action.payload).length !== 0) {
        action.payload.data.forEach(e => {
          userProposalSent.push(e);
        });
      }
      return {
        ...state,
        hasMoreSentProposals: action.payload.hasNext,
        userProposalSent,
        loadingMoreSentProposals: false,
      };
    case FETCH_PROPOSALS_SENT_STARTED:
      return {
        ...state,
        loadingSentProposals: true,
      };
    case FETCH_PROPOSALS_INBOX_STARTED:
      return {
        ...state,
        loadingInboxProposals: true,
      };
    case FETCH_MORE_SENT_PROPOSALS_STARTED:
      return {
        ...state,
        loadingMoreSentProposals: true,
      };
    case FETCH_MORE_INBOX_PROPOSALS_STARTED:
      return {
        ...state,
        loadingMoreInboxProposals: true,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case ADD_PROPOSAL_TO_INVESTOR_LISTINGS:
      return {
        ...state,
        proposalToInvestorListings: action.payload,
      };
    case ADD_ALL_USER_LISTINGS:
      return {
        ...state,
        allUserListings: action.payload,
      };
    case ADD_PROPOSAL_TO_ADVISOR_LISTINGS:
      return {
        ...state,
        proposalToAdvisorListings: action.payload,
      };
    case ADD_TO_SENT_PROPOSALS:
      let { sentProposals } = { ...state };
      sentProposals = [...sentProposals, action.payload];
      return {
        ...state,
        sentProposals,
      };
    case FETCH_CONTACTED_BUSINESSES:
      return {
        ...state,
        contactedBusinesses: action.payload,
      };
    case FETCH_CONTACTED_FRANCHISES:
      return {
        ...state,
        contactedFranchises: action.payload,
      };
    case FETCH_USERS:
      return {
        ...state,
        adminUserListings: action.payload.data,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount,
        loadingUserListings: false,
      };
    case SEARCH_USERS_STARTED:
      return {
        ...state,
        loadingUserListings: true,
      };
    default:
      return state;
  }
};
