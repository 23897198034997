import commonService from '../../common/services/commonService';
import {
  UPDATE_BUSINESSES,
  UPDATE_INVESTORS,
  UPDATE_ADVISORS,
  UPDATE_FRANCHISES,
} from './types';

export const searchListings = (listingType, data) => dispatch => {
  let action = '';
  if (listingType === 'business') action = UPDATE_BUSINESSES;
  if (listingType === 'investor') action = UPDATE_INVESTORS;
  if (listingType === 'advisor') action = UPDATE_ADVISORS;
  if (listingType === 'franchise') action = UPDATE_FRANCHISES;

  if (action) {
    commonService
      .searchListings(listingType, data)
      .then(res => {
        dispatch({
          type: action,
          payload: res.data,
        });
      })
      .catch(err => {
        dispatch({
          type: action,
          payload: { data: [] },
        });
      });
  }
};
