import http from './httpService';
import baseApiUrl from '../../config';

const getInvestors = (queryString = '', pageNumber = 1) => {
  return http.get(
    `${baseApiUrl}investors?interests=${queryString}&pageNumber=${pageNumber}`,
  );
};

const getInvestor = (id, edit) => {
  const queryString = edit ? '?edit=true' : '';
  return http.get(`${baseApiUrl}investors/${id}${queryString}`);
};

const deleteInvestor = async id => {
  try {
    const { status } = await http.post(`${baseApiUrl}investors/${id}/delete`);
    if (status === 200) {
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

const addProfile = async investorDto => {
  const { data } = await http.post(
    `${baseApiUrl}investors/register`,
    investorDto,
  );

  return data;
};

const getAdminInvestorListings = (pageNumber = 1) => {
  try {
    return http.get(`${baseApiUrl}investors?all=true&pageNumber=${pageNumber}`);
  } catch (error) {
    console.log(error);
  }
};

const searchAdminInvestorListings = (
  startDate,
  endDate,
  searchQuery,
  status,
  byId = false,
  pageNumber = 1,
) => {
  try {
    startDate = startDate && startDate.toISOString();
    endDate = endDate && endDate.toISOString();
    if (startDate && endDate)
      return http.get(
        `${baseApiUrl}investors?all=true&startDate=${startDate}&endDate=${endDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else if (startDate)
      return http.get(
        `${baseApiUrl}investors?all=true&startDate=${startDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else if (endDate)
      return http.get(
        `${baseApiUrl}investors?all=true&endDate=${endDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else
      return http.get(
        `${baseApiUrl}investors?all=true&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
  } catch (error) {
    console.log(error);
  }
};

const approveInvestorListing = async id => {
  try {
    const { status } = await http.post(`${baseApiUrl}investors/${id}/approve`);
    if (status === 200) return true;
  } catch (error) {
    console.log(error);
  }
};

const disapproveInvestorListing = async id => {
  try {
    const { status } = await http.post(
      `${baseApiUrl}investors/${id}/disapprove`,
    );
    if (status === 200) return true;
  } catch (error) {
    console.log(error);
  }
};

const declineInvestorListing = async dto => {
  const { status } = await http.post(
    `${baseApiUrl}investors/${dto.profileId}/decline`,
    {
      reason: dto.reason,
    },
  );
  if (status === 200) return true;
};

const requestInvestorListingChange = async dto => {
  const { status } = await http.post(
    `${baseApiUrl}investors/${dto.profileId}/request-change`,
    {
      reason: dto.reason,
    },
  );
  if (status === 200) return true;
};

export default {
  getInvestors,
  getInvestor,
  addProfile,
  deleteInvestor,
  getAdminInvestorListings,
  searchAdminInvestorListings,
  approveInvestorListing,
  disapproveInvestorListing,
  declineInvestorListing,
  requestInvestorListingChange,
};
