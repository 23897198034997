import React, { Component } from 'react';
import CommunityPostTable from './CommunityPostTable';
import { connect } from 'react-redux';
import Pagination from './../../common/components/Pagination';
import { CircularProgress } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';
import withSizes from 'react-sizes';
import { fetchStartupPosts } from '../../state/actions/startup';

class CommunityPosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      status: '',
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.props.fetchStartupPosts();
  }

  onPageChange = page => {
    if (typeof page === 'number') {
      this.setState({ loading: true });
      this.props.fetchStartupPosts(page);
    }
  };

  onPreviousPage = () => {
    this.setState({ loading: true });
    this.props.fetchStartupPosts(this.props.pageNumber - 1);
  };

  onNextPage = () => {
    this.setState({ loading: true });
    this.props.fetchStartupPosts(this.props.pageNumber + 1);
  };

  render() {
    const {
      posts,
      postPageNumber,
      postPageSize,
      postTotalCount,
      loadingPosts,
    } = this.props;
    return (
      <div className="admin-custom">
        <div className="ml-lg-5">
          <h1>Community Post Overview</h1>
          <div className="w-50 my-3 border"></div>
          <div className="d-flex flex-wrap mb-5">
            <div className="d-flex flex-column justify-content-around w-100 w-lg-30 w-md-30 text-center bg-white shadow rounded-1 p-4 m-2">
              <p className="h6 mb-4">Total Posts</p>
              <div className="h2 font-weight-bold text-primary">
                {loadingPosts ? (
                  <CircularProgress color="primary" size={'1rem'} />
                ) : (
                  postTotalCount
                )}
              </div>
            </div>
            <div className="d-block d-lg-none d-md-none w-100"></div>
            <div className="d-flex justify-content-around w-100 w-lg-60 w-md-60 text-center bg-white shadow rounded-1 p-2 m-2">
              <div className="d-flex flex-column justify-content-around">
                <p className="h6 mb-4">Approved</p>
                <div className="input-group px-2">
                  {' '}
                  <div className="h2 font-weight-bold text-primary">0 </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-around">
                <p className="h6 mb-4">Pending</p>
                <div className="input-group">
                  {' '}
                  <div className="h2 font-weight-bold text-primary">0 </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-50 my-3 border"></div>

          <div className="d-flex flex-nowrap my-4">
            <div className="form-inline w-70">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Posts"
                  aria-label="Search Posts"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline btn-admin-primary"
                    type="button"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>

            <select className="browser-default custom-select w-30">
              <option value="">See All</option>
              <option value="Approved">Approved</option>
              <option value="Pending">Pending</option>
              <option value="ChangesRequired">Changes Required</option>
              <option value="Draft">Draft</option>
              <option value="Sold">Sold</option>
              <option value="Declined">Declined</option>
              <option value="Deleted">Deleted</option>
            </select>
          </div>
          <div className="d-flex justify-content-between w-lg-60 w-md-60 mb-3">
            <p className="h7 font-weight-normal m-0">Legend: &nbsp;</p>
            <div className="d-flex align-items-center">
              <div className="rounded-circle bg-danger mr-2 status"></div>
              <p className="h7 font-weight-normal m-0">Yet approved</p>
            </div>
            <div className="d-flex align-items-center">
              <div className="rounded-circle bg-warning mr-2 status"></div>
              <p className="h7 font-weight-normal m-0">Approval In progress</p>
            </div>
            <div className="d-flex align-items-center">
              <div className="rounded-circle bg-success mr-2 status"></div>
              <p className="h7 font-weight-normal m-0">Approved</p>
            </div>
          </div>
          <div className="profiles-table">
            <CommunityPostTable posts={posts} loadingPosts={loadingPosts} />
            <div className="d-flex justify-content-center my-3">
              <Pagination
                currentPage={postPageNumber}
                pageSize={postPageSize}
                totalItem={postTotalCount}
                onPageChange={this.onPageChange}
                onPreviousPage={this.onPreviousPage}
                onNextPage={this.onNextPage}
                hideComponent={loadingPosts ? 'd-none' : ''}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapSizesToProps = sizes => ({
  isMobile: withSizes.isMobile(sizes),
  isTablet: withSizes.isTablet(sizes),
});

export default withSizes(mapSizesToProps)(
  connect(
    state => ({
      loadingPosts: state.startup.loadingPosts,
      posts: state.startup.posts,
      postPageNumber: state.startup.postPageNumber,
      postPageSize: state.startup.postPageSize,
      postTotalCount: state.startup.postTotalCount,
    }),
    { fetchStartupPosts },
  )(CommunityPosts),
);
