import React from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';

const Pagination = props => {
  const { currentPage, pageSize, totalItem, hideComponent } = props;
  const pagesCount = Math.ceil(totalItem / pageSize);
  let showForwardDots = false;
  let showBackwardDots = false;

  if (pagesCount === 1) return null;

  let trimPages = [];

  if (currentPage >= 5 && pagesCount < currentPage + 3) {
    trimPages = _.range(currentPage - 3, pagesCount + 1);
    showBackwardDots = true;
    showForwardDots = false;
  } else if (currentPage >= 5 && pagesCount > currentPage + 4) {
    trimPages = _.range(currentPage - 3, currentPage + 4);
    showBackwardDots = true;
    showForwardDots = true;
  } else if (currentPage < 5 && pagesCount > 4) {
    trimPages = _.range(1, currentPage + 4);
    showBackwardDots = false;
    showForwardDots = true;
  } else if (currentPage < 5 && pagesCount < 4) {
    trimPages = _.range(1, pagesCount + 1);
    showBackwardDots = false;
    showForwardDots = false;
  }

  return trimPages.length > 1 ? (
    <nav aria-label="Page navigation example">
      <ul className={`pagination ${hideComponent}`}>
        <li
          className={currentPage === 1 ? 'page-item disabled' : 'page-item'}
          onClick={currentPage > 1 ? props.onPreviousPage : void 0}
        >
          <button className="page-link cursor-pointer" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {!trimPages.includes(1) && (
          <li
            className={1 === currentPage ? 'page-item active' : 'page-item'}
            onClick={() => props.onPageChange(1)}
          >
            <button className="page-link cursor-pointer">{1}</button>
          </li>
        )}
        {showBackwardDots && (
          <div className="d-flex justify-content-center align-items-center mx-2">
            <span>
              <span className="p-1 font-weight-bolder h5">.</span>
              <span className="p-1 font-weight-bolder h5">.</span>
              <span className="p-1 font-weight-bolder h5">.</span>
            </span>
          </div>
        )}

        {trimPages.map(page => (
          <li
            className={page === currentPage ? 'page-item active' : 'page-item'}
            key={page}
            onClick={() => props.onPageChange(page)}
          >
            <button className="page-link cursor-pointer">{page}</button>
          </li>
        ))}
        {showForwardDots && (
          <div className="d-flex justify-content-center align-items-center mx-2">
            <span>
              <span className="p-1 font-weight-bolder h5">.</span>
              <span className="p-1 font-weight-bolder h5">.</span>
              <span className="p-1 font-weight-bolder h5">.</span>
            </span>
          </div>
        )}
        {!trimPages.includes(pagesCount) && (
          <li
            className={
              pagesCount === currentPage ? 'page-item active' : 'page-item'
            }
            onClick={() => props.onPageChange(pagesCount)}
          >
            <button className="page-link cursor-pointer">{pagesCount}</button>
          </li>
        )}
        <li
          className={
            currentPage === pagesCount ? 'page-item disabled' : 'page-item'
          }
          onClick={currentPage !== pagesCount ? props.onNextPage : void 0}
        >
          <button className="page-link cursor-pointer" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </nav>
  ) : null;
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalItem: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPreviousPage: PropTypes.func.isRequired,
  onNextPage: PropTypes.func.isRequired,
};

export default Pagination;
