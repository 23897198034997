import React, { Component } from 'react';
import Joi from 'joi-browser';
import Swal from 'sweetalert2';
import equal from 'fast-deep-equal';
import queryString from 'query-string';
import franchiseService from '../../common/services/franchiseService';
import Select from 'react-select';
import { connect } from 'react-redux';
import withReactContent from 'sweetalert2-react-content';
import planTypes from '../../common/utils/planTypes';
import SubscriptionModal from '../../common/components/SubscriptionModal';
import subscriptionService from '../../common/services/subscriptionService';
import { setHeaders, resetHeaders } from './../../state/actions/layout';
import NumberFormat from 'react-number-format';
import { editFranchise, resetForm } from '../../state/actions/franchise';
import { CircularProgress } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { scrollToRef } from '../../common/utils/helpers';
import FranchisePlans from './FranchisePlans';
import profileStatus from './../../common/utils/profileStatus';
import profileType from './../../common/utils/profileType';

class RegisterFranchiseForm extends Component {
  state = {
    formdata: {
      firstName: '',
      lastName: '',
      workEmail: '',
      phoneNumber: '',
      yourDesignation: '',
      brandName: '',
      website: '',
      whatOffering: '',
      yourIndustry: '',
      aboutBrand: '',
      productsServices: '',
      startYear: '',
      headquartersLocation: '',
      outletsNumber: '',
      expectation: '',
      supportKind: '',
      dealerProcedure: '',
      expandLocations: '',
      dealershipFormatsNum: '',
      formatName: '',
      spaceMin: '',
      spaceMax: '',
      investmentMin: '',
      investmentMax: '',
      brandFee: '',
      averageStaffNum: '',
      averageMonthlySales: '',
      royaltyDetails: '',
      averageEbitda: '',
      agreement: '',
      couponCode: '',
    },
    logo: {},
    picture: {},
    proofDocsFiles: [],
    docs: [],
    errors: {},
    previousData: {},
    id: '',
    selectedPlan: '',
    yourIndustry: null,
    whatOffering: null,
    subscriptionPlans: [],
    isValidCode: '',
    isSaving: false,
    isSubmitting: false,
    hasSubscribed: false,
    isValidating: false,
    reApprovalWarning: {},
    reApprovalRequiredFields: [
      'aboutBrand',
      'productsServices',
      'headquartersLocation',
      'expectation',
      'supportKind',
      'dealerProcedure',
      'expandLocations',
    ],
  };

  async componentDidMount() {
    const { id } = queryString.parse(this.props.location.search);

    // editing
    if (id) this.props.editFranchise(id);

    this.autofillFormFields();

    const subscriptionPlans = await subscriptionService.getSubscriptionPlans();
    this.setState({ subscriptionPlans });

    this.props.setHeaders({
      title: 'Register a franchise',
      url: this.props.history.location.pathname,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!equal(this.props.currentUser, prevProps.currentUser)) {
      this.autofillFormFields();
    }

    if (!equal(this.props.formdata, prevProps.formdata)) {
      this.autofillFormFields();
    }
    if (!equal(this.state, prevState)) {
      this.getMultiSelectValue();
    }
  }

  componentWillUnmount() {
    this.props.resetHeaders();
    this.props.resetForm();
  }

  multiSelectOptions = {
    yourIndustryOptions: [
      { value: 'Agriculture', label: 'Agriculture' },
      {
        value: 'Construction/ Real Estate',
        label: 'Construction/ Real Estate',
      },
      { value: 'Consumer Goods', label: 'Consumer Goods' },
      { value: 'Financial Services', label: 'Financial Services' },
      { value: 'Healthcare', label: 'Healthcare' },
      { value: 'Industrial Goods', label: 'Industrial Goods' },
      {
        value: 'Information & Communications Technology',
        label: 'Information & Communications Technology',
      },
      { value: 'Natural Resources', label: 'Natural Resources' },
      { value: 'Oil & Gas', label: 'Oil & Gas' },
      { value: 'Services', label: 'Services' },
      { value: 'Utilities', label: 'Utilities' },
      { value: 'Conglomerates', label: 'Conglomerates' },
    ],
    whatOfferingOptions: [
      { value: 'Franchise opportunity', label: 'Franchise opportunity' },
      { value: 'Dealership opportunity', label: 'Dealership opportunity' },
      { value: 'Reseller opportunity', label: 'Reseller opportunity' },
      { value: 'Distributor opportunity', label: 'Distributor opportunity' },
      { value: 'Sales partner', label: 'Sales partner' },
    ],
  };

  autofillFormFields() {
    const { formdata, currentUser } = this.props;

    if (formdata && formdata.id) {
      const form = { ...this.state.formdata };
      const salesFormat = formdata.salesFormats && formdata.salesFormats[0];

      form.firstName = formdata.firstName;
      form.lastName = formdata.lastName;
      form.workEmail = formdata.workEmail;
      form.phoneNumber = formdata.phoneNumber || '';
      form.yourDesignation = formdata.yourDesignation;
      form.brandName = formdata.brandName;
      form.website = formdata.website;
      form.whatOffering = formdata.whatOffering;
      form.yourIndustry = formdata.yourIndustry;
      form.aboutBrand = formdata.aboutBrand;
      form.productsServices = formdata.productsServices;
      form.startYear = formdata.startYear || '';
      form.headquartersLocation = formdata.headquartersLocation;
      form.outletsNumber = formdata.outletsNumber || '';
      form.expectation = formdata.expectation;
      form.supportKind = formdata.supportKind;
      form.dealerProcedure = formdata.dealerProcedure;
      form.expandLocations = formdata.expandLocations;
      form.dealershipFormatsNum = formdata.dealershipFormatsNum || '';
      form.formatName = salesFormat.formatName;
      form.spaceMin = salesFormat.spaceMin || '';
      form.spaceMax = salesFormat.spaceMax || '';
      form.investmentMin = salesFormat.investmentMin || '';
      form.investmentMax = salesFormat.investmentMax || '';
      form.brandFee = salesFormat.brandFee || '';
      form.averageStaffNum = salesFormat.averageStaffNum || '';
      form.averageMonthlySales = salesFormat.averageMonthlySales || '';
      form.royaltyDetails = salesFormat.royaltyDetails || '';
      form.averageEbitda = salesFormat.averageEbitda || '';
      form.agreement = 'true';
      this['agreement'].checked = true;

      let logoServerFiles = formdata.fileUploads.filter(
        file => file.fileGroup === 'Logo',
      );

      let storePhotosServerFiles = formdata.fileUploads.filter(
        file => file.fileGroup === 'Photos',
      );

      let documentsServerFiles = formdata.fileUploads.filter(
        file => file.fileGroup === 'Docs',
      );
      let proofOfBusinessServerFiles = formdata.fileUploads.filter(
        file => file.fileGroup === 'ProofOfDocs',
      );

      let logo =
        (logoServerFiles &&
          logoServerFiles.map(file => {
            return {
              name: file.name,
              type: file.type,
              size: file.size,
              base64Url: file.fileUrl,
            };
          }))[0] || {};
      let picture =
        storePhotosServerFiles.map(file => {
          return {
            name: file.name,
            type: file.type,
            size: file.size,
            base64Url: file.fileUrl,
          };
        })[0] || {};
      let proofDocsFiles = proofOfBusinessServerFiles.map(file => {
        return {
          name: file.name,
          type: file.type,
          size: file.size,
          base64Url: file.fileUrl,
        };
      });
      let docs = documentsServerFiles.map(file => {
        return {
          name: file.name,
          type: file.type,
          size: file.size,
          base64Url: file.fileUrl,
        };
      });

      const { subscriptions } = formdata;
      let subscriptionPlan = '';
      let hasSubscribed = false;

      if (subscriptions && subscriptions.length > 0) {
        subscriptionPlan = subscriptions[0].planType;
        hasSubscribed = true;
      }

      this.setState({
        formdata: form,
        logo,
        picture,
        proofDocsFiles,
        docs,
        id: formdata.id,
        selectedPlan: subscriptionPlan,
        hasSubscribed,
        previousData: formdata,
      });
    } else if (currentUser && currentUser.userId) {
      const form = { ...this.state.formdata };
      form.firstName = currentUser.firstname;
      form.lastName = currentUser.surname;
      form.workEmail = currentUser.email;
      form.phoneNumber = currentUser.phoneNumber || '';
      this.setState({ formdata: form, selectedPlan: this.props.selectedPlan });
    }
  }

  schema = {
    firstName: Joi.string()
      .required()
      .label('First Name'),
    lastName: Joi.string()
      .required()
      .label('Last Name'),
    workEmail: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .label('Work Email'),
    phoneNumber: Joi.string()
      .min(3)
      .required()
      .label('Phone Number'),
    yourDesignation: Joi.string()
      .required()
      .label('Your Designation'),
    brandName: Joi.string()
      .required()
      .label('Brand Name'),
    website: Joi.string()
      .required()
      .label('Website'),
    whatOffering: Joi.string()
      .required()
      .label('What Offering'),
    yourIndustry: Joi.string()
      .required()
      .label('Your Industry'),
    aboutBrand: Joi.string()
      .required()
      .label('About Brand'),
    productsServices: Joi.string()
      .required()
      .label('Products and Services'),
    startYear: Joi.number()
      .integer()
      .min(1900)
      .max(new Date().getFullYear())
      .required()
      .label('Year'),
    headquartersLocation: Joi.string()
      .required()
      .label('Head Quarters'),
    outletsNumber: Joi.number()
      .required()
      .label('Outlets Number'),
    expectation: Joi.string()
      .required()
      .label('Expectation'),
    supportKind: Joi.string()
      .required()
      .label('Support'),
    dealerProcedure: Joi.string()
      .required()
      .label('Dealership Procedure'),
    expandLocations: Joi.string()
      .required()
      .label('Expand Locations'),
    dealershipFormatsNum: Joi.number()
      .required()
      .label('Dealership Formants Number'),
    formatName: Joi.string()
      .required()
      .label('Format Name'),
    spaceMin: Joi.number()
      .required()
      .label('Space Min'),
    spaceMax: Joi.number()
      .required()
      .label('Space Max'),
    investmentMin: Joi.number()
      .required()
      .label('Min Investment'),
    investmentMax: Joi.number()
      .required()
      .label('Max Investment'),
    brandFee: Joi.number()
      .required()
      .label('Brand Fee'),
    averageStaffNum: Joi.number()
      .required()
      .label('Average Staff Number'),
    averageMonthlySales: Joi.number()
      .required()
      .label('Average Monthly Sales'),
    royaltyDetails: Joi.string()
      .required()
      .label('Royalty Details'),
    averageEbitda: Joi.number()
      .required()
      .max(99)
      .label('Average Ebitda'),
    agreement: Joi.string()
      .required()
      .valid('true')
      .label('Agreement'),
    couponCode: Joi.optional(),
  };

  isValidData() {
    const { error } = Joi.validate(this.state.formdata, this.schema, {
      abortEarly: false,
    });

    if (!error) return true;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    this.setState({ errors: errors || {} });

    if (errors) {
      const firstError = this[Object.keys(errors)[0]];
      scrollToRef(firstError);
      firstError.focus({ preventScroll: true });
      return false;
    } else {
      return true;
    }
  }

  onAgreementChange = e => {
    const { name } = e.target;
    const formdata = { ...this.state.formdata };
    formdata[name] = e.target.checked ? 'true' : 'false';
    const errors = { ...this.state.errors };
    errors[name] = '';
    this.setState({ formdata, errors });
  };

  onChange = (e, isFormatted = false) => {
    const { name, value } = e.target;
    const formdata = { ...this.state.formdata };
    formdata[name] = isFormatted
      ? parseInt(value.replace(/,/g, ''), 10)
      : value;
    const errors = { ...this.state.errors };
    errors[name] = '';

    // reset selectedPlan if coupon code is changing
    let selectedPlan = this.state.selectedPlan;
    if (formdata[name] === 'couponCode') selectedPlan = '';

    this.setState({ formdata, selectedPlan, errors });
  };

  onFocusTextFields = e => {
    const { previousData: franchise, reApprovalRequiredFields } = this.state;
    if (franchise.isApproved) {
      const { name } = e.target;
      if (reApprovalRequiredFields.some(x => x === name)) {
        const { reApprovalWarning: oldData } = this.state;
        const reApprovalWarning = { ...oldData };
        reApprovalWarning[name] =
          'Editing this field will require Admin reapproval';
        this.setState({ reApprovalWarning });
      }
    }
  };

  onBlurTextFields = e => {
    const { previousData: franchise, reApprovalRequiredFields } = this.state;
    if (franchise.isApproved) {
      const { name, value } = e.target;
      if (reApprovalRequiredFields.some(x => x === name)) {
        if (value === franchise[name]) {
          const { reApprovalWarning: oldData } = this.state;
          const reApprovalWarning = { ...oldData };
          reApprovalWarning[name] = '';
          this.setState({ reApprovalWarning });
        }
      }
    }
  };

  onPhoneChange = phone => {
    const formdata = { ...this.state.formdata };
    formdata['phoneNumber'] = phone;
    const errors = { ...this.state.errors };
    errors['phoneNumber'] = '';
    this.setState({ formdata, errors });
  };

  validateCouponCode = async e => {
    const { couponCode } = this.state.formdata;

    if (couponCode) {
      // validate coupon code
      this.setState({ isValidating: true });
      const res = await subscriptionService.validateCouponCode(
        couponCode,
        'Franchise',
      );
      if (res) {
        this.setState({
          selectedPlan: planTypes.coupon,
          isValidCode: true,
          isValidating: false,
        });
      } else {
        this.setState({
          selectedPlan: '',
          isValidCode: false,
          isValidating: false,
        });
      }
    }
  };

  getMultiSelectValue = () => {
    const fields = ['whatOffering', 'yourIndustry'];
    const state = { ...this.state };
    fields.forEach(field => {
      let options = [];
      const value = this.state.formdata[field].split(',');
      if (value[0] === '') {
        return;
      }
      value.forEach(option => {
        options.push({ value: option.trim(), label: option.trim() });
      });
      state[field] = options;
      if (field === 'yourIndustry') {
        this.multiSelectOptions[field + 'Options'].forEach(option => {
          option['disabled'] = 'yes';
        });
      }
    });
    this.setState(state);
  };

  onMultiSelect = (selectedOptions, name, maxOptions = 1000) => {
    const state = { ...this.state };
    state[name] = selectedOptions;
    this.setState(state);

    //Dynamically limit selectable options
    if (selectedOptions && selectedOptions.length >= maxOptions) {
      this.multiSelectOptions[name + 'Options'].forEach(option => {
        option['disabled'] = 'yes';
      });
    } else {
      this.multiSelectOptions[name + 'Options'].forEach(option => {
        option['disabled'] = 'no';
      });
    }

    //Stringify selected options
    let value = '';
    if (selectedOptions) {
      selectedOptions.forEach((option, index) => {
        value =
          index === 0 ? value + option.value : value + ', ' + option.value;
      });
    }

    //Update state
    const formdata = { ...this.state.formdata };
    formdata[name] = value;
    const errors = { ...this.state.errors };
    errors[name] = '';
    this.setState({ formdata, errors });
  };

  onHandleLogoUpload = async e => {
    const file = e.target.files[0];
    if (file && file.size <= 2000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadstart = () => {};
      reader.onloadend = () => {
        const base64Url = reader.result;
        if (base64Url) {
          const newFile = {
            name: file.name,
            type: file.type,
            size: file.size,
            base64Url: reader.result,
          };
          this.setState({ logo: newFile });
        }
      };
    } else {
      Swal.fire('Error', 'File is too large', 'error');
    }
  };

  onHandlePictureUpload = async e => {
    const file = e.target.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      Swal.fire('Error', 'Only Jpeg and Png images are allowed', 'error');
    } else {
      if (file && file.size <= 2000000) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = () => {};
        reader.onloadend = () => {
          const base64Url = reader.result;
          if (base64Url) {
            const newFile = {
              name: file.name,
              type: file.type,
              size: file.size,
              base64Url: reader.result,
            };
            this.setState({ picture: newFile });
          }
        };
      } else {
        Swal.fire('Error', 'File is too large', 'error');
      }
    }
  };

  onHandleProofDocsUpload = async e => {
    const file = e.target.files[0];
    if (file && file.size <= 2000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadstart = () => {};
      reader.onloadend = () => {
        const base64Url = reader.result;
        if (base64Url) {
          const newFile = {
            name: file.name,
            type: file.type,
            size: file.size,
            base64Url: reader.result,
          };
          const proofDocsFiles = [...this.state.proofDocsFiles];
          proofDocsFiles.push(newFile);
          this.setState({ proofDocsFiles });
        }
      };
    } else {
      Swal.fire('Error', 'File is too large', 'error');
    }
  };

  onHandleDocsUpload = async e => {
    const file = e.target.files[0];
    if (file && file.size <= 2000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadstart = () => {};
      reader.onloadend = () => {
        const base64Url = reader.result;
        if (base64Url) {
          const newFile = {
            name: file.name,
            type: file.type,
            size: file.size,
            base64Url: reader.result,
          };
          const docs = [...this.state.docs];
          docs.push(newFile);
          this.setState({ docs });
        }
      };
    } else {
      Swal.fire('Error', 'File is too large', 'error');
    }
  };

  onDeleteProofDocsFile = file => {
    const files = [...this.state.proofDocsFiles];
    const filteredFiles = files.filter(x => x.name !== file.name);
    this.setState({ proofDocsFiles: filteredFiles });
  };

  onDeleteDocsFile = file => {
    const files = [...this.state.docs];
    const filteredFiles = files.filter(x => x.name !== file.name);
    this.setState({ docs: filteredFiles });
  };

  MySwal = withReactContent(Swal);

  promptPayment = (plan, franchiseId, workEmail, isUpgrading = false) => {
    const plans = [...this.state.subscriptionPlans];
    const selectedPlan = plans.find(x => x.planType === plan);

    const { email, phoneNumber } = this.props.currentUser;
    const successMessage = isUpgrading
      ? 'Subscription was successful and your changes was saved'
      : email !== workEmail
      ? `A verification link has been sent to ${workEmail}. You must confirm your email before your listing will be approved.`
      : 'Your request has been sent for approval';

    if (email && selectedPlan && franchiseId) {
      this.MySwal.fire({
        customClass: 'bg-transparent w-lg-30 w-md-30 w-90',
        showConfirmButton: false,
        html: (
          <SubscriptionModal
            planId={selectedPlan.planId}
            amount={selectedPlan.amount}
            userEmail={email}
            userPhone={phoneNumber}
            listingId={franchiseId}
            listingType={profileType.Franchise}
            redirectTo={`user/franchises/${franchiseId}`}
            successMessage={successMessage}
            history={this.props.history}
          ></SubscriptionModal>
        ),
      });
    }
  };

  onSelectPlan = plan => {
    if (
      this.state.previousData.status &&
      this.state.previousData.status !== profileStatus.Draft
    ) {
      this.upgradePlan(plan);
    } else {
      this.setState({ selectedPlan: plan });
    }
  };

  upgradePlan = async plan => {
    if (!this.isValidData()) return;

    const { id, formdata } = this.state;
    var dto = this.getDtoObject();

    await franchiseService.addFranchise(dto);

    this.promptPayment(plan, id, formdata.workEmail, true);
  };

  save = async e => {
    e.preventDefault();
    var dto = this.getDtoObject();

    if (dto) {
      try {
        this.setState({ isSaving: true });
        const res = await franchiseService.addFranchise(dto);
        if (res && res.id) {
          this.setState({ isSaving: false });
          Swal.fire('Success', 'Changes saved successfully', 'success').then(
            () => {
              this.props.history.replace(`/user/franchises/${res.id}`);
            },
          );
        } else {
          this.setState({ isSaving: false });
          Swal.fire('Error', 'Unable to save changes', 'error');
        }
      } catch (error) {
        this.setState({ isSaving: false });
      }
    }
  };

  getDtoObject() {
    const { formdata, previousData } = this.state;

    previousData.firstName = formdata.firstName;
    previousData.lastName = formdata.lastName;
    previousData.workEmail = formdata.workEmail;
    previousData.phoneNumber = formdata.phoneNumber;
    previousData.yourDesignation = formdata.yourDesignation;
    previousData.brandName = formdata.brandName;
    previousData.website = formdata.website;
    previousData.whatOffering = formdata.whatOffering;
    previousData.yourIndustry = formdata.yourIndustry;
    previousData.aboutBrand = formdata.aboutBrand;
    previousData.productsServices = formdata.productsServices;
    previousData.startYear = formdata.startYear || 0;
    previousData.headquartersLocation = formdata.headquartersLocation;
    previousData.outletsNumber = formdata.outletsNumber || 0;
    previousData.expectation = formdata.expectation;
    previousData.supportKind = formdata.supportKind;
    previousData.dealerProcedure = formdata.dealerProcedure;
    previousData.expandLocations = formdata.expandLocations;
    previousData.dealershipFormatsNum = formdata.dealershipFormatsNum || 0;
    previousData.couponCode = formdata.couponCode;

    previousData.salesFormats = [
      {
        formatName: formdata.formatName,
        spaceMin: formdata.spaceMin || 0,
        spaceMax: formdata.spaceMax || 0,
        investmentMin: formdata.investmentMin || 0,
        investmentMax: formdata.investmentMax || 0,
        brandFee: formdata.brandFee || 0,
        averageStaffNum: formdata.averageStaffNum || 0,
        averageMonthlySales: formdata.averageMonthlySales || 0,
        royaltyDetails: formdata.royaltyDetails,
        averageEbitda: formdata.averageEbitda || 0,
      },
    ];
    previousData.logo = this.state.logo && this.state.logo;
    previousData.picture = this.state.picture && this.state.picture;
    previousData.proofDocsFiles =
      this.state.proofDocsFiles && this.state.proofDocsFiles;
    previousData.docs = this.state.docs && this.state.docs;
    previousData.subscriptionPlan = this.state.selectedPlan || 0;

    let fileGroupCode = [];

    const logo = 8,
      storePhotos = 1,
      documents = 6,
      proofOfBusiness = 5;

    fileGroupCode.push(logo);
    fileGroupCode.push(storePhotos);
    fileGroupCode.push(documents);
    fileGroupCode.push(proofOfBusiness);

    previousData.fileGroupCode = fileGroupCode;

    return previousData;
  }

  onSubmit = async e => {
    e.preventDefault();

    if (!this.isValidData()) return;

    const payload = this.getDtoObject();
    const { selectedPlan, hasSubscribed, previousData } = this.state;

    if (!payload) return;

    if (!selectedPlan) {
      Swal.fire(
        'Error',
        'You must select a plan or enter a valid subscription code before submitting',
        'error',
      );
      return;
    }

    const workEmail = payload.workEmail.trim().toLowerCase();

    try {
      this.setState({ isSubmitting: true });

      const res = await franchiseService.addFranchise(payload);

      if (res && hasSubscribed) {
        this.setState({ isSubmitting: false });
        const message = previousData.isApproved
          ? 'Your changes has been submitted and may require Admin approval'
          : 'Your changes has been submitted successfully';

        Swal.fire('Changes submitted successfully', message, 'success').then(
          () => {
            this.props.history.replace(`user/franchises/${res.id}`);
          },
        );
      } else if (
        res &&
        (selectedPlan === planTypes.premiumFranchise ||
          selectedPlan === planTypes.professionalFranchise)
      ) {
        this.setState({ isSubmitting: false });
        payload.id = res.id;
        // redirect to payment platform
        this.promptPayment(selectedPlan, res.id, workEmail);
      } else if (
        res &&
        (selectedPlan === planTypes.coupon ||
          selectedPlan === planTypes.basicFranchise)
      ) {
        this.setState({ isSubmitting: false });
        payload.id = res.id;
        const message =
          this.props.currentUser.email !== workEmail
            ? `A verification link has been sent to ${payload.workEmail}. You must confirm your email before your listing will be approved.`
            : 'Your request has been sent for approval';

        Swal.fire(
          'Franchise profile registered successfully',
          message,
          'success',
        ).then(() => {
          this.props.history.replace(`user/franchises/${res.id}`);
        });
      } else {
        this.setState({ isSubmitting: false });
        Swal.fire('An error occured', '', 'error');
      }
    } catch (error) {
      this.setState({ isSubmitting: false });
    }
  };

  getPercentages() {
    let percentages = [];
    for (let i = 0; i <= 100; i++) {
      percentages.push(i);
    }
    return percentages;
  }

  render() {
    const { hasSubscribed, previousData } = this.state;

    return (
      <div className="container max-w-rem-100">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="d-flex justify-content-center flex-column text-center mt-3 mb-5">
              <h3 className="text-primary font-weight-bold">
                Register a Franchise opportunity
              </h3>
              <p className="text-muted small">
                Connect with franchisees around the world
              </p>
            </div>
            <form
              onSubmit={this.onSubmit}
              method="POST"
              noValidate
              name="register-franchise-form"
            >
              <div className="bg-white shadow rounded-2 py-5 px-3">
                <div className="fieldset border-top border-bottom">
                  <div className="form-row py-2">
                    <div className="col-12 px-md-4 mb-3 pb-2 border-bottom">
                      <h5>Personal Information</h5>
                      <div className="text-sm text-muted">
                        Information marked{' '}
                        <sup className="font-italic text-danger">*private</sup>{' '}
                        will not be displayed publicly
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="firstName">
                        Authorized Person's First Name{' '}
                        <sup className="font-italic text-danger text-small">
                          *private
                        </sup>
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        onChange={this.onChange}
                        value={this.state.formdata.firstName}
                        className={
                          this.state.errors['firstName']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.firstName = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['firstName']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="lastName">
                        Authorized Person's Last Name{' '}
                        <sup className="font-italic text-danger text-small">
                          *private
                        </sup>
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        onChange={this.onChange}
                        value={this.state.formdata.lastName}
                        className={
                          this.state.errors['lastName']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.lastName = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['lastName']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="workEmail">
                        Work Email{' '}
                        <sup className="font-italic text-danger text-small">
                          *private
                        </sup>{' '}
                        <span className="text-sm text-italic text-muted pl-2">
                          (Improve your rating)
                        </span>
                      </label>
                      <input
                        type="email"
                        id="workEmail"
                        name="workEmail"
                        onChange={this.onChange}
                        value={this.state.formdata.workEmail}
                        className={
                          this.state.errors['workEmail']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.workEmail = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['workEmail']}
                      </div>
                    </div>

                    <div
                      className="form-group col-md-6 px-md-4 mb-3"
                      ref={elem => {
                        this.phoneNumber = elem;
                      }}
                    >
                      <label htmlFor="phoneNumber">
                        Phone Number{' '}
                        <sup className="font-italic text-danger text-small">
                          *private
                        </sup>
                        <span className="text-sm text-muted pl-2">
                          (For faster verification)
                        </span>
                      </label>
                      <PhoneInput
                        country={'ng'}
                        value={this.state.formdata.phoneNumber}
                        onChange={this.onPhoneChange}
                        inputClass={
                          this.state.errors['phoneNumber']
                            ? 'form-control w-100 is-invalid'
                            : 'form-control w-100'
                        }
                      />
                      <div
                        className={
                          this.state.errors['phoneNumber'] ? 'is-invalid' : ''
                        }
                      ></div>
                      <div className="invalid-feedback">
                        {this.state.errors['phoneNumber']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="yourDesignation">
                        Your designation{' '}
                        <sup className="font-italic text-danger text-small">
                          *private
                        </sup>
                      </label>
                      <input
                        type="text"
                        id="yourDesignation"
                        name="yourDesignation"
                        onChange={this.onChange}
                        value={this.state.formdata.yourDesignation}
                        className={
                          this.state.errors['yourDesignation']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.yourDesignation = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['yourDesignation']}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fieldset border-top border-bottom">
                  <div className="form-row py-2">
                    <div className="col-12 px-md-4 mb-3 pb-2">
                      <h5>Public Information</h5>
                      <div className="text-sm text-muted">
                        All{' '}
                        <span className="font-italic text-danger text-small">
                          * fields
                        </span>{' '}
                        are required.
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="brandName">
                        Brand name{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      <input
                        type="text"
                        id="brandName"
                        name="brandName"
                        onChange={this.onChange}
                        value={this.state.formdata.brandName}
                        className={
                          this.state.errors['brandName']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.brandName = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['brandName']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="website">
                        Website{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      <input
                        type="text"
                        id="website"
                        name="website"
                        onChange={this.onChange}
                        value={this.state.formdata.website}
                        className={
                          this.state.errors['website']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.website = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['website']}
                      </div>
                    </div>

                    <div
                      className="form-group col-md-6 px-md-4 mb-3"
                      ref={elem => {
                        this.whatOffering = elem;
                      }}
                    >
                      <label htmlFor="whatOffering">
                        What I am offering{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      <Select
                        options={this.multiSelectOptions.whatOfferingOptions}
                        isOptionDisabled={option => option.disabled === 'yes'}
                        isMulti
                        id="whatOffering"
                        value={this.state.whatOffering}
                        onChange={selectedOptions => {
                          this.onMultiSelect(
                            selectedOptions,
                            'whatOffering',
                            5,
                          );
                        }}
                        className={
                          this.state.errors['whatOffering'] ? 'is-invalid' : ''
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['whatOffering']}
                      </div>
                    </div>

                    <div
                      className="form-group col-md-6 px-md-4 mb-3"
                      ref={elem => {
                        this.yourIndustry = elem;
                      }}
                    >
                      <label htmlFor="yourIndustry">
                        Select your industry{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      <Select
                        options={this.multiSelectOptions.yourIndustryOptions}
                        isOptionDisabled={option => option.disabled === 'yes'}
                        isMulti
                        id="yourIndustry"
                        value={this.state.yourIndustry}
                        onChange={selectedOptions => {
                          this.onMultiSelect(
                            selectedOptions,
                            'yourIndustry',
                            1,
                          );
                        }}
                        className={
                          this.state.errors['yourIndustry'] ? 'is-invalid' : ''
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['yourIndustry']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="aboutBrand">
                        About your brand{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      {this.state.reApprovalWarning['aboutBrand'] && (
                        <div className="text-danger small mb-2">
                          <em>
                            Note: {this.state.reApprovalWarning['aboutBrand']}
                          </em>
                        </div>
                      )}
                      <textarea
                        id="aboutBrand"
                        name="aboutBrand"
                        onChange={this.onChange}
                        onFocus={this.onFocusTextFields}
                        onBlur={this.onBlurTextFields}
                        value={this.state.formdata.aboutBrand}
                        rows="3"
                        className={
                          this.state.errors['aboutBrand']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.aboutBrand = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['aboutBrand']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="productsServices">
                        Mention all products/services your brand provides{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      {this.state.reApprovalWarning['productsServices'] && (
                        <div className="text-danger small mb-2">
                          <em>
                            Note:{' '}
                            {this.state.reApprovalWarning['productsServices']}
                          </em>
                        </div>
                      )}
                      <textarea
                        id="productsServices"
                        name="productsServices"
                        onChange={this.onChange}
                        onFocus={this.onFocusTextFields}
                        onBlur={this.onBlurTextFields}
                        value={this.state.formdata.productsServices}
                        rows="3"
                        className={
                          this.state.errors['productsServices']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.productsServices = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['productsServices']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="startYear">
                        When did your brand start operations?{' '}
                        <span className="text-danger text-small">*</span>
                      </label>

                      <input
                        type="number"
                        placeholder="Year"
                        id="startYear"
                        onChange={this.onChange}
                        value={this.state.formdata.startYear}
                        name="startYear"
                        className={
                          this.state.errors['startYear']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.startYear = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['startYear']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="headquartersLocation">
                        Where is your brand's headquarters?{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      {this.state.reApprovalWarning['headquartersLocation'] && (
                        <div className="text-danger small mb-2">
                          <em>
                            Note:{' '}
                            {
                              this.state.reApprovalWarning[
                                'headquartersLocation'
                              ]
                            }
                          </em>
                        </div>
                      )}
                      <input
                        type="text"
                        id="headquartersLocation"
                        name="headquartersLocation"
                        onChange={this.onChange}
                        onFocus={this.onFocusTextFields}
                        onBlur={this.onBlurTextFields}
                        value={this.state.formdata.headquartersLocation}
                        className={
                          this.state.errors['headquartersLocation']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.headquartersLocation = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['headquartersLocation']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="outletsNumber">
                        How many outlets do you already have?{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      <input
                        type="text"
                        id="outletsNumber"
                        name="outletsNumber"
                        onChange={this.onChange}
                        value={this.state.formdata.outletsNumber}
                        className={
                          this.state.errors['outletsNumber']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.outletsNumber = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['outletsNumber']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="expectation">
                        What is your expectation from a franchisee?{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      {this.state.reApprovalWarning['expectation'] && (
                        <div className="text-danger small mb-2">
                          <em>
                            Note: {this.state.reApprovalWarning['expectation']}
                          </em>
                        </div>
                      )}
                      <textarea
                        id="expectation"
                        name="expectation"
                        onChange={this.onChange}
                        onFocus={this.onFocusTextFields}
                        onBlur={this.onBlurTextFields}
                        value={this.state.formdata.expectation}
                        rows="3"
                        className={
                          this.state.errors['expectation']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.expectation = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['expectation']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="supportKind">
                        What kind of support can the dealer expect from you?{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      {this.state.reApprovalWarning['supportKind'] && (
                        <div className="text-danger small mb-2">
                          <em>
                            Note: {this.state.reApprovalWarning['supportKind']}
                          </em>
                        </div>
                      )}
                      <textarea
                        id="supportKind"
                        name="supportKind"
                        onChange={this.onChange}
                        onFocus={this.onFocusTextFields}
                        onBlur={this.onBlurTextFields}
                        value={this.state.formdata.supportKind}
                        rows="3"
                        className={
                          this.state.errors['supportKind']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.supportKind = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['supportKind']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="dealerProcedure">
                        What is the procedure to become your dealer?{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      {this.state.reApprovalWarning['dealerProcedure'] && (
                        <div className="text-danger small mb-2">
                          <em>
                            Note:{' '}
                            {this.state.reApprovalWarning['dealerProcedure']}
                          </em>
                        </div>
                      )}
                      <textarea
                        id="dealerProcedure"
                        name="dealerProcedure"
                        onChange={this.onChange}
                        onFocus={this.onFocusTextFields}
                        onBlur={this.onBlurTextFields}
                        value={this.state.formdata.dealerProcedure}
                        rows="3"
                        className={
                          this.state.errors['dealerProcedure']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.dealerProcedure = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['dealerProcedure']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="expandLocations">
                        Mention cities or countries you want to expand in{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      {this.state.reApprovalWarning['expandLocations'] && (
                        <div className="text-danger small mb-2">
                          <em>
                            Note:{' '}
                            {this.state.reApprovalWarning['expandLocations']}
                          </em>
                        </div>
                      )}
                      <input
                        type="text"
                        id="expandLocations"
                        name="expandLocations"
                        onChange={this.onChange}
                        onFocus={this.onFocusTextFields}
                        onBlur={this.onBlurTextFields}
                        value={this.state.formdata.expandLocations}
                        className={
                          this.state.errors['expandLocations']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.expandLocations = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['expandLocations']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="dealershipFormatsNum">
                        How many dealership formats do you have?{' '}
                        <span className="text-danger text-small">*</span>
                      </label>
                      <input
                        type="text"
                        id="dealershipFormatsNum"
                        name="dealershipFormatsNum"
                        onChange={this.onChange}
                        value={this.state.formdata.dealershipFormatsNum}
                        className={
                          this.state.errors['dealershipFormatsNum']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={elem => {
                          this.dealershipFormatsNum = elem;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['dealershipFormatsNum']}
                      </div>
                    </div>

                    <div className="fieldset border-top border-bottom mb-3">
                      <div className="form-row py-2">
                        <div className="col-12 px-md-4 mb-3 pb-2">
                          <h5>Format 1</h5>
                        </div>

                        <div className="form-group col-md-6 px-md-4 mb-3">
                          <label htmlFor="formatName">
                            Format name{' '}
                            <span className="text-danger text-small">*</span>
                          </label>
                          <input
                            type="text"
                            id="formatName"
                            name="formatName"
                            onChange={this.onChange}
                            value={this.state.formdata.formatName}
                            className={
                              this.state.errors['formatName']
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            ref={elem => {
                              this.formatName = elem;
                            }}
                          />
                          <div className="invalid-feedback">
                            {this.state.errors['formatName']}
                          </div>
                        </div>

                        <div className="form-group col-md-6 px-md-4 mb-3">
                          <label htmlFor="">
                            Space required (sq ft){' '}
                            <span className="text-danger text-small">*</span>
                          </label>
                          <div className="row">
                            <div className="col-sm-6">
                              <div
                                className="input-group mb-2"
                                ref={elem => {
                                  this.spaceMin = elem;
                                }}
                              >
                                <div className="input-group-prepend">
                                  <div className="input-group-text">Min</div>
                                </div>
                                <NumberFormat
                                  thousandSeparator={true}
                                  type="text"
                                  id="spaceMin"
                                  name="spaceMin"
                                  onChange={e => this.onChange(e, true)}
                                  value={this.state.formdata.spaceMin}
                                  className={
                                    this.state.errors['spaceMin']
                                      ? 'form-control is-invalid'
                                      : 'form-control'
                                  }
                                />
                                <div className="invalid-feedback">
                                  {this.state.errors['spaceMin']}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div
                                className="input-group mb-2"
                                ref={elem => {
                                  this.spaceMax = elem;
                                }}
                              >
                                <div className="input-group-prepend">
                                  <div className="input-group-text">Max</div>
                                </div>
                                <NumberFormat
                                  thousandSeparator={true}
                                  type="text"
                                  id="spaceMax"
                                  name="spaceMax"
                                  onChange={e => this.onChange(e, true)}
                                  value={this.state.formdata.spaceMax}
                                  className={
                                    this.state.errors['spaceMax']
                                      ? 'form-control is-invalid'
                                      : 'form-control'
                                  }
                                />
                                <div className="invalid-feedback">
                                  {this.state.errors['spaceMax']}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form-group col-md-6 px-md-4 mb-3">
                          <label htmlFor="">
                            Total investment needed (₦){' '}
                            <span className="text-danger text-small">*</span>
                          </label>
                          <div className="row">
                            <div className="col-sm-6">
                              <div
                                className="input-group mb-2"
                                ref={elem => {
                                  this.investmentMin = elem;
                                }}
                              >
                                <div className="input-group-prepend">
                                  <div className="input-group-text">Min</div>
                                </div>
                                <NumberFormat
                                  thousandSeparator={true}
                                  type="text"
                                  id="investmentMin"
                                  name="investmentMin"
                                  onChange={e => this.onChange(e, true)}
                                  value={this.state.formdata.investmentMin}
                                  className={
                                    this.state.errors['investmentMin']
                                      ? 'form-control is-invalid'
                                      : 'form-control'
                                  }
                                />
                                <div className="invalid-feedback">
                                  {this.state.errors['investmentMin']}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div
                                className="input-group mb-2"
                                ref={elem => {
                                  this.investmentMax = elem;
                                }}
                              >
                                <div className="input-group-prepend">
                                  <div className="input-group-text">Max</div>
                                </div>
                                <NumberFormat
                                  thousandSeparator={true}
                                  type="text"
                                  id="investmentMax"
                                  name="investmentMax"
                                  onChange={e => this.onChange(e, true)}
                                  value={this.state.formdata.investmentMax}
                                  className={
                                    this.state.errors['investmentMax']
                                      ? 'form-control is-invalid'
                                      : 'form-control'
                                  }
                                />
                                <div className="invalid-feedback">
                                  {this.state.errors['investmentMax']}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form-group col-md-6 px-md-4 mb-3">
                          <label
                            htmlFor="brandFee"
                            ref={elem => {
                              this.brandFee = elem;
                            }}
                          >
                            Brand fee included in this investment (₦){' '}
                            <span className="text-danger text-small">*</span>
                          </label>
                          <NumberFormat
                            thousandSeparator={true}
                            type="text"
                            id="brandFee"
                            name="brandFee"
                            onChange={e => this.onChange(e, true)}
                            value={this.state.formdata.brandFee}
                            className={
                              this.state.errors['brandFee']
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.errors['brandFee']}
                          </div>
                        </div>

                        <div className="form-group col-md-6 px-md-4 mb-3">
                          <label htmlFor="averageStaffNum">
                            Average number of staff needed{' '}
                            <span className="text-danger text-small">*</span>
                          </label>
                          <input
                            type="text"
                            id="averageStaffNum"
                            name="averageStaffNum"
                            onChange={this.onChange}
                            value={this.state.formdata.averageStaffNum}
                            className={
                              this.state.errors['averageStaffNum']
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            ref={elem => {
                              this.averageStaffNum = elem;
                            }}
                          />
                          <div className="invalid-feedback">
                            {this.state.errors['averageStaffNum']}
                          </div>
                        </div>

                        <div
                          className="form-group col-md-6 px-md-4 mb-3"
                          ref={elem => {
                            this.averageMonthlySales = elem;
                          }}
                        >
                          <label htmlFor="averageMonthlySales">
                            Average monthly sales (₦){' '}
                            <span className="text-danger text-small">*</span>
                          </label>
                          <NumberFormat
                            thousandSeparator={true}
                            type="text"
                            id="averageMonthlySales"
                            name="averageMonthlySales"
                            onChange={e => this.onChange(e, true)}
                            value={this.state.formdata.averageMonthlySales}
                            className={
                              this.state.errors['averageMonthlySales']
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.errors['averageMonthlySales']}
                          </div>
                        </div>

                        <div className="form-group col-md-6 px-md-4 mb-3">
                          <label htmlFor="royaltyDetails">
                            Mention details of royalty or commission paid{' '}
                            <span className="text-danger text-small">*</span>
                          </label>
                          <textarea
                            id="royaltyDetails"
                            name="royaltyDetails"
                            onChange={this.onChange}
                            value={this.state.formdata.royaltyDetails}
                            rows="3"
                            className={
                              this.state.errors['royaltyDetails']
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            ref={elem => {
                              this.royaltyDetails = elem;
                            }}
                          />
                          <div className="invalid-feedback">
                            {this.state.errors['royaltyDetails']}
                          </div>
                        </div>

                        <div className="form-group col-md-6 px-md-4 mb-3">
                          <label htmlFor="averageEbitda">
                            Average EBITDA margin of the franchise (%){' '}
                            <span className="text-danger text-small">*</span>
                          </label>
                          <input
                            type="text"
                            id="averageEbitda"
                            name="averageEbitda"
                            onChange={this.onChange}
                            value={this.state.formdata.averageEbitda}
                            className={
                              this.state.errors['averageEbitda']
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            ref={elem => {
                              this.averageEbitda = elem;
                            }}
                          />
                          <div className="invalid-feedback">
                            {this.state.errors['averageEbitda']}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4">
                      <h5>
                        Brand logo
                        <span className="text-sm-1">
                          (Max file size 2MB)
                        </span>{' '}
                        <span className="text-muted small">optional</span>
                      </h5>
                      <label className="small" htmlFor="">
                        Attach brand logo
                      </label>
                      <div>
                        <div className="upload-btn-wrapper">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-lg"
                          >
                            Upload logo
                          </button>
                          <input
                            id="franchiseUploadLogo"
                            type="file"
                            name="file"
                            onChange={this.onHandleLogoUpload}
                          />
                        </div>
                      </div>
                      <div>
                        {this.state.logo.name && (
                          <div className="mb-2">
                            {this.state.logo.type === 'application/pdf' ? (
                              <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                            ) : (
                              <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                            )}
                            <span className="small pr-3">
                              <a
                                href={this.state.logo.base64Url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {this.state.logo.name}
                              </a>
                            </span>
                            <i
                              className="far fa-times-circle text-danger cursor-pointer"
                              onClick={() => this.setState({ logo: {} })}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4">
                      <h5>
                        Photos
                        <span className="text-sm-1">
                          (Max file size 2MB)
                        </span>{' '}
                        <span className="text-muted small">optional</span>
                      </h5>
                      <label className="small" htmlFor="">
                        Add photos of your facility/store
                      </label>
                      <div>
                        <div className="upload-btn-wrapper">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-lg"
                          >
                            Upload photo
                          </button>
                          <input
                            id="franchiseUploadPhoto"
                            type="file"
                            name="file"
                            onChange={this.onHandlePictureUpload}
                          />
                        </div>
                      </div>
                      <div>
                        {this.state.picture.name && (
                          <div className="mb-2">
                            {this.state.picture.type === 'application/pdf' ? (
                              <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                            ) : (
                              <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                            )}
                            <span className="small pr-3">
                              <a
                                href={this.state.picture.base64Url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {this.state.picture.name}
                              </a>
                            </span>
                            <i
                              className="far fa-times-circle text-danger cursor-pointer"
                              onClick={() => this.setState({ picture: {} })}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4">
                      <h5>
                        Attach proof of business
                        <span className="text-sm-1">
                          (Max file size 2MB)
                        </span>{' '}
                        <span className="text-muted small">optional</span>
                      </h5>
                      <label className="small" htmlFor="">
                        You can attach government recognized documents such as
                        incorporation certificates, Tax certificates, etc.
                        Uploading this document enables our team review and
                        approve your application faster
                      </label>
                      <div>
                        <div className="upload-btn-wrapper">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-lg"
                          >
                            Upload picture
                          </button>
                          <input
                            id="franchiseUploadPicture"
                            type="file"
                            name="file"
                            onChange={this.onHandleProofDocsUpload}
                          />
                        </div>
                      </div>
                      <div>
                        {this.state.proofDocsFiles.map(file => (
                          <div className="mb-2" key={file.name}>
                            {file.type === 'application/pdf' ? (
                              <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                            ) : (
                              <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                            )}
                            <span className="small pr-3">
                              <a
                                href={file.base64Url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                            </span>
                            <i
                              className="far fa-times-circle text-danger cursor-pointer"
                              onClick={() => this.onDeleteProofDocsFile(file)}
                            ></i>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4">
                      <h5>
                        Documents
                        <span className="text-sm-1">
                          (Max file size 2MB)
                        </span>{' '}
                        <span className="text-muted small">optional</span>
                      </h5>
                      <label className="small" htmlFor="">
                        Attach brochures or documents
                      </label>
                      <div>
                        <div className="upload-btn-wrapper">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-lg"
                          >
                            Upload docs
                          </button>
                          <input
                            id="franchiseUploadDocs"
                            type="file"
                            name="file"
                            onChange={this.onHandleDocsUpload}
                          />
                        </div>
                      </div>
                      <div>
                        {this.state.docs.map(file => (
                          <div className="mb-2" key={file.name}>
                            {file.type === 'application/pdf' ? (
                              <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                            ) : (
                              <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                            )}
                            <span className="small pr-3">
                              <a
                                href={file.base64Url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                            </span>
                            <i
                              className="far fa-times-circle text-danger cursor-pointer"
                              onClick={() => this.onDeleteDocsFile(file)}
                            ></i>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fieldset border-top border-bottom">
                  <div className="py-4">
                    <h4 className="text-center text-muted font-weight-bold">
                      Choose a plan
                    </h4>
                  </div>
                  <FranchisePlans
                    currentPlan={this.state.selectedPlan}
                    planChange={this.onSelectPlan}
                    isDraft={
                      previousData.status &&
                      previousData.status === profileStatus.Draft
                    }
                    isUpgrading={
                      previousData.status &&
                      previousData.status !== profileStatus.Draft
                    }
                    {...this.props}
                  />

                  <div className="mx-5 mb-5">
                    <p>Have a subscription code?</p>
                    <div className="form-row">
                      <div className="col-md-10 mb-3">
                        <input
                          type="text"
                          name="couponCode"
                          onChange={this.onChange}
                          value={this.state.formdata.couponCode}
                          id="couponCode"
                          className="form-control form-control-sm"
                          placeholder="Enter subscription code"
                        />
                      </div>
                      <div className="col-md-2">
                        <button
                          className="btn btn-sm btn-primary w-100"
                          type="button"
                          disabled={hasSubscribed}
                          onClick={
                            !hasSubscribed ? this.validateCouponCode : void 0
                          }
                        >
                          {this.state.isValidating ? (
                            <CircularProgress color="inherit" size={'1rem'} />
                          ) : (
                            'Validate'
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="py-2">
                      {this.state.isValidCode && (
                        <span>
                          <i className="fa fa-check-circle fa-2x text-success"></i>
                          <span className="px-3">Valid Code.</span>
                        </span>
                      )}
                      {this.state.isValidCode === false && (
                        <span>
                          <i className="fa fa-times-circle fa-2x text-danger"></i>
                          <span className="px-3">
                            code not valid or not usable on this form.
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-12 my-3">
                  <div className="row">
                    <div className="col-1">
                      <div className="text-right">
                        <input
                          type="checkbox"
                          name="agreement"
                          onChange={this.onAgreementChange}
                          value="true"
                          ref={input => {
                            this.agreement = input;
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-11">
                      <p>
                        I agree to the terms and conditions of this platform,
                        including its privacy and refund policies.{' '}
                        <a
                          className="text-primary font-weight-bold"
                          href="/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View terms and conditions.&nbsp;
                        </a>
                        <a
                          className="text-primary font-weight-bold"
                          href="/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View privacy policy.&nbsp;
                        </a>
                        <a
                          className="text-primary font-weight-bold"
                          href="/refunds"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View refunds policy.
                        </a>
                      </p>
                      {this.state.errors['agreement'] && (
                        <small className="text-danger">
                          You must accept agreement before submitting
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center py-5">
                <div className="col-md-8 col-xl-5">
                  <button
                    type="button"
                    onClick={this.save}
                    className="btn btn-outline-primary text-primary bg-white btn-block btn-lg mb-3"
                    disabled={this.state.isSaving}
                  >
                    {this.state.isSaving ? (
                      <CircularProgress color="inherit" size={'1rem'} />
                    ) : (
                      'Save changes and exit'
                    )}
                  </button>
                </div>
                <div className="col-md-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block btn-lg"
                    disabled={this.state.isSubmitting}
                  >
                    {this.state.isSubmitting ? (
                      <CircularProgress color="inherit" size={'1rem'} />
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    currentUser: state.user.currentUser,
    formdata: state.franchise.formdata,
    selectedPlan: state.investor.selectedPlan,
  }),
  { editFranchise, resetForm, setHeaders, resetHeaders },
)(RegisterFranchiseForm);
