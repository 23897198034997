import React, { Component } from 'react';
import AdvisorPlans from './AdvisorPlans';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SubscriptionModal from './../../common/components/SubscriptionModal';
import subscriptionService from '../../common/services/subscriptionService';
import { fetchAdvisorDetails } from '../../state/actions/advisor';
import { spacePascalWord } from './../../common/utils/helpers';

class UpgradeAdvisorPlan extends Component {
  MySwal = withReactContent(Swal);
  state = {
    id: '',
    subscriptionPlans: {},
  };

  async componentDidMount() {
    const { id } = this.props.match.params;
    const { advisor } = this.props;

    if (!advisor || !advisor.id) this.props.fetchAdvisorDetails(id);

    const subscriptionPlans = await subscriptionService.getSubscriptionPlans();

    this.setState({
      id,
      subscriptionPlans,
    });
  }

  planChange = plan => {
    const { id, isOwner } = this.props.advisor;
    if (!isOwner) this.props.history.push(`/advisors/${id}`);

    this.promptPayment(plan);
  };

  promptPayment = plan => {
    const plans = [...this.state.subscriptionPlans];
    const selectedPlan = plans.find(x => x.planType === plan);

    const { email, phoneNumber } = this.props.currentUser;
    const { id: advisorId, email: workEmail } = this.props.advisor;
    if (email && selectedPlan && advisorId) {
      this.MySwal.fire({
        customClass: 'bg-transparent w-lg-30 w-md-30 w-90',
        showConfirmButton: false,
        html: (
          <SubscriptionModal
            planId={selectedPlan.planId}
            amount={selectedPlan.amount}
            userEmail={email}
            listingEmail={workEmail}
            userPhone={phoneNumber}
            listingId={advisorId}
            listingType="Advisor"
            redirectTo={`user/advisors/${advisorId}`}
            successMessage={`Subscription to ${spacePascalWord(
              plan,
            )} plan was successful`}
            history={this.props.history}
          ></SubscriptionModal>
        ),
      });
    }
  };

  render() {
    const { advisor } = this.props;
    const currentPlan =
      advisor && advisor.subscription && advisor.subscription.planType;

    return (
      <div className="scale-down-lg-7">
        <h3 className="text-center">Upgrade/change Advisor plan</h3>
        <AdvisorPlans
          currentPlan={currentPlan}
          planChange={this.planChange}
          isUpgrading={true}
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    advisor: state.advisor.advisorDetails,
    currentUser: state.user.currentUser,
  }),
  { fetchAdvisorDetails },
)(UpgradeAdvisorPlan);
