import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';
import {
  addProposalListings,
  addToSentProposals,
} from '../../state/actions/user';
import ProposalForm from './proposalForm';
import userService from '../services/userService';
import InvestorPaywall from './InvestorPaywall';
import AdvisorPaywall from './AdvisorPaywall';

class SendProposalBttn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listingToSend: null,
      listingTypeToSend: null,
      proposalData: {
        investorID: 0,
        proposalTitle: '',
        proposal: '',
        businessID: 0,
        franchiseID: 0,
        startupID: 0,
        advisorID: 0,
      },
    };
    this.handleOnNameSelect = this.handleOnNameSelect.bind(this);
    this.handleOnTypeSelect = this.handleOnTypeSelect.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnDone = this.handleOnDone.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  MySwal = withReactContent(Swal);

  handleOnTypeSelect(e) {
    let { proposalData } = this.state;
    const { sendTo } = this.props;
    proposalData = {
      ...proposalData,
      investorID:
        sendTo === 'investor' ? this.state.proposalData.investorID : 0,
      advisorID: sendTo === 'advisor' ? this.state.proposalData.advisorID : 0,
      businessID: 0,
      franchiseID: 0,
    };
    this.setState({
      proposalData,
      listingTypeToSend: e.target.value,
    });
  }

  handleOnNameSelect(e) {
    let { proposalData } = { ...this.state };
    const { sendTo } = this.props;
    let listType = this.state.listingTypeToSend;
    if (sendTo === 'investor') {
      proposalData = {
        ...proposalData,
        investorID: parseInt(this.props.userId),
        advisorID: listType === 'Advisor' ? parseInt(e.target.value) : 0,
      };
    } else {
      proposalData = {
        ...proposalData,
        advisorID: parseInt(this.props.userId),
        investorID: listType === 'Investor' ? parseInt(e.target.value) : 0,
      };
    }
    proposalData = {
      ...proposalData,
      businessID: listType === 'Business' ? parseInt(e.target.value) : 0,
      franchiseID: listType === 'Franchise' ? parseInt(e.target.value) : 0,
      startupID: listType === 'Startup' ? parseInt(e.target.value) : 0,
    };

    this.setState({
      listingToSend: e.target.value,
      proposalData,
    });
  }

  handleOnSubmit(e) {
    e.preventDefault();
    const { sendTo } = this.props;
    const { proposalData } = {
      ...this.state,
    };

    const res =
      sendTo === 'investor'
        ? userService.sendInvestorProposal(proposalData)
        : userService.sendAdvisorProposal(proposalData);
    return res;
  }

  handleOnDone() {
    this.MySwal.clickConfirm();
  }

  handleOnChange(e) {
    const { name, value } = e.target;
    let { proposalData } = { ...this.state };
    proposalData[name] = value;
    this.setState({
      ...this.state,
      proposalData,
    });
  }

  componentDidMount() {
    const {
      proposalToInvestorListings,
      proposalToAdvisorListings,
      addProposalListings,
      sendTo,
    } = this.props;
    switch (sendTo) {
      case 'investor':
        if (
          proposalToInvestorListings === undefined ||
          proposalToInvestorListings.length <= 0
        ) {
          addProposalListings(sendTo);
        }
        break;
      case 'advisor':
        if (
          proposalToAdvisorListings === undefined ||
          proposalToAdvisorListings.length <= 0
        ) {
          addProposalListings(sendTo);
        }
        break;
      default:
        break;
    }
  }

  render() {
    const { canSendProposal } = this.props;
    /* prepare the options to display*/

    const allowedListings = {
      Business: [],
      Investor: [],
      Advisor: [],
      Franchise: [],
      Startup: [],
    };

    if (this.props.sendTo === 'investor') {
      delete allowedListings['Investor'];
      Object.keys(allowedListings).forEach(listingType => {
        allowedListings[
          listingType
        ] = this.props.proposalToInvestorListings.filter(
          listing => listing.profileType === listingType,
        );
      });
    } else {
      delete allowedListings['Advisor'];
      Object.keys(allowedListings).forEach(listingType => {
        allowedListings[
          listingType
        ] = this.props.proposalToAdvisorListings.filter(
          listing => listing.profileType === listingType,
        );
      });
    }
    const getPaywall = type => {
      switch (type) {
        case 'investor':
          return <InvestorPaywall></InvestorPaywall>;
        case 'advisor':
          return <AdvisorPaywall></AdvisorPaywall>;
        default:
          return (
            <div className="text-danger">
              Error: User has not paid, a paywall must be returned.
            </div>
          );
      }
    };
    return (
      <div className="w-100">
        <button
          className="btn p-2 py-2a btn-primary rounded-1 h5 w-100"
          onClick={() => {
            canSendProposal
              ? this.MySwal.fire({
                  customClass: 'bg-white shadow w-lg-60 w-md-60 w-90',
                  showConfirmButton: false,
                  html: (
                    <ProposalForm
                      sendTo={this.props.sendTo}
                      allowedListings={allowedListings}
                      handleOnNameSelect={this.handleOnNameSelect}
                      handleOnTypeSelect={this.handleOnTypeSelect}
                      handleOnSubmit={this.handleOnSubmit}
                      handleOnChange={this.handleOnChange}
                    ></ProposalForm>
                  ),
                  showCloseButton: true,
                })
              : this.MySwal.fire({
                  customClass: 'bg-transparent w-lg-60 w-md-60 w-90',
                  showConfirmButton: false,
                  html: getPaywall(this.props.sendTo),
                });
          }}
        >
          Send Proposal
        </button>
      </div>
    );
  }
}
export default connect(
  state => ({
    proposalToInvestorListings: state.user.proposalToInvestorListings,
    proposalToAdvisorListings: state.user.proposalToAdvisorListings,
  }),
  { addProposalListings, addToSentProposals },
)(SendProposalBttn);
