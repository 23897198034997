import React from 'react';
import subscriptionService from '../services/subscriptionService';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import { paymentPublicKey } from '../../config';

const SubscriptionModal = props => {
  const {
    planId,
    amount,
    userEmail,
    userPhone,
    redirectTo,
    listingType,
    listingId,
    successMessage,
  } = props;

  const subscribeWithRave = () => {
    var x = window.getpaidSetup({
      PBFPubKey: paymentPublicKey,
      customer_email: userEmail,
      amount,
      customer_phone: userPhone,
      currency: 'NGN',
      txref: `rave-${listingType}-${listingId}-${new Date().getTime()}`,
      payment_plan: planId,
      meta: [
        {
          metaname: 'productID', // to identify the exact listing item the user subscribed for
          metavalue: `${listingType}-${listingId}`,
        },
      ],
      onclose: function() {},
      callback: async function(response) {
        var txref = response.tx.txRef; // collect txRef returned and pass to a server page to complete status check.
        var subscriptionId = response.tx.id;
        if (
          response.tx.chargeResponseCode === '00' ||
          response.tx.chargeResponseCode === '0'
        ) {
          // redirect to a success page
          // save subscription to user subscription on server

          const result = await subscriptionService.subscribeUser({
            userEmail,
            listingType,
            listingId,
            planId,
            txref,
            subscriptionId,
          });

          if (result) {
            Swal.fire(
              listingType + ' profile subscription successful',
              successMessage,
              'success',
            ).then(() => {
              window.location.href = '/' + redirectTo;
            });
          }
        } else {
          Swal.fire(
            'An error has occured',
            'Unable to complete subscription',
            'error',
          );
        }

        x.close(); // use this to close the modal immediately after payment.
      },
    });
  };

  return (
    <div className="card rounded-1 shadow bg-white">
      <div className="p-5">
        <p>
          Proceed to payment of <br />
          <strong>
            <NumberFormat
              value={amount}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'₦'}
            />
          </strong>
        </p>

        <div>
          <button onClick={subscribeWithRave} className="btn btn-primary">
            Subscribe Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionModal;
