import React, { Component } from 'react';
import { RESETFORMS } from '../../state/actions/types';
import { Route, Switch } from 'react-router-dom';
import store from '../../state/store';
import RegisterInvestorForm1 from './RegisterInvestorForm1';
import InvestorPlans from './InvestorPlans';

class RegisterInvestor extends Component {
  componentWillUnmount() {
    store.dispatch({ type: RESETFORMS });
  }

  render() {
    return (
      <div>
        <Switch>
          <Route
            path="/register-investor/plans"
            component={() => {
              return (
                <div className="container">
                  <div className="w-100 text-center font-weight-bold text-pricing h2a mb-5">
                    Choose an investor plan
                  </div>
                  <InvestorPlans {...this.props} />
                </div>
              );
            }}
          />
          <Route path="/" component={RegisterInvestorForm1} />
        </Switch>
      </div>
    );
  }
}

export default RegisterInvestor;
