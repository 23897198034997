import React from 'react';

const AppFooter = () => {
  return (
    <footer
      className="d-lg-flex justify-content-between p-5 text-white bg-footer custom-home"
      id="footer"
    >
      <div className="text-footer-shade h5">
        <div alt="compex logo" className="bg-compex-logo"></div>
        <p className="h5 font-weight-bold mb-4">Compex Africa Limited</p>
        <p>
          287 Ajose Adeogun Street,<br></br>
          Victoria Island,<br></br>
          Lagos, Nigeria.
        </p>
        <p>
          Compex Africa. All rights reserved ©{' '}
          {String(new Date().getFullYear())}
        </p>
      </div>
      <div className="d-flex col-lg-4 col-xl-4 col-12 p-0 justify-content-between">
        <div className="font-weight-bold h5 text-sky-grey">
          <h5 className="text-footer-shade font-weight-bold h5 mb-4">LEGAL</h5>
          <a href="/privacy" className="text-decoratioin-none text-sky-grey">
            <p>Privacy Policy</p>
          </a>
          <a href="/refunds" className="text-decoratioin-none text-sky-grey">
            <p>Refund Policy</p>
          </a>
          <a href="/terms" className="text-decoratioin-none text-sky-grey">
            <p>Terms and Conditions</p>
          </a>
        </div>
        <div className="font-weight-bold h5 text-sky-grey">
          <h5 className="text-footer-shade font-weight-bold h5 mb-4">
            COMPANY
          </h5>
          <a href="/about" className="text-decoration-none text-sky-grey">
            <p>About</p>
          </a>
          <a href="/contact" className="text-decoratioin-none text-sky-grey">
            <p>Contact</p>
          </a>
          <a href="/Careers" className="text-decoratioin-none text-sky-grey">
            <p>Careers</p>
          </a>
          <a href="/faq" className="text-decoratioin-none text-sky-grey">
            <p>F.A.Q</p>
          </a>
        </div>
      </div>
      <div className="text-sky-grey">
        <div className="d-flex justify-content-between mb-3 w-40 w-lg-100">
          <h5>
            <a
              href="https://www.facebook.com/compexafrica/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f text-footer-shade"></i>
            </a>
          </h5>
          <h5>
            <a
              href="https://twitter.com/compexafrica"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter text-footer-shade"></i>
            </a>
          </h5>
          <h5>
            <a
              href="https://www.instagram.com/compexafrica/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram text-footer-shade"></i>
            </a>
          </h5>
          <h5>
            <a
              href="https://www.linkedin.com/company/compexafrica"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin-in text-footer-shade"></i>
            </a>
          </h5>
        </div>
        <p className="mb-0 font-weight-bold h5">+234-817-999-9931</p>
        <p className="font-weight-bold h5">+234-817-999-9930</p>
        <p className="font-weight-bold h5">hello@compexafrica.com</p>
      </div>
    </footer>
  );
};

export default AppFooter;
