import React, { Component } from 'react';
import BusinessListingTableItem from './BusinessListingTableItem';
import { CircularProgress } from '@material-ui/core';

class BusinessListingsTable extends Component {
  render() {
    let { loadingListings } = this.props;
    return (
      <div className="table-responsive shadow">
        <table className="table">
          <thead className="bg-admin-primary text-white h7">
            <tr>
              <th>Count</th>
              <th>Business ID</th>
              <th>Title of Listing</th>
              <th>Location</th>
              <th>Date Listed</th>
              <th>Price</th>
              <th>Type of Sale</th>
              <th>Listing Status</th>
              <th>Featured</th>
              <th>Lister First Name</th>
              <th>Lister Last Name</th>
              <th>Official Email</th>
              <th>Phone Number</th>
              <th>Plan</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {loadingListings ? (
              <tr>
                <td>
                  <CircularProgress color="primary" size={'1rem'} />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ) : (
              this.props.listings.map((listing, index) => (
                <BusinessListingTableItem
                  key={listing.id}
                  listing={listing}
                  number={this.props.listings.length - index}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default BusinessListingsTable;
