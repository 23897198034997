import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { startupZoneDomain } from '../../config';

const NavDesktopLinks = props => {
  const { currentUser } = props;

  return (
    <div className="w-100 border-bottom border-border px-5">
      <div className="w-100 d-none d-lg-block d-xl-block"></div>
      <div className="custom-nav bg-nav d-flex flex-wrap justify-content-between w-100 mt-3 align-items-center">
        <ul className="navbar-nav">
          <li className="nav-item my-auto mx-2 nav-drop">
            <NavLink to="/businesses" className="nav-link text-primary h7">
              Businesses for sale
              <span className="sr-only">(current)</span>
            </NavLink>
            <div className="bg-primary position-absolute d-none plain-drop-item">
              <NavLink to="/businesses" className="nav-link text-white h7 mb-0">
                Businesses for sale
              </NavLink>
              <NavLink
                to="/register-business/plans"
                className="nav-link text-white h7 mb-0"
              >
                Register Business
              </NavLink>
              <NavLink
                to="/covid-intervention"
                className="nav-link text-white d-none"
              >
                COVID-19 Intervention
              </NavLink>
            </div>
          </li>
          <li className="nav-item my-auto mx-2 nav-drop">
            <NavLink to="/investors" className="nav-link text-primary h7">
              Investors <span className="sr-only">(current)</span>
            </NavLink>
            <div className="bg-primary position-absolute d-none plain-drop-item">
              <Link
                className="nav-link text-white h7 mb-0"
                to={{
                  pathname: '/lenders',
                }}
              >
                Lenders
              </Link>
              <NavLink to="/investors" className="nav-link text-white h7 mb-0">
                Investors
              </NavLink>
              <NavLink
                to="/register-investor/plans"
                className="nav-link text-white h7 mb-0"
              >
                Register Investor
              </NavLink>
            </div>
          </li>
          <li className="nav-item my-auto mx-2 nav-drop">
            <NavLink to="/advisors" className="nav-link text-primary h7">
              Advisors
            </NavLink>
            <div className="bg-primary position-absolute d-none plain-drop-item">
              <NavLink to="/advisors" className="nav-link text-white h7 mb-0">
                Advisors
              </NavLink>
              <NavLink
                to="/register-advisor/plans"
                className="nav-link text-white h7 mb-0"
              >
                Register Advisor
              </NavLink>
            </div>
          </li>
          <li className="nav-item my-auto mx-2 nav-drop">
            <NavLink to="/franchises" className="nav-link text-primary h7">
              Franchises
            </NavLink>
            <div className="bg-primary position-absolute d-none plain-drop-item">
              <NavLink to="/franchises" className="nav-link text-white h7 mb-0">
                Franchises
              </NavLink>
              <NavLink
                to="/register-franchise/plans"
                className="nav-link text-white h7 mb-0"
              >
                Register Franchise
              </NavLink>
            </div>
          </li>
          <li className="nav-item my-auto mx-2 nav-drop">
            <a
              href={`${startupZoneDomain}`}
              className="nav-link text-primary h7"
            >
              Startup Zone
            </a>
          </li>
          {currentUser.isAdmin && (
            <li className="nav-item my-auto mx-2 nav-drop">
              <NavLink
                to="/admin/dashboard"
                className="nav-link text-primary h7"
              >
                Admin
              </NavLink>
            </li>
          )}
        </ul>
        <ul className="navbar-nav">
          <li className="nav-item my-auto mx-2">
            <NavLink to="/#pricing" className="nav-link text-primary h7">
              Pricing
            </NavLink>
          </li>
          <li className="nav-item my-auto mx-2 nav-drop">
            <NavLink to="/faq" className="nav-link text-primary h7">
              F.A.Q
            </NavLink>
          </li>
          <li className="nav-item my-auto mx-2 nav-drop">
            <NavLink to="/about" className="nav-link text-primary h7">
              Company
            </NavLink>
            <div className="bg-primary position-absolute d-none plain-drop-item w-10">
              <NavLink to="/about" className="nav-link text-white h7 mb-0">
                About Us
              </NavLink>
              <NavLink to="/contact" className="nav-link text-white h7 mb-0">
                Contact
              </NavLink>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavDesktopLinks;
