import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setHeaders, resetHeaders } from './../../state/actions/layout';
import { withRouter } from 'react-router-dom';

const Careers = props => {
  const description =
    'Our people are central to our success and growth. To join a team of enterprising and bright individuals, apply and let us take it from there.';
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setHeaders({
        title: 'Careers',
        url: props.history.location.pathname,
        description,
      }),
    );

    return function cleanup() {
      dispatch(resetHeaders());
    };
  }, [dispatch, description, props.history.location.pathname]);
  return (
    <div className="container custom-home py-5">
      <div className="d-flex flex-column align-items-center justify-content-center text-center bg-about mb-5">
        <p className="h1 text-subscribe-light font-weight-bold">Careers</p>
      </div>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        Our people are central to our success and growth.
      </p>
      <p className="h7 line-height-5 text-about mb-4">
        To join a team of enterprising and bright individuals, apply and let us
        take it from there.
      </p>
    </div>
  );
};

export default withRouter(Careers);
