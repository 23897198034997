import React, { Component } from 'react';
import notification from '../../assets/img/ring.svg';
import { connect } from 'react-redux';
import commonService from '../services/commonService';
import profileType from '../utils/profileType';
import { fetchUserNotifications } from '../../state/actions/user';

class Notifications extends Component {
  viewNotification = (link, id) => {
    commonService.markNotificationRead(id).then(res => {
      this.props.fetchUserNotifications();
      this.props.history.push(link);
    });
  };

  markAllRead = () => {
    commonService.markAllNotificationsRead().then(res => {
      this.props.fetchUserNotifications();
    });
  };

  isReadClass = (classes, status) => {
    return status ? classes + ' bg-white' : classes + ' bg-unread';
  };

  render() {
    const { notifications } = this.props;

    let unreadCount = 0;
    let notificationViews = [];

    if (notifications && notifications.length > 0) {
      unreadCount = notifications.filter(x => !x.isRead).length;

      notifications.forEach(notification => {
        const data = JSON.parse(notification.data);
        const link =
          data.profileType === profileType.Business
            ? `/businesses/${data.profileId}`
            : data.profileType === profileType.Investor
            ? `/investors/${data.profileId}`
            : data.profileType === profileType.Advisor
            ? `/advisors/${data.profileId}`
            : `/franchises/${data.profileId}`;

        let notificationView = {
          link,
          date: new Date(notification.createdAt).toDateString(),
          id: notification.id,
          listingId: data.profileId,
          listingType: data.profileType,
          isRead: notification.isRead,
        };

        if (notification.eventType === 'ProfileContacted') {
          notificationView.display = (
            <span>
              You can view a new {data.profileType} listing: {data.profileTitle}
              . You have <strong> {data.contactsRemaining} </strong> contacts
              unit left
            </span>
          );
        } else if (notification.eventType === 'NewProfileContactView') {
          notificationView.display = (
            <span>
              You have a new view on your {data.profileType} listing:{' '}
              {data.profileTitle}
            </span>
          );
        } else if (notification.eventType === 'ProposalSent') {
          notificationView.display = (
            <span>
              You sucessfully sent proposal to{' '}
              {data.profileType === 'Investor' || data.profileType === 'Advisor'
                ? 'an '
                : 'a '}{' '}
              {data.profileType} listing: {data.profileTitle}. You have{' '}
              <strong> {data.contactsRemaining} </strong> units left
            </span>
          );
        }
        notificationViews.push(notificationView);
      });

      const notificationsClone = [...notificationViews];
      notificationViews.length = 0;
      notificationViews = notificationsClone.slice(0, 6);
    }

    return (
      <React.Fragment>
        <div
          className="button-as-link nav-link"
          id="navbarDropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="d-flex position-relative max-w-rem-2a">
            <img
              src={notification}
              alt="..."
              className="img-fluid p-1"
              width="30"
            ></img>
            {unreadCount > 0 && (
              <div
                className="counter-wrapper position-absolute"
                style={{ top: '-2px', right: '0px' }}
              >
                <span
                  className="badge badge-danger counter"
                  style={{ fontSize: '.7rem' }}
                >
                  {unreadCount}
                </span>
              </div>
            )}
          </div>
        </div>
        <div
          className="dropdown-menu bg-light notifications"
          style={{ minWidth: '20rem' }}
          aria-labelledby="navbarDropdown"
        >
          <div className="d-flex justify-content-between bg-white p-2 border-bottom mb-2">
            <div>Notifications</div>
            <div onClick={this.markAllRead} className="btn btn-link p-0 m-0">
              Mark all as read
            </div>
          </div>
          {notificationViews.length > 0 &&
            notificationViews.map(view => (
              <div
                onClick={() => this.viewNotification(view.link, view.id)}
                className={this.isReadClass(
                  'mx-2 mb-2 border rounded p-2 cursor-pointer notification ',
                  view.isRead,
                )}
                key={view.id}
              >
                <div>{view.display}</div>
                <div className="text-right">
                  <em
                    className="text-sm-1 text-muted"
                    style={{ fontSize: '.6rem' }}
                  >
                    {view.date}
                  </em>
                </div>
              </div>
            ))}
          {notificationViews.length === 0 && (
            <div className="mx-2 mb-2 border rounded p-2 text-danger">
              No notifications
            </div>
          )}
          <div className="d-flex justify-content-between bg-white p-2 border-top">
            <div className="btn btn-link p-0 m-0">See all</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    notifications: state.user.userNotifications,
  }),
  { fetchUserNotifications },
)(Notifications);
