import React, { Component } from 'react';
import NavBarMobile from '../common/components/NavBarMobile';
import NavBarDesktop from '../common/components/NavBarDesktop';

class NavBar extends Component {
  state = {
    prevScrollpos: window.pageYOffset,
  };
  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  };
  handleScroll = () => {
    var currentScrollPos = window.pageYOffset;
    if (this.state.prevScrollpos > currentScrollPos) {
      this.navBarDesktop.style.top = '0';
    } else {
      this.navBarDesktop.style.top = '-103px';
    }
    this.setState({ prevScrollpos: currentScrollPos });
  };

  render() {
    return (
      <div>
        <NavBarMobile></NavBarMobile>
        <div
          className="fixed-top d-none d-lg-block d-xl-block"
          ref={elem => {
            this.navBarDesktop = elem;
          }}
          style={{ transition: 'top 0.3s' }}
        >
          <NavBarDesktop></NavBarDesktop>
        </div>
      </div>
    );
  }
}

export default NavBar;
