import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchAdminAdvisorListings } from '../../state/actions/advisor';
import advisorService from '../../common/services/advisorService';
import AdminActionModal from '../../common/components/AdminActionModal';
import withReactContent from 'sweetalert2-react-content';
import profileType from './../../common/utils/profileType';
import profileStatus from './../../common/utils/profileStatus';
import Swal from 'sweetalert2';
import { spacePascalWord } from './../../common/utils/helpers';

class AdvisorListingTableItem extends Component {
  MySwal = withReactContent(Swal);
  state = {};

  approveAdvisorListing = async id => {
    Swal.fire(
      'Are you sure?',
      'Do you want to approve listing?',
      'question',
    ).then(async value => {
      if (!value) return;
      const res = await advisorService.approveAdvisorListing(id);
      if (res) {
        this.props.fetchAdminAdvisorListings();
        Swal.fire('Listing Approved Successfully', '', 'success');
      } else {
        Swal.fire('Unable to approve listing', '', 'error');
      }
    });
  };

  disapproveAdvisorListing = async id => {
    Swal.fire(
      'Are you sure?',
      'Do you want to disapprove listing?',
      'question',
    ).then(async value => {
      if (!value) return;
      const res = await advisorService.disapproveAdvisorListing(id);
      if (res) {
        this.props.fetchAdminAdvisorListings();
        Swal.fire('Listing Disapproved Successfully', '', 'success');
      } else {
        Swal.fire('Unable to disapprove listing', '', 'error');
      }
    });
  };

  changeListingStatus = async (status, id) => {
    this.MySwal.fire({
      customClass: 'bg-transparent w-lg-30 w-md-30 w-90',
      showConfirmButton: false,
      html: (
        <AdminActionModal
          action={status}
          profileType={profileType.Advisor}
          profileId={id}
          refreshListings={this.props.fetchAdminAdvisorListings}
        ></AdminActionModal>
      ),
    });
  };

  render() {
    const { listing, number } = this.props;
    return (
      <tr>
        <td>
          <Link to={`/user/advisors/${listing.id}`}>{number}</Link>
        </td>
        <td>
          <Link to={`/user/advisors/${listing.id}`}>{listing.id}</Link>
        </td>
        <td>
          <Link to={`/user/advisors/${listing.id}`}>{listing.firstName}</Link>
        </td>
        <td>
          <Link to={`/user/advisors/${listing.id}`}>{listing.lastName}</Link>
        </td>

        <td>{listing.officialEmail}</td>
        <td>{listing.phoneNumber}</td>
        <td>{listing.type}</td>
        <td>{listing.plan}</td>
        <td>
          {listing.createdAt && new Date(listing.createdAt).toLocaleString()}
        </td>

        <td>
          <div className="d-flex">
            <span
              className={
                listing.status === profileStatus.Approved
                  ? 'badge badge-success mx-4'
                  : listing.status === profileStatus.Draft ||
                    listing.status === profileStatus.Declined
                  ? 'badge badge-danger mx-4'
                  : listing.status === profileStatus.Pending ||
                    listing.status === profileStatus.ChangesRequired
                  ? 'badge badge-warning mx-4'
                  : 'badge'
              }
            >
              {spacePascalWord(listing.status)}
            </span>
            <button
              className="d-block pr-3 border-0 bg-transparent text-primary"
              id={`${listing.id}`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              {listing.isApproved && (
                <button
                  className="dropdown-item cursor-pointer"
                  onClick={() => this.disapproveAdvisorListing(listing.id)}
                >
                  Disapprove
                </button>
              )}
              {!listing.isApproved && (
                <button
                  className="dropdown-item cursor-pointer"
                  onClick={() => this.approveAdvisorListing(listing.id)}
                >
                  Approve
                </button>
              )}
              <button
                className="dropdown-item cursor-pointer"
                onClick={() => this.changeListingStatus('Decline', listing.id)}
              >
                Decline
              </button>
              <button
                className=" dropdown-item cursor-pointer"
                onClick={() =>
                  this.changeListingStatus('Request Change', listing.id)
                }
              >
                Request Change
              </button>
            </div>
          </div>
        </td>
        <td>
          <Link to={`/admin/dashboard/user-listings/${listing.id}`}>
            {listing.firstName}
          </Link>
        </td>
        <td>
          <Link to={`/admin/dashboard/user-listings/${listing.id}`}>
            {listing.lastName}
          </Link>
        </td>
      </tr>
    );
  }
}

export default connect(null, { fetchAdminAdvisorListings })(
  AdvisorListingTableItem,
);
