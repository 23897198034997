import React from 'react';
import { NavLink } from 'react-router-dom';
import bgValuation from '../../assets/img/bg-valuation.png';
import UserBusinessListingsList from './UserBusinessListingsList';

const UserBusinessListings = props => {
  return (
    <div>
      <div className="ml-lg-5">
        <h1>Businesses you listed</h1>
        <div className="w-50 my-5 py-3">
          <hr></hr>
        </div>
        <UserBusinessListingsList {...props} />
      </div>
      <NavLink to="/register-business" className="btn btn-primary my-5 ml-lg-5">
        List a business for sale
      </NavLink>
      <div className="my-5 py-5 ml-lg-5">
        <div className="card bg-primary text-white border-0 rounded-0">
          <img
            className="card-img d-none d-lg-block d-xl-block"
            src={bgValuation}
            alt="Card"
          ></img>
          <div className="card-img-overlay d-none d-lg-block d-xl-block align-middle">
            <div className="d-flex h-100 align-items-center">
              <p className="card-text h1a">
                Online business valuation at your fingertips
              </p>
              <button className="btn btn-primary w-25 h2">Coming soon</button>
            </div>
          </div>
          <div className="card-body d-lg-none d-xl-none">
            <h2 className="card-text">
              Online business valuation at your fingertips
            </h2>
            <button className="btn btn-outline-light my-3">Coming soon</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBusinessListings;
