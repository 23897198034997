import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import communityService from '../../common/services/communityService';
import { fetchStartupPosts } from '../../state/actions/startup';

class CommunityPostTableItem extends Component {
  MySwal = withReactContent(Swal);
  state = {};

  approvePost = async id => {
    Swal.fire({
      title: `Are you sure?
      Do you want to approve this Post?
      `,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await communityService.approvePost(id);
          if (!response) {
            throw new Error(response.statusText);
          }
          return response;
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
      if (result.value === true) {
        this.props.fetchStartupPosts();
        Swal.fire('Post Approved Successfully', '', 'success');
      }
    });
  };

  disapprovePost = async id => {
    Swal.fire({
      title: `Are you sure?
      Do you want to disapprove this Post?
      `,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await communityService.disapprovePost(id);
          if (!response) {
            throw new Error(response.statusText);
          }
          return response;
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
      if (result.value === true) {
        this.props.fetchStartupPosts();
        Swal.fire('Post Disapproved Successfully', '', 'success');
      }
    });
  };

  render() {
    const { post, number } = this.props;
    return (
      <tr>
        <td>
          <Link to={`/admin/dashboard/posts/${post.id}`}>{number}</Link>
        </td>
        <td>
          <Link to={`/admin/dashboard/posts/${post.id}`}>{post.id}</Link>
        </td>
        <td>
          <Link to={`/admin/dashboard/posts/${post.id}`}>
            <div>{post.title}</div>
          </Link>
        </td>

        <td>{post.createdAt && new Date(post.createdAt).toLocaleString()}</td>
        <td>{post.postCategoryName}</td>
        <td>
          <div className="d-flex">
            <span
              className={
                post.isApproved
                  ? 'badge badge-success mx-4'
                  : 'badge badge-warning mx-4'
              }
            >
              {post.isApproved ? 'Approved' : 'Pending'}
            </span>
            <button
              className=" button-as-link d-block pr-3"
              id={`${post.id}`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              {post.isApproved && (
                <button
                  className="dropdown-item cursor-pointer"
                  onClick={() => this.disapprovePost(post.id)}
                >
                  Disapprove
                </button>
              )}
              {!post.isApproved && (
                <button
                  className="dropdown-item cursor-pointer"
                  onClick={() => this.approvePost(post.id)}
                >
                  Approve
                </button>
              )}

              <button className="dropdown-item">Hide</button>
              <button className="dropdown-item">Show</button>
              <button className="dropdown-item">Archive</button>
              <button className="dropdown-item">Mark as closed/Sold</button>
            </div>
          </div>
        </td>

        <td>
          <Link to={`/admin/dashboard/posts/${post.id}`}>
            <div>{post.userFirstname}</div>
          </Link>
        </td>
        <td>
          <Link to={`/admin/dashboard/posts/${post.id}`}>
            <div>{post.userLastname}</div>
          </Link>
        </td>
      </tr>
    );
  }
}

export default connect(null, { fetchStartupPosts })(CommunityPostTableItem);
