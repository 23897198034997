import React from 'react';

const Privacy = () => {
  return (
    <div className="container custom-home py-5">
      <div className="d-flex flex-column align-items-center justify-content-center text-center bg-about mb-5">
        <p className="h1 text-subscribe-light font-weight-bold">
          Privacy Policy
        </p>
      </div>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        COMPEXAFRICA is dedicated to ensuring that the protection of your data
        and personal information is guaranteed. We value confidentiality and
        ensure that your information is protected at all cost. When registering
        or creating a profile on our website, kindly ensure that you read
        through our privacy policy.
      </p>
      <p className="h7 line-height-5 text-about mb-4">
        This privacy policy (the “Policy”) clarifies the use of any private
        information or data such as name, email address, telephone numbers,
        employer information, financial information, transactional information
        and other personal information or personal data(Names, email address,
        phone number, account number) which may be supplied to the Company.
        Please be aware that with the guide of tolerating the private strategy
        and the expressions and conditions all through enlistment or the sending
        of an enquiry, you explicitly consent to the collection, storage,
        processing, use and exposure of your personal information as depicted on
        this Policy.
      </p>
      <p className="h7 line-height-5 text-about mb-4">
        Any confidential information and/or document shared with the Company for
        professional services will only be used for the intended purpose and
        will not be shared with any external party.
      </p>
      <p className="h7 line-height-5 text-about mb-4">
        This purposes are not limited to:
      </p>
      <ul className="h7 line-height-5 text-about mb-4">
        <li>
          Advertising your profile on the website to facilitate our services.
        </li>
        <li>
          Sharing detailed information such as profit margin, asking price,
          revenue figures, valuation multiples and business description etc.
        </li>
        <li>Performing advisory and valuation services when necessary.</li>
        <li>
          Improving our services, collecting fees, enforcing our policies and
          Terms and Conditions.
        </li>
        <li>
          Responding to legal requirements, preventing and potentially
          prohibited or illegal activities
        </li>
      </ul>
      <p className="h7 line-height-5 text-about mb-4">
        We submit testimonials on the site online procured from clients. These
        testimonials might contain the title, city, state or neighbourhood and
        exchange of the client. The Company is fully committed to protecting
        your confidential information and data. Where this website is linked to
        any website operated by any third party, the Company accepts no
        responsibility or liability arising in respect of any content,
        information or transaction on or carried out on such website. The
        presence of a link on our website shall not be deemed to be a
        recommendation or endorsement of such website by the Company.
      </p>
      <p className="h7 line-height-5 text-about mb-4">
        We retain confidential information from closed accounts to derive
        industry knowledge, comply with the law, prevent fraud, collect any fees
        owed, resolve disputes and take other actions otherwise permitted by
        law.
      </p>
      <p className="h7 line-height-5 text-about mb-4">
        We reserve the right to amend this Privacy Policy at any time and for
        any reason.
      </p>
    </div>
  );
};

export default Privacy;
