import {
  FETCH_INVESTOR_LISTINGS,
  EDITINVESTOR,
  RESETFORMS,
  UPDATE_INVESTORS,
  FETCH_INVESTOR_DETAILS,
  FETCH_ADMIN_INVESTOR_LISTINGS,
  FETCH_ADMIN_INVESTOR_LISTINGS_STARTED,
  SELECT_PLAN,
} from '../actions/types';

const initialState = {
  formdata: {},
  investorListings: [],
  loadingInvestorListings: false,
  investorDetails: {},
  adminInvestorListings: [],
  pageNumber: 1,
  pageSize: 50,
  totalCount: 0,
  selectedPlan: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVESTOR_LISTINGS:
      const { data, pageNumber, pageSize, totalCount } = action.payload;
      return {
        ...state,
        investorListings: data,
        pageNumber,
        pageSize,
        totalCount,
      };
    case UPDATE_INVESTORS:
      return {
        ...state,
        investorListings: action.payload.data,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount,
      };
    case FETCH_INVESTOR_DETAILS:
      return {
        ...state,
        investorDetails: action.payload,
      };
    case EDITINVESTOR:
      return {
        ...state,
        formdata: action.payload,
      };
    case RESETFORMS:
      return {
        ...state,
        formdata: {},
        selectedPlan: '',
      };
    case FETCH_ADMIN_INVESTOR_LISTINGS:
      return {
        ...state,
        adminInvestorListings: action.payload.data,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount,
        loadingInvestorListings: false,
      };
    case FETCH_ADMIN_INVESTOR_LISTINGS_STARTED:
      return {
        ...state,
        loadingInvestorListings: true,
      };
    case SELECT_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };
    default:
      return state;
  }
};
