import React, { Component } from 'react';
import CountryRegionData from '../../assets/datasets/CountryRegionData';

export class CountryInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: '',
      countries: CountryRegionData.map(x => x.countryName),
      filteredCountries: [],
    };
  }

  handleChange = e => {
    var key = e.keyCode || e.charCode;
    if (key === 8 || key === 46) {
      this.props.onChange(e.target.value, true);
      this.setState({ country: '' });
    } else {
      let { value: country } = e.target;

      if (country && country.length > 0) {
        country = country.trim().toLowerCase();

        const { countries } = this.state;

        const filteredCountries = countries.filter(item =>
          item.toLowerCase().startsWith(country),
        );
        this.setState({ filteredCountries, country });
        this.props.onChange(e.target.value);
      }
    }
  };

  render() {
    const { filteredCountries, country } = this.state;

    return (
      <>
        <input
          className={this.props.className && this.props.className}
          list={this.props.id && this.props.id}
          type="text"
          value={this.props.value}
          onChange={this.handleChange}
          onKeyDown={this.handleChange}
          placeholder="Type in country..."
        />
        <datalist id={this.props.id && this.props.id}>
          {filteredCountries.map(function(item) {
            if (
              filteredCountries.length === 1 &&
              item.length === country.length
            ) {
              return null;
            } else {
              return <option value={item} key={item} />;
            }
          })}
        </datalist>
      </>
    );
  }
}

export class RegionInput extends Component {
  state = { searchString: '' };

  handleChange = e => {
    var key = e.keyCode || e.charCode;
    if (key === 8 || key === 46) {
      this.setState({ searchString: '' });
      this.props.onChange('');
    } else {
      this.props.onChange(e.target.value);
      this.setState({ searchString: e.target.value });
    }
  };
  render() {
    const { country } = this.props;
    if (country) {
      var countryObj = CountryRegionData.find(
        ({ countryName }) =>
          countryName.toLowerCase() === country.toLowerCase(),
      );
    }
    var regions = '';
    if (countryObj) {
      regions = countryObj.regions;
    }
    var searchString = this.state.searchString.trim().toLowerCase();
    if (searchString.length > 0) {
      if (regions && regions.length > 0) {
        regions = regions.filter(function(item) {
          return item.name.toLowerCase().startsWith(searchString);
        });
      }
    }
    return (
      <>
        <input
          className={this.props.className && this.props.className}
          list={this.props.id && this.props.id}
          type="text"
          value={this.props.value}
          onChange={this.handleChange}
          onKeyDown={this.handleChange}
          placeholder="Type in region..."
        />
        <datalist id={this.props.id && this.props.id}>
          {regions &&
            regions.map(function(item) {
              if (
                regions.length === 1 &&
                item.name.length === searchString.length
              ) {
                return null;
              } else {
                return <option value={item.name} key={item.name} />;
              }
            })}
        </datalist>
      </>
    );
  }
}

export class RegionSelect extends Component {
  handleChange = e => {
    this.props.onChange(e.target.value);
  };
  render() {
    const { country } = this.props;
    if (country) {
      var countryObj = CountryRegionData.find(
        ({ countryName }) =>
          countryName.toLowerCase() === country.toLowerCase(),
      );
    }
    var regions = '';
    if (countryObj) {
      regions = countryObj.regions;
    }

    return (
      <>
        <select
          className={this.props.className && this.props.className}
          onChange={this.handleChange}
        >
          <option value="">click to select region</option>
          {regions &&
            regions.map(function(item) {
              return (
                <option value={item.name} key={item.name}>
                  {item.name}
                </option>
              );
            })}
        </select>
      </>
    );
  }
}
