import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import authService from '../services/authService';
import { googleAppId } from '../../config';
import { CircularProgress } from '@material-ui/core';

const GoogleLoginButton = props => {
  const [isTriggered, setIstriggered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadLogin = () => {
    setIsLoading(true);
    setIstriggered(true);
  };

  const onSuccess = async response => {
    console.log(response);

    if (!response.accessToken) {
      setIsLoading(false);
      setIstriggered(false);
      return;
    }

    try {
      setIsLoading(true);
      const res = await authService.googleLogin(response);
      if (res) {
        setIsLoading(false);
        const { state } = props.location;
        const redirectPath = state ? state.from.pathname : '/user/dashboard';
        props.history.replace(redirectPath);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onFailure = response => {
    console.log(response);
    setIstriggered(false);
    setIsLoading(false);
  };

  return (
    <>
      <div className="btn border border-thick min-w-rem-13" onClick={loadLogin}>
        <div className="d-flex justify-content-center align-items-center">
          <div className="bg-google-icon" style={{ maxWidth: '1.5rem' }}></div>
          {isLoading ? (
            <span className="px-3 min-w-90">
              <CircularProgress color="inherit" size={'1.3rem'} />
            </span>
          ) : (
            <span
              className="text-muted pl-3"
              style={{ fontFamily: 'Roboto', fontWeight: 500 }}
            >
              {props.displayText}
            </span>
          )}
        </div>
      </div>

      {isTriggered && (
        <GoogleLogin
          clientId={googleAppId}
          autoLoad={true}
          render={renderProps => <div onClick={renderProps.onClick}></div>}
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
        />
      )}
    </>
  );
};

export default GoogleLoginButton;
