import React from 'react';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';

const Proposals = ({ proposalType, readProposal, hideProposalList }) => {
  let counter = useSelector(state => state);

  let proposals =
    proposalType === 'inbox'
      ? counter.user.userProposalsInbox
      : counter.user.userProposalSent;

  let isLoading =
    proposalType === 'inbox'
      ? counter.user.loadingInboxProposals
      : counter.user.loadingSentProposals;

  const proposalList =
    proposals && proposals.length ? (
      <div className={hideProposalList}>
        {proposals.map(proposal => {
          let senderDes = '';
          let senderAdvisor = proposal && proposal['proposals'][0].advisor;
          let senderInvestor = proposal && proposal['proposals'][0].investor;
          let senderBusiness = proposal && proposal['proposals'][0].business;
          let senderFranchise = proposal && proposal['proposals'][0].franchise;
          let senderStartup = proposal && proposal['proposals'][0].startup;
          if (senderAdvisor) {
            senderDes = senderAdvisor.whoYouAre;
          }
          if (senderInvestor) {
            senderDes = senderInvestor.whoYouAre;
          }
          if (senderBusiness) {
            senderDes = senderBusiness.businessName;
          }
          if (senderStartup) {
            senderDes = senderStartup.startupName;
          }
          if (senderFranchise) {
            senderDes = senderFranchise.brandName;
          }
          return (
            <div key={proposal.id} className="p-3 border">
              <Link
                to={`/user/${proposalType}/proposals/${proposal.id}`}
                className="d-flex flex-column text-body"
                onClick={() => {
                  readProposal(proposal.id, proposalType);
                }}
              >
                <div className="d-flex flex-row">
                  <p className="h7 d-inline-block w-60">{senderDes}</p>
                  <p className="h7 d-inline-block w-40">
                    {new Date(proposal.created).toLocaleDateString()}
                  </p>
                </div>
                <p className="h7 font-weight-bold">{proposal.subject}</p>
                <div className="d-flex flex-row align-items-end">
                  <p className="h7 font-weight-normal d-inline-block text-truncate w-80">
                    {proposal.proposals[0].body}
                  </p>

                  {proposalType === 'sent' && proposal.listingViewed ? (
                    <p className="text-primary h7 font-weight-bold d-inline-block w-20 mr-2">
                      Viewed
                    </p>
                  ) : proposalType === 'sent' && proposal.isRead ? (
                    <p className="text-secondary h7 font-weight-bold d-inline-block w-20 mr-2">
                      Read
                    </p>
                  ) : (
                    ''
                  )}

                  {(proposal.isRead && proposalType === 'inbox') ||
                  proposalType === 'sent' ? (
                    ''
                  ) : (
                    <p className="text-danger h7 font-weight-bold d-inline-block w-20">
                      New
                    </p>
                  )}
                </div>

                <div className="details">
                  <i className="mdi mdi-star favorite"></i>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    ) : (
      <p className="p-4">You have no proposals at the moment</p>
    );

  return (
    <div>
      {isLoading ? (
        <div className="ml-3">
          <CircularProgress />
        </div>
      ) : (
        proposalList
      )}
    </div>
  );
};

export default Proposals;
