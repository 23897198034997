import React, { Component } from 'react';
import UserListingsTable from './UserListingsTable';
import { connect } from 'react-redux';
import {
  fetchAdminUserListings,
  searchAdminUserListings,
} from '../../state/actions/user';
import Pagination from './../../common/components/Pagination';
import { CircularProgress, Switch } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomDatePicker } from './../../common/utils/helpers';
import withSizes from 'react-sizes';
import { CSVLink } from 'react-csv';
import userService from './../../common/services/userService';

class UserListings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalUserListings: 134155463264,
      totalApproved: 13415245235,
      totalPending: 1341252345,
      allUserListings: [],
      userSearchString: '',
      searchPlaceholder: 'User Name',
      status: '',
      byId: false,
      startDate: null,
      endDate: null,
      csvData: [],
      csvHeaders: [
        { label: 'firstName', key: 'firstName' },
        { label: 'lastName', key: 'lastName' },
        { label: 'email', key: 'email' },
        { label: 'phoneNumber', key: 'phoneNumber' },
        { label: 'dateRegistered', key: 'dateRegistered' },
      ],
      exportLoading: false,
    };
    this.ref = React.createRef();
    this.csvLink = React.createRef();
  }

  componentDidMount() {
    this.props.fetchAdminUserListings();
  }

  fetchDataForExport = e => {
    e.preventDefault();
    this.setState({ exportLoading: true });
    console.log(this.state.exportLoading);
    userService
      .getAllUsersForExport()
      .then(response => {
        console.log(response.data.data);
        response && this.setState({ csvData: response.data.data });
        (() => {
          // click the CSVLink component to trigger the CSV download
          this.csvLink.current.link.click();
        })();
      })
      .finally(() => {
        this.setState({ exportLoading: false });
      });
  };

  setStartDate(startDate) {
    this.setState({ startDate });
  }

  setEndDate(endDate) {
    this.setState({ endDate });
  }

  handleFilterChange = e => {
    const status = e.target.value;
    this.setState({ status });
    this.userFilter(status);
  };

  onSwitch = async e => {
    const { checked } = e.target;
    this.setState({
      byId: checked,
      searchPlaceholder: checked ? 'User ID' : 'User Name',
    });
  };

  onPageChange = page => {
    const { byId, userSearchString, status, startDate, endDate } = this.state;
    if (typeof page === 'number') {
      this.setState({ loading: true });
      this.props.searchAdminUserListings(
        startDate,
        endDate,
        userSearchString,
        status,
        byId,
        page,
      );
    }
  };

  onPreviousPage = () => {
    const { byId, userSearchString, status, startDate, endDate } = this.state;
    this.setState({ loading: true });
    this.props.searchAdminUserListings(
      startDate,
      endDate,
      userSearchString,
      status,
      byId,
      this.props.pageNumber - 1,
    );
  };

  onNextPage = () => {
    const { byId, userSearchString, status, startDate, endDate } = this.state;
    this.setState({ loading: true });
    this.props.searchAdminUserListings(
      startDate,
      endDate,
      userSearchString,
      status,
      byId,
      this.props.pageNumber + 1,
    );
  };

  setUserSearchString = searchString => {
    this.setState({ userSearchString: searchString });
  };

  userSearch = async e => {
    e.preventDefault();
    const { byId, userSearchString, status, startDate, endDate } = this.state;
    if (byId) {
      this.props.searchAdminUserListings(
        startDate,
        endDate,
        userSearchString,
        status,
        byId,
      );
    } else {
      this.props.searchAdminUserListings(
        startDate,
        endDate,
        userSearchString,
        status,
      );
    }
  };

  userFilter = status => {
    const { byId, userSearchString, startDate, endDate } = this.state;
    if (byId) {
      this.props.searchAdminUserListings(
        startDate,
        endDate,
        userSearchString,
        status,
        byId,
      );
    } else {
      this.props.searchAdminUserListings(
        startDate,
        endDate,
        userSearchString,
        status,
      );
    }
  };

  render() {
    const {
      adminUserListings: allListings,
      pageNumber,
      pageSize,
      totalCount,
      loadingUserListings,
      isMobile,
      isTablet,
    } = this.props;
    const { byId, searchPlaceholder, startDate, endDate } = this.state;

    return (
      <div className="admin-custom">
        <div className="ml-lg-5">
          <h1>Users Overview</h1>
          <div className="w-50 my-3 border"></div>
          <div className="d-flex flex-wrap mb-5">
            <div className="d-flex flex-column justify-content-around w-100 w-lg-30 w-md-30 text-center bg-white shadow rounded-1 p-4 m-2">
              <p className="h6 mb-4">Total Users</p>
              <div className="h2 font-weight-bold text-primary">
                {loadingUserListings ? (
                  <CircularProgress color="primary" size={'1rem'} />
                ) : (
                  totalCount
                )}
              </div>
            </div>
            <div className="d-block d-lg-none d-md-none w-100"></div>
            <div className="d-flex justify-content-around w-100 w-lg-60 w-md-60 text-center bg-white shadow rounded-1 p-2 m-2">
              <div className="d-flex flex-column justify-content-around">
                <p className="h6 mb-4">From</p>
                <div className="input-group px-2">
                  <DatePicker
                    todayButton="Today"
                    selected={startDate}
                    onChange={date => this.setStartDate(date)}
                    isClearable
                    placeholderText="Select Date"
                    customInput={<CustomDatePicker ref={this.ref} />}
                    withPortal={isMobile || isTablet}
                  />
                </div>
              </div>
              <div className="d-flex flex-column  justify-content-around">
                <p className="h6 mb-4">To</p>
                <div className="input-group">
                  <DatePicker
                    todayButton="Today"
                    selected={endDate}
                    onChange={date => this.setEndDate(date)}
                    isClearable
                    customInput={<CustomDatePicker ref={this.ref} />}
                    withPortal={isMobile || isTablet}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-50 my-3 border"></div>
          <div className=" ml-3">
            <Switch
              color="primary"
              name="renewal"
              value={byId}
              checked={byId}
              onChange={e => this.onSwitch(e)}
            />
            {byId ? 'By User ID' : 'By User Name'}
          </div>
          <div className="d-flex flex-wrap my-4 justify-content-between">
            <div className="form-inline my-2">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder={searchPlaceholder}
                  aria-label={searchPlaceholder}
                  aria-describedby="basic-addon2"
                  onChange={event =>
                    this.setUserSearchString(event.target.value)
                  }
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline btn-admin-primary"
                    type="button"
                    onClick={this.userSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <select
              className="browser-default custom-select w-50 w-md-30 my-2"
              value={this.state.status}
              onChange={this.handleFilterChange}
            >
              <option value="">See All</option>
              <option value="Verified">Verified</option>
              <option value="NotVerified">Not Verified</option>
              <option value="Suspended">Suspended</option>
              <option value="NotSuspended">Not suspended</option>
            </select>

            <CSVLink
              filename={'compexafrica-users.csv'}
              data={this.state.csvData}
              headers={this.state.csvHeaders}
              className="d-none"
              ref={this.csvLink}
            />

            <div
              onClick={e => this.fetchDataForExport(e)}
              className="cursor-pointer d-flex px-2 my-2 align-items-center text-center border-radius border-thick border-suceess"
            >
              {this.state.exportLoading ? (
                <CircularProgress color="primary" size={'1rem'} />
              ) : (
                <>
                  export{' '}
                  <i className="px-2 fas fa-file-export text-success"></i>{' '}
                </>
              )}
            </div>
          </div>
          <div className="profiles-table">
            <UserListingsTable
              listings={allListings}
              loadingUserListings={loadingUserListings}
            />
            <div className="d-flex justify-content-center my-3">
              <Pagination
                currentPage={pageNumber}
                pageSize={pageSize}
                totalItem={totalCount}
                onPageChange={this.onPageChange}
                onPreviousPage={this.onPreviousPage}
                onNextPage={this.onNextPage}
                hideComponent={loadingUserListings ? 'd-none' : ''}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapSizesToProps = sizes => ({
  isMobile: withSizes.isMobile(sizes),
  isTablet: withSizes.isTablet(sizes),
});

export default withSizes(mapSizesToProps)(
  connect(
    state => ({
      loadingUserListings: state.user.loadingUserListings,
      adminUserListings: state.user.adminUserListings,
      pageNumber: state.user.pageNumber,
      pageSize: state.user.pageSize,
      totalCount: state.user.totalCount,
    }),
    { fetchAdminUserListings, searchAdminUserListings },
  )(UserListings),
);
