import React from 'react';
import { Link } from 'react-router-dom';
import Tick from './../../assets/img/tick.svg';

const SuccessfulListing = () => {
  return (
    <div className="d-flex flex-column align-items-center container pb-5">
      <div className="rounded-circle bg-primary p-3 w-25 col-lg-1 my-5">
        <img src={Tick} alt="..."></img>
      </div>
      <h1 className="h4 text-verify-dark">
        Thanks for registering a business for sale
      </h1>
      <div className="card bg-white shadow rounded mb-3 col-lg-6 my-5">
        <div className="card-body px-5 py-5">
          <p className="card-text h5 text-verify-light font-weight-light text-center">
            Your business listing has been successfully uploaded and is
            currently being reviewed. You will be notified once it is approved.
          </p>
        </div>
      </div>
      <div className="pb-5">
        <Link to="/user/businesses" className="btn btn-primary">
          Go to Dashboard
        </Link>
      </div>
    </div>
  );
};

export default SuccessfulListing;
