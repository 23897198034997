import React, { Component } from 'react';

class SearchForm extends Component {
  state = {
    formdata: {
      text: '',
      type: 'all',
    },
  };

  onChange = e => {
    const { name, value } = e.target;
    const formdata = { ...this.state.formdata };
    formdata[name] = value;
    this.setState({ formdata });
  };

  submit = e => {
    e.preventDefault();
    this.props.onSearch(this.state.formdata);
  };

  switchNavColour = () => {
    const parent = document.getElementById('navParent');
    if (parent.classList.contains('bg-body')) {
      parent.classList.add('bg-landing');
      parent.classList.remove('bg-body');
    } else {
      parent.classList.add('bg-body');
      parent.classList.remove('bg-landing');
    }
  };

  render() {
    return (
      <form onSubmit={this.submit} className="my-lg-0">
        <div className="d-flex flex-nowrap my-lg-2 my-xl-2 mb-5">
          <input
            onChange={this.onChange}
            onFocus={this.props.addFilter}
            value={this.state.formdata.text}
            name="text"
            className="rounded-right-0 border-right-0 border-primary rounded-left form-control mx-0 w-75"
            type="search"
            placeholder="Search for businesses, investors and professional advisers"
            aria-label="Search"
          />
          <div className="p-0">
            <select
              onChange={this.onChange}
              value={this.state.formdata.type}
              name="type"
              className="rounded-left-0 form-control border-primary rounded-right"
            >
              <option value="all">All</option>
              <option value="full">Full Sale</option>
              <option value="partial">Partial Sale</option>
              <option value="capital">Capital Raise</option>
            </select>
          </div>
          <button
            className="btn btn-primary ml-2 my-2 my-sm-0 d-none d-lg-block d-xl-block"
            type="submit"
          >
            <i className="fas fa-search text-white text-right"></i>
          </button>
        </div>
        <button
          className="btn btn-primary mb-3 w-100 d-lg-none d-xl-none d-inline"
          type="submit"
          data-target="#navbarMobileFilter"
          data-toggle="collapse"
          onClick={this.switchNavColour}
        >
          <div className="d-flex flex-no-wrap align-items-center">
            <p className="w-90 m-0">Search</p>
            <i className="fas fa-search text-white text-right"></i>
          </div>
        </button>
      </form>
    );
  }
}

export default SearchForm;
