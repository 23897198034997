import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchInvestorDetails } from '../../state/actions/investor';
import { fetchBusinessListings } from '../../state/actions/business';
import SendProposalBttn from '../../common/components/SendProposalBttn';
import CarouselTemp from '../../common/components/CarouselTemp';
import { Link } from 'react-router-dom';
import investorsService from '../../common/services/investorsService';
import Swal from 'sweetalert2';
import BusinessFranchiseListingItem from '../../common/components/BusinessFranchiseListingItem';
import NumberFormat from 'react-number-format';
import { spacePascalWord, camelPad } from '../../common/utils/helpers';
import profileStatus from './../../common/utils/profileStatus';
import randomstring from 'randomstring';
import FadeIn from 'react-fade-in';
import { CircularProgress } from '@material-ui/core';

class InvestorListingItemPage extends Component {
  state = {
    loading: null,
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.setState({
        loading: true,
      });
      this.props.fetchInvestorDetails(id);
    }
    this.props.fetchBusinessListings();
  }

  componentDidUpdate(prevProps) {
    if (this.props.investor !== prevProps.investor) {
      this.setState({
        loading: false,
      });
    }
  }

  deleteInvestor = async id => {
    Swal.fire(
      'Are you sure?',
      'Do you want to delete listing?',
      'warning',
    ).then(async ok => {
      if (ok.value) {
        const res = await investorsService.deleteInvestor(id);
        if (res) {
          Swal.fire('Listing deleted Successfully', '', 'success').then(() => {
            this.props.history.replace('/user/investors');
          });
        } else {
          Swal.fire('Unable to delete listing', '', 'error');
        }
      }
    });
  };

  render() {
    let { investor, businessListings, currentUser } = this.props;
    const getLocations = () => {
      let locations = [];
      investor.locations &&
        investor.locations.split(',').forEach((location, index) =>
          locations.push(
            <div key={randomstring.generate()} className="mr-2">
              <div></div>
              <p className="h8a font-weight-normal">
                <span className=" h2 p-2 font-weight-bold text-danger">
                  &#8226;
                </span>
                {location}
              </p>
            </div>,
          ),
        );
      return locations;
    };
    const getIndustries = () => {
      let industries = [];
      investor.industries &&
        investor.industries.split(',').forEach((industry, index) =>
          industries.push(
            <div key={randomstring.generate()} className="mr-2">
              <p className="h8a font-weight-normal">
                <span className=" h2 p-2 font-weight-bold text-success">
                  &#8226;
                </span>
                {industry}
              </p>
            </div>,
          ),
        );
      return industries;
    };

    let recommendedBusinesses = [];
    if (businessListings && businessListings.length > 0) {
      for (let index = 0; index < businessListings.length; index++) {
        recommendedBusinesses.push(
          <div key={randomstring.generate()}>
            <BusinessFranchiseListingItem
              listing={businessListings[index]}
              whichListingType="business"
            />
            {businessListings[index + 1] && (
              <BusinessFranchiseListingItem
                listing={businessListings[index + 1]}
                whichListingType="business"
              />
            )}
          </div>,
        );
      }
    }

    return (
      <div>
        {this.state.loading ? (
          <div
            style={{ minHeight: '60vh' }}
            className="d-flex justify-content-center align-items-center"
          >
            <CircularProgress color="primary" size={'3rem'} />
          </div>
        ) : (
          investor && (
            <FadeIn>
              <div>
                {(investor.isOwner || currentUser.isAdmin) && (
                  <div className="w-100 rounded bg-white shadow-lg d-flex flex-column p-4 px-5 mb-3">
                    <p className="h8 text-details min-h-rem-lg-2">
                      Hi {investor.firstName && investor.firstName}{' '}
                      {investor.lastName && investor.lastName}, welcome. You can
                      edit your preference using the buttons below.
                    </p>
                    <div className="d-flex justify-content-between pt-3 flex-wrap">
                      <div
                        className={
                          investor.status === profileStatus.Approved
                            ? 'text-success mb-3'
                            : investor.status === profileStatus.Pending ||
                              investor.status === profileStatus.ChangesRequired
                            ? 'text-warning mb-3'
                            : investor.status === profileStatus.Draft ||
                              investor.status === profileStatus.Declined
                            ? 'text-danger mb-3'
                            : ' mb-3'
                        }
                      >
                        <span>
                          <i className="fas fa-circle"></i>
                        </span>
                        <span className="small mx-2">
                          {spacePascalWord(investor.status)}
                        </span>
                        {!investor.subscription && (
                          <>
                            <i className="fas fa-square text-muted mr-2"></i>
                            <span className="text-muted">No plan</span>
                          </>
                        )}
                        {investor.subscription && (
                          <>
                            <i className="fas fa-square text-success mr-2"></i>
                            <Link
                              to={`/user/subscriptions/${investor.subscription.subscriptionId}`}
                            >
                              {investor.subscription.planType &&
                                spacePascalWord(
                                  investor.subscription.planType,
                                  true,
                                ) + ' plan'}
                            </Link>
                          </>
                        )}
                      </div>
                      {!investor.subscription && (
                        <>
                          <div className="w-100"></div>

                          <div>
                            <Link
                              to={`/register-investor?id=${investor.id}`}
                              className="mx-3"
                            >
                              Select plan
                            </Link>
                          </div>
                        </>
                      )}
                      {investor.subscription && (
                        <>
                          <div className="w-100"></div>

                          <Link
                            to={`/user/investors/${investor.id}/upgrade`}
                            className="btn btn-outline-primary h8 font-weight-bold min-w-20"
                          >
                            Change or renew plan
                          </Link>
                        </>
                      )}

                      <Link
                        className="btn btn-outline-primary h8 font-weight-bold min-w-20"
                        to={`/register-investor?id=${investor.id}`}
                      >
                        {investor.status === profileStatus.Draft
                          ? 'Complete profile'
                          : 'Edit'}
                      </Link>
                      <button
                        className="btn btn-outline-primary h8 font-weight-bold min-w-20"
                        onClick={() => this.deleteInvestor(investor.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                )}
                <div className="h-100 d-flex flex-wrap flex-md-nowrap flex-xl-nowrap mb-5 h-rem-lg-details-7">
                  <div className="min-h-100 w-100 w-md-80 rounded d-flex flex-column bg-white shadow-lg p-4">
                    <div className="w-100">
                      <p className="h3 font-weight-bold">
                        {investor.whoYouAre && investor.whoYouAre}
                        {', '}
                        {investor.currentLocation && investor.currentLocation}
                      </p>
                    </div>
                    <div className="w-100 border-top mb-5 mt-3"></div>

                    <div className="d-flex flex-row w-100 my-2">
                      <p className="w-30 min-w-30 max-w-30 h7 font-weight-bold">
                        Professional Summary
                      </p>
                      <p className="pl-3 h8a font-weight-normal">
                        {investor.aboutYourself && investor.aboutYourself}
                      </p>
                    </div>
                    <div className="d-flex flex-row w-100 my-2">
                      <p className="w-30 min-w-30 max-w-30 h7 font-weight-bold">
                        Transaction Preference
                      </p>
                      <p className="pl-3 h8a font-weight-normal">
                        {investor.interests &&
                          investor.interests.split(',').join(', ')}
                      </p>
                    </div>
                    <div className="d-flex flex-row w-100 my-2">
                      <p className="w-30 min-w-30 max-w-30 h7 font-weight-bold">
                        Investment Size
                      </p>
                      <p className="pl-3 h8a font-weight-normal">
                        up to{' '}
                        {investor.investmentRangeTo && (
                          <NumberFormat
                            value={investor.investmentRangeTo}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'N'}
                          />
                        )}
                      </p>
                    </div>
                    <div className="d-flex flex-row w-100 my-2">
                      <p className="w-30 min-w-30 max-w-30 h7 font-weight-bold">
                        Investment Criteria
                      </p>
                      <p className="pl-3 h8a font-weight-normal">
                        {investor.factorsLookingFor}
                      </p>
                    </div>
                    <div className="d-flex flex-row w-100 my-2">
                      <p className="w-30 min-w-30 max-w-30 h7 font-weight-bold">
                        Status
                      </p>
                      <p className="pl-3 h8a font-weight-normal">Active</p>
                    </div>
                    <div className="d-flex flex-row w-100 my-2">
                      <p className="w-30 min-w-30 max-w-30 h7 font-weight-bold">
                        Sector Preference
                      </p>
                      <p className="pl-3 h8a font-weight-normal">
                        {investor.companySector}
                      </p>
                    </div>
                    <div className="d-flex flex-row w-100 my-2">
                      <p className="w-30 min-w-30 max-w-30 h7 font-weight-bold">
                        Location Preference
                      </p>
                      <p className="pl-3 h8a font-weight-normal">
                        {investor.currentLocation && investor.currentLocation}
                      </p>
                    </div>
                    <div className="h7 font-weight-bold mt-4">Preferences</div>
                    <div className="h6 font-weight-normal my-2">Industries</div>
                    <div className="d-flex flex-wrap h8a font-weight-normal">
                      {getIndustries()}
                    </div>
                    <div className="h6 font-weight-normal my-2">Locations</div>
                    <div className="d-flex flex-wrap h8a font-weight-normal">
                      {getLocations()}
                    </div>

                    {(investor.isOwner || currentUser.isAdmin) && (
                      <React.Fragment>
                        <div className="d-flex flex-row w-100 my-3">
                          <p className="w-30 min-w-30 max-w-30 h7 font-weight-bold">
                            Name
                          </p>
                          <p className="pl-3 h8a font-weight-normal">
                            {investor.firstName &&
                              `${investor.firstName} ${investor.lastName}`}
                          </p>
                        </div>
                        <div className="d-flex flex-row w-100 my-2">
                          <p className="w-30 min-w-30 max-w-30 h7 font-weight-bold">
                            Phone
                          </p>
                          <p className="pl-3 h8a font-weight-normal">
                            {investor.phoneNumber && investor.phoneNumber}
                          </p>
                        </div>
                        <div className="d-flex flex-row w-100 my-2">
                          <p className="w-30 min-w-30 max-w-30 h7 font-weight-bold">
                            Email
                          </p>
                          <p className="pl-3 h8a font-weight-normal">
                            {investor.email && investor.email}
                          </p>
                        </div>
                        <div className="d-flex flex-row w-100 my-2">
                          <p className="w-30 min-w-30 max-w-30 h7 font-weight-bold">
                            Company
                          </p>
                          <p className="pl-3 h8a font-weight-normal">
                            {investor.yourCompany && investor.yourCompany}
                          </p>
                        </div>
                        <div className="d-flex flex-wrap mt-3">
                          <p className="h7 font-weight-bold w-100 mb-3">
                            Documents&nbsp;
                          </p>

                          {investor.files && investor.files.length ? (
                            investor.files.map(document => (
                              <div
                                key={randomstring.generate()}
                                className="w-50"
                              >
                                <div className="my-2">
                                  {document.category
                                    ? document.category
                                    : camelPad(document.fileGroup)}
                                </div>
                                <div className="w-md-50 pr-2 d-flex mb-2">
                                  {document.type === 'application/pdf' ? (
                                    <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                                  ) : document.type ===
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                                    <i className="far fa-file-word fa-2x pr-2 text-info"></i>
                                  ) : document.type ===
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
                                    <i className="far fa-file-excel fa-2x pr-2 text-success"></i>
                                  ) : (
                                    <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                                  )}
                                  &nbsp;
                                  <a
                                    href={document.fileUrl}
                                    className="h7 font-weight-normal"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {document.name}
                                  </a>
                                </div>
                                <div className="d-block d-lg-none d-md-none w-100"></div>
                              </div>
                            ))
                          ) : (
                            <p className="text-danger">
                              No document was uploaded
                            </p>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                  <div className="d-none d-lg-block ml-3">
                    {!investor.isOwner && (
                      <div className="mb-4 d-none d-md-block d-lg-block">
                        <SendProposalBttn
                          sendTo="investor"
                          canSendProposal={investor.canSendProposal}
                          userId={this.props.match.params.id}
                          disable={investor.isOwner || investor.hasProposed}
                        ></SendProposalBttn>
                      </div>
                    )}

                    <div className="min-w-rem-lg-22 min-h-rem-lg-64 min-h-rem-md-64 w-100 d-md-flex flex-column d-none d-lg-block d-sm-block shadow bg-white rounded position-relative p-4">
                      <p className="h4a text-center font-weight-bold mb-4">
                        Recommended Businesses
                      </p>
                      <CarouselTemp
                        itemsPerSlide={1}
                        id={'recommendedBusinesses'}
                        listings={recommendedBusinesses}
                        ride={'false'}
                      ></CarouselTemp>
                      <div className="border-top mt-3 d-flex justify-content-around py-4 position-absolute position-left-0 position-bottom-0 w-100">
                        <div>
                          <i className="fas fa-bookmark"></i>&nbsp;Bookmark
                        </div>
                        <div className="border-right"></div>
                        <div>
                          <i className="fas fa-share"></i>&nbsp;Share
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!investor.isOwner && (
                  <div className="w-md-25 w-50 mb-3a">
                    <SendProposalBttn
                      sendTo="investor"
                      canSendProposal={investor.canSendProposal}
                      userId={this.props.match.params.id}
                      disable={investor.isOwner || investor.hasProposed}
                    ></SendProposalBttn>
                  </div>
                )}
              </div>
            </FadeIn>
          )
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    investor: state.investor.investorDetails,
    currentUser: state.user.currentUser,
    businessListings: state.business.businessListings,
  }),
  { fetchInvestorDetails, fetchBusinessListings },
)(InvestorListingItemPage);
