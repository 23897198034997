import React, { Component } from 'react';
import InvestorPlans from './InvestorPlans';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SubscriptionModal from './../../common/components/SubscriptionModal';
import subscriptionService from '../../common/services/subscriptionService';
import { fetchInvestorDetails } from '../../state/actions/investor';
import { spacePascalWord } from './../../common/utils/helpers';
import profileType from './../../common/utils/profileType';

class UpgradeInvestorPlan extends Component {
  MySwal = withReactContent(Swal);
  state = {
    id: '',
    subscriptionPlans: {},
  };

  async componentDidMount() {
    const { id } = this.props.match.params;
    const { investor } = this.props;

    if (!investor || !investor.id) this.props.fetchInvestorDetails(id);

    const subscriptionPlans = await subscriptionService.getSubscriptionPlans();

    this.setState({
      id,
      subscriptionPlans,
    });
  }

  planChange = plan => {
    const { id, isOwner } = this.props.investor;
    if (!isOwner) this.props.history.push(`/investors/${id}`);

    this.promptPayment(plan);
  };

  promptPayment = plan => {
    const plans = [...this.state.subscriptionPlans];
    const selectedPlan = plans.find(x => x.planType === plan);

    const { email, phoneNumber } = this.props.currentUser;
    const { id: investorId, email: workEmail } = this.props.investor;
    if (email && selectedPlan && investorId) {
      this.MySwal.fire({
        customClass: 'bg-transparent w-lg-30 w-md-30 w-90',
        showConfirmButton: false,
        html: (
          <SubscriptionModal
            planId={selectedPlan.planId}
            amount={selectedPlan.amount}
            userEmail={email}
            listingEmail={workEmail}
            userPhone={phoneNumber}
            listingId={investorId}
            listingType={profileType.Investor}
            redirectTo={`user/investors/${investorId}`}
            successMessage={`Subscription to ${spacePascalWord(
              plan,
            )} plan was successful`}
            history={this.props.history}
          ></SubscriptionModal>
        ),
      });
    }
  };

  render() {
    const { investor } = this.props;
    const currentPlan =
      investor && investor.subscription && investor.subscription.planType;

    return (
      <div className="scale-down-lg-7">
        <h3 className="text-center">Upgrade/change Investor plan</h3>
        <InvestorPlans
          currentPlan={currentPlan}
          planChange={this.planChange}
          isUpgrading={true}
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    investor: state.investor.investorDetails,
    currentUser: state.user.currentUser,
  }),
  { fetchInvestorDetails },
)(UpgradeInvestorPlan);
