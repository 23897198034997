import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Proposals from './UserProposals';
import ProposalDetails from './UserProposalDetail';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

const UserProposalContainer = ({
  proposalType,
  currentUser,
  readProposal,
  viewListing,
  hideProposalList,
  showProposalDetail,
  setSearchText,
  searchProposal,
  resetURL,
  loadItems,
  viewButtonLoading,
  viewLinkLoading,
}) => {
  let reduxState = useSelector(state => state);
  let proposals =
    proposalType === 'inbox'
      ? reduxState.user.userProposalsInbox
      : reduxState.user.userProposalSent;

  let loadingMore =
    proposalType === 'inbox'
      ? reduxState.user.loadingMoreInboxProposals
      : reduxState.user.loadingMoreSentProposals;

  let hasMore =
    proposalType === 'inbox'
      ? reduxState.user.hasMoreInboxProposals
      : reduxState.user.hasMoreSentProposals;

  let displayLoadMore = hasMore ? '' : 'd-none';

  return (
    <div
      className="email-wrapper wrapper tab-pane fade show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div className="row align-items-stretch border bg-white rounded shadow">
        <div className="mail-list-container col-md-3 pt-4 pb-4 rounded-right bg-white">
          <div className="inner-form border-bottom pb-4 px-3">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search Proposal"
                aria-label="Search Proposal"
                aria-describedby="basic-addon2"
                onChange={event => setSearchText(event.target.value)}
              />
              <div className="input-group-append">
                <button
                  className="btn-search"
                  type="button"
                  onClick={() => {
                    searchProposal(proposalType);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div
            style={{ height: 610, overflow: 'auto' }}
            className={hideProposalList}
          >
            <Proposals
              hideProposalList={hideProposalList}
              proposals={proposals}
              proposalType={proposalType}
              userId={currentUser.userId}
              readProposal={readProposal}
            />
            <div className="d-flex flex-row-reverse p-2">
              <button
                type="button"
                className={`btn btn-light btn-sm pull-right ${displayLoadMore}`}
                onClick={() => {
                  loadItems(proposalType);
                }}
              >
                {loadingMore ? (
                  <CircularProgress color="inherit" size={'1rem'} />
                ) : (
                  'Load More >'
                )}
              </button>
            </div>
          </div>
        </div>
        <div
          className={`${showProposalDetail} mail-view d-md-block col-md-9 border-left bg-white`}
        >
          <Route
            render={({ location }) => (
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  appear={true}
                  timeout={300}
                  classNames="slide"
                >
                  <Switch location={location}>
                    <Route
                      path={`/user/${proposalType}/proposals/:id`}
                      render={props => (
                        <ProposalDetails
                          {...props}
                          resetURL={resetURL}
                          proposalType={proposalType}
                          proposals={proposals}
                          viewListing={viewListing}
                          currentUser={currentUser}
                          viewButtonLoading={viewButtonLoading}
                          viewLinkLoading={viewLinkLoading}
                        />
                      )}
                    />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default UserProposalContainer;
