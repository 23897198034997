import React from 'react';
import { useHistory } from 'react-router-dom';

const DetailsFooter = () => {
  const history = useHistory();

  return (
    <div className="custom-home">
      <div className="bg-business">
        <div className="container h-100">
          <div className="h-100 d-flex flex-wrap justify-content-between align-items-center py-5">
            <p
              className="text-business-white font-weight-bold w-md-70 d-none d-lg-block d-xl-block"
              style={{ fontSize: '3.125rem' }}
            >
              Selling your business or looking for investments?
              <br />
              It's easy!
            </p>
            <p
              className="text-business-white font-weight-bold w-md-70 d-block d-lg-none d-xl-none"
              style={{ fontSize: '2.7rem' }}
            >
              Selling your business or looking for investments?
              <br />
              It's easy!
            </p>
            <button
              className="btn text-primary bg-white font-weight-bold h1e p-3 px-4 rounded-1"
              onClick={e => {
                e.preventDefault();
                history.push(`/register-business/plans`);
              }}
            >
              Start Here
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsFooter;
