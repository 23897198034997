import React from 'react';
import tempAvatar from '../../assets/img/user.svg';
import { connect } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import auth from '../services/authService';
import { searchListings } from '../../state/actions/common';
import SearchForm from './SearchForm';
import FilterForm from './FilterForm';
import NavDesktopLinks from './NavDesktopLinks';
import Notifications from './Notifications';
import ProposalNotification from './ProposalNotification';

const NavBarDesktop = props => {
  const { currentUser } = props;

  const history = useHistory();

  const onSearch = data => {
    const pathName = window.location.pathname;
    let listingType = '';
    if (pathName.startsWith('/businesses')) listingType = 'business';
    if (pathName.startsWith('/investors')) listingType = 'investor';
    if (pathName.startsWith('/advisors')) listingType = 'advisor';
    if (pathName.startsWith('/franchises')) listingType = 'franchise';

    props.searchListings(listingType, data);
  };

  const logout = () => {
    auth.logOutUser();
    history.replace('/');
  };

  const addFilter = () => {
    document.getElementById('nav-filter-item').classList.add('d-block');
  };

  const removeFilter = () => {
    document.getElementById('nav-filter-item').classList.remove('d-block');
  };

  return (
    <div className="navbar-plain d-none d-lg-block d-xl-block">
      <nav
        className="navbar navbar-expand-lg navbar-light bg-nav p-0 custom-nav"
        id="nav-logged-in"
      >
        <div className="collapse navbar-collapse flex-wrap">
          <div className="w-100">
            <ul className="navbar-nav justify-content-between w-100 py-4 mt-1 px-5 border-bottom border-border">
              <li className="nav-item p-0 my-auto">
                <NavLink to="/" className="nav-link">
                  <img
                    className="compex-logo"
                    width="150"
                    src={require('../../assets/img/compex-logo.svg')}
                    alt=""
                  />
                </NavLink>
              </li>
              <li className="nav-item my-auto w-10">
                <span className="flag-icon flag-icon-ng"></span>&nbsp;NGN
              </li>
              <div className="col-lg-6 my-auto">
                <SearchForm
                  onSearch={onSearch}
                  addFilter={addFilter}
                  removeFilter={removeFilter}
                />
              </div>
              {props.isLoggedIn ? (
                <>
                  <li className="nav-item p-0 pl-2 w-10 my-auto dropdown">
                    <ProposalNotification history={history} />
                  </li>
                  <li className="nav-item p-0 pl-2 w-10 my-auto dropdown">
                    <Notifications history={history} />
                  </li>
                  <li className={'nav-item p-0 pl-2 w-20 my-auto'}>
                    <NavLink to="/user/dashboard" className="btn btn-primary">
                      My Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown p-0 pl-2 w-15 my-auto text-center">
                    <div
                      className="cursor-pointer"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          currentUser.photoUrl
                            ? currentUser.photoUrl
                            : tempAvatar
                        }
                        alt="..."
                        style={{
                          minHeight: '3.2rem',
                          minWidth: '3.2rem',
                          maxHeight: '3.2rem',
                          maxWidth: '3.2rem',
                        }}
                        className="img-fluid rounded-circle p-1"
                      ></img>
                    </div>
                    <div
                      className="dropdown-menu min-w-rem-7"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link to="/user/dashboard" className="dropdown-item">
                        Dashboard
                      </Link>
                      <button
                        className=" dropdown-item cursor-pointer"
                        onClick={logout}
                      >
                        Sign out
                      </button>
                    </div>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item p-0 my-auto mx-2">
                    <NavLink to="/login" className="nav-link text-primary h7">
                      Sign in
                    </NavLink>
                  </li>
                  <li className="nav-item p-0 my-auto mx-2">
                    <NavLink to="/signup" className="nav-link text-primary h7">
                      Sign up
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
            <div className="w-100 d-none d-lg-block d-xl-block"></div>
            <div
              className="d-none w-100 px-5 border-bottom border-border position-relative"
              id="nav-filter-item"
            >
              <FilterForm removeFilter={removeFilter} onFilter={onSearch} />
            </div>
          </div>
          <NavDesktopLinks currentUser={currentUser}></NavDesktopLinks>
        </div>
      </nav>
    </div>
  );
};

export default connect(
  state => ({
    isLoggedIn: state.user.isLoggedIn,
    currentUser: state.user.currentUser,
  }),
  {
    searchListings,
  },
)(NavBarDesktop);
