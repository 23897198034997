import React from 'react';
import { useHistory } from 'react-router-dom';
import Truncate from 'react-truncate';
import { getAbbreviation } from '../utils/helpers';
import businessSectors from '../utils/businessSectors';

const BusinessFranchiseListingItem = props => {
  const history = useHistory();
  const { listing, whichListingType, hasContacted } = props;
  const imgUrl =
    listing.photos && listing.photos.length > 0
      ? listing.photos[0].fileUrl
      : listing.sector && businessSectors[listing.sector.replace(/[- ]/g, '')]
      ? businessSectors[listing.sector.replace(/[- ]/g, '')].defaultImage
      : businessSectors.default.defaultImage;
  const bgImgStyle = {
    background:
      'linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,.1)), url("' +
      imgUrl +
      '")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    height: '163px',
  };

  return (
    <div className="listing-item">
      {whichListingType === 'business' ? (
        <div className="card rounded-2 mb-5 mx-2 listing-item-shadow">
          <div
            style={bgImgStyle}
            className="rounded-t-2 pt-2 cursor-pointer"
            onClick={() => history.push(`/businesses/${listing.id}`)}
          ></div>
          <div className="card-body d-flex flex-column">
            <p className="card-title h7 text-primary font-weight-bold">
              <Truncate lines={2} ellipsis={<span>...</span>}>
                {listing.shortDescription}
              </Truncate>
            </p>
            <div className="d-flex justify-content-between">
              <div className="">
                <i className="fas fa-store-alt text-success"></i>&nbsp;
                {listing.listingType}
              </div>
              <div className="font-weight-bold">
                ₦
                {getAbbreviation(
                  listing.askingPrice ||
                    listing.capitalRaised ||
                    listing.partialSaleAskingPrice,
                )}
              </div>
              <div className="d-none">
                <i className="fas fa-star text-warning"></i>6
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2 mb-3">
              <div className="min-w-rem-6">
                EBITDA Margin{' '}
                <span className="font-weight-bold">{listing.ebitda} %</span>
              </div>
              <div className="text-right ml-1">
                <i className="fas fa-map-marker-alt text-primary"></i>&nbsp;
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {listing.registeredLocation}
                </Truncate>
              </div>
            </div>
            <div className="d-flex flex-row-reverse mt-auto">
              <button
                onClick={e => {
                  e.preventDefault();
                  history.push(`/businesses/${listing.id}`);
                }}
                className="btn btn-outline-primary font-weight-bold py-1 px-2"
              >
                {hasContacted ? 'View Business' : 'Contact Business'}
              </button>
            </div>
          </div>
        </div>
      ) : whichListingType === 'franchise' ? (
        <div className="card rounded-2 mb-5 mx-2 listing-item-shadow">
          <div
            style={bgImgStyle}
            className="rounded-t-2 pt-2 cursor-pointer"
            onClick={() => history.push(`/franchises/${listing.id}`)}
          ></div>

          <div className="card-body d-flex flex-column">
            <p className="card-title h7 text-primary font-weight-bold">
              <Truncate lines={2} ellipsis={<span>...</span>}>
                {listing.brandName}
              </Truncate>
            </p>
            <div className="d-flex justify-content-between">
              <div className="">
                <span>Est. </span>
                {listing.startYear}
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2 mb-2">
              <div className="min-w-rem-6">
                EBITDA Margin:{' '}
                <span className="font-weight-bold">{listing.ebitda} %</span>
              </div>
              <div className="text-right ml-1">
                <i className="fas fa-map-marker-alt text-primary"></i>&nbsp;
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {listing.registeredLocation}
                </Truncate>
              </div>
            </div>
            <div className="d-flex justify-content-between mb-3">
              <div className="min-w-rem-6">
                Exp. Monthly Sales: <br />
                <span className="font-weight-bold">
                  ₦{getAbbreviation(listing.averageMonthlySales)}
                </span>
              </div>
              <div className="text-right ml-1">
                Inv. Required: <br />
                <span className="font-weight-bold">
                  ₦{getAbbreviation(listing.investmentMin)}
                  {' - '}₦{getAbbreviation(listing.investmentMax)}
                </span>
              </div>
            </div>
            <div className="d-flex flex-row-reverse mt-auto">
              <button
                href=""
                onClick={e => {
                  e.preventDefault();
                  history.push(`/franchises/${listing.id}`);
                }}
                className="btn btn-outline-primary font-weight-bold py-1 px-2"
              >
                {hasContacted ? 'View Franchise' : 'Contact Franchise'}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BusinessFranchiseListingItem;
