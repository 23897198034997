import React, { Component } from 'react';
import Joi from 'joi-browser';
import equal from 'fast-deep-equal';
import queryString from 'query-string';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { editListing } from '../../state/actions/business';
import listing from '../../common/services/listingsService';
import NumberFormat from 'react-number-format';
import { CircularProgress } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  CountryInput,
  RegionInput,
} from '../../common/components/LocationInput';
import { scrollToRef } from '../../common/utils/helpers';
import businessSectors from '../../common/utils/businessSectors';
import BusinessPlans from './BusinessPlans';
import SubscriptionModal from '../../common/components/SubscriptionModal';
import subscriptionService from '../../common/services/subscriptionService';
import profileStatus from './../../common/utils/profileStatus';
import profileType from './../../common/utils/profileType';
import planTypes from '../../common/utils/planTypes';
import investmentTypes from '../../common/utils/investmentTypes';

class Covid19Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formdata: {
        firstName: '',
        lastName: '',
        workEmail: '',
        phoneNumber: '',
        companyName: '',
        businessName: '',
        businessWebsite: '',
        whoIAm: '',
        businessLegalType: '',
        numberOfStaff: '',
        registeredLocation: '',
        beginOperationsMonth: '',
        beginOperationsYear: '',
        incorporationNumber: '',
        incomeSource: '',
        sector: '',
        capitalRaised: '',
        listingType: investmentTypes.interventionFund,
        shortDescription: '',
        country: '',
        region: '',
        acceptAgreements: '',
      },
      id: 0,
      activeStep: 0,
      errors: {},
      country: '',
      region: '',
      previousData: {},
      formError: false,
      selectedPlan: '',
      hasSubscribed: false,
      subscriptionPlans: [],
      isValidCode: '',
      isSubmitting: false,
      isValidating: false,
      isSaving: false,
      reApprovalWarning: {},
      reApprovalRequiredFields: [
        'incorporationNumber',
        'incomeSource',
        'shortDescription',
      ],
    };
  }

  async componentDidMount() {
    const { id } = queryString.parse(this.props.location.search);

    // editing
    if (id) {
      this.props.editListing(id);
    }

    this.autofillFormFields();
    const subscriptionPlans = await subscriptionService.getSubscriptionPlans();
    this.setState({ subscriptionPlans });
  }

  componentDidUpdate(prevProps) {
    if (!equal(this.props.currentUser, prevProps.currentUser)) {
      this.autofillFormFields();
    }

    if (!equal(this.props.formdata, prevProps.formdata)) {
      this.autofillFormFields();
    }
  }

  autofillFormFields() {
    const { formdata, currentUser } = this.props;
    if (formdata && formdata.id) {
      const date = new Date(formdata.beginOperationDate);
      const form = { ...this.state.formdata };
      form.firstName = formdata.firstName;
      form.lastName = formdata.lastName;
      form.workEmail = formdata.workEmail;
      form.phoneNumber = formdata.phoneNumber || '';
      form.companyName = formdata.companyName;
      form.businessName = formdata.businessName;
      form.businessWebsite = formdata.businessWebsite;
      form.whoIAm = formdata.whoIAm;
      form.registeredLocation = formdata.registeredLocation;
      form.beginOperationsMonth = date && date.getMonth() + 1;
      form.beginOperationsYear = date && date.getFullYear();
      form.incorporationNumber = formdata.incorporationNumber;
      form.incomeSource = formdata.incomeSource;
      form.sector = formdata.sector;
      form.listingType = formdata.listingType;
      form.askingPrice = formdata.askingPrice || '';
      form.shortDescription = formdata.shortDescription;

      const country = form.registeredLocation.split(', ')[1];
      const region = form.registeredLocation.split(', ')[0];
      form.country = country;
      form.region = region;

      this.setState({
        formdata: form,
        id: formdata.id,
        previousData: formdata,
        activeStep: 3,
        country: country,
        region: region,
        //  selectedPlan: this.props.selectedPlan,
      });
    } else if (currentUser.userId) {
      const form = { ...this.state.formdata };
      form.firstName = currentUser.firstname;
      form.lastName = currentUser.surname;
      form.workEmail = currentUser.email;
      form.phoneNumber = currentUser.phoneNumber || '';

      this.setState({ formdata: form, selectedPlan: this.props.selectedPlan });
    }
  }

  schema = {
    listingType: Joi.string(),
    firstName: Joi.string()
      .required()
      .label('Field'),
    lastName: Joi.string()
      .required()
      .label('Field'),
    workEmail: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .label('Field'),
    phoneNumber: Joi.string()
      .min(3)
      .required()
      .label('Field'),
    companyName: Joi.string()
      .required()
      .label('Field'),
    businessName: Joi.string()
      .required()
      .label('Business Name'),
    businessWebsite: Joi.optional(),
    whoIAm: Joi.string()
      .required()
      .label('Field'),
    businessLegalType: Joi.string()
      .required()
      .label('Field'),
    registeredLocation: Joi.string()
      .required()
      .label('Registered Location'),
    beginOperationsMonth: Joi.number()
      .integer()
      .min(1)
      .max(12)
      .required()
      .label('Month'),
    beginOperationsYear: Joi.number()
      .integer()
      .min(1900)
      .max(new Date().getFullYear())
      .required()
      .label('Year'),
    incorporationNumber: Joi.string()
      .required()
      .label('Incorporation Number'),
    incomeSource: Joi.optional(),
    sector: Joi.string()
      .required()
      .label('Sector'),
    numberOfStaff: Joi.string()
      .required()
      .label('Field'),
    shortDescription: Joi.string()
      .min(3)
      .max(100)
      .required()
      .label('Description'),
    country: Joi.string()
      .required()
      .label('Country'),
    region: Joi.string()
      .required()
      .label('Region'),
    capitalRaised: Joi.number()
      .integer()
      .required()
      .label('Loan Amount'),
    acceptAgreements: Joi.string()
      .required()
      .valid('true')
      .label('Agreement'),
  };

  validate() {
    const { error } = Joi.validate(this.state.formdata, this.schema, {
      abortEarly: false,
    });
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  onChange = (e, isFormatted = false) => {
    const { name, value } = e.target;
    const formdata = { ...this.state.formdata };

    formdata[name] = isFormatted
      ? parseInt(value.replace(/,/g, ''), 10)
      : value;
    const errors = { ...this.state.errors };
    errors[name] = '';
    this.setState({ formdata, errors });
  };

  onFocusTextFields = e => {
    const { previousData: business, reApprovalRequiredFields } = this.state;
    if (business.isApproved) {
      const { name } = e.target;
      if (reApprovalRequiredFields.some(x => x === name)) {
        const { reApprovalWarning: oldData } = this.state;
        const reApprovalWarning = { ...oldData };
        reApprovalWarning[name] =
          'Editing this field will require Admin reapproval';
        this.setState({ reApprovalWarning });
      }
    }
  };

  onBlurTextFields = e => {
    const { previousData: business, reApprovalRequiredFields } = this.state;
    if (business.isApproved) {
      const { name, value } = e.target;
      if (reApprovalRequiredFields.some(x => x === name)) {
        if (value === business[name]) {
          const { reApprovalWarning: oldData } = this.state;
          const reApprovalWarning = { ...oldData };
          reApprovalWarning[name] = '';
          this.setState({ reApprovalWarning });
        }
      }
    }
  };

  onPhoneChange = phone => {
    const formdata = { ...this.state.formdata };
    formdata['phoneNumber'] = phone;
    const errors = { ...this.state.errors };
    errors['phoneNumber'] = '';
    this.setState({ formdata, errors });
  };

  selectRegion(val) {
    const formdata = { ...this.state.formdata };
    formdata['registeredLocation'] = val + ', ' + this.state.country;
    formdata.region = val;
    this.setState({ formdata, region: val });
  }

  selectCountry(val, clearRegion = false) {
    const formdata = this.state.formdata;
    formdata.country = val;
    this.setState({ formdata, country: val });
    if (clearRegion) {
      formdata['registeredLocation'] = '';
      this.setState({ region: '', country: '', formdata });
    }
  }

  onAgreementChange = e => {
    const { name } = e.target;
    const formdata = { ...this.state.formdata };
    formdata[name] = e.target.checked ? 'true' : 'false';
    const errors = { ...this.state.errors };
    errors[name] = '';
    this.setState({ formdata, errors });
  };

  onSelectPlan = plan => {
    if (
      this.state.previousData.status &&
      this.state.previousData.status !== profileStatus.Draft
    ) {
      this.upgradePlan(plan);
    } else {
      this.setState({ selectedPlan: plan });
    }
  };

  upgradePlan = plan => {
    const { id, formdata } = this.state;
    this.promptPayment(plan, id, formdata.workEmail, true);
  };

  MySwal = withReactContent(Swal);

  promptPayment = (plan, businessId, workEmail, isUpgrading = false) => {
    const plans = [...this.state.subscriptionPlans];
    const selectedPlan = plans.find(x => x.planType === plan);

    const { email, phoneNumber } = this.props.currentUser;
    const successMessage = isUpgrading
      ? 'Subscription was successful and your changes was saved'
      : email !== workEmail
      ? `A verification link has been sent to ${workEmail}. You must confirm your email before your listing will be approved.`
      : 'Your request has been sent for approval';

    if (email && selectedPlan && businessId) {
      this.MySwal.fire({
        customClass: 'bg-transparent w-lg-30 w-md-30 w-90',
        showConfirmButton: false,
        html: (
          <SubscriptionModal
            planId={selectedPlan.planId}
            amount={selectedPlan.amount}
            userEmail={email}
            userPhone={phoneNumber}
            listingId={businessId}
            listingType={profileType.Business}
            redirectTo="listingsuccessful"
            successMessage={successMessage}
            history={this.props.history}
          ></SubscriptionModal>
        ),
      });
    }
  };

  onSubmit = async e => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) {
      console.log(errors);
      const firstError = this[Object.keys(errors)[0]];
      scrollToRef(firstError);
      firstError.focus({ preventScroll: true });
      return;
    }

    if (!this.state.selectedPlan) {
      Swal.fire(
        'Error',
        'You must select a plan or enter a valid subscription code before submitting',
        'error',
      );
      return;
    }

    const { beginOperationsMonth, beginOperationsYear } = this.state.formdata;
    const { previousData, hasSubscribed, selectedPlan } = this.state;

    // post data to server
    const formdata = { ...this.state.formdata };
    formdata.id = this.state.id;

    if (beginOperationsMonth && beginOperationsYear) {
      formdata['beginOperationDate'] = new Date(
        beginOperationsYear,
        beginOperationsMonth - 1,
        1,
      );
    }
    delete formdata.beginOperationsMonth;
    delete formdata.beginOperationsYear;

    // set values to post
    previousData.id = this.state.id;
    previousData.firstName = formdata.firstName;
    previousData.lastName = formdata.lastName;
    previousData.workEmail = formdata.workEmail;
    previousData.phoneNumber = formdata.phoneNumber;
    previousData.companyName = formdata.companyName;
    previousData.businessName = formdata.businessName;
    previousData.businessWebsite = formdata.businessWebsite;
    previousData.whoIAm = formdata.whoIAm;
    previousData.businessLegalType = formdata.businessLegalType;
    previousData.registeredLocation = formdata.registeredLocation;
    previousData.beginOperationDate = formdata.beginOperationDate.toISOString();
    previousData.incorporationNumber = formdata.incorporationNumber;
    previousData.incomeSource = formdata.incomeSource;
    previousData.sector = formdata.sector;
    previousData.capitalRaised = formdata.capitalRaised || 0;
    previousData.listingType = formdata.listingType;
    previousData.askingPrice = formdata.askingPrice || 0;
    previousData.equity = formdata.equity || 0;
    previousData.partialSaleAskingPrice = formdata.partialSaleAskingPrice || 0;
    previousData.shortDescription = formdata.shortDescription;

    previousData.acceptAgreements = true;
    this['acceptAgreements'].checked = true;
    previousData.subscriptionPlan = selectedPlan;

    console.log(previousData);
    try {
      this.setState({ isSaving: true });
      const res = await listing.register(previousData);

      if (res && hasSubscribed) {
        this.setState({ isSaving: false });
        Swal.fire(
          'Changes submitted successfully',
          'Your changes has been submitted successfully',
          'success',
        ).then(() => {
          this.props.history.replace(`/user/businesses/${res.id}`);
        });
      } else if (
        res &&
        (selectedPlan === planTypes.activeBusiness ||
          selectedPlan === planTypes.premiumBusiness ||
          selectedPlan === planTypes.professionalBusiness)
      ) {
        this.setState({ isSubmitting: false });
        // redirect to payment platform
        this.promptPayment(selectedPlan, res.id, previousData.workEmail);
      } else if (res && selectedPlan === planTypes.basicBusiness) {
        this.setState({ isSubmitting: false });
        const message =
          this.props.currentUser.email !== previousData.workEmail
            ? `A verification link has been sent to ${previousData.workEmail}. You must confirm your email before your listing will be approved.`
            : 'Your request has been sent for approval';

        Swal.fire(
          'Business listing registered successfully',
          message,
          'success',
        ).then(() => {
          this.props.history.replace(`/user/businesses/${res.id}`);
        });
      } else {
        this.setState({ isSaving: false });
        Swal.fire('An error occured', '', 'error');
      }
    } catch (error) {
      this.setState({ isSaving: false });
    }
  };

  render() {
    // console.log(this.state.selectedPlan);
    var sectors = [];
    for (let [key, obj] of Object.entries(businessSectors)) {
      sectors.push(
        <option key={key} value={obj.value === 'Sector' ? '' : obj.value}>
          {obj.value}
        </option>,
      );
    }
    return (
      <div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="d-flex justify-content-center flex-column text-center my-3">
              <h3 className="text-primary font-weight-bold">
                COVID-19 Intervention Fund
              </h3>
              <p className="text-muted">
                Kindly fill out the following information as accurate as
                possible
              </p>
              <p className="text-muted">
                All fields are required except stated otherwise
              </p>
            </div>
            <form onSubmit={this.onSubmit} method="POST" noValidate>
              <div className=" bg-white shadow rounded-2 py-5 px-3">
                <div className="fieldset border-top border-bottom">
                  <div className="form-row py-2">
                    <div className="col-12 px-md-4 mb-3 pb-2 border-bottom">
                      <h5>Personal Information</h5>
                      <div className="text-sm text-muted">
                        Information marked{' '}
                        <sup className="font-italic text-danger">*private</sup>{' '}
                        will not be displayed publicly
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="firstName">
                        First Name{' '}
                        <sup className="font-italic text-danger text-small">
                          *private
                        </sup>
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        onChange={this.onChange}
                        value={this.state.formdata.firstName || ''}
                        className={
                          this.state.errors['firstName']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={input => {
                          this.firstName = input;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['firstName']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="lastName">
                        Last Name{' '}
                        <sup className="font-italic text-danger text-small">
                          *private
                        </sup>
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        onChange={this.onChange}
                        value={this.state.formdata.lastName || ''}
                        className={
                          this.state.errors['lastName']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={input => {
                          this.lastName = input;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['lastName']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="workEmail">
                        Work Email{' '}
                        <sup className="font-italic text-danger text-small">
                          *private
                        </sup>{' '}
                        <span className="text-sm text-muted pl-2">
                          (Improve rating)
                        </span>
                      </label>
                      <input
                        type="email"
                        id="workEmail"
                        name="workEmail"
                        onChange={this.onChange}
                        value={this.state.formdata.workEmail || ''}
                        className={
                          this.state.errors['workEmail']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={input => {
                          this.workEmail = input;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['workEmail']}
                      </div>
                    </div>

                    <div
                      className="form-group col-md-6 px-md-4 mb-3"
                      ref={input => {
                        this.phoneNumber = input;
                      }}
                    >
                      <label htmlFor="phoneNumber">
                        Phone Number{' '}
                        <sup className="font-italic text-danger text-small">
                          *private
                        </sup>
                        <span className="text-sm text-muted pl-2">
                          (For faster verification)
                        </span>
                      </label>
                      <PhoneInput
                        country={'ng'}
                        value={this.state.formdata.phoneNumber}
                        onChange={this.onPhoneChange}
                        inputClass={
                          this.state.errors['phoneNumber']
                            ? 'form-control w-100 is-invalid'
                            : 'form-control w-100'
                        }
                      />
                      <div
                        className={
                          this.state.errors['phoneNumber'] ? 'is-invalid' : ''
                        }
                      ></div>
                      <div className="invalid-feedback">
                        {this.state.errors['phoneNumber']}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4 mb-3">
                      <label htmlFor="companyName">
                        Company Name{' '}
                        <sup className="font-italic text-danger text-small">
                          *private
                        </sup>
                      </label>
                      <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        onChange={this.onChange}
                        value={this.state.formdata.companyName}
                        className={
                          this.state.errors['companyName']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        ref={input => {
                          this.companyName = input;
                        }}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors['companyName']}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 px-md-4 mb-3 mt-3 pt-2 pb-2 border-bottom">
                  <h5>Business Information</h5>
                  <div className="text-sm text-muted">
                    Information entered here is displayed for public view to
                    match you. All{' '}
                    <span className="font-italic text-danger text-small">
                      * fields
                    </span>{' '}
                    are required.
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6 px-md-4 mb-5">
                    <label htmlFor="businessName">
                      Business Name{' '}
                      <span className="text-danger text-small">*</span>
                    </label>
                    <input
                      type="text"
                      id="businessName"
                      name="businessName"
                      onChange={this.onChange}
                      value={this.state.formdata.businessName}
                      className={
                        this.state.errors['businessName']
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      ref={input => {
                        this.businessName = input;
                      }}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors['businessName']}
                    </div>
                  </div>

                  <div className="form-group col-md-6 px-md-4 mb-5">
                    <label htmlFor="businessWebsite">
                      Business Website{' '}
                      <span className="text-sm-1 text-muted">(optional)</span>
                    </label>
                    <input
                      type="text"
                      id="businessWebsite"
                      name="businessWebsite"
                      onChange={this.onChange}
                      value={this.state.formdata.businessWebsite}
                      className="form-control"
                      ref={input => {
                        this.businessWebsite = input;
                      }}
                    />
                  </div>

                  <div className="form-group col-md-6 px-md-4 mb-5">
                    <label htmlFor="whoIAm">
                      You are a(n){' '}
                      <span className="text-danger text-small">*</span>
                    </label>
                    <select
                      id="whoIAm"
                      onChange={this.onChange}
                      value={this.state.formdata.whoIAm}
                      name="whoIAm"
                      className={
                        this.state.errors['whoIAm']
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      ref={input => {
                        this.whoIAm = input;
                      }}
                    >
                      <option value="">select</option>
                      <option value="business owner/director">
                        Business Owner/Director
                      </option>
                      <option value="ceo">CEO</option>
                      <option value="business analyst">Business Analyst</option>
                      <option value="lawyer">Lawyer</option>
                      <option value="broker">Broker</option>
                      <option value="founder">Founder</option>
                      <option value="co-founder">Co-Founder</option>
                    </select>
                    <div className="invalid-feedback">
                      {this.state.errors['whoIAm']}
                    </div>
                  </div>

                  <div className="form-group col-md-6 px-md-4 mb-5">
                    <label htmlFor="businessLegalType">
                      Select your business legal entity type{' '}
                      <span className="text-danger text-small">*</span>
                    </label>
                    <select
                      id="businessLegalType"
                      onChange={this.onChange}
                      value={this.state.formdata.businessLegalType}
                      name="businessLegalType"
                      className={
                        this.state.errors['businessLegalType']
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      ref={input => {
                        this.businessLegalType = input;
                      }}
                    >
                      <option value="">select</option>
                      <option value="Limited Liability Company (LLC)">
                        Limited Liability Company (LLC)
                      </option>
                      <option value="Public Limited Company (PLC)">
                        Public Limited Company (PLC)
                      </option>
                      <option value="Sole Proprietorship">
                        Sole Proprietorship
                      </option>
                      <option value="Business Name">Business Name</option>
                      <option value="Company Limited by Guarantee">
                        Company Limited by Guarantee
                      </option>
                    </select>
                    <div className="invalid-feedback">
                      {this.state.errors['businessLegalType']}
                    </div>
                  </div>

                  <div className="form-group col-md-6 px-md-4 mb-5">
                    <label htmlFor="incorporationNumber">
                      Incorporation number{' '}
                      <span className="text-danger text-small">*</span>
                      {this.state.reApprovalWarning['incorporationNumber'] && (
                        <div className="text-danger small">
                          <em>
                            Note:{' '}
                            {
                              this.state.reApprovalWarning[
                                'incorporationNumber'
                              ]
                            }
                          </em>
                        </div>
                      )}
                    </label>
                    <input
                      type="incorporationNumber"
                      id="incorporationNumber"
                      name="incorporationNumber"
                      onChange={this.onChange}
                      onFocus={this.onFocusTextFields}
                      onBlur={this.onBlurTextFields}
                      value={this.state.formdata.incorporationNumber}
                      className={
                        this.state.errors['incorporationNumber']
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      ref={elem => {
                        this.incorporationNumber = elem;
                      }}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors['incorporationNumber']}
                    </div>
                  </div>

                  <div className="form-group col-md-6 px-md-4 mb-5">
                    <label htmlFor="sector">
                      Sector <span className="text-danger text-small">*</span>
                    </label>
                    <select
                      id="sector"
                      onChange={this.onChange}
                      value={this.state.formdata.sector}
                      name="sector"
                      className={
                        this.state.errors['sector']
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      ref={elem => {
                        this.sector = elem;
                      }}
                    >
                      {sectors}
                    </select>
                    <div className="invalid-feedback">
                      {this.state.errors['sector']}
                    </div>
                  </div>

                  <div
                    className="form-group col-md-6 px-md-4 mb-5"
                    ref={elem => {
                      this.registeredLocation = elem;
                    }}
                  >
                    <label htmlFor="registeredLocation">
                      Where is your business registered{' '}
                      <span className="text-danger text-small">*</span>
                    </label>
                    <div
                      className={
                        this.state.errors['registeredLocation'] ||
                        this.state.errors.country ||
                        this.state.errors.region
                          ? 'row is-invalid'
                          : 'row'
                      }
                    >
                      <div
                        className="col-sm-6"
                        ref={elem => {
                          this.country = elem;
                        }}
                      >
                        <CountryInput
                          onChange={this.selectCountry.bind(this)}
                          className={
                            this.state.errors['country']
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                          value={this.state.country}
                          id="registeredCountry"
                        />
                      </div>
                      <div
                        className="col-sm-6"
                        ref={elem => {
                          this.region = elem;
                        }}
                      >
                        <RegionInput
                          country={this.state.country}
                          onChange={this.selectRegion.bind(this)}
                          id="registeredRegion"
                          className={
                            this.state.errors['region']
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                          value={this.state.region}
                        />
                      </div>
                    </div>
                    <div className="invalid-feedback">
                      {this.state.errors['registeredLocation'] ||
                        this.state.errors.country ||
                        this.state.errors.region}
                    </div>
                  </div>

                  <div className="form-group col-md-6 px-md-4">
                    <label htmlFor="numberOfStaff">
                      How many employees do you have?{' '}
                      <span className="text-danger text-small">*</span>
                    </label>
                    <select
                      type="text"
                      id="numberOfStaff"
                      name="numberOfStaff"
                      onChange={this.onChange}
                      value={this.state.formdata.numberOfStaff}
                      className={
                        this.state.errors['numberOfStaff']
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      ref={elem => {
                        this.numberOfStaff = elem;
                      }}
                    >
                      <option value="">Select</option>
                      <option value="1">1</option>
                      <option value="2-10">2-10</option>
                      <option value="11-50">11-50</option>
                      <option value="51-200">51-200</option>
                      <option value="201-500">201-500</option>
                      <option value="501-1000">501-1000</option>
                      <option value="1001-5000">1001-5000</option>
                      <option value="5001-10,000">5001-10,000</option>
                      <option value="10,000+">10,000+</option>
                    </select>
                    <div className="invalid-feedback">
                      {this.state.errors['numberOfStaff']}
                    </div>
                  </div>

                  <div className="form-group col-md-6 px-md-4 mb-5">
                    <label htmlFor="beginOperationsMonth">
                      When did the business begin operations?{' '}
                      <span className="text-danger text-small">*</span>
                    </label>
                    <div className="row">
                      <div className="col-sm-6">
                        <select
                          id="beginOperationsMonth"
                          onChange={this.onChange}
                          value={this.state.formdata.beginOperationsMonth}
                          name="beginOperationsMonth"
                          className={
                            this.state.errors['beginOperationsMonth']
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                          ref={elem => {
                            this.beginOperationsMonth = elem;
                          }}
                        >
                          <option>Month</option>
                          <option value="01">January</option>
                          <option value="02">February</option>
                          <option value="03">March</option>
                          <option value="04">April</option>
                          <option value="05">May</option>
                          <option value="06">June</option>
                          <option value="07">July</option>
                          <option value="08">August</option>
                          <option value="09">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </select>
                        <div className="invalid-feedback">
                          {this.state.errors['beginOperationsMonth']}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="number"
                          placeholder="Year"
                          id="beginOperationsYear"
                          onChange={this.onChange}
                          value={this.state.formdata.beginOperationsYear}
                          name="beginOperationsYear"
                          className={
                            this.state.errors['beginOperationsYear']
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                          ref={elem => {
                            this.beginOperationsYear = elem;
                          }}
                        />
                        <div className="invalid-feedback">
                          {this.state.errors['beginOperationsYear']}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-6 px-md-4 mb-5">
                    <label htmlFor="incomeSource">
                      What is your main source of income?
                      <span className="text-sm-1 text-muted"> (optional)</span>
                      {this.state.reApprovalWarning['incomeSource'] && (
                        <div className="text-danger small">
                          <em>
                            Note: {this.state.reApprovalWarning['incomeSource']}
                          </em>
                        </div>
                      )}
                    </label>
                    <input
                      type="text"
                      id="incomeSource"
                      onChange={this.onChange}
                      onFocus={this.onFocusTextFields}
                      onBlur={this.onBlurTextFields}
                      value={this.state.formdata.incomeSource}
                      name="incomeSource"
                      className={
                        this.state.errors['incomeSource']
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      ref={elem => {
                        this.incomeSource = elem;
                      }}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors['incomeSource']}
                    </div>
                  </div>
                  <div className="col-md-12"></div>

                  <div className="col-md-6"></div>

                  <div
                    className="form-group col-md-8 px-md-4 mb-5 "
                    id="capitalRaisedDiv"
                    ref={div => {
                      this.capitalRaisedDiv = div;
                    }}
                  >
                    <label htmlFor="capitalRaised">Intervention Amount</label>
                    <div
                      className="input-group mb-2"
                      ref={div => {
                        this.capitalRaised = div;
                      }}
                    >
                      <div className="input-group-prepend">
                        <div className="input-group-text">₦</div>
                      </div>

                      <NumberFormat
                        thousandSeparator={true}
                        type="text"
                        id="capitalRaised"
                        name="capitalRaised"
                        onChange={e => this.onChange(e, true)}
                        value={this.state.formdata.capitalRaised}
                        className={
                          this.state.errors['capitalRaised']
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />

                      <div className="invalid-feedback">
                        {this.state.errors['capitalRaised']}
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-12 px-md-4 mb-5">
                    <label htmlFor="shortDescription">
                      One-line short description{' '}
                      <span className="text-danger text-small">*</span>
                      <span className="text-sm-1 text-muted pl-1">
                        (max 100 characters)
                      </span>
                      <p className="text-sm-1 text-muted mb-0">
                        <em>
                          Example: Highly Profitable Leading International Hotel{' '}
                        </em>
                        <br />
                      </p>
                      {this.state.reApprovalWarning['shortDescription'] && (
                        <div className="text-danger small">
                          <em>
                            Note:{' '}
                            {this.state.reApprovalWarning['shortDescription']}
                          </em>
                        </div>
                      )}
                    </label>
                    <textarea
                      type="text"
                      id="shortDescription"
                      name="shortDescription"
                      onChange={this.onChange}
                      onFocus={this.onFocusTextFields}
                      onBlur={this.onBlurTextFields}
                      value={this.state.formdata.shortDescription}
                      rows="1"
                      maxLength="100"
                      className={
                        this.state.errors['shortDescription']
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      ref={div => {
                        this.shortDescription = div;
                      }}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors['shortDescription']}
                    </div>
                    <em className="text-sm-1 text-danger">
                      Note: Do not include buisness name or contact information
                      here.
                    </em>
                  </div>
                </div>

                <div className="fieldset mt-5 border-top-2 border-bottom-2">
                  <div className="py-4">
                    <h4 className="text-center text-muted font-weight-bold">
                      Choose a plan
                    </h4>
                  </div>
                  <BusinessPlans
                    covidIntervention={true}
                    currentPlan={this.state.selectedPlan}
                    planChange={this.onSelectPlan}
                    isDraft={false}
                    isUpgrading={false}
                    {...this.props}
                  />
                </div>

                <div className="form-group col-md-12 my-3">
                  <div className="row">
                    <div className="col-1">
                      <div className="text-right">
                        <input
                          type="checkbox"
                          name="acceptAgreements"
                          onChange={this.onAgreementChange}
                          value="true"
                          ref={input => {
                            this.acceptAgreements = input;
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-11">
                      <p>
                        I agree to the terms and conditions of this platform,
                        including its privacy and refund policies. <br />
                        <a
                          className="text-primary font-weight-bold"
                          href="/terms"
                          target="_blank"
                        >
                          View terms and conditions.&nbsp;
                        </a>
                        <a
                          className="text-primary font-weight-bold"
                          href="/privacy"
                          target="_blank"
                        >
                          View privacy policy.&nbsp;
                        </a>
                        <a
                          className="text-primary font-weight-bold"
                          href="/refunds"
                          target="_blank"
                        >
                          View refunds policy.
                        </a>
                      </p>
                      {this.state.errors['acceptAgreements'] && (
                        <small className="text-danger">
                          You must accept agreement before submitting
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center w-100 py-5">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg w-lg-30 w-sm-60 w-xs-60"
                >
                  {this.state.isSaving ? (
                    <CircularProgress color="inherit" size={'1rem'} />
                  ) : (
                    'Save and continue'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    selectedPlan: state.business.selectedPlan,
    formdata: state.business.formdata,
    currentUser: state.user.currentUser,
  }),
  { editListing },
)(Covid19Form);
