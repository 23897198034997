import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import tempAvatar from '../../assets/img/user.svg';
import { Switch } from '@material-ui/core';
import Truncate from 'react-truncate';
import { getAbbreviation, spacePascalWord } from '../../common/utils/helpers';
import advisorService from '../../common/services/advisorService';
import investorsService from '../../common/services/investorsService';
import Swal from 'sweetalert2';
import profileStatus from './../../common/utils/profileStatus';

class UserAdvisorInvestorItem extends Component {
  state = {};

  onSwitch = e => {
    let { availability } = { ...this.state };
    availability = e.target.checked;
    this.setState({ availability });
  };

  deleteAdvisor = async id => {
    Swal.fire(
      'Are you sure?',
      'Do you want to delete this advisor profile?',
      'warning',
    ).then(async ok => {
      if (ok.value) {
        const res = await advisorService.deleteAdvisor(id);
        if (res) {
          Swal.fire('Profile deleted Successfully', '', 'success').then(() => {
            this.props.refreshListings();
          });
        } else {
          Swal.fire('Unable to delete profile', '', 'error');
        }
      }
    });
  };

  deleteInvestor = async id => {
    Swal.fire(
      'Are you sure?',
      'Do you want to delete this investor profile?',
      'warning',
    ).then(async ok => {
      if (!ok.value) return;
      const res = await investorsService.deleteInvestor(id);
      if (res) {
        Swal.fire('Profile deleted Successfully', '', 'success').then(() => {
          this.props.refreshListings();
        });
      } else {
        Swal.fire('Unable to delete profile', '', 'error');
      }
    });
  };

  render() {
    const { listing, whichListing } = this.props;

    return (
      <div className="investor-listing-item">
        <div
          className={`${this.props.className}
                d-flex flex-column 
                rounded-2 shadow bg-white
                py-3 px-4 mb-5 mx-2`}
        >
          <div className="d-flex flex-row align-items-start justify-content-between">
            <div className="w-15" href="#">
              <img
                src={tempAvatar}
                alt="..."
                className=" img-fluid rounded-circle p-1"
                style={{
                  minHeight: '4rem',
                  minWidth: '4rem',
                  maxHeight: '4rem',
                  maxWidth: '4rem',
                }}
              ></img>
            </div>
            <div
              className="rounded-circle bg-success"
              style={{ width: '8px', height: '8px' }}
            ></div>
            <div className="w-70 line-height-2 mb-3">
              <p className="h8 d-inline">{listing.whoYouAre}</p>
              <br></br>
              <p className="h10 d-inline">
                {listing.whoYouAre && listing.whoYouAre} {' in '}{' '}
                {listing.currentLocation && listing.currentLocation}
              </p>
            </div>
            <div className="dropdown">
              <button
                className="button-as-link d-block text-primary"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="h4 font-weight-bold fas fa-ellipsis-v"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <Link
                  to={`/register-${whichListing}?id=${listing.id}`}
                  className="dropdown-item"
                  href=""
                >
                  Edit
                </Link>
                {listing &&
                  (whichListing === 'advisor' ? (
                    <button
                      className="dropdown-item h7"
                      onClick={() => this.deleteAdvisor(listing.id)}
                    >
                      Delete
                    </button>
                  ) : whichListing === 'investor' ? (
                    <button
                      className="dropdown-item h7"
                      onClick={() => this.deleteInvestor(listing.id)}
                    >
                      Delete
                    </button>
                  ) : (
                    ''
                  ))}
              </div>
            </div>
          </div>
          <div className="w-100 border-top mb-3"></div>
          <div className="d-flex flex-row justify-content-between">
            <div className="h9 line-height-2 font-weight-bold">Interests: </div>
            <div className="h9 line-height-2 w-70">
              <Truncate lines={1} ellipsis={<span>...</span>}>
                {listing.interests && listing.interests}
              </Truncate>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="h9 line-height-2 font-weight-bold">
              Background:{' '}
            </div>
            <div className="h9 line-height-2 w-70 pl-1">
              <Truncate lines={3} ellipsis={<span>...</span>}>
                {listing.aboutYourself && listing.aboutYourself}
              </Truncate>
            </div>
          </div>
          <div className="w-100 border-top mb-3"></div>
          <div className="d-flex justify-content-between align-items-center mt-2 mb-3">
            <div className="d-flex h9 align-items-center">
              <p className="font-weight-bold m-0 text-uppercase">
                <span
                  className={
                    listing.status === profileStatus.Approved
                      ? 'text-success'
                      : listing.status === profileStatus.Pending ||
                        listing.status === profileStatus.ChangesRequired
                      ? 'text-warning'
                      : listing.status === profileStatus.Draft ||
                        listing.status === profileStatus.Declined
                      ? 'text-danger'
                      : ''
                  }
                >
                  {spacePascalWord(listing.status)}
                </span>
              </p>
            </div>
            <div className="h9 d-none">
              <Switch color="primary" onChange={e => this.onSwitch(e)} />
              {this.state.availability ? 'AVAILABLE' : 'UNAVAILABLE'}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="h9 line-height-2 font-weight-bold">Locations</div>
            <div className="h9 line-height-2 w-70">
              {listing.locations && listing.locations}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="h9 line-height-2 font-weight-bold">Industries</div>
            <div className="h9 line-height-2 w-70">
              <Truncate lines={3} ellipsis={<span>...</span>}>
                {listing.industries && listing.industries}
              </Truncate>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="h9 line-height-2 font-weight-bold">
              Investment size
            </div>
            <div className="h9 line-height-2">
              ₦&nbsp;{getAbbreviation(listing.investmentRangeFrom)}
              &nbsp;-&nbsp; ₦&nbsp;{getAbbreviation(listing.investmentRangeTo)}
              <div className="h9 line-height-2"></div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end py-2">
            {listing &&
              (whichListing === 'advisor' ? (
                <Link
                  to={'/user/advisors/' + listing.id}
                  className="btn btn-outline-primary p-0 px-4"
                >
                  View
                </Link>
              ) : whichListing === 'investor' ? (
                <Link
                  to={'/user/investors/' + listing.id}
                  className="btn btn-outline-primary p-0 px-4"
                >
                  View
                </Link>
              ) : (
                <Link to="" className="btn btn-outline-primary p-0 px-4">
                  View
                </Link>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default UserAdvisorInvestorItem;
