import React, { Component } from 'react';
import queryString from 'query-string';
import authService from '../../common/services/authService';
import Swal from 'sweetalert2';

class ConfirmUserEmail extends Component {
  async componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    const { email, token } = values;

    const res = await authService.confirmUserEmail(email, token);

    if (res) {
      this.props.history.replace('/user/confirm-email-success');
    } else {
      Swal.fire(
        'Verification error',
        'Unable to verify email. Please try again or contact admin',
        'error',
      ).then(() => {
        this.props.history.replace('/');
      });
    }
  }

  render() {
    return <div></div>;
  }
}

export default ConfirmUserEmail;
