import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAdvisorListings } from '../../state/actions/advisor';
import AdvisorInvestorListingItem from '../../common/components/AdvisorInvestorListingItem';
import AdvisorInvestorPlaceholder from '../../common/components/AdvisorInvestorPlaceholder';
import FadeIn from 'react-fade-in';
import Pagination from '../../common/components/Pagination';
import randomstring from 'randomstring';
import { setHeaders, resetHeaders } from './../../state/actions/layout';

class AdvisorListings extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    this.props.fetchAdvisorListings();

    this.props.setHeaders({
      title:
        'Advisors - Professionals offering advisory services | CompexAfrica',
      description:
        'Verified Business Advisors: Provide Advisory Services to Businesses and Investors. Get connected with Investors and Businesses in Nigeria, Africa and overseas',
      url: this.props.history.location.pathname,
      keywords:
        'business for sale, buy a business, sell my business, business brokers, franchise opportunities',
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.advisorListings !== prevProps.advisorListings) {
      this.setState({ ...this.state, loading: false });
    }
  }

  componentWillUnmount() {
    this.props.resetHeaders();
  }

  onPageChange = page => {
    if (typeof page === 'number') {
      this.setState({ loading: true });
      this.props.fetchAdvisorListings(page);
    }
  };

  onPreviousPage = () => {
    this.setState({ loading: true });
    this.props.fetchAdvisorListings(this.props.pageNumber - 1);
  };

  onNextPage = () => {
    this.setState({ loading: true });
    this.props.fetchAdvisorListings(this.props.pageNumber + 1);
  };

  render() {
    const { advisorListings, pageNumber, pageSize, totalCount } = this.props;

    let placeholders = [];
    for (let i = 0; i < 9; i++) {
      placeholders.push(
        <FadeIn key={randomstring.generate()}>
          <AdvisorInvestorPlaceholder />
        </FadeIn>,
      );
    }

    return (
      <div className="container max-w-rem-sm-42 max-w-rem-lg-60 max-w-rem-xxl-80 max-w-rem-5xl-100">
        <div className="d-flex flex-wrap justify-content-between mb-5">
          <p className="h3 font-weight-bold">
            Professionals offering advisory services
          </p>
          <div className="input-group mb-3 w-30 d-none">
            <div className="input-group-prepend">
              <button className="btn btn-outline-secondary" type="button">
                Sort by
              </button>
            </div>
            <select className="custom-select" id="inputGroupSelect03">
              <option value="Choose...">Choose...</option>
              <option value="mostViewed">Most viewed</option>
            </select>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center justify-content-sm-start">
          {this.state.loading
            ? placeholders
            : advisorListings.map(listing => (
                <FadeIn key={randomstring.generate()}>
                  <AdvisorInvestorListingItem
                    listing={listing}
                    currentUser={this.props.currentUser}
                    className="fixed-width-height"
                    whichListingType="advisor"
                  ></AdvisorInvestorListingItem>
                </FadeIn>
              ))}
        </div>
        {advisorListings.length > 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={pageNumber}
              pageSize={pageSize}
              totalItem={totalCount}
              onPageChange={this.onPageChange}
              onPreviousPage={this.onPreviousPage}
              onNextPage={this.onNextPage}
            />
          </div>
        )}
        {!this.state.loading && advisorListings.length === 0 && (
          <FadeIn>
            <div className="text-center py-5">
              <p className="text-danger">No Result Found</p>
            </div>
          </FadeIn>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    advisorListings: state.advisor.advisorListings,
    pageNumber: state.advisor.pageNumber,
    pageSize: state.advisor.pageSize,
    totalCount: state.advisor.totalCount,
    currentUser: state.user.currentUser,
  }),
  { fetchAdvisorListings, setHeaders, resetHeaders },
)(AdvisorListings);
