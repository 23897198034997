import React, { Component } from 'react';
import Swal from 'sweetalert2';
import profileType from './../utils/profileType';
import listingsService from './../services/listingsService';
import advisorService from './../services/advisorService';
import investorsService from './../services/investorsService';
import franchiseService from './../services/franchiseService';
import { CircularProgress } from '@material-ui/core';

class AdminActionModal extends Component {
  state = {
    reason: '',
    isLoading: false,
  };

  onChange = e => {
    this.setState({ reason: e.target.value });
  };

  action = async e => {
    e.preventDefault();

    const { action, profileId, profileType: type } = this.props;
    const { reason } = this.state;

    if (!profileId || !type || !reason) return;

    let actionFunction = void 0;

    if (type === profileType.Business) {
      actionFunction =
        action === 'Decline'
          ? listingsService.declineListing
          : action === 'Request Change'
          ? listingsService.requestListingChange
          : void 0;
    } else if (type === profileType.Advisor) {
      actionFunction =
        action === 'Decline'
          ? advisorService.declineAdvisorListing
          : action === 'Request Change'
          ? advisorService.requestAdvisorListingChange
          : void 0;
    } else if (type === profileType.Investor) {
      actionFunction =
        action === 'Decline'
          ? investorsService.declineInvestorListing
          : action === 'Request Change'
          ? investorsService.requestInvestorListingChange
          : void 0;
    } else if (type === profileType.Franchise) {
      actionFunction =
        action === 'Decline'
          ? franchiseService.declineFranchiseListing
          : action === 'Request Change'
          ? franchiseService.requestFranchiseListingChange
          : void 0;
    }

    try {
      this.setState({ isLoading: true });
      const res = await actionFunction({ profileId, reason });
      if (res) {
        this.setState({ isLoading: false });
        this.props.refreshListings();
        Swal.fire('Success', 'Action successful', 'success');
      } else {
        this.setState({ isLoading: false });
        Swal.fire('Failed', 'Action unsuccessful', 'error');
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <div>
        <div className="card rounded-1 shadow bg-white">
          <div className="p-5">
            <form onSubmit={this.action}>
              <h3 className="mb-2 font-weight-bold">{this.props.action}</h3>
              <div className="form-row mb-3 text-left">
                <div className="form-group col-md-12">
                  <label htmlFor="reason">Enter reason:</label>
                  <textarea
                    id="reason"
                    name="reason"
                    value={this.state.reason}
                    onChange={this.onChange}
                    className="form-control"
                    rows="3"
                    placeholder="Reason for your action"
                    required
                  />
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? (
                      <CircularProgress color="inherit" size={'1rem'} />
                    ) : (
                      <span>{this.props.action}</span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminActionModal;
