import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchBusinessListings } from '../../state/actions/business';
import BusinessFranchiseListingItem from '../../common/components/BusinessFranchiseListingItem';
import BusinessFranchisePlaceholder from '../../common/components/BusinessFranchisePlaceholder';
import FadeIn from 'react-fade-in';
import Pagination from '../../common/components/Pagination';
import randomstring from 'randomstring';
import { setHeaders, resetHeaders } from './../../state/actions/layout';

class BusinessListings extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    this.props.fetchBusinessListings();

    this.props.setHeaders({
      title:
        'Businesses - Business sellers and investment seekers | CompexAfrica',
      description:
        'Buy a Business: Sell a Business in Nigeria, Africa and Overseas, get loans, raise capital, and connect with investors in Nigeria, Africa and Overseas',
      url: this.props.history.location.pathname,
      keywords:
        'Buy a business in Nigeria, Africa and Overseas, Businesses for Sale and Investment Opportunities, Invest in a Business, Sell your Business, Finance your Business, Lenders, Buyers / Investors,',
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.businessListings !== prevProps.businessListings) {
      this.setState({ ...this.state, loading: false });
    }
  }

  componentWillUnmount() {
    this.props.resetHeaders();
  }

  onPageChange = page => {
    if (typeof page === 'number') {
      this.setState({ loading: true });
      this.props.fetchBusinessListings(page);
    }
  };

  onPreviousPage = () => {
    this.setState({ loading: true });
    this.props.fetchBusinessListings(this.props.pageNumber - 1);
  };

  onNextPage = () => {
    this.setState({ loading: true });
    this.props.fetchBusinessListings(this.props.pageNumber + 1);
  };

  render() {
    const { businessListings, pageNumber, pageSize, totalCount } = this.props;

    let placeholders = [];
    for (let i = 0; i < 9; i++) {
      placeholders.push(
        <FadeIn key={randomstring.generate()}>
          <div
            style={{
              marginLeft: '19px',
              marginRight: '19px',
            }}
          >
            <BusinessFranchisePlaceholder />
          </div>
        </FadeIn>,
      );
    }

    return (
      <div className="container max-w-rem-sm-42 max-w-rem-lg-60 max-w-rem-xxl-80 max-w-rem-5xl-100">
        <div className="d-flex flex-wrap justify-content-between mb-5">
          <p className="h3 font-weight-bold">
            Business sellers and investment seekers
          </p>
          <div className="input-group mb-3 w-30 d-none">
            <div className="input-group-prepend">
              <button className="btn btn-outline-secondary" type="button">
                Sort by
              </button>
            </div>
            <select className="custom-select" id="inputGroupSelect03">
              <option defaultValue>Choose...</option>
              <option value="mostViewed">Most viewed</option>
            </select>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center justify-content-sm-start">
          {this.state.loading
            ? placeholders
            : businessListings.map(listing => (
                <FadeIn key={randomstring.generate()}>
                  <div
                    style={{
                      marginLeft: '19px',
                      marginRight: '19px',
                    }}
                  >
                    <BusinessFranchiseListingItem
                      listing={listing}
                      currentUser={this.props.currentUser}
                      whichListingType={'business'}
                    />
                  </div>
                </FadeIn>
              ))}
        </div>
        {businessListings.length > 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={pageNumber}
              pageSize={pageSize}
              totalItem={totalCount}
              onPageChange={this.onPageChange}
              onPreviousPage={this.onPreviousPage}
              onNextPage={this.onNextPage}
            />
          </div>
        )}
        {!this.state.loading && businessListings.length === 0 && (
          <FadeIn>
            <div className="text-center py-5">
              <p className="text-danger">No Result Found</p>
            </div>
          </FadeIn>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    businessListings: state.business.businessListings,
    pageSize: state.business.pageSize,
    pageNumber: state.business.pageNumber,
    totalCount: state.business.totalCount,
    currentUser: state.user.currentUser,
  }),
  { fetchBusinessListings, setHeaders, resetHeaders },
)(BusinessListings);
