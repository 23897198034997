import React from 'react';
import PlanCard from '../../common/components/PlanCard';
import planTypes from '../../common/utils/planTypes';
import { connect } from 'react-redux';
import { selectPlan, resetForm } from '../../state/actions/business';
import { planActionText } from './../../common/utils/helpers';

const BusinessPlans = props => {
  const onSelectPlan = plan => {
    if (props.isUpgrading || props.isDraft) {
      props.planChange(plan);
      return;
    }

    props.selectPlan(plan);
    if (props.covidIntervention)
      props.history.push('/register-business/intervention');
    else props.history.push('/register-business');
  };

  const currentPlan =
    props.isUpgrading || props.isDraft ? props.currentPlan : props.selectedPlan;

  console.log(currentPlan);

  const actionText = planType => {
    return planActionText(props.isUpgrading, planType, props.currentPlan);
  };

  return (
    <div className="d-flex flex-wrap flex-lg-nowrap h-100 justify-content-center">
      <PlanCard
        name={planTypes.basicBusiness}
        amount="Free"
        onSelectPlan={onSelectPlan}
        selectedPlan={currentPlan}
        covidIntervention={props.covidIntervention}
        actionText={actionText(planTypes.activeBusiness)}
        disabled={props.isUpgrading ? true : false}
        features={[
          'Speedy profile activation',
          <span>
            Send <b>2 business proposals</b> to investors and buyers
          </span>,
          '1 year validity',
          'Email support',
        ]}
        className="p-4 rounded bg-white shadow-lg shadow-lg-left mt-lg-5 mb-5 plan"
        style={{ width: '319px', maxWidth: '364px', height: '395px' }}
      />
      <div className="d-block d-lg-none d-xl-none w-100"></div>
      <PlanCard
        name={planTypes.activeBusiness}
        amount="NGN 35,000"
        onSelectPlan={onSelectPlan}
        selectedPlan={currentPlan}
        covidIntervention={props.covidIntervention}
        actionText={actionText(planTypes.activeBusiness)}
        disabled={false}
        features={[
          <span>
            Pitch <b>video recording</b>
          </span>,
          'Speedy profile activation',
          <span>
            Send <b>50 business proposals</b> to investors and buyers
          </span>,
          '1 year validity',
          'Email support',
        ]}
        className="p-4 rounded bg-white shadow-lg shadow-lg-left mt-lg-5 mb-5 plan"
        style={{ width: '319px', maxWidth: '364px', height: '395px' }}
      />
      <div className="d-block d-lg-none d-xl-none w-100"></div>
      <PlanCard
        name={planTypes.premiumBusiness}
        amount="NGN 49,000"
        onSelectPlan={onSelectPlan}
        selectedPlan={currentPlan}
        covidIntervention={props.covidIntervention}
        actionText={actionText(planTypes.premiumBusiness)}
        disabled={false}
        recommended={true}
        features={[
          <span>
            <span>
              Everything in <b>Active Plan</b>
            </span>
            &nbsp;
            <i className="fas fa-plus text-primary font-weight-bold"></i>
          </span>,
          <span>
            Summarized <b>business plan</b>
          </span>,
          <span>
            Send <b>80 business proposals</b> to investors and buyers
          </span>,
          <span>
            Compex Africa sends your proposal to <b>top 20 buyers/investors</b>{' '}
            in your sector
          </span>,
          <span>
            Compex Africa promotes your profile actively in regular{' '}
            <b>Industry Mailers</b>
          </span>,
          <span>
            Generate <b>NDA draft</b> to maintain confidentiality of your
            business identity
          </span>,
        ]}
        className="p-5 bg-white rounded shadow-lg mt-lg-3 mb-5 plan"
        style={{
          width: '319px',
          maxWidth: '364px',
          height: '574px',
          zIndex: '2',
        }}
        headerFontSize="h2a"
      />
      <div className="d-block d-lg-none d-xl-none w-100"></div>
      <PlanCard
        name={planTypes.professionalBusiness}
        amount="NGN 300,000"
        onSelectPlan={onSelectPlan}
        selectedPlan={currentPlan}
        covidIntervention={props.covidIntervention}
        actionText={actionText(planTypes.professionalBusiness)}
        disabled={false}
        features={[
          <span>
            <span>
              Everything in <b>Premium Plan</b>
            </span>
            &nbsp;
            <i className="fas fa-plus text-primary font-weight-bold"></i>
          </span>,
          <span>
            Summarized <b>business valuation</b>
          </span>,
          <span>
            Send <b>unlimited business proposals</b> to investors and buyers
          </span>,
          <span>
            Compex Africa sends your proposal to <b>top 50 buyers/investors</b>{' '}
            in your sector
          </span>,
          <span>
            Compex Africa to prepare <b>Information Memorandum</b> and{' '}
            <b>Financial Projection</b>
          </span>,
          <span>
            <b>Account Manager</b> who will be a single point of contact to
            manage your profile
          </span>,
        ]}
        className="p-4 bg-white rounded shadow-lg shadow-lg-right mt-lg-5 mb-5 plan"
        style={{
          minWidth: '320px',
          maxWidth: '364px',
          height: '511px',
          minHeight: '511px',
        }}
      />
    </div>
  );
};

export default connect(
  state => ({
    selectedPlan: state.business.selectedPlan,
  }),
  { selectPlan, resetForm },
)(BusinessPlans);
