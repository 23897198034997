import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPostDetails } from './../../state/actions/startup';
import tempAvatar from '../../assets/img/user.svg';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import communityService from '../../common/services/communityService';
import FadeIn from 'react-fade-in';
import { CircularProgress } from '@material-ui/core';

const PostDetail = props => {
  const MySwal = withReactContent(Swal);
  const postDetail = useSelector(state => state.startup.post);
  const loadingPostDetail = useSelector(
    state => state.startup.loadingPostDetail,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const id = props.match.params.id;
    if (id) dispatch(fetchPostDetails(id));
  }, [dispatch, props.match.params.id]);

  const approvePost = async id => {
    MySwal.fire({
      title: `Are you sure?
      Do you want to approve this Post?
      `,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await communityService.approvePost(id);
          if (!response) {
            throw new Error(response.statusText);
          }
          return response;
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
      if (result.value === true) {
        this.props.fetchStartupPosts();
        Swal.fire('Post Approved Successfully', '', 'success');
      }
    });
  };

  const disapprovePost = async id => {
    MySwal.fire({
      title: `Are you sure?
      Do you want to disapprove this Post?
      `,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await communityService.disapprovePost(id);
          if (!response) {
            throw new Error(response.statusText);
          }
          return response;
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
      if (result.value === true) {
        this.props.fetchStartupPosts();
        Swal.fire('Post Disapproved Successfully', '', 'success');
      }
    });
  };

  return loadingPostDetail ? (
    <div
      style={{ minHeight: '60vh' }}
      className="d-flex justify-content-center align-items-center"
    >
      <CircularProgress color="primary" size={'3rem'} />
    </div>
  ) : (
    <FadeIn>
      {postDetail && (
        <div className="d-flex flex-column p-5 mb-5">
          <div className="d-flex">
            <div className="d-flex rounded bg-white">
              <p className="h6 px-5 py-md-2 my-auto">
                Status: {postDetail.isApproved ? 'Approved' : 'Pending'}
              </p>
            </div>
            <div className="ml-auto">
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle px-5 py-2"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ backgroundColor: 'white' }}
                >
                  Actions
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {postDetail.isApproved && (
                    <button
                      className="dropdown-item cursor-pointer"
                      onClick={() => disapprovePost(postDetail.id)}
                    >
                      Disapprove
                    </button>
                  )}
                  {!postDetail.isApproved && (
                    <button
                      className="dropdown-item cursor-pointer"
                      onClick={() => approvePost(postDetail.id)}
                    >
                      Approve
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className=" border-bottom mt-5 pb-3">
            <p className="font-weight-bold">{postDetail.title}</p>
            <div className="d-flex postlisting">
              <div
                class={`mt-1 mr-3 technology ${postDetail.postCategoryName}`}
                style={{ backgroundColor: 'rgb(32, 2, 179)' }}
              ></div>
              {postDetail.postCategoryName}
            </div>
          </div>
          <div className=" pt-3">
            <div className="row">
              <div className="col-md-2">
                <div className="w-50 w-md-90">
                  <img
                    src={postDetail.userPhotoUrl || tempAvatar}
                    alt="poster"
                    className="w-100 rounded-circle d-inline-block"
                  />
                </div>
              </div>
              <div className="col-10">
                <div className="row pt-3">
                  <span className="font-weight-bold">{`${postDetail.userFirstname} ${postDetail.userLastname}`}</span>

                  <div className="ml-auto">
                    {new Date(postDetail.createdAt).toDateString()}
                  </div>
                </div>
                <div className="row mt-5">
                  <div
                    id="postBody"
                    className="pt-5 pb-2"
                    dangerouslySetInnerHTML={{ __html: postDetail.body }}
                  ></div>
                </div>
                <div className=" d-flex row">
                  <div className="font-weight-bold">
                    {postDetail.commentsCount} Replies &nbsp;&nbsp;&nbsp;&nbsp;
                    {postDetail.views} Views
                  </div>
                  <div className="ml-auto font-weight-bold">
                    {postDetail.likes} <i className="far fa-heart"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </FadeIn>
  );
};

export default PostDetail;
