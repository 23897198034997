import http from './httpService';
import baseApiUrl from '../../config';

const getPosts = (pageNumber = 1) => {
  return http.get(`${baseApiUrl}posts?all=true&pageNumber=${pageNumber}`);
};

const getPostDetails = id => {
  return http.get(`${baseApiUrl}posts/${id}?viewed=true`);
};

const approvePost = async id => {
  const { status } = await http.post(`${baseApiUrl}posts/${id}/approve`);
  if (status === 200) return true;
};

const disapprovePost = async id => {
  const { status } = await http.post(`${baseApiUrl}posts/${id}/disapprove`);
  if (status === 200) return true;
};

export default {
  getPosts,
  getPostDetails,
  approvePost,
  disapprovePost,
};
