import React, { Component } from 'react';
import Tick from './../../assets/img/tick.svg';
import { Link } from 'react-router-dom';
import auth from '../../common/services/authService';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { CircularProgress } from '@material-ui/core';

class VerifyEmail extends Component {
  state = {
    loading: false,
  };

  resendConfirmationEmail = async e => {
    e.preventDefault();
    try {
      const { email } = this.props.currentUser;
      this.setState({ loading: true });
      const res = await auth.resendConfirmationEmail(email);
      if (res) {
        Swal.fire(
          'Email Resent Successfully',
          `A new verification email has been sent to ${email}. Didn't get an email after a few minutes? Please check you spam, promotions, social or junk folder.`,
          'success',
        );
        this.setState({ loading: false });
      } else {
        Swal.fire(
          'Action failed',
          `Unable to resend email. Try again`,
          'error',
        );
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <div className="d-flex flex-column align-items-center container pb-5">
        <div className="rounded-circle bg-primary p-3 w-25 col-lg-1 mb-5">
          <img src={Tick} alt="..."></img>
        </div>
        <h1 className="h3 text-verify-dark">Verification link sent</h1>
        <div className="card bg-white shadow rounded mb-3 col-lg-6 my-5">
          <div className="card-body px-5 py-5">
            <div className="card-text h5 text-verify-light font-weight-light text-center">
              A verification link has been sent to your email. Kindly click the
              link to verify your account and have access to your dashboard.
              <div className="my-3 text-center">
                <span className="font-weight-bold">
                  Didn't get an email after a few minutes?
                </span>
                <br />
                Please check you spam, promotions, social or junk folder.
                <br />
                {this.state.loading ? (
                  <button className="btn btn-lg btn-primary my-2">
                    <CircularProgress color="inherit" size={'1rem'} />
                  </button>
                ) : (
                  <button
                    className="button-as-link text-primary"
                    onClick={this.resendConfirmationEmail}
                  >
                    Resend Verification Email
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="pb-5">
          <Link to="/login" className="btn btn-primary">
            Login
          </Link>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({ currentUser: state.user.currentUser }),
  null,
)(VerifyEmail);
