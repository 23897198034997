import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RegisterFranchiseForm from './RegisterFranchiseForm';
import FranchisePlans from './FranchisePlans';

const AddFranchise = props => {
  return (
    <div>
      <Switch>
        <Route
          path="/register-franchise/plans"
          component={() => {
            return (
              <>
                <div className="w-100 text-center font-weight-bold text-pricing h2a mb-5">
                  Choose a franchise plan
                </div>
                <FranchisePlans {...props} />
              </>
            );
          }}
        />
        <Route path="/" component={RegisterFranchiseForm} />
      </Switch>
    </div>
  );
};

export default AddFranchise;
