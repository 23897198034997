import React from 'react';

const FaqDropDown = props => {
  const { question, answer, id } = props;

  return (
    <div className="mb-3">
      <div
        className="d-flex bg-white shadow shadow-light text-plus align-items-center"
        data-toggle="collapse"
        data-target={'#' + id}
      >
        <div className="w-90 p-4">{question}</div>
        <i className="fas fa-plus font-size-5"></i>
      </div>
      <div className="collapse bg-transparent" id={id}>
        <p className="p-4">{answer}</p>
      </div>
    </div>
  );
};

export default FaqDropDown;
