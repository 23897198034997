import React from 'react';
import ContentLoader from 'react-content-loader';

const AdvisorInvestorPlaceholder = () => (
  <div className="pb-5 mx-2" style={{ width: '293px' }}>
    <ContentLoader
      height={410}
      width={293}
      speed={2}
      primaryColor="#cccccc"
      secondaryColor="#f3f3f3"
    ></ContentLoader>
  </div>
);

export default AdvisorInvestorPlaceholder;
