import React, { Component } from 'react';
import userService from '../services/userService';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import BusinessPaywall from './BusinessPaywall';
import FranchisePaywall from './FranchisePaywall';

class ContactBttn extends Component {
  state = {
    error: null,
  };

  MySwal = withReactContent(Swal);

  handleOnClick = () => {
    if (!this.props.isLoggedIn) {
      this.props.history.push('/login'); // TODO: redirect back here
      return;
    }

    const { sendTo, businessId, franchiseId, canContact } = this.props;
    const MySwal = this.MySwal;

    switch (sendTo) {
      case 'business':
        !canContact
          ? MySwal.fire({
              customClass: 'bg-transparent w-lg-60 w-md-60 w-90',
              showConfirmButton: false,
              html: <BusinessPaywall></BusinessPaywall>,
            })
          : MySwal.fire({
              title: <p>Are you sure?</p>,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Contact business',
            }).then(async result => {
              if (result.value) {
                const res = await userService.contactBusiness({
                  businessId,
                });
                if (res) {
                  MySwal.fire(
                    'Success',
                    "You can now view this business's contact details",
                    'success',
                  ).then(() => {
                    window.location.reload();
                  });
                } else {
                  MySwal.fire(
                    'An error has occured',
                    `
                        We are unable to display this business's
                        contact details at the moment. Please try again shortly.
                        `,
                    'error',
                  );
                }
              }
            });
        break;
      case 'franchise':
        !canContact
          ? MySwal.fire({
              customClass: 'bg-transparent w-lg-60 w-md-60 w-90',
              showConfirmButton: false,
              html: <FranchisePaywall></FranchisePaywall>,
            })
          : MySwal.fire({
              title: <p>Are you sure?</p>,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Contact franchise',
            }).then(async result => {
              if (result.value) {
                const res = await userService.contactFranchise({
                  franchiseId,
                });
                if (res) {
                  MySwal.fire(
                    'Success',
                    "You can now view this franchise's contact details",
                    'success',
                  ).then(() => {
                    window.location.reload();
                  });
                } else {
                  MySwal.fire(
                    'An error has occured',
                    `
                        We are unable to display this franchise's
                        contact details at the moment. Please try again shortly.
                        `,
                    'error',
                  );
                }
              }
            });
        break;
      default:
        this.setState({
          error: `
                    Specify a sendTo prop
                    and confirm you are supplying a businessId or franchiseId

                    API Usage:
                    <ContactBttn
                    sendTo={business || franchise}
                    businessId={business listing Id}
                    franchiseId={franchise listing Id}
                    ></ContactBttn>
                    `,
        });
        console.log(this.state.error);
        break;
    }
  };

  render() {
    const { sendTo, showDocBttn } = this.props;
    const docBttn = showDocBttn ? showDocBttn : false;
    return (
      <>
        {!docBttn ? (
          <div className="w-100">
            <button
              className="btn p-2 py-2a btn-primary rounded-1 h5 w-100"
              disabled={this.props.disable}
              onClick={() => {
                this.handleOnClick();
              }}
            >
              Contact {sendTo}
            </button>
            {this.state.error && (
              <pre className="text-danger">{this.state.error}</pre>
            )}
          </div>
        ) : (
          <div className="w-100 custom-bttn">
            <button
              className="btn btn-white btn-shrink bg-white mt-auto mb-5 shadow-lg rounded-1 text-bright-primary font-weight-bold h7 w-100"
              disabled={this.props.disable}
              onClick={() => {
                this.handleOnClick();
              }}
            >
              View attached documents&nbsp;
              <i className="fas fa-lock text-dark py-2"></i>
            </button>
            {this.state.error && (
              <pre className="text-danger">{this.state.error}</pre>
            )}
          </div>
        )}
      </>
    );
  }
}

export default connect(state => ({
  isLoggedIn: state.user.isLoggedIn,
}))(ContactBttn);
