import React from 'react';
import PlanCard from '../../common/components/PlanCard';
import planTypes from '../../common/utils/planTypes';
import { connect } from 'react-redux';
import { selectPlan } from '../../state/actions/investor';
import { planActionText } from '../../common/utils/helpers';

const AdvisorPlans = props => {
  const onSelectPlan = plan => {
    if (props.isUpgrading || props.isDraft) {
      props.planChange(plan);
      return;
    }

    props.selectPlan(plan);
    props.history.push('/register-advisor');
  };

  const currentPlan =
    props.isUpgrading || props.isDraft ? props.currentPlan : props.selectedPlan;

  const actionText = planType => {
    return planActionText(props.isUpgrading, planType, props.currentPlan);
  };

  return (
    <div className="d-flex flex-wrap flex-lg-nowrap h-100 justify-content-center">
      <PlanCard
        name={planTypes.basicAdvisor}
        amount="Free"
        onSelectPlan={onSelectPlan}
        selectedPlan={currentPlan}
        actionText={actionText(planTypes.basicAdvisor)}
        disabled={props.isUpgrading ? true : false}
        features={['Basic buyer profile', '2 introductions', '1 year validity']}
        className="p-4 bg-white rounded shadow-lg shadow-lg-left mt-lg-5 mb-5 plan"
        style={{ width: '305px', maxWidth: '364px', height: '404px' }}
      />
      <PlanCard
        name={planTypes.activeAdvisor}
        amount="NGN 31,000"
        onSelectPlan={onSelectPlan}
        selectedPlan={currentPlan}
        actionText={actionText(planTypes.activeAdvisor)}
        disabled={false}
        features={[
          'Active buyer profile',
          '10 introductions',
          '1 year validity',
          'Metrics of all businesses',
          'View contact details',
          'View business name',
          'Email support',
        ]}
        className="p-4 bg-white rounded shadow-lg shadow-lg-left mt-lg-5 mb-5 plan"
        style={{ width: '305px', maxWidth: '364px', height: '425px' }}
      />
      <PlanCard
        name={planTypes.premiumAdvisor}
        amount="NGN 49,000"
        onSelectPlan={onSelectPlan}
        selectedPlan={currentPlan}
        actionText={actionText(planTypes.premiumAdvisor)}
        disabled={false}
        recommended={true}
        features={[
          'Premium buyer profile',
          '25 introductions',
          '1 year validity',
          'Metrics of all businesses',
          'View contact details',
          'View business name',
          'Email support',
        ]}
        className="p-5 bg-white rounded shadow-lg mt-lg-3 mb-5 plan"
        style={{
          width: '305px',
          maxWidth: '364px',
          height: '487px',
          zIndex: '2',
        }}
        headerFontSize="h2a"
      />
      <PlanCard
        name={planTypes.professionalAdvisor}
        amount="NGN 120,000"
        onSelectPlan={onSelectPlan}
        selectedPlan={currentPlan}
        actionText={actionText(planTypes.professionalAdvisor)}
        disabled={false}
        recommended={true}
        features={[
          'Professional buyer profile',
          'Unlimited introductions',
          '1 year validity',
          'Metrics of all businesses',
          'View contact details',
          'View business name',
          'Email and phone support',
        ]}
        className="p-4 bg-white rounded shadow-lg shadow-lg-right mt-lg-5 mb-5 plan"
        style={{ width: '305px', maxWidth: '364px', height: '425px' }}
      />
    </div>
  );
};

export default connect(
  state => ({
    selectedPlan: state.advisor.selectedPlan,
  }),
  { selectPlan },
)(AdvisorPlans);
