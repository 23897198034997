import {
  FETCH_FRANCHISE_LISTINGS,
  UPDATE_FRANCHISES,
  EDITFRANCHISE,
  FETCH_ADMIN_FRANCHISE_LISTINGS,
  FETCH_FRANCHISE_LISTINGS_STARTED,
  FETCH_FRANCHISE_DETAILS,
  SELECT_PLAN,
  RESETFORMS,
} from '../actions/types';

const initialState = {
  formdata: {},
  franchiseListings: [],
  loadingFranchiseListings: false,
  franchiseDetails: {},
  adminFranchiseListings: [],
  pageNumber: 1,
  pageSize: 50,
  totalCount: 0,
  selectedPlan: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FRANCHISE_LISTINGS:
      const { data, pageNumber, pageSize, totalCount } = action.payload;
      return {
        ...state,
        franchiseListings: data,
        pageNumber,
        pageSize,
        totalCount,
      };
    case FETCH_FRANCHISE_DETAILS:
      return {
        ...state,
        franchiseDetails: action.payload,
      };
    case EDITFRANCHISE:
      return {
        ...state,
        formdata: action.payload,
      };
    case UPDATE_FRANCHISES:
      return {
        ...state,
        franchiseListings: action.payload.data,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount,
      };
    case FETCH_ADMIN_FRANCHISE_LISTINGS:
      return {
        ...state,
        adminFranchiseListings: action.payload.data,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount,
        loadingFranchiseListings: false,
      };
    case FETCH_FRANCHISE_LISTINGS_STARTED:
      return {
        ...state,
        loadingFranchiseListings: true,
      };
    case SELECT_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };
    case RESETFORMS:
      return {
        ...state,
        formdata: {},
        selectedPlan: '',
      };
    default:
      return state;
  }
};
