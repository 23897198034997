import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchBusinessListings } from '../../state/actions/business';
import BusinessFranchiseListingItem from '../../common/components/BusinessFranchiseListingItem';
import BusinessFranchisePlaceholder from '../../common/components/BusinessFranchisePlaceholder';
import randomstring from 'randomstring';
import CarouselTemp from '../../common/components/CarouselTemp';
import AdvisorInvestorPlaceholder from '../../common/components/AdvisorInvestorPlaceholder';
import { fetchFranchiseListings } from '../../state/actions/franchise';
import { fetchInvestorListings } from '../../state/actions/investor';
import AdvisorInvestorListingItem from '../../common/components/AdvisorInvestorListingItem';
import { fetchAdvisorListings } from '../../state/actions/advisor';
import UserBusinessListingsList from './UserBusinessListingsList';
import UserFranchiseListingsList from './UserFranchiseListingsList';
import UserInvestorListingsList from './UserInvestorListingsList';
import UserAdvisorListingsList from './UserAdvisorListingsList';
import { getAllUserListings } from '../../state/actions/user';

class DashboardLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businesses: { loading: true, profiles: [] },
      franchises: { loading: true, profiles: [] },
      investors: { loading: true, profiles: [] },
      advisors: { loading: true, profiles: [] },
    };
  }

  componentDidMount() {
    this.props.fetchBusinessListings();
    this.props.fetchFranchiseListings();
    this.props.fetchInvestorListings();
    this.props.fetchAdvisorListings();
    this.props.getAllUserListings();
  }

  componentDidUpdate(prevProps) {
    if (this.props.businessListings !== prevProps.businessListings) {
      let businesses = [];
      this.props.businessListings.forEach(listing =>
        businesses.push(
          <div
            key={'business' + listing.id}
            style={{
              marginLeft: '19px',
              marginRight: '19px',
            }}
          >
            <BusinessFranchiseListingItem
              listing={listing}
              currentUser={this.props.currentUser}
              whichListingType={'business'}
            />
          </div>,
        ),
      );
      this.setState({ businesses: { loading: false, profiles: businesses } });
    }

    if (this.props.franchiseListings !== prevProps.franchiseListings) {
      let franchises = [];
      this.props.franchiseListings.forEach(listing =>
        franchises.push(
          <div
            key={'franchise' + listing.id}
            style={{
              marginLeft: '19px',
              marginRight: '19px',
            }}
          >
            <BusinessFranchiseListingItem
              listing={listing}
              currentUser={this.props.currentUser}
              whichListingType={'franchise'}
            />
          </div>,
        ),
      );
      this.setState({ franchises: { loading: false, profiles: franchises } });
    }

    if (this.props.investorListings !== prevProps.investorListings) {
      let investors = [];
      this.props.investorListings.forEach(listing =>
        investors.push(
          <div
            key={'investor' + listing.id}
            style={{
              marginLeft: '19px',
              marginRight: '19px',
            }}
          >
            <AdvisorInvestorListingItem
              listing={listing}
              currentUser={this.props.currentUser}
              className="fixed-width-height"
              whichListingType="investor"
            />
          </div>,
        ),
      );
      this.setState({ investors: { loading: false, profiles: investors } });
    }

    if (this.props.advisorListings !== prevProps.advisorListings) {
      let advisors = [];
      this.props.advisorListings.forEach(listing =>
        advisors.push(
          <div
            key={'investor' + listing.id}
            style={{
              marginLeft: '19px',
              marginRight: '19px',
            }}
          >
            <AdvisorInvestorListingItem
              listing={listing}
              currentUser={this.props.currentUser}
              className="fixed-width-height"
              whichListingType="investor"
            />
          </div>,
        ),
      );
      this.setState({ advisors: { loading: false, profiles: advisors } });
    }
  }

  render() {
    console.log('rendered');
    const { currentUser } = this.props;
    const placeholders = [
      <BusinessFranchisePlaceholder key={randomstring.generate()} />,
      <BusinessFranchisePlaceholder key={randomstring.generate()} />,
      <BusinessFranchisePlaceholder key={randomstring.generate()} />,
    ];

    const investorPlaceholders = [
      <AdvisorInvestorPlaceholder key={randomstring.generate()} />,
      <AdvisorInvestorPlaceholder key={randomstring.generate()} />,
      <AdvisorInvestorPlaceholder key={randomstring.generate()} />,
    ];
    return (
      <>
        <div className="w-90 bg-dashboard rounded-2 shadow-lg p-5 mx-auto mb-6">
          <p className="h1y text-about font-weight-bold">
            Hi {currentUser && currentUser.firstname}
          </p>
          <p className="h7 text-about mb-5" style={{ lineHeight: '1.25rem' }}>
            Welcome to COMPEX AFRICA, we help you acquire businesses, raise
            capital for your company, find joint venture partners, or realize
            value through a strategic sale. Our global network of investors and
            businesses helps you successfully achieve your strategic objectives.
          </p>
          <div className="d-flex flex-wrap">
            <div className="w-100 w-lg-25 mb-3">
              <NavLink to="/register-business/plans">
                <div
                  className="bg-hand-cash-icon mx-auto mb-2 h-rem-3"
                  style={{ maxWidth: '30%' }}
                ></div>
                <p className="font-weight-bold text-primary text-center h8a p-2">
                  Sell or raise capital for your business
                </p>
              </NavLink>
            </div>
            <div className="w-100 w-lg-25 mb-3">
              <NavLink to="/register-investor/plans">
                <div
                  className="bg-briefcase-icon mx-auto mb-2 h-rem-3"
                  style={{ maxWidth: '30%' }}
                ></div>
                <p className="font-weight-bold text-primary text-center h8a p-2">
                  Buy or invest in a business of your choice
                </p>
              </NavLink>
            </div>
            <div className="w-100 w-lg-25 mb-3">
              <NavLink to="/register-franchise/plans">
                <div
                  className="bg-shop-blue-icon mx-auto mb-2 h-rem-3"
                  style={{ maxWidth: '30%' }}
                ></div>
                <p className="font-weight-bold text-primary text-center h8a p-2">
                  List a franchise opportunity
                </p>
              </NavLink>
            </div>
            <div className="w-100 w-lg-25 mb-3">
              <NavLink to="/register-advisor/plans">
                <div
                  className="bg-advisor-avatar mx-auto mb-2 h-rem-3"
                  style={{ maxWidth: '30%' }}
                ></div>
                <p className="font-weight-bold text-primary text-center h8a p-2">
                  Register as an advisor
                </p>
              </NavLink>
            </div>
          </div>
        </div>
        {this.props.allUserListings.find(
          ({ profileType }) => profileType === 'Business',
        ) && (
          <div className="w-100 mb-5 ml-lg-5">
            <p className="h4a text-white font-weight-bold mb-4 bg-bright-primary p-3 rounded-2 w-rem-17 text-center">
              Your business profiles
            </p>
            <p className="h7 mb-4 ml-3">Please complete any draft profiles.</p>
            <UserBusinessListingsList {...this.props} />
          </div>
        )}

        <div className="w-100 mb-5">
          <div className="d-flex justify-content-between w-lg-90">
            <p className="h4a text-darkdash font-weight-bold mb-4 ml-5">
              Businesses for sale/capital raise
            </p>
            <NavLink to="/businesses" className="min-w-50 text-right">
              <p className="h4a">
                View <br className="d-lg-none" />
                more
              </p>
            </NavLink>
          </div>
          {this.state.businesses.loading ? (
            <CarouselTemp
              itemsPerSlide={3}
              id={'dashboardBusinessesPlaceholders'}
              listings={placeholders}
              controls={true}
            ></CarouselTemp>
          ) : (
            <CarouselTemp
              itemsPerSlide={3}
              id={'dashboardBusinesses'}
              listings={this.state.businesses.profiles}
              controls={true}
              ride={'false'}
              interval={'90000'}
            ></CarouselTemp>
          )}
        </div>
        {this.props.allUserListings.find(
          ({ profileType }) => profileType === 'Franchise',
        ) && (
          <div className="w-100 mb-5 ml-lg-5">
            <p className="h4a text-white font-weight-bold mb-4 bg-bright-primary p-3 rounded-2 w-rem-17 text-center">
              Your franchise profiles
            </p>
            <p className="h7 mb-4 ml-3">Please complete any draft profiles.</p>
            <UserFranchiseListingsList {...this.props} />
          </div>
        )}
        <div className="w-100 mb-5">
          <div className="d-flex justify-content-between w-lg-90">
            <p className="h4a text-darkdash font-weight-bold mb-4 ml-5">
              Franchise opportunities
            </p>
            <NavLink to="/franchises" className="min-w-50 text-right">
              <p className="h4a">
                View
                <br className="d-lg-none" />
                &nbsp; more
              </p>
            </NavLink>
          </div>
          {this.state.franchises.loading ? (
            <CarouselTemp
              itemsPerSlide={3}
              id={'dashboardFranchisesPlaceholders'}
              listings={placeholders}
              controls={true}
            ></CarouselTemp>
          ) : (
            <CarouselTemp
              itemsPerSlide={3}
              id={'dashboardFranchises'}
              listings={this.state.franchises.profiles}
              controls={true}
              ride={'false'}
              interval={'90000'}
            ></CarouselTemp>
          )}
        </div>
        {this.props.allUserListings.find(
          ({ profileType }) => profileType === 'Investor',
        ) && (
          <div className="w-100 mb-5 ml-lg-5">
            <p className="h4a text-white font-weight-bold mb-4 bg-bright-primary p-3 rounded-2 w-rem-17 text-center">
              Your Investor profiles
            </p>
            <p className="h7 mb-4 ml-3">Please complete any draft profiles.</p>
            <UserInvestorListingsList {...this.props} />
          </div>
        )}

        <div className="w-100 mb-5">
          <div className="d-flex justify-content-between w-lg-90">
            <p className="h4a text-darkdash font-weight-bold mb-4 ml-5">
              Looking for investors?
            </p>
            <NavLink to="/investors">
              <p className="h4a">View more</p>
            </NavLink>
          </div>
          {this.state.investors.loading ? (
            <CarouselTemp
              itemsPerSlide={3}
              id={'dashboardInvestorsPlaceholders'}
              listings={investorPlaceholders}
              controls={true}
            ></CarouselTemp>
          ) : (
            <CarouselTemp
              itemsPerSlide={3}
              id={'dashboardInvestors'}
              listings={this.state.investors.profiles}
              controls={false}
              interval={'5000'}
            ></CarouselTemp>
          )}
        </div>

        {this.props.allUserListings.find(
          ({ profileType }) => profileType === 'Advisor',
        ) && (
          <div className="w-100 mb-8 ml-lg-5">
            <p className="h4a text-white font-weight-bold mb-4 bg-bright-primary p-3 rounded-2 w-rem-17 text-center">
              Your Advisor profiles
            </p>
            <p className="h7 mb-4 ml-3 d-none">
              Please complete any draft profiles.
            </p>
            <UserAdvisorListingsList {...this.props} />
          </div>
        )}

        <div className="w-100 mb-8">
          <div className="d-flex justify-content-between w-lg-90">
            <p className="h4a text-darkdash font-weight-bold mb-4 ml-5">
              Looking for advisors?
            </p>
            <NavLink to="/advisors">
              <p className="h4a">View more</p>
            </NavLink>
          </div>
          {this.state.advisors.loading ? (
            <CarouselTemp
              itemsPerSlide={3}
              id={'dashboardAdvisorsPlaceholders'}
              listings={investorPlaceholders}
              controls={true}
            ></CarouselTemp>
          ) : (
            <CarouselTemp
              itemsPerSlide={3}
              id={'dashboardAdvisors'}
              listings={this.state.advisors.profiles}
              controls={false}
              interval={'5000'}
            ></CarouselTemp>
          )}
        </div>
      </>
    );
  }
}

export default connect(
  state => ({
    currentUser: state.user.currentUser,
    businessListings: state.business.businessListings,
    franchiseListings: state.franchise.franchiseListings,
    investorListings: state.investor.investorListings,
    advisorListings: state.advisor.advisorListings,
    allUserListings: state.user.allUserListings,
  }),
  {
    fetchBusinessListings,
    fetchFranchiseListings,
    fetchInvestorListings,
    fetchAdvisorListings,
    getAllUserListings,
  },
)(DashboardLanding);
