import React, { Component } from 'react';
import { connect } from 'react-redux';
import Joi from 'joi-browser';
import authService from '../../common/services/authService';
import {
  hideNavbar,
  hideFooter,
  showNavbar,
  showFooter,
} from './../../state/actions/layout';
import { CircularProgress } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FormAlertMessage from '../../common/components/FormAlertMessage';
import GoogleLoginButton from './../../common/components/GoogleLoginButton';
import FacebookLoginButton from '../../common/components/FacebookLoginButton';

export class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        passwordConfirm: '',
      },
      errors: {},
      loading: undefined,
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.props.hideNavbar();
    this.props.hideFooter();
  }

  componentWillUnmount() {
    this.props.showNavbar();
    this.props.showFooter();
  }

  onChange = e => {
    const { name, value } = e.target;
    const account = { ...this.state.account };
    account[name] = value;
    const errors = { ...this.state.errors };
    errors[name] = '';
    this.setState({ account, errors });
  };

  onPhoneChange = phone => {
    const account = { ...this.state.account };
    account['phoneNumber'] = phone;
    const errors = { ...this.state.errors };
    errors['phoneNumber'] = '';
    this.setState({ account, errors });
  };

  schema = {
    firstName: Joi.string()
      .min(3)
      .required()
      .label('First Name'),
    lastName: Joi.string()
      .min(3)
      .required()
      .label('Last Name'),
    phoneNumber: Joi.string()
      .required()
      .label('Phone'),
    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .label('Email'),
    password: Joi.string()
      .min(6)
      .required()
      .label('Password'),
    passwordConfirm: Joi.string()
      .min(6)
      .required()
      .label('Password Confirmation'),
  };

  validate() {
    const { error } = Joi.validate(this.state.account, this.schema, {
      abortEarly: false,
    });
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  onSubmit = async e => {
    e.preventDefault();
    let path = window.location.protocol + '//' + window.location.host;
    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) return;

    if (this.state.account.password !== this.state.account.passwordConfirm) {
      this.setState({
        errors: { passwordConfirm: 'Password confirm must match password' },
      });
      return;
    }

    try {
      this.setState({ ...this.state, loading: true });

      const result = await authService.signUp({
        ...this.state.account,
        Url: path,
      });

      if (result) {
        this.setState({ ...this.state, loading: false });

        this.props.history.replace('/user/registration-success');
      } else {
        this.setState({ loading: false });
      }
    } catch (err) {
      this.setState({ loading: false });

      const { response } = err;

      if (response && response.data && response.data.title) {
        let message = response.data.title;
        this.setState({ errorMessage: message });
      }
    }
  };

  render() {
    const state = this.state;
    return (
      <div className="bg-white shadow p-5 rounded">
        <form
          onSubmit={this.onSubmit}
          method="POST"
          className="small"
          noValidate
          name="sign-up-form"
        >
          <div className="form-row">
            <div className="form-group col-md-6 px-md-4">
              <label htmlFor="firstname">First Name</label>
              <input
                type="text"
                id="firstname"
                name="firstName"
                onChange={this.onChange}
                value={state.account.firstName}
                className={
                  this.state.errors['firstName']
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {this.state.errors['firstName']}
              </div>
            </div>
            <div className="form-group col-md-6 px-md-4">
              <label htmlFor="lastname">Last Name</label>
              <input
                type="text"
                id="lastname"
                name="lastName"
                onChange={this.onChange}
                value={state.account.lastName}
                className={
                  this.state.errors['lastName']
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {this.state.errors['lastName']}
              </div>
            </div>
            <div className="form-group col-md-6 px-md-4">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={this.onChange}
                value={state.account.email}
                className={
                  this.state.errors['email']
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {this.state.errors['email']}
              </div>
            </div>
            <div className="form-group col-md-6 px-md-4">
              <label htmlFor="phone">Phone Number</label>
              <PhoneInput
                country={'ng'}
                value={state.account.phoneNumber}
                onChange={this.onPhoneChange}
                inputClass={
                  this.state.errors['phoneNumber']
                    ? 'form-control w-100 is-invalid'
                    : 'form-control w-100'
                }
              />
              <div
                className={this.state.errors['phoneNumber'] ? 'is-invalid' : ''}
              ></div>
              <div className="invalid-feedback">
                {this.state.errors['phoneNumber']}
              </div>
            </div>
            <div className="form-group col-md-6 px-md-4">
              <label htmlFor="lastname">
                Password{' '}
                <span className="font-italic">Minimum of 6 characters</span>
              </label>
              <input
                type="password"
                id="password"
                name="password"
                onChange={this.onChange}
                value={state.account.password}
                className={
                  this.state.errors['password']
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {this.state.errors['password']}
              </div>
            </div>
            <div className="form-group col-md-6 px-md-4">
              <label htmlFor="passwordConfirm">Confirm Password</label>
              <input
                type="password"
                id="passwordConfirm"
                name="passwordConfirm"
                onChange={this.onChange}
                value={state.account.passwordConfirm}
                className={
                  this.state.errors['passwordConfirm']
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {this.state.errors['passwordConfirm']}
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-6">
              {this.state.errorMessage && (
                <FormAlertMessage message={this.state.errorMessage} />
              )}
            </div>
          </div>

          <div className="row justify-content-center py-5">
            <div className="col-md-3">
              <button type="submit" className="btn btn-primary btn-block">
                {this.state.loading ? (
                  <CircularProgress color="inherit" size={'1rem'} />
                ) : (
                  'Sign up'
                )}
              </button>
            </div>
          </div>
        </form>

        <div className="d-flex flex-column flex-md-row justify-content-center mt-2">
          <div className="mx-2 my-2 text-center">
            <GoogleLoginButton
              displayText={'Sign up with Google'}
              {...this.props}
            />
          </div>

          <div className="mx-2 my-2 text-center">
            <FacebookLoginButton
              displayText={'Sign up with Facebook'}
              {...this.props}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  hideNavbar,
  hideFooter,
  showFooter,
  showNavbar,
})(SignUp);
