import React, { Component } from 'react';
import StartupListingsTableItem from './StartupListingsTableItem';
import { CircularProgress } from '@material-ui/core';

class StartupListingsTable extends Component {
  state = {};
  render() {
    let { loadingStartups } = this.props;
    return (
      <div className="table-responsive shadow">
        <table className="table">
          <thead className="bg-admin-primary text-white h7">
            <tr>
              <th>Count</th>
              <th>Startup ID</th>
              <th>Startup Name</th>
              <th>Location</th>
              <th>Date Listed</th>
              <th>Investment Needed</th>
              <th>Status</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Sector</th>
              <th>Plan</th>
              <th>Lister First Name</th>
              <th>Lister Last Name</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {loadingStartups ? (
              <tr>
                <td>
                  <CircularProgress color="primary" size={'1rem'} />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ) : (
              this.props.startups.map((startup, index) => (
                <StartupListingsTableItem
                  key={startup.id}
                  startup={startup}
                  number={this.props.startups.length - index}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default StartupListingsTable;
