import React from 'react';
import PlanCard from '../../common/components/PlanCard';
import planTypes from '../../common/utils/planTypes';
import { connect } from 'react-redux';
import { selectPlan } from '../../state/actions/franchise';
import { planActionText } from '../../common/utils/helpers';

const FranchisePlans = props => {
  const onSelectPlan = plan => {
    if (props.isUpgrading || props.isDraft) {
      props.planChange(plan);
      return;
    }

    props.selectPlan(plan);
    props.history.push('/register-franchise');
  };

  const currentPlan =
    props.isUpgrading || props.isDraft ? props.currentPlan : props.selectedPlan;

  const actionText = planType => {
    return planActionText(props.isUpgrading, planType, props.currentPlan);
  };

  return (
    <div className="d-flex flex-wrap flex-lg-nowrap h-100 justify-content-center">
      <PlanCard
        name={planTypes.basicFranchise}
        amount="Free"
        onSelectPlan={onSelectPlan}
        selectedPlan={currentPlan}
        actionText={actionText(planTypes.professionalFranchise)}
        disabled={props.isUpgrading ? true : false}
        features={[
          'Speedy profile activation',
          <span>
            Notify up to <b>2 investors and distributors</b> on Compex Africa
            with your business opportunity
          </span>,
          '1 year validity',
        ]}
        className="p-4 bg-white rounded shadow-lg shadow-lg-right mt-lg-5 mb-5 plan"
        style={{
          width: '319px',
          maxWidth: '364px',
          height: '395px',
          minHeight: '395px',
        }}
      />
      <div className="d-block d-lg-none d-xl-none w-100"></div>
      <PlanCard
        name={planTypes.premiumFranchise}
        amount="NGN 59,500"
        onSelectPlan={onSelectPlan}
        selectedPlan={currentPlan}
        actionText={actionText(planTypes.premiumFranchise)}
        disabled={false}
        recommended={true}
        features={[
          'Speedy profile activation',
          <span>
            Notify up to <b>80 investors and distributors</b> on Compex Africa
            with your business opportunity
          </span>,
          <span>
            Compex Africa sends your proposals to{' '}
            <b>top 50 investors and distributors</b> in your sector
          </span>,
          <span>
            Compex Africa promotes your profile actively in regular{' '}
            <b>Investor Newsletter</b>
          </span>,
          '1 year validity',
        ]}
        className="p-5 bg-white rounded shadow-lg mt-lg-3 mb-5 plan"
        style={{
          width: '319px',
          maxWidth: '364px',
          height: '574px',
          zIndex: '2',
        }}
        headerFontSize="h2a"
      />
      <div className="d-block d-lg-none d-xl-none w-100"></div>
      <PlanCard
        name={planTypes.professionalFranchise}
        amount="NGN 300,000"
        onSelectPlan={onSelectPlan}
        selectedPlan={currentPlan}
        actionText={actionText(planTypes.professionalFranchise)}
        disabled={false}
        features={[
          <span>
            <span>
              Everything in <b>Premium Plan</b>
            </span>
            &nbsp;
            <i className="fas fa-plus text-primary font-weight-bold"></i>
          </span>,
          <span>
            Notify <b>Unlimited investors and distributors</b> on Compex Africa
            with your business opportunity
          </span>,
        ]}
        className="p-4 bg-white rounded shadow-lg shadow-lg-right mt-lg-5 mb-5 plan"
        style={{
          width: '319px',
          maxWidth: '364px',
          height: '395px',
          minHeight: '395px',
        }}
      />
    </div>
  );
};

export default connect(
  state => ({
    selectedPlan: state.franchise.selectedPlan,
  }),
  { selectPlan },
)(FranchisePlans);
