import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import QuickStats from './QuickStats';
import BusinessListings from './BusinessListings';
import UserListings from './UserListings';
import FranchiseListings from './FranchiseListings';
import AdvisorListings from './AdvisorListings';
import InvestorListings from './InvestorListings';
import InvestorListingItemPage from '../investors/InvestorListingItemPage';
import AdvisorListingItemPage from '../advisors/AdvisorListingItemPage';
import FranchiseListingItemPage from '../franchise/FranchiseListingItemPage';
import UserDashboard from '../user/Dashboard';
import Coupons from './Coupons';
import BusinessListingItemPage from '../businesses/BusinessListingItemPage';
import userService from '../../common/services/userService';
import StartupListings from './StartupListings';
import CommunityPosts from './CommunityPosts';
import PostDetail from './PostDetail';

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardStatistics: {},
      subscriptionStatistics: {},
      loading: null,
      subscriptionLoader: null,
      isPaid: true,
      days: -1,
    };
  }

  onSwitch = async e => {
    const { days } = this.state;
    const { checked } = e.target;
    this.setState({
      subscriptionLoader: true,
      isPaid: checked,
    });

    const subscriptionStatistics = await userService.getSubscriptionStatistics(
      days,
      checked,
    );

    if (subscriptionStatistics) {
      this.setState({ subscriptionStatistics, isPaid: checked });
    }
    this.setState({
      subscriptionLoader: false,
    });
  };

  getSubscriptionStatistics = async e => {
    const days = parseInt(e.target.value);
    const { isPaid } = this.state;
    this.setState({
      days,
      subscriptionLoader: true,
    });
    const subscriptionStatistics = await userService.getSubscriptionStatistics(
      days,
      isPaid,
    );
    if (subscriptionStatistics) this.setState({ subscriptionStatistics });

    this.setState({
      subscriptionLoader: false,
    });
  };

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    const dashboardStatistics = await userService.getDashboardStatistics();
    const subscriptionStatistics = await userService.getSubscriptionStatistics(
      this.state.days,
      this.state.isPaid,
    );
    if (subscriptionStatistics) this.setState({ subscriptionStatistics });
    if (dashboardStatistics) this.setState({ dashboardStatistics });
    this.setState({
      loading: false,
    });
  }
  render() {
    const {
      dashboardStatistics,
      loading,
      subscriptionStatistics,
      subscriptionLoader,
      isPaid,
    } = this.state;
    return (
      <div>
        <div className="container px-lg-0 mx-auto">
          <div className="row">
            <div className="col-lg-3 d-none d-lg-block d-xl-block flex-column align-items-center">
              <div className="">
                <div className="card shadow my-5 rounded">
                  <div className="card-body p-5">
                    <Link
                      className="d-block h7 text-darkdash font-weight-bold py-2"
                      to={`/admin/dashboard`}
                    >
                      Dashboard
                    </Link>
                    <Link
                      className="d-block h7 text-darkdash font-weight-bold py-2"
                      to={`/admin/dashboard/users`}
                    >
                      Users
                    </Link>
                    <Link
                      className="d-block h7 text-darkdash font-weight-bold py-2"
                      to={`/admin/dashboard/businesses`}
                    >
                      Business listings
                    </Link>
                    <Link
                      className="d-block h7 text-darkdash font-weight-bold py-2"
                      to={`/admin/dashboard/investors`}
                    >
                      Investor listings
                    </Link>
                    <Link
                      className="d-block h7 text-darkdash font-weight-bold py-2"
                      to={`/admin/dashboard/advisors`}
                    >
                      Advisor listings
                    </Link>
                    <Link
                      className="d-block h7 text-darkdash font-weight-bold py-2"
                      to={`/admin/dashboard/franchises`}
                    >
                      Franchise listings
                    </Link>
                    <Link
                      className="d-block h7 text-darkdash font-weight-bold py-2"
                      to={`/admin/dashboard/startups`}
                    >
                      Startup listings
                    </Link>
                    <Link
                      className="d-block h7 text-darkdash font-weight-bold py-2"
                      to={`/admin/dashboard/community`}
                    >
                      Community
                    </Link>
                  </div>
                </div>

                <div className="card shadow my-5 rounded">
                  <div className="card-body p-5">
                    <button className=" button-as-link d-block h7 text-darkdash font-weight-bold py-2">
                      Introductions
                    </button>
                  </div>
                </div>

                <div className="card shadow my-5 rounded">
                  <div className="card-body p-5">
                    <button className="button-as-link d-block h7 text-darkdash font-weight-bold py-2">
                      Account
                    </button>
                    <button className="button-as-link d-block h7 text-darkdash font-weight-bold py-2">
                      Settings
                    </button>
                    {this.props.currentUser &&
                      this.props.currentUser.isSuperAdmin && (
                        <Link
                          to="/admin/dashboard/coupons"
                          className="d-block h7 text-darkdash font-weight-bold py-2"
                        >
                          Coupons
                        </Link>
                      )}
                    <Link
                      to="/logout"
                      className="d-block h7 text-darkdash font-weight-bold py-2"
                    >
                      Log Out
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 pt-lg-5 px-lg-0">
              <Switch>
                <Route
                  path={'/admin/dashboard/investors/:id'}
                  component={InvestorListingItemPage}
                />
                <Route
                  path={'/admin/dashboard/investors'}
                  render={props => <InvestorListings {...props} />}
                />
                <Route
                  path={'/admin/dashboard/advisors/:id'}
                  component={AdvisorListingItemPage}
                />
                <Route
                  path={'/admin/dashboard/advisors'}
                  render={props => <AdvisorListings {...props} />}
                />
                <Route
                  path={'/admin/dashboard/franchises/:id'}
                  component={FranchiseListingItemPage}
                />
                <Route
                  path={'/admin/dashboard/franchises'}
                  render={props => <FranchiseListings {...props} />}
                />
                <Route
                  path={'/admin/dashboard/startups'}
                  render={props => <StartupListings {...props} />}
                />
                <Route
                  path={'/admin/dashboard/community'}
                  render={props => <CommunityPosts {...props} />}
                />
                <Route
                  path={'/admin/dashboard/posts/:id'}
                  component={PostDetail}
                />
                <Route
                  path={'/admin/dashboard/users/:id'}
                  component={UserDashboard}
                />
                <Route
                  path={'/admin/dashboard/users'}
                  render={props => <UserListings {...props} />}
                />
                <Route
                  path={'/admin/dashboard/businesses/:id'}
                  component={BusinessListingItemPage}
                />
                <Route
                  path={'/admin/dashboard/businesses'}
                  render={props => <BusinessListings {...props} />}
                />
                <Route path={`/admin/dashboard/coupons`} component={Coupons} />

                <Route
                  path={'/admin/dashboard'}
                  render={props => (
                    <QuickStats
                      {...props}
                      dashboardStatistics={dashboardStatistics}
                      getSubscriptionStatistics={this.getSubscriptionStatistics}
                      subscriptionStatistics={subscriptionStatistics}
                      loading={loading}
                      subscriptionLoader={subscriptionLoader}
                      onSwitch={this.onSwitch}
                      isPaid={isPaid}
                    />
                  )}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  currentUser: state.user.currentUser,
}))(AdminDashboard);
