import React from 'react';

const Terms = () => {
  return (
    <div className="container custom-home py-5">
      <div className="d-flex flex-column align-items-center justify-content-center text-center bg-about mb-5">
        <p className="h1 text-subscribe-light font-weight-bold">
          Website Terms and Conditions
        </p>
      </div>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        By using www.compexafrica.com (our “Website”) or any service provided by
        COMPEXAFRICA (“COMPEXAFRICA”) via the Website, you hereby irrevocably
        and unconditionally agreed to these terms and conditions:
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        The Service
      </p>
      <p className="h7 line-height-5 mb-4">
        The Website has been designed to facilitate the ease of contact between
        buyers/investors and sellers of small and medium-sized businesses
        through the process of automated searching, shortlisting and contacting
        prospects (the “Service”). You are solely responsible for your
        interaction with other users of the Website (“Users”). <br></br>
        <br></br>You should contact other Users through the Website only if you
        have a genuine interest in the offering advertised on the Website by the
        relevant User and not for any other purpose.<br></br>
        <br></br>You are responsible for verifying all information provided in
        relation to any offering, taking appropriate safety measures and
        conducting your own due diligence before transacting with any other
        User. Your use of the Website is at your own risk and you assume full
        responsibility and risk of loss resulting from the use of thereof.
        <br></br>
        <br></br>COMPEXAFRICA reserves the right to decide the index order in
        which offerings are listed or displayed on the Website.
      </p>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        Our Privacy Policy
      </p>
      <p className="h7 line-height-5 mb-4">
        COMPEXAFRICA stores and uses personal information collected from
        visitors to the website in accordance with the Privacy Policy which is
        published on the Website.
      </p>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        We undertake to comply with the terms of our Privacy Policy which is
        published on the Website.
      </p>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        Intellectual Property Ownership
      </p>
      <p className="h7 line-height-5 mb-4">
        Any information, publication, design, product, process, software,
        technology, know-how, or idea described on this Website may be the
        subject of other rights, including other intellectual property rights,
        which are owned by COMPEXAFRICA or other interested parties and are not
        licensed to you hereunder. Any transfer, sale, distribution,
        reproduction, copying or modification of any material contained on this
        website (in whole or in part) is strictly prohibited. <br></br>
        <br></br>COMPEXAFRICA retains all right, title, and interest in the
        Service and the Website and the corresponding intellectual property
        rights and reserves all rights not explicitly granted.
      </p>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        Copyright
      </p>
      <p className="h7 line-height-5 mb-4">
        Copyright in the Service and the Website (including text, graphics,
        logos, icons, sound recordings and software) is owned or licensed by
        COMPEXAFRICA. Other than for the purposes of, and subject to the
        conditions prescribed under, the Nigerian Copyright Act Cap. C28 Laws of
        the Federation of Nigeria 2004 and similar legislation which applies in
        your location, and except as expressly authorised by these Terms and
        Conditions, you may not in any form or by any means: <br></br>
        <br></br>
        (a) adapt, reproduce, store, distribute, print, display, perform,
        publish, republish or create derivative works from any part of the
        Service or the Website without COMPEXAFRICA’s express prior written; or
        <br></br>
        <br></br>(b) Commercialise any information, products or services
        obtained from any part of the Service or the Website without
        COMPEXAFRICA’ express prior written consent.
      </p>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        Trademarks
      </p>
      <p className="h7 line-height-5 mb-4">
        Except where otherwise specified, any work or device to which is
        attached the symbol [please insert the impression of COMPEXAFRICA’
        trademark] is a registered trade mark. If you use COMPEXAFRICA’
        trademarks in reference to your activities, products or services, you
        must include a statement attributing the trademark to COMPEXAFRICA. You
        must not use COMPEXAFRICA’ trademark:
        <br></br>
        <br></br>
        (a) In or as the whole or part of your own trademarks;
        <br></br>
        <br></br>
        (b) In connection with activities, products or services which are not
        ours;
        <br></br>
        <br></br>
        (c) In a manner which may be confusing, misleading or deceptive; or
        <br></br>
        <br></br>
        (d) In a manner that disparages us, our information, products or
        services (including without limitation, the Service and the Website).
      </p>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        Intellectual Property in Information Provided
      </p>
      <p className="h7 line-height-5 mb-4">
        You agree that all copyright which subsists in any material or
        information provided by you to COMPEXAFRICA is assigned to COMPEXAFRICA.
        You will not post, transmit or otherwise provide any material or
        information in which the copyright is owned by another person or entity
        and you warrant that all material and information provided is your
        original work and not sourced from any third-party.<br></br>
        <br></br>
        If you believe that the content of any material uploaded or posted onto
        the Website infringes your intellectual property rights, please notify
        us by email to enable us take corrective measures.
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        5. Legal Access
      </p>
      <p className="h7 line-height-5 mb-4">
        You agree to ensure that your access to the Website and use of the
        Service is not illegal or prohibited by laws which apply to you. You are
        solely responsible for the legality of your actions under all applicable
        laws.<br></br>
        <br></br>
        Subsequent to the activation of your profile, you may update same with
        new information. However, changing the profile materially in such a way
        that it represents a different business, is prohibited.
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        6. Full Authorisation
      </p>
      <p className="h7 line-height-5 mb-4">
        By using the Website, you represent and warrant, to the extent that you
        are acting on behalf of a person or entity, that you have the full
        authorisation of such person of business to use the Service or the
        Website in contemplation of a proposed transaction.
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        7. Negative Covenants
      </p>
      <p className="h7 line-height-5 mb-4">
        You represent, warrant and covenant that:<br></br>
        <br></br>
        (a) you will not disclose any information provided to you through the
        Service to anyone without the prior permission of the person or entity
        that provided it to you;<br></br>
        <br></br>(b) you will not use the Service to engage in any form of
        harassment or offensive behaviour, including but not limited to the
        distribution of any sexually and/or racially offensive, abusive,
        threatening, vulgar, obscene, harassing, libelous, slanderous, or
        objectionable material of any kind, nor any unlawful or illegal
        material, or any material that infringes or violates another party's
        rights (including intellectual property rights and privacy rights);
        <br></br>
        <br></br>
        (c) you will not transmit "spam," chain letters, junk mail, or engage in
        any unsolicited mass distribution of e-mail;
        <br></br>
        <br></br>
        (d) you will not use any "deep-link", "page-scrape", "robot", "spider"
        or other automatic devices, program, algorithm or methodology, or any
        similar or equivalent manual process, to access, acquire, copy or
        monitor any portion or content of the Website;
        <br></br>
        <br></br>
        (e) you will not use the Service or the Website for any unauthorised
        commercial purposes;
        <br></br>
        <br></br>
        (f) you will not solicit or attempt to solicit any money from any member
        or other user of the Service or the Website;
        <br></br>
        <br></br>
        (g) you will not use the Service to distribute, promote or otherwise
        publish any material containing any solicitation for funds, advertising
        or solicitation for goods or services
        <br></br>
        <br></br>
        (h) you will not harass other Users by continuing to attempt to
        communicate with someone who has clearly asked you to cease
        communications;
        <br></br>
        <br></br>
        (i) you will not post or transmit material which contains viruses or
        other computer codes, files or programs which are designed to limit or
        destroy the functionality of computer software or hardware;
        <br></br>
        <br></br>
        (j) you will not offer any form of public securities, shares, bonds, or
        anything similar through the Website.
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        8. Information
      </p>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        8.1. Monitoring Information
      </p>
      <p className="h7 line-height-5 mb-4">
        We reserve the right to monitor all profiles, messages, chat, instant
        messages, videos and audio recordings to ensure that they conform to the
        requirements of these Terms and Conditions. You expressly acknowledge
        and agree to our collection, whether directly or by third-party security
        providers, of certain non-personal data to be used for the sole purpose
        of ensuring a secure and safe online environment for you. Even though no
        personal data is collected, we ensure that any third parties with which
        it contracts for security services observes the highest data protection
        and privacy standards.<br></br>
        <br></br>
        COMPEXAFRICA has no responsibility or liability for the deletion or
        failure to store any content maintained or transmitted by the Website or
        the service.
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        8.2. Security of Information
      </p>
      <p className="h7 line-height-5 mb-4">
        We undertake to take all reasonable steps to preserve the security of
        Users’ personal data transmitted to us through the Website in accordance
        with the Nigerian Data Protection Regulations 2019.
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        9. Disclaimer
      </p>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        9.1. No Investment Advice
      </p>
      <p className="h7 line-height-5 mb-4">
        Profile advertisements and other materials posted on the Website are not
        intended to amount to any form of investment advice from [COMPEXAFRICA].
        COMPEXAFRICA is not a broker or the agent of any User, and COMPEXAFRICA
        does not partake in any kind of negotiations or discussions between the
        Users.
      </p>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        9.2. No Warranty as to Completeness of Information
      </p>
      <p className="h7 line-height-5 mb-4">
        Accordingly, COMPEXAFRICA makes no representations or warranties that
        the material or information provided through the Service and on the
        Website (including any contact profile, advice, opinion, statement or
        other information displayed, uploaded or distributed by COMPEXAFRICA or
        any party) is reliable, accurate or complete or that your access to the
        Service or the Website will be uninterrupted, timely or secure. We are
        not liable for any loss arising from any action taken or reliance made
        by you on any information or material provided through the Service or on
        the Website. You should make your own enquiries before acting or relying
        on any information or material which appears on the Website. You
        acknowledge that any reliance upon any material or information shall be
        at your own risk.
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        9.3. No Warranty as to Availability
      </p>
      <p className="h7 line-height-5 mb-4">
        The Service is distributed on an “as is” basis. We do not warrant that
        the Service or the Website will be uninterrupted or error-free. There
        may be delays omissions, and interruptions in the availability of the
        Service or the Website. Where permitted by law, you acknowledge that the
        Service (and the availability of the Website) is provided without any
        warranties of any kind whatsoever, either express or implied, including
        but not limited to the implied warranties of merchantability and fitness
        for a particular purpose.
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        9.4. Implied Warranties Excluded
      </p>
      <p className="h7 line-height-5 mb-4">
        To the extent permitted by law, any condition or warranty which would
        otherwise be implied into these Terms and Conditions is hereby excluded.
        Where legislation implies any condition of warranty, and that
        legislation prohibits us from excluding or modifying the application of,
        or our liability under, any such condition or warranty, that condition
        or warranty will be deemed included by our liability will be limited for
        a breach of that condition or warranty to the following:
        <br></br>
        <br></br>
        (a) If the breach relates to services
        <br></br>
        <br></br>
        (i) The supply of the services again; or
        <br></br>
        <br></br>
        (ii) The payment of the cost of having the services supplied again.
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        9.5. No Liability for Loss
      </p>
      <p className="h7 line-height-5 mb-4">
        We do not accept responsibility for any loss or damage, however caused
        (including through negligence), which you may directly or indirectly
        suffer in connection with your use of the Service or the Website, nor do
        we accept any responsibility for any loss arising out of your use of, or
        reliance on, information contained in or accessed through the Service or
        the Website. For the avoidance of doubt and without limiting the
        generality of the foregoing:
        <br></br>
        <br></br>
        (a) COMPEXAFRICA does not accept any responsibility or liability for any
        direct, indirect, tentative, incidental, special or consequential
        damages arising out of or in any way connected with your use of the
        Service or the Website or with any delay or inability to use the Service
        or the Website, or for any information obtained through the Service or
        the Website, or otherwise arising out of the use of the Website, whether
        based under contract, negligence or other tort, strict liability or
        otherwise, even if COMPEXAFRICA has been advised of the possibility of
        such damage;
        <br></br>
        <br></br>
        (b) COMPEXAFRICA does not accept any responsibility or liability for any
        information or material which you submit to the Website nor do we accept
        any responsibility for any use or misuse of any information or material
        which you submit to the Service or the Website by other Users;
        <br></br>
        <br></br>
        (c) COMPEXAFRICA does not accept any responsibility or liability for the
        conduct of any User, including without limitation any conduct which
        causes physical injury to any person;
        <br></br>
        <br></br>
        (d) Any advertisement/profile on the Website does not constitute a
        binding offer so that acceptance by the User can bind the party
        advertising on the Website;
        <br></br>
        <br></br>
        (e) Certain links in the Website lead to resources maintained by
        third-parties over whom COMPEXAFRICA has no control and makes no
        representations or warranties as to the accuracy of, or any other aspect
        relating to, those resources.
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        10. Termination of Your Access to Service
      </p>
      <p className="h7 line-height-5 mb-4">
        COMPEXAFRICA may, in its absolute discretion, terminate or suspend your
        access to all or part of the Service or the Website at any time, with or
        without notice, for any reason, including without limitation, any
        fraudulent, abusive, or otherwise illegal activity, or that which may
        otherwise affect the enjoyment of the Service by others.
        <br></br>
        <br></br>
        You agree that COMPEXAFRICA shall not be liable to you or to any
        third-party for any modification, suspension or discontinuance of the
        Service.
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        11. Indemnity and Release
      </p>
      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        11.1. Indemnity
      </p>
      <p className="h7 line-height-5 mb-4">
        You agree to indemnify, defend and hold COMPEXAFRICA, its affiliates,
        related bodies corporate, shareholders, officers, employees, agents and
        representatives harmless from and against any and all claims, loss,
        damage, liability and/or expense (including legal costs on a full
        indemnity basis) that may be incurred by COMPEXAFRICA, its affiliates,
        related bodies corporate, shareholders, officers, employees, agents and
        representatives arising out of or in connection with your use of the
        Service or the Website, including without limitation any breach by you
        of these Terms and Conditions or claims made by other Users against you.
        You agree to cooperate fully in COMPEXAFRICA’ defence of any claim. We
        reserve the right (but are under no obligation) to assume the exclusive
        defence and control of any matter otherwise subject to indemnification
        by you, provided that you shall remain liable for any such claim.
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        11.2. Release
      </p>
      <p className="h7 line-height-5 mb-4">
        You hereby waive, release, forgive, discharge and relinquish any and all
        claims that you now have or may have against COMPEXAFRICA, its
        affiliates, related bodies corporate, shareholders, officers, employees,
        agents and representatives which are connected with, arise out of or
        relate directly or indirectly to your use of the Service or the Website.
      </p>

      <p className="h7 line-height-5 text-primary font-weight-bold mb-4">
        12. Governing Law
      </p>
      <p className="h7 line-height-5 mb-4">
        These Terms and Conditions are governed by the laws of the Federal
        Republic of Nigeria. You hereby agree to submit to the exclusive
        jurisdiction of the courts of the Federal Republic of Nigeria.
      </p>
    </div>
  );
};

export default Terms;
