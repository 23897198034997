import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { editListing, resetForm } from '../../state/actions/business';
import AddBusinessForm2 from './AddBusinessForm2';
import AddBusinessForm3 from './AddBusinessForm3';
import AddBusinessForm4 from './AddBusinessForm4';
import AddBusinessForm1 from './AddBusinessForm1';
import BusinessPlans from './BusinessPlans';
import { setHeaders, resetHeaders } from './../../state/actions/layout';
import Covid19Form from './Covid19Form';

class AddBusiness extends Component {
  componentDidMount() {
    this.props.setHeaders({
      title: 'Register business for sale',
      url: this.props.history.location.pathname,
    });
    const values = queryString.parse(this.props.location.search);
    const id = values.id;

    if (isNaN(Number(id))) return;
  }

  componentWillUnmount() {
    this.props.resetForm();
    this.props.resetHeaders();
  }

  render() {
    return (
      <div>
        <Switch>
          <Route
            path="/register-business/plans"
            component={() => {
              return (
                <div className="container">
                  <div className="w-100 text-center font-weight-bold text-pricing h2a mb-5">
                    Choose a business plan
                  </div>
                  <BusinessPlans {...this.props} />
                </div>
              );
            }}
          />
          <Route
            path="/register-business/intervention-plans"
            component={() => {
              return (
                <div className="container">
                  <div className="w-100 text-center font-weight-bold text-pricing h2a mb-5">
                    Choose a business plan
                  </div>
                  <BusinessPlans {...this.props} covidIntervention={true} />
                </div>
              );
            }}
          />
          <Route
            path="/register-business/intervention"
            component={Covid19Form}
          />
          <Route path="/register-business/2" component={AddBusinessForm2} />
          <Route path="/register-business/3" component={AddBusinessForm3} />
          <Route path="/register-business/4" component={AddBusinessForm4} />
          <Route path="/" component={AddBusinessForm1} />
        </Switch>
      </div>
    );
  }
}

export default connect(null, {
  editListing,
  resetForm,
  setHeaders,
  resetHeaders,
})(AddBusiness);
