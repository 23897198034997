import React, { Component } from 'react';
import Joi from 'joi-browser';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import auth from '../../common/services/authService';
import { CircularProgress } from '@material-ui/core';
import queryString from 'query-string';
import {
  hideNavbar,
  hideFooter,
  showNavbar,
  showFooter,
} from './../../state/actions/layout';
import FormAlertMessage from '../../common/components/FormAlertMessage';

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {
        password: '',
        confirmPassword: '',
      },
      email: '',
      token: '',
      errors: {},
      unmatchError: false,
      loading: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.props.hideNavbar();
    this.props.hideFooter();

    const values = queryString.parse(this.props.location.search);
    const { email, token } = values;

    this.setState({ email, token });
  }

  schema = {
    password: Joi.string()
      .min(6)
      .required()
      .label('Password'),
    confirmPassword: Joi.string()
      .min(6)
      .required()
      .label('Password'),
  };

  validate() {
    const { error } = Joi.validate(this.state.account, this.schema, {
      abortEarly: false,
    });
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  componentWillUnmount() {
    this.props.showNavbar();
    this.props.showFooter();
  }

  onChange = e => {
    const { name, value } = e.target;
    const account = { ...this.state.account };
    account[name] = value;
    const errors = { ...this.state.errors };
    errors[name] = '';
    this.setState({ account, errors });
  };

  onSubmit = async e => {
    e.preventDefault();

    const { password, confirmPassword } = this.state.account;
    const errors = this.validate();

    this.setState({ errors: errors || {} });

    if (errors) return;

    if (password !== confirmPassword) {
      this.setState({ unmatchError: true });
      return;
    } else {
      this.setState({ unmatchError: false });
    }

    try {
      const { email, token } = this.state;
      this.setState({ loading: true });
      const result = await auth.resetPassword(email, password, token);
      if (result) {
        this.props.history.replace('/password-reset-success');
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({ loading: false });

      const { response } = error;

      if (response && response.data && response.data.title) {
        let message = response.data.title;
        this.setState({ errorMessage: message });
      }
    }
  };

  render() {
    return (
      <div className="p-3">
        <div className="d-flex justify-content-center fixed-top justify-content-md-between align-items-center px-5 pt-3 mb-3 bg-body">
          <Link to="/" className="nav-link">
            <img
              className="compex-logo"
              src={require('../../assets/img/compex-logo.svg')}
              alt=""
            />
          </Link>
          <div className="d-none d-md-block">
            <Link to="/login">
              <span className="text-primary font-weight-bold px-2">
                Sign in
              </span>
            </Link>
          </div>
        </div>
        <div className="min-vh-100">
          <div className="text-center mb-5">
            <h3 className="text-primary">Change Password</h3>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-4">
              <form
                onSubmit={this.onSubmit}
                method="POST"
                className="bg-white shadow p-5 rounded font-weight-bold"
                noValidate
              >
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label htmlFor="password">Enter New Password</label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={this.state.account.password}
                      onChange={this.onChange}
                      className={
                        this.state.errors['password']
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                    />
                    <div className="invalid-feedback">
                      {this.state.errors['password']}
                    </div>
                  </div>

                  <div className="form-group col-md-12">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={this.state.account.confirmPassword}
                      onChange={this.onChange}
                      className={
                        this.state.errors['confirmPassword']
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                    />
                    <div className="invalid-feedback">
                      {this.state.errors['confirmPassword']}
                    </div>
                    {this.state.unmatchError && (
                      <div className="text-danger small py-2">
                        Confirm password must match new password
                      </div>
                    )}
                  </div>
                </div>
                {this.state.errorMessage && (
                  <FormAlertMessage message={this.state.errorMessage} />
                )}
                <div className="row justify-content-center py-3">
                  <div className="col-md-6">
                    <button type="submit" className="btn btn-primary btn-block">
                      {this.state.loading ? (
                        <CircularProgress color="inherit" size={'1rem'} />
                      ) : (
                        'Submit'
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  hideNavbar,
  hideFooter,
  showFooter,
  showNavbar,
})(ResetPassword);
