import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './pages/home/Home';
import SignUp from './pages/auth/SignUp';
import Login from './pages/auth/Login';
import AddBusiness from './pages/businesses/AddBusiness';
import BusinessListings from './pages/businesses/BusinessListings';
import UserDashboard from './pages/user/Dashboard';
import VerifyEmail from './pages/auth/VerifyEmail';
import ConfirmUserEmail from './pages/auth/ConfirmUserEmail';
import UserEmailConfirmSuccess from './pages/auth/UserEmailConfirmSuccess';
import SuccessfulListing from './pages/businesses/SuccessfulListing';
import RegisterInvestor from './pages/investors/RegisterInvestor';
import ProtectedRoute from './common/components/ProtectedRoute';
import AdminDashboard from './pages/admin/Dashboard';
import AdminRoute from './common/components/AdminRoute';
import InvestorListings from './pages/investors/InvestorListings';
import AdvisorListings from './pages/advisors/AdvisorListings';
import FranchiseListings from './pages/franchise/FranchiseListings';
import AdvisorListingItemPage from './pages/advisors/AdvisorListingItemPage';
import InvestorListingItemPage from './pages/investors/InvestorListingItemPage';
import FranchiseListingItemPage from './pages/franchise/FranchiseListingItemPage';
import PageNotFound from './common/components/PageNotFound';
import BusinessListingItemPage from './pages/businesses/BusinessListingItemPage';
import ConfirmListingEmail from './common/components/ConfirmListingEmail';
import About from './pages/footer/About';
import Privacy from './pages/footer/Privacy';
import Refunds from './pages/footer/Refunds';
import Terms from './pages/footer/Terms';
import Contact from './pages/footer/Contact';
import Careers from './pages/footer/Careers';
import Faq from './pages/footer/Faq';
import ForgotPassword from './pages/auth/ForgotPassword';
import RequestPasswordSuccess from './pages/auth/RequestPasswordSuccess';
import ResetPassword from './pages/auth/ResetPassword';
import ResetPasswordSuccess from './pages/auth/ResetPasswordSuccess';
import DetailsFooter from './common/components/DetailsFooter';
import AddFranchise from './pages/franchise/AddFranchise';
import AddAdvisor from './pages/advisors/AddAdvisor';
// import Covid19Listing from './pages/businesses/Covid19Listing';

const Routes = () => {
  return (
    <Switch>
      <Route path="/about" component={About} />
      <Route path="/contact" component={Contact} />
      <Route path="/faq" component={Faq} />
      <Route path="/careers" component={Careers} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/terms" component={Terms} />
      <Route path="/Refunds" component={Refunds} />
      <Route path="/user/registration-success" component={VerifyEmail} />
      <Route path="/user/confirm-email" component={ConfirmUserEmail} />
      <Route
        path="/user/confirm-email-success"
        component={UserEmailConfirmSuccess}
      />
      <Route path="/confirm-listing-email" component={ConfirmListingEmail} />
      <ProtectedRoute path="/user" component={UserDashboard} />
      <Route path="/signup" component={SignUp} />
      <Route path="/verifyemail" component={VerifyEmail} />
      <Route path="/login" component={Login} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route
        path="/request-password-success"
        component={RequestPasswordSuccess}
      />
      <Route path="/password-reset-success" component={ResetPasswordSuccess} />
      <Route path="/password-reset" component={ResetPassword} />
      <Route
        path="/businesses/:id"
        render={props => (
          <>
            <div className="container">
              <BusinessListingItemPage {...props} />
            </div>
            <DetailsFooter />
          </>
        )}
      />
      <Route path="/businesses" component={BusinessListings} />
      <Route
        path="/franchises/:id"
        render={props => (
          <>
            <div className="container">
              <FranchiseListingItemPage {...props} />
            </div>
            <DetailsFooter />
          </>
        )}
      />
      <Route path="/franchises" component={FranchiseListings} />
      <Route
        path="/advisors/:id"
        render={props => (
          <>
            <div className="container">
              <AdvisorListingItemPage {...props} />
            </div>
            <DetailsFooter />
          </>
        )}
      />
      <Route path="/advisors" component={AdvisorListings} />
      <Route
        path="/investors/:id"
        render={props => (
          <>
            <div className="container">
              <InvestorListingItemPage {...props} />
            </div>
            <DetailsFooter />
          </>
        )}
      />
      <Route path="/investors" component={InvestorListings} />
      <Route path="/lenders" component={InvestorListings} />
      <ProtectedRoute path="/register-investor" component={RegisterInvestor} />
      <AdminRoute path="/admin" component={AdminDashboard} />
      <ProtectedRoute path="/register-franchise" component={AddFranchise} />
      <ProtectedRoute path="/register-advisor" component={AddAdvisor} />
      <ProtectedRoute path="/register-business" component={AddBusiness} />
      {/* <Route path="/covid-intervention" component={Covid19Listing} /> */}
      <ProtectedRoute
        path="/listingsuccessful"
        exact
        component={SuccessfulListing}
      />
      <Route exact path="/" component={Home} />
      <Route path="*" exact component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
