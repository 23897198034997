import {
  FETCH_LISTING_DETAILS,
  FETCH_FEATURED_LISTINGS,
  RESETFORMS,
  EDITLISTING,
  FETCH_ADMIN_LISTINGS,
  FETCH_ADMIN_LISTINGS_STARTED,
  FETCH_BUSINESS_LISTINGS,
  UPDATE_BUSINESSES,
  SELECT_PLAN,
} from '../actions/types';

const initialState = {
  formdata: {},
  businessListings: [],
  loadingListings: false,
  featuredListings: [],
  advisorListings: [],
  franchiseListings: [],
  adminBusinessListings: [],
  listingDetails: {},
  pageNumber: 1,
  pageSize: 50,
  totalCount: 0,
  selectedPlan: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUSINESS_LISTINGS:
      const { data, pageNumber, pageSize, totalCount } = action.payload;
      return {
        ...state,
        businessListings: data,
        pageNumber,
        pageSize,
        totalCount,
      };

    case FETCH_FEATURED_LISTINGS:
      return {
        ...state,
        featuredListings: action.payload.data,
      };
    case UPDATE_BUSINESSES:
      return {
        ...state,
        businessListings: action.payload.data,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount,
      };
    case FETCH_ADMIN_LISTINGS:
      return {
        ...state,
        adminBusinessListings: action.payload.data,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount,
        loadingListings: false,
      };
    case FETCH_ADMIN_LISTINGS_STARTED:
      return {
        ...state,
        loadingListings: true,
      };
    case FETCH_LISTING_DETAILS:
      return {
        ...state,
        listingDetails: action.payload,
      };
    case EDITLISTING:
      return {
        ...state,
        formdata: action.payload,
      };
    case RESETFORMS:
      return {
        ...state,
        formdata: {},
        selectedPlan: '',
      };
    case SELECT_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };
    default:
      return state;
  }
};
