import React from 'react';
import FadeIn from 'react-fade-in';
import { CircularProgress, Switch } from '@material-ui/core';

const QuickStats = ({
  dashboardStatistics,
  loading,
  getSubscriptionStatistics,
  subscriptionLoader,
  subscriptionStatistics,
  onSwitch,
  isPaid,
}) => {
  return (
    <div className="custom-center">
      {
        <FadeIn>
          <div className="ml-lg-5">
            <h1>Compex Africa Overview</h1>
            <div className="w-50 my-3 border"></div>
            {dashboardStatistics && (
              <div className="d-flex flex-wrap mb-5">
                <div className="w-100 w-lg-30 w-md-30 text-center bg-white shadow rounded-1 p-4 m-2">
                  <p className="h6">Total Users</p>
                  <div className="h2 font-weight-bold text-primary">
                    {loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      dashboardStatistics && dashboardStatistics.totalUsers
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="text-center">
                      <div className="h7 text-primary">
                        {loading ? (
                          <CircularProgress color="primary" size={'1rem'} />
                        ) : (
                          dashboardStatistics &&
                          dashboardStatistics.totalUsersLast7Days
                        )}
                      </div>
                      <p className="h8">Last 7 days</p>
                    </div>
                    <div className="text-center">
                      <div className="h7 text-primary">
                        {loading ? (
                          <CircularProgress color="primary" size={'1rem'} />
                        ) : (
                          dashboardStatistics &&
                          dashboardStatistics.totalUsersLast30Days
                        )}
                      </div>
                      <p className="h8">Last 30 days</p>
                    </div>
                  </div>
                </div>
                <div className="d-block d-lg-none d-md-none w-100"></div>
                <div className="w-100 w-lg-30 w-md-30 text-center bg-white shadow rounded-1 p-4 m-2">
                  <p className="h6">Total Listings</p>
                  <div className="h2 font-weight-bold text-primary">
                    {loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      dashboardStatistics && dashboardStatistics.totalListings
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="text-center">
                      <div className="h7 text-primary">
                        {loading ? (
                          <CircularProgress color="primary" size={'1rem'} />
                        ) : (
                          dashboardStatistics &&
                          dashboardStatistics.totalListingsLast7Days
                        )}
                      </div>
                      <p className="h8">Last 7 days</p>
                    </div>
                    <div className="text-center">
                      <div className="h7 text-primary">
                        {loading ? (
                          <CircularProgress color="primary" size={'1rem'} />
                        ) : (
                          dashboardStatistics &&
                          dashboardStatistics.totalListingsLast30Days
                        )}
                      </div>
                      <p className="h8">Last 30 days</p>
                    </div>
                  </div>
                </div>
                <div className="d-block d-lg-none d-md-none w-100"></div>
                <div className="w-100 w-lg-30 w-md-30 text-center bg-white shadow rounded-1 p-4 m-2">
                  <p className="h6">Total connections</p>
                  <div className="h2 font-weight-bold text-primary">
                    {loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      dashboardStatistics &&
                      dashboardStatistics.totalConnections
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="text-center">
                      <div className="h7 text-primary">
                        {loading ? (
                          <CircularProgress color="primary" size={'1rem'} />
                        ) : (
                          dashboardStatistics &&
                          dashboardStatistics.totalConnectionsLast7Days
                        )}
                      </div>
                      <p className="h8">Last 7 days</p>
                    </div>
                    <div className="text-center">
                      <div className="h7 text-primary">
                        {loading ? (
                          <CircularProgress color="primary" size={'1rem'} />
                        ) : (
                          dashboardStatistics &&
                          dashboardStatistics.totalConnectionsLast30Days
                        )}
                      </div>
                      <p className="h8">Last 30 days</p>
                    </div>
                  </div>
                </div>
                <div className="d-block d-lg-none d-md-none w-100"></div>
                <div className="w-100 w-lg-30 w-md-30 text-center bg-white shadow rounded-1 p-4 m-2">
                  <p className="h6">Total Businesses </p>
                  <div className="h2 font-weight-bold text-primary">
                    {loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      dashboardStatistics && dashboardStatistics.totalBusinesses
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="text-center">
                      <div className="h7 text-primary">
                        {loading ? (
                          <CircularProgress color="primary" size={'1rem'} />
                        ) : (
                          dashboardStatistics &&
                          dashboardStatistics.totalBusinessesLast7Days
                        )}
                      </div>
                      <p className="h8">Last 7 days</p>
                    </div>
                    <div className="text-center">
                      <div className="h7 text-primary">
                        {loading ? (
                          <CircularProgress color="primary" size={'1rem'} />
                        ) : (
                          dashboardStatistics &&
                          dashboardStatistics.totalBusinessesLast30Days
                        )}
                      </div>
                      <p className="h8">Last 30 days</p>
                    </div>
                  </div>
                </div>
                <div className="d-block d-lg-none d-md-none w-100"></div>
                <div className="w-100 w-lg-30 w-md-30 text-center bg-white shadow rounded-1 p-4 m-2">
                  <p className="h6 text-success">Approved Listings</p>
                  <div className="h2 font-weight-bold text-primary">
                    {loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      dashboardStatistics && dashboardStatistics.approvedListing
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="text-center">
                      <div className="h7 text-primary">
                        {loading ? (
                          <CircularProgress color="primary" size={'1rem'} />
                        ) : (
                          dashboardStatistics &&
                          dashboardStatistics.approvedListingLast7Days
                        )}
                      </div>
                      <p className="h8">Last 7 days</p>
                    </div>
                    <div className="text-center">
                      <div className="h7 text-primary">
                        {loading ? (
                          <CircularProgress color="primary" size={'1rem'} />
                        ) : (
                          dashboardStatistics &&
                          dashboardStatistics.approvedListingLast30Days
                        )}
                      </div>
                      <p className="h8">Last 30 days</p>
                    </div>
                  </div>
                </div>
                <div className="d-block d-lg-none d-md-none w-100"></div>
                <div className="w-100 w-lg-30 w-md-30 text-center bg-white shadow rounded-1 p-4 m-2">
                  <p className="h6 text-danger">Pending Listings</p>
                  <div className="h2 font-weight-bold text-primary">
                    {loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      dashboardStatistics && dashboardStatistics.pendingListing
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="text-center">
                      <div className="h7 text-primary">
                        {loading ? (
                          <CircularProgress color="primary" size={'1rem'} />
                        ) : (
                          dashboardStatistics &&
                          dashboardStatistics.pendingListingLast7Days
                        )}
                      </div>
                      <p className="h8">Last 7 days</p>
                    </div>
                    <div className="text-center">
                      <div className="h7 text-primary">
                        {loading ? (
                          <CircularProgress color="primary" size={'1rem'} />
                        ) : (
                          dashboardStatistics &&
                          dashboardStatistics.pendingListingLast30Days
                        )}
                      </div>
                      <p className="h8">Last 30 days</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="w-50 my-3 border"></div>

            <div className="dropdown m-2">
              <select
                onChange={e => {
                  getSubscriptionStatistics(e);
                }}
              >
                <option className="dropdown-item cursor-pointer" value={-1}>
                  All Time
                </option>
                <option className="dropdown-item cursor-pointer" value={0}>
                  Today
                </option>
                <option className="dropdown-item cursor-pointer" value={7}>
                  Last 7 Days
                </option>
                <option className="dropdown-item cursor-pointer" value={30}>
                  Last 30 Days
                </option>
              </select>
              <div className="d-inline ml-3">
                <Switch
                  color="primary"
                  name="renewal"
                  value={isPaid}
                  checked={isPaid}
                  onChange={e => onSwitch(e)}
                />
                {isPaid ? 'PAID' : 'FREE'}
              </div>
            </div>

            <div className="d-flex flex-wrap mb-5">
              <div className="w-100 w-lg-30 w-md-30 text-center bg-white shadow rounded-1 p-4 m-2">
                <p className="h8a font-weight-bold text-darkdash mb-3">
                  Business Plan Subscribers
                </p>
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Basic Plan
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.businessPlanStats &&
                      subscriptionStatistics.businessPlanStats.totalBasicPlan
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Active Plan
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.businessPlanStats &&
                      subscriptionStatistics.businessPlanStats.totalActivePlan
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Premium Plan
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.businessPlanStats &&
                      subscriptionStatistics.businessPlanStats.totalPremiumPlan
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Professional Plan
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.businessPlanStats &&
                      subscriptionStatistics.businessPlanStats
                        .totalProfessionalPlan
                    )}
                  </div>
                </div>
                <div className="w-100 border-bottom border-darkdash my-2"></div>

                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Total
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.businessPlanStats &&
                      subscriptionStatistics.businessPlanStats.total
                    )}
                  </div>
                </div>
              </div>

              <div className="w-100 w-lg-30 w-md-30 text-center bg-white shadow rounded-1 p-4 m-2">
                <p className="h8a font-weight-bold text-darkdash mb-3">
                  Investor Plan Subscribers
                </p>
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Basic Plan
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.investorPlanStats &&
                      subscriptionStatistics.investorPlanStats.totalBasicPlan
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Active Plan
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.investorPlanStats &&
                      subscriptionStatistics.investorPlanStats.totalActivePlan
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Premium Plan
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.investorPlanStats &&
                      subscriptionStatistics.investorPlanStats.totalPremiumPlan
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Professional Plan
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.investorPlanStats &&
                      subscriptionStatistics.investorPlanStats
                        .totalProfessionalPlan
                    )}
                  </div>
                </div>
                <div className="w-100 border-bottom border-darkdash my-2"></div>

                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Total
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.investorPlanStats &&
                      subscriptionStatistics.investorPlanStats.total
                    )}
                  </div>
                </div>
              </div>

              <div className="w-100 w-lg-30 w-md-30 text-center bg-white shadow rounded-1 p-4 m-2">
                <p className="h8a font-weight-bold text-darkdash mb-3">
                  Advisor Plan Subscribers
                </p>
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Basic Plan
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.advisorPlanStats &&
                      subscriptionStatistics.advisorPlanStats.totalBasicPlan
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Active Plan
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.advisorPlanStats &&
                      subscriptionStatistics.advisorPlanStats.totalActivePlan
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Premium Plan
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.advisorPlanStats &&
                      subscriptionStatistics.advisorPlanStats.totalPremiumPlan
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Professional Plan
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.advisorPlanStats &&
                      subscriptionStatistics.advisorPlanStats
                        .totalProfessionalPlan
                    )}
                  </div>
                </div>
                <div className="w-100 border-bottom border-darkdash my-2"></div>

                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Total
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.advisorPlanStats &&
                      subscriptionStatistics.advisorPlanStats.total
                    )}
                  </div>
                </div>
              </div>

              <div className="w-100 w-lg-30 w-md-30 text-center bg-white shadow rounded-1 p-4 m-2">
                <p className="h8a font-weight-bold text-darkdash mb-3">
                  Franchise Plan Subscribers
                </p>
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Basic Plan
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.franchisePlanStats &&
                      subscriptionStatistics.franchisePlanStats.totalBasicPlan
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Premium Plan
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.franchisePlanStats &&
                      subscriptionStatistics.franchisePlanStats.totalPremiumPlan
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Professional Plan
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.franchisePlanStats &&
                      subscriptionStatistics.franchisePlanStats
                        .totalProfessionalPlan
                    )}
                  </div>
                </div>
                <div className="w-100 border-bottom border-darkdash my-2"></div>

                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left font-weight-bold text-darkstats h8">
                    Total
                  </div>
                  <div className=" h8a text-left font-weight-bold text-bright-primary">
                    {subscriptionLoader || loading ? (
                      <CircularProgress color="primary" size={'1rem'} />
                    ) : (
                      subscriptionStatistics &&
                      subscriptionStatistics.franchisePlanStats &&
                      subscriptionStatistics.franchisePlanStats.total
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-50 my-3 border mb-5"></div>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="bg-sky-grey shadow p-4 text-center rounded-1 m-3">
                <p className="h5 font-weight-bold">
                  Business Listings Overview
                </p>
              </div>
              <div className="d-block d-lg-none d-md-none w-100"></div>
              <div className="bg-sky-grey shadow p-4 text-center rounded-1 m-3">
                <p className="h5 font-weight-bold">Top Cities</p>
              </div>
            </div>
          </div>
        </FadeIn>
      }
    </div>
  );
};

export default QuickStats;
