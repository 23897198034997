export const HIDE_NAVBAR = 'HIDE_NAVBAR';
export const SHOW_NAVBAR = 'SHOW_NAVBAR';
export const SHOW_FOOTER = 'SHOW_FOOTER';
export const HIDE_FOOTER = 'HIDE_FOOTER';
export const FETCH_POSTS = 'FETCH_POSTS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FETCH_USER_LISTINGS = 'FETCH_USER_LISTINGS';
export const FETCH_USER_INVESTORS = 'FETCH_USER_INVESTORS';
export const FETCH_LISTING_DETAILS = 'FETCH_LISTING_DETAILS';
export const FETCH_INVESTOR_DETAILS = 'FETCH_INVESTOR_DETAILS';
export const RESETFORMS = 'RESETFORMS';
export const EDITLISTING = 'EDITLISTING';
export const EDITFRANCHISE = 'EDITFRANCHISE';
export const EDITINVESTOR = 'EDITINVESTOR';
export const EDITADVISOR = 'EDITADVISOR';
export const FETCH_ADMIN_LISTINGS = 'FETCH_ADMIN_LISTINGS';
export const FETCH_ADMIN_LISTINGS_STARTED = 'FETCH_ADMIN_LISTINGS_STARTED';
export const SEARCH_LISTINGS = 'SEARCH_LISTINGS';
export const FETCH_USER_PROPOSALS = 'FETCH_USER_PROPOSALS';
export const FETCH_USER_PROPOSALS_INBOX = 'FETCH_USER_PROPOSALS_INBOX';
export const FETCH_USER_PROPOSALS_SENT = 'FETCH_USER_PROPOSALS_SENT';
export const FETCH_PROPOSALS_SENT_STARTED = 'FETCH_PROPOSALS_SENT_STARTED';
export const FETCH_PROPOSALS_INBOX_STARTED = 'FETCH_PROPOSALS_INBOX_STARTED';
export const FETCH_MORE_SENT_PROPOSALS = 'FETCH_MORE_SENT_PROPOSALS';
export const FETCH_MORE_INBOX_PROPOSALS = 'FETCH_MORE_INBOX_PROPOSALS';
export const FETCH_MORE_SENT_PROPOSALS_STARTED =
  'FETCH_MORE_SENT_PROPOSALS_STARTED';
export const FETCH_MORE_INBOX_PROPOSALS_STARTED =
  'FETCH_MORE_INBOX_PROPOSALS_STARTED';
export const UPDATE_BUSINESSES = 'UPDATE_BUSINESSES';
export const UPDATE_INVESTORS = 'UPDATE_INVESTORS';
export const UPDATE_ADVISORS = 'UPDATE_ADVISORS';
export const UPDATE_FRANCHISES = 'UPDATE_FRANCHISES';
export const FETCH_BUSINESS_LISTINGS = 'FETCH_BUSINESS_LISTINGS';
export const FETCH_INVESTOR_LISTINGS = 'FETCH_INVESTOR_LISTINGS';
export const FETCH_FEATURED_LISTINGS = 'FETCH_FEATURED_LISTINGS';
export const FETCH_ADVISOR_LISTINGS = 'FETCH_ADVISOR_LISTINGS';
export const FETCH_ADVISOR_DETAILS = 'FETCH_ADVISOR_DETAILS';
export const FETCH_USER_ADVISORS = 'FETCH_USER_ADVISORS';
export const FETCH_USER_FRANCHISES = 'FETCH_USER_FRANCHISES';
export const FETCH_FRANCHISE_LISTINGS = 'FETCH_FRANCHISE_LISTINGS';
export const FETCH_FRANCHISE_DETAILS = 'FETCH_FRANCHISE_DETAILS';
export const ADD_PROPOSAL_TO_INVESTOR_LISTINGS =
  'ADD_PROPOSAL_TO_INVESTOR_LISTINGS';
export const ADD_PROPOSAL_TO_ADVISOR_LISTINGS =
  'ADD_PROPOSAL_TO_ADVISOR_LISTINGS';
export const ADD_TO_SENT_PROPOSALS = 'ADD_TO_SENT_PROPOSALS';
export const FETCH_CONTACTED_BUSINESSES = 'FETCH_CONTACTED_BUSINESSES';
export const FETCH_CONTACTED_FRANCHISES = 'FETCH_CONTACTED_FRANCHISES';
export const FETCH_ADMIN_INVESTOR_LISTINGS = 'FETCH_ADMIN_INVESTOR_LISTINGS';
export const FETCH_ADMIN_INVESTOR_LISTINGS_STARTED =
  'FETCH_ADMIN_INVESTOR_LISTINGS_STARTED';
export const FETCH_ADMIN_ADVISOR_LISTINGS = 'FETCH_ADMIN_ADVISOR_LISTINGS';
export const FETCH_ADVISOR_LISTINGS_STARTED = 'FETCH_ADVISOR_LISTINGS_STARTED';
export const FETCH_ADMIN_FRANCHISE_LISTINGS = 'FETCH_ADMIN_FRANCHISE_LISTINGS';
export const FETCH_FRANCHISE_LISTINGS_STARTED =
  'FETCH_FRANCHISE_LISTINGS_STARTED';
export const FETCH_USERS = 'FETCH_USERS';
export const SEARCH_USERS_STARTED = 'SEARCH_USERS_STARTED';
export const FETCH_USER_NOTIFICATIONS = 'FETCH_USER_NOTIFICATIONS';
export const SET_HEADERS = 'SET_HEADERS';
export const RESET_HEADERS = 'RESET_HEADERS';
export const SELECT_PLAN = 'SELECT_PLAN';
export const ADD_ALL_USER_LISTINGS = 'ADD_ALL_USER_LISTINGS';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const FETCH_ADMIN_STARTUPS = 'FETCH_ADMIN_STARTUPS';
export const FETCH_ADMIN_STARTUPS_STARTED = 'FETCH_ADMIN_STARTUPS_STARTED';
export const FETCH_STARTUP_POSTS = 'FETCH_STARTUP_POSTS';
export const FETCH_STARTUP_POSTS_STARTED = 'FETCH_STARTUP_POSTS_STARTED';
export const FETCH_POST_DETAILS = 'FETCH_POST_DETAILS';
export const FETCH_POST_DETAILS_STARTED = 'FETCH_POST_DETAILS_STARTED';
