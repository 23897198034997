import React, { useEffect } from 'react';
import FaqDropDown from './FaqDropDown';
import { useDispatch } from 'react-redux';
import { setHeaders, resetHeaders } from './../../state/actions/layout';
import { withRouter } from 'react-router-dom';

const Faq = props => {
  const description = 'Get answers to frequently asked questions';
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setHeaders({
        title: 'FAQs',
        url: props.history.location.pathname,
        description,
      }),
    );

    return function cleanup() {
      dispatch(resetHeaders());
    };
  }, [description, dispatch, props.history.location.pathname]);
  const account = [
    {
      question:
        "Why can't I access all features of COMPEXAFRICA without registering?",
      answer: `Compex Africa is a private network of members and hence all users are required to register and login before they can perform actions like send proposals to investors, contact businesses, or list new profiles. It is quick and easy to register on Compex Africa.`,
    },

    {
      question: 'Can I change my email address?',
      answer: `No. Once your profile is verified, no changes can be made to your email address. If you've mistakenly singed up with a wrong or incorrect email address you will need to sign up again with the correct one to receive a verification link.`,
    },

    {
      question:
        'Why has COMPEXAFRICA not gotten back to me after submitting my profile?',
      answer: `COMPEXAFRICA follows a verification process. Due to a high rate of profile listings, verification can take between 3 to 5 working days. To aid verification process, ensure that all information updated on your profile is complete and relevant.`,
    },

    {
      question: 'Why was my profile edited by COMPEXAFRICA?',
      answer: `COMPEXAFRICA reserves the right to edit profiles before activating them. Profiles that reveal the identity of the business or promotor and images uploaded with a company name or a logo will be blurred or removed.`,
    },

    {
      question: 'How do I close or delete my profile?',
      answer: `You can close or delete your profile from your dashboard or profile page.`,
    },
  ];

  const business = [
    {
      question: 'Can I list multiple businesses?',
      answer: `Yes, COMPEXAFRICA allows you to list multiple businesses. You can also list other people's businesses, each with a different email from the one you used to register, although each new email will go through an email verification process.`,
    },

    {
      question:
        'Can I be an investor/ Advisor and also list my business for funding?',
      answer: `Yes you can and vice versa. As an investor or advisor, you can register your business for sale. You can register yours and other people's businesses and franchises, and also be an investor, advisor.`,
    },

    {
      question:
        'What information do I need to know before listing my business on Compex Africa?',
      answer: `Your business needs to be a registered Company registered with the relevant government authorities for verification and activation of your profile. Your business financials need to be provided. These include: Latest reported annual revenue, Average monthly sales, EBITDA Margin (%) *, Value of physical assets.
      `,
    },

    {
      question: 'Can I modify my business listing?',
      answer: `You can modify your listing by signing in to your accout, locating your profile and clicking the EDIT button in the top right had menu. Once verified, you can’t modify your listing.`,
    },

    {
      question: 'How do I follow up on a proposal sent to an Investor/Buyer?',
      answer: `At the moment the only way is to send a new proposal. If you don't hear from the buyer or investor within a period of time, it means they have not interested in your proposal or are considering accepting. Once it is accepted, you you can expect them to contact you using the contact details in the profile you sent along with your proposal.`,
    },
    {
      question: 'How do I cancel a proposal?',
      answer: 'You cannot cancel a proposal once sent',
    },
    {
      question:
        'How long does it take to successfully close a deal on COMPEXAFRICA?',
      answer:
        'Deals can take anywhere between 10 days to 2 years duration and is highly dependent on the attractiveness of the industry, readiness of both parties and business proposal. Deal duration can also be affected by depth of due diligence conducted, and information availability.',
    },
    {
      question:
        'I have been contacted by several investors or buyers but am still unable to successfully close a deal. What should I do?',
      answer:
        'Once the introduction has been made through the platform, the responsibility of taking the discussion forward and successfully closing the deal lies on the business owner and the acquirer / investor. For professional help make sure to seek advice from our advisors on our platform or from owners of similar profiles you see have closed. COMPEXAFRICA is a marketplace which helps connect businesses with acquirers / investors. The deal finally closing or not is dependent on the quality of the business and the attractiveness of the offer which COMPEXAFRICA will not have any control on. There has to be an agreement between the buyer and seller for a successful deal closure.',
    },
  ];

  const investor = [
    {
      question: 'After I connect with a business, what next?',
      answer: `The connection gets automatically approved by COMPEXAFRICA which will alert the business. Additionally, you will have access to view their verified contact details, and any documents they have provided about the business.`,
    },

    {
      question:
        'If a business owner doesn’t respond after the introduction, what should one do?',
      answer: `If this happens after you have tried every means, it means that they are likely not interested to make the transaction, hence you can follow up after a few weeks if you wish to.`,
    },
  ];

  const franchise = [
    {
      question:
        'I want to expand my business by finding franchise partners. How do I do that on COMPEXAFRICA?',
      answer: `You can search the businesses or seek advisory services for people interested in franchising. You can also register your business as a franchise in order to be exposed to various franchising opportunities. The platform helps market your brand to investors who are looking to open up a franchise.`,
    },
  ];

  const subscription = [
    {
      question: 'What happens if I don’t use my subscription within the year?',
      answer: `If you don’t use subscription, your subscription expires and you lose access to the platform.`,
    },
    {
      question: 'Does Compex Africa offer a discount on subscription plans?',
      answer: `Compex Africa does not provide any seasonal discounts. The pricing is as per what you see on the website.`,
    },
  ];

  return (
    <div className="container custom-home py-5">
      <div className="d-flex flex-column align-items-center justify-content-center text-center mb-5">
        <p className="h1 text-primary font-weight-bold">
          Frequently Asked Questions
        </p>
      </div>
      <h2 className="mb-3">Account</h2>
      <div className="flex flex-wrap justify-content-between">
        <iframe
          title="How to edit business profiles on desktop"
          width="330"
          height="315"
          src="https://www.youtube.com/embed/AuvYTosEDWc"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="mr-lg-3 mb-3"
        ></iframe>
        <iframe
          title="How to edit business profiles on mobile"
          width="330"
          height="315"
          src="https://www.youtube.com/embed/IHaFbTJtFjc"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="mb-3"
        ></iframe>
      </div>
      {account.map((obj, index) => {
        return (
          <FaqDropDown
            key={index}
            question={obj.question}
            answer={obj.answer}
            id={'a' + index}
          ></FaqDropDown>
        );
      })}
      <h2 className="mb-3 mt-6">Business</h2>
      <iframe
        title="How businesses can benefit from Compex Africa"
        width="330"
        height="315"
        src="https://www.youtube.com/embed/v3OS11DNnlQ"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="mb-3"
      ></iframe>
      {business.map((obj, index) => {
        return (
          <FaqDropDown
            key={index}
            question={obj.question}
            answer={obj.answer}
            id={'b' + index}
          ></FaqDropDown>
        );
      })}
      <h2 className="mb-3 mt-6">Investor</h2>
      <iframe
        title="Investors benefit from Compex Africa"
        width="330"
        height="315"
        src="https://www.youtube.com/embed/-08epQLKDjQ"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="mb-3"
      ></iframe>
      {investor.map((obj, index) => {
        return (
          <FaqDropDown
            key={index}
            question={obj.question}
            answer={obj.answer}
            id={'i' + index}
          ></FaqDropDown>
        );
      })}
      <h2 className="mb-3 mt-6">Franchise</h2>
      {franchise.map((obj, index) => {
        return (
          <FaqDropDown
            key={index}
            question={obj.question}
            answer={obj.answer}
            id={'f' + index}
          ></FaqDropDown>
        );
      })}
      <h2 className="mb-3 mt-6">Subscription</h2>
      {subscription.map((obj, index) => {
        return (
          <FaqDropDown
            key={index}
            question={obj.question}
            answer={obj.answer}
            id={'s' + index}
          ></FaqDropDown>
        );
      })}
    </div>
  );
};

export default withRouter(Faq);
