import React, { Component } from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  fetchUserProposalSent,
  fetchUserProposalsInbox,
  fetchMoreInboxProposals,
  fetchMoreSentProposals,
} from '../../state/actions/user';
import userService from '../../common/services/userService';
import withSizes from 'react-sizes';
import UserProposalContainer from './UserProposalContainer';
import { CircularProgress } from '@material-ui/core';
import FadeIn from 'react-fade-in';
import { startupZoneDomain } from '../../config';

class ProposalsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 2,
      pageSize: 10,
      hasMoreItems: true,
      inbox: [],
      sentProposals: [],
      hideProposalList: '',
      showProposalDetail: this.props.isMobile ? 'd-none' : '',
      searchString: '',
      isSearching: false,
      loading: false,
      viewButtonLoading: false,
      viewLinkLoading: false,
    };
    this.readProposal = this.readProposal.bind(this);
    this.viewListing = this.viewListing.bind(this);
    this.searchProposal = this.searchProposal.bind(this);
    this.setSearchText = this.setSearchText.bind(this);
    this.loadItems = this.loadItems.bind(this);
  }

  loadItems = proposalType => {
    let { pageNumber, pageSize } = this.state;

    if (proposalType === 'inbox') {
      this.props.fetchMoreInboxProposals(pageNumber, pageSize);
    } else {
      this.props.fetchMoreSentProposals(pageNumber, pageSize);
    }
    ++pageNumber;
    this.setState({ pageNumber });
  };

  resetURL = () => {
    if (this.props.isMobile || this.props.isTablet) {
      this.setState({ hideProposalList: '' });
      this.setState({ showProposalDetail: 'd-none' });
    } else {
      this.setState({ hideProposalList: '' });
      this.setState({ showProposalDetail: '' });
    }
  };

  searchProposal = proposalType => {
    this.resetURL();
    let { pageNumber, pageSize, searchString } = this.state;
    pageNumber = 1;
    if (proposalType === 'inbox') {
      this.props.history.push('/user/inbox/proposals');
      this.props.fetchUserProposalsInbox(pageNumber, pageSize, searchString);
    } else {
      this.props.history.push('/user/sent/proposals');
      this.props.fetchUserProposalSent(pageNumber, pageSize, searchString);
    }
    this.setState({ isSearching: true });
  };

  setSearchText = searchString => {
    this.setState({ searchString: searchString });
  };

  readProposal = async (id, proposalType) => {
    if (this.props.isMobile || this.props.isTablet) {
      this.setState({ hideProposalList: 'd-none' });
      this.setState({ showProposalDetail: '' });
    }
    if (proposalType === 'inbox') {
      const found = this.state.inbox.some(
        inboxProposal => inboxProposal.id === id,
      );
      if (found) {
      } else return;
    }
  };

  viewListing = async (
    listingLink,
    proposalId,
    proposalType,
    sharedListingType,
    e,
  ) => {
    e.preventDefault();
    if (proposalType === 'inbox') {
      if (e.target.id === 'button') this.setState({ viewButtonLoading: true });
      else this.setState({ viewLinkLoading: true });
      await userService.viewProposalListing(proposalId);
    }
    if (sharedListingType === 'startups') {
      window.location.href = `${startupZoneDomain}${listingLink}`;
    } else {
      const { history } = this.props;
      history.push(listingLink);
    }
  };

  componentDidMount() {
    this.setState({
      loading: true,
    });

    let sentProposals = this.props.userProposalSent;
    let inbox = this.props.userProposalsInbox;
    if (inbox) {
      this.setState({
        loading: false,
      });
    }
    this.setState({
      ...this.state,
      inbox,
      sentProposals,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userProposalSent !== prevProps.userProposalSent ||
      this.props.userProposalsInbox !== prevProps.userProposalsInbox
    ) {
      this.setState({
        isSearching: false,
        loading: false,
      });
    }
  }

  render() {
    let sentProposals = this.props.userProposalSent;
    let inbox = this.props.userProposalsInbox;
    const { currentUser, hasMoreProposals } = this.props;
    return (
      <div>
        {this.state.loading ? (
          <div
            style={{ minHeight: '60vh' }}
            className="d-flex justify-content-center align-items-center"
          >
            <CircularProgress color="primary" size={'3rem'} />
          </div>
        ) : (
          <FadeIn>
            <h1>Proposals</h1>

            <hr></hr>

            <div className="admin-custom">
              <div className="ml-lg-5">
                <ul className="nav nav-pills mb-3" id="pills-tab">
                  <li className="nav-item">
                    <NavLink
                      className="nav-link "
                      to="/user/inbox/proposals"
                      onClick={() => {
                        this.resetURL();
                      }}
                    >
                      Inbox
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/user/sent/proposals"
                      onClick={() => {
                        this.resetURL();
                      }}
                    >
                      Sent
                    </NavLink>
                  </li>
                </ul>

                <div
                  className="content-wrapper tab-content rounded mb-5"
                  id="pills-tabContent"
                >
                  <Switch>
                    <Route
                      path={'/user/inbox/proposals'}
                      render={props => (
                        <UserProposalContainer
                          {...props}
                          hideProposalList={this.state.hideProposalList}
                          showProposalDetail={this.state.showProposalDetail}
                          proposals={inbox}
                          proposalType="inbox"
                          currentUser={currentUser}
                          readProposal={this.readProposal}
                          viewListing={this.viewListing}
                          resetURL={this.resetURL}
                          searchProposal={this.searchProposal}
                          setSearchText={this.setSearchText}
                          isSearching={this.state.isSearching}
                          loadItems={this.loadItems}
                          hasMoreProposals={hasMoreProposals}
                          viewButtonLoading={this.state.viewButtonLoading}
                          viewLinkLoading={this.state.viewLinkLoading}
                        />
                      )}
                    />

                    <Route
                      path={'/user/sent/proposals'}
                      render={() => (
                        <UserProposalContainer
                          hideProposalList={this.state.hideProposalList}
                          showProposalDetail={this.state.showProposalDetail}
                          proposals={sentProposals && sentProposals}
                          proposalType="sent"
                          currentUser={currentUser}
                          readProposal={this.readProposal}
                          viewListing={this.viewListing}
                          resetURL={this.resetURL}
                          searchProposal={this.searchProposal}
                          setSearchText={this.setSearchText}
                          loadItems={this.loadItems}
                          hasMoreProposals={hasMoreProposals}
                          viewButtonLoading={this.state.viewButtonLoading}
                          viewLinkLoading={this.state.viewLinkLoading}
                        />
                      )}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </FadeIn>
        )}
      </div>
    );
  }
}

const mapSizesToProps = sizes => ({
  isMobile: withSizes.isMobile(sizes),
  isTablet: withSizes.isTablet(sizes),
});

const mapStateToProps = state => {
  return {
    userProposals: state.user.userProposals,
    userProposalsInbox: state.user.userProposalsInbox,
    userProposalSent: state.user.userProposalSent,
    hasMoreProposals: state.user.hasMoreProposals,
  };
};

export default withSizes(mapSizesToProps)(
  connect(mapStateToProps, {
    fetchUserProposalsInbox,
    fetchUserProposalSent,
    fetchMoreInboxProposals,
    fetchMoreSentProposals,
  })(ProposalsPage),
);
