import React from 'react';
import ContentLoader from 'react-content-loader';

const BusinessFranchisePlaceholder = () => (
  <div className="pb-5 mx-2" style={{ width: '253px' }}>
    <ContentLoader
      height={355}
      width={253}
      speed={2}
      primaryColor="#cccccc"
      secondaryColor="#f3f3f3"
    ></ContentLoader>
  </div>
);

export default BusinessFranchisePlaceholder;
