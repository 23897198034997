import React, { Component } from 'react';
import UserListingTableItem from './UserListingTableItem';
import { CircularProgress } from '@material-ui/core';

class UserListingsTable extends Component {
  render() {
    let { loadingUserListings } = this.props;
    return (
      <div className="table-responsive shadow">
        <table className="table">
          <thead className="bg-admin-primary text-white h7">
            <tr>
              <th>Count</th>
              <th>User ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Date Registered</th>
              <th>Verified</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {loadingUserListings ? (
              <tr>
                <td>
                  <CircularProgress color="primary" size={'1rem'} />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ) : (
              this.props.listings.map((listing, index) => (
                <UserListingTableItem
                  key={listing.id}
                  listing={listing}
                  number={this.props.listings.length - index}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default UserListingsTable;
