import http from './httpService';
import baseApiUrl from '../../config';

const getUserListings = userId => {
  return http.get(`${baseApiUrl}businesses?userid=${userId}`);
};

const getProposalListings = sendTo => {
  return http.get(`${baseApiUrl}users/listings?exclude=${sendTo}`);
};

const getDashboardStatistics = async () => {
  try {
    const { status, data } = await http.get(
      `${baseApiUrl}users/admin/listingstats`,
    );
    if (status === 200) {
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.log(error);
  }
};

const getSubscriptionStatistics = async (lastDays = -1, isPaid = false) => {
  try {
    const { status, data } = await http.get(
      `${baseApiUrl}users/admin/SubscriptionStats?lastDays=${lastDays}&isPaid=${isPaid}`,
    );
    if (status === 200) {
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.log(error);
  }
};

const sendInvestorProposal = async proposal => {
  const { status } = await http.post(
    `${baseApiUrl}proposals/investor`,
    proposal,
  );
  if (status === 200) return true;
};

const sendAdvisorProposal = async proposal => {
  const { status } = await http.post(
    `${baseApiUrl}proposals/advisor`,
    proposal,
  );
  if (status === 200) return true;
};

const contactBusiness = async payload => {
  const { status } = await http.post(
    `${baseApiUrl}businesses/${payload.businessId}/contact`,
    payload,
  );
  if (status === 200) return true;
};

const contactFranchise = async payload => {
  const { status } = await http.post(
    `${baseApiUrl}franchises/${payload.franchiseId}/contact`,
    payload,
  );
  if (status === 200) return true;
};

const getUserProposals = async (
  pageNumber = 1,
  pageSize = 10,
  searchString = '',
) => {
  return await http.get(
    `${baseApiUrl}proposals?PageNumber=${pageNumber}&PageSize=${pageSize}&searchString=${searchString}`,
  );
};

const getUserProposalsInbox = (
  pageNumber = 1,
  pageSize = 10,
  searchString = '',
) => {
  return http.get(
    `${baseApiUrl}proposals/inbox?PageNumber=${pageNumber}&PageSize=${pageSize}&searchString=${searchString}`,
  );
};

const getUserProposalSent = (
  pageNumber = 1,
  pageSize = 10,
  searchString = '',
) => {
  return http.get(
    `${baseApiUrl}proposals/sent?PageNumber=${pageNumber}&PageSize=${pageSize}&searchString=${searchString}`,
  );
};

const viewProposalListing = async id => {
  const { status } = await http.post(`${baseApiUrl}proposals/${id}/viewed`);
  if (status === 200) return true;
};

const readProposal = async id => {
  const { status } = await http.post(`${baseApiUrl}proposals/${id}/read`);
  if (status === 200) return true;
};

const markAllProposalRead = async () => {
  return http.post(`${baseApiUrl}proposals/readall`);
};
const getUserInvestorListings = userId => {
  return http.get(`${baseApiUrl}investors?userid=${userId}`);
};

const getUserContext = () => {
  return http.get(`${baseApiUrl}users/context`);
};

const getNotifications = () => {
  return http.get(`${baseApiUrl}notifications`);
};

const getUserAdvisorListings = userId => {
  return http.get(`${baseApiUrl}advisors?userid=${userId}`);
};

const getUserFranchiseListings = userId => {
  return http.get(`${baseApiUrl}franchises?userid=${userId}`);
};

const getContactedBusinesses = () => {
  return http.get(`${baseApiUrl}users/interests?profileType=Business`);
};

const getContactedFranchises = () => {
  return http.get(`${baseApiUrl}users/interests?profileType=Franchise`);
};

const getAllUsers = (pageNumber = 1) => {
  try {
    return http.get(`${baseApiUrl}users?all=true&pageNumber=${pageNumber}`);
  } catch (error) {
    console.log(error);
  }
};

const getAllUsersForExport = async (pageNumber = 1, pageSize = 20000) => {
  return await http.get(
    `${baseApiUrl}users?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );
};

const searchUsers = (
  startDate,
  endDate,
  searchQuery,
  status,
  byId = false,
  pageNumber = 1,
) => {
  try {
    startDate = startDate && startDate.toISOString();
    endDate = endDate && endDate.toISOString();
    if (startDate && endDate)
      return http.get(
        `${baseApiUrl}users?startDate=${startDate}&endDate=${endDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else if (startDate)
      return http.get(
        `${baseApiUrl}users?startDate=${startDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else if (endDate)
      return http.get(
        `${baseApiUrl}users?endDate=${endDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else
      return http.get(
        `${baseApiUrl}users?byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
  } catch (error) {
    console.log(error);
  }
};

const suspendUser = async id => {
  try {
    const { status } = await http.post(`${baseApiUrl}users/${id}/suspend`);
    if (status === 200) return true;
  } catch (error) {
    console.log(error);
  }
};

const unsuspendUser = async id => {
  try {
    const { status } = await http.post(`${baseApiUrl}users/${id}/unsuspend`);
    if (status === 200) return true;
  } catch (error) {
    console.log(error);
  }
};

const getUserSubscriptions = async () => {
  try {
    const { status, data } = await http.get(`${baseApiUrl}users/subscriptions`);
    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};

const changeSubscriptionRenewal = async (id, renewal) => {
  try {
    const { status } = await http.get(
      `${baseApiUrl}subscriptions/${id}/renewal?status=${renewal}`,
    );
    if (status === 200) return true;
  } catch (error) {
    console.log(error);
  }
};

const getUserSubscription = async id => {
  try {
    const { status, data } = await http.get(
      `${baseApiUrl}users/subscriptions/${id}`,
    );
    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export default {
  getUserListings,
  getProposalListings,
  sendInvestorProposal,
  sendAdvisorProposal,
  getUserProposals,
  getUserProposalsInbox,
  getUserProposalSent,
  viewProposalListing,
  readProposal,
  markAllProposalRead,
  getUserInvestorListings,
  getUserFranchiseListings,
  getUserAdvisorListings,
  getContactedBusinesses,
  getContactedFranchises,
  contactBusiness,
  contactFranchise,
  getAllUsers,
  suspendUser,
  unsuspendUser,
  getUserSubscriptions,
  getUserSubscription,
  changeSubscriptionRenewal,
  getNotifications,
  getUserContext,
  getDashboardStatistics,
  getSubscriptionStatistics,
  searchUsers,
  getAllUsersForExport,
};
