import React, { Component } from 'react';
import Joi from 'joi-browser';
import planTypes from '../../common/utils/planTypes';
import subscriptionService from '../../common/services/subscriptionService';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';

class Coupons extends Component {
  state = {
    formdata: {
      planType: '',
    },
    errors: {},
    plans: [],
    newCode: '',
    loading: false,
  };

  componentDidMount() {
    const plansArr = Object.values(planTypes);

    // no need creating coupon for free plans
    const plans = plansArr.filter(
      x => x !== planTypes.basicInvestor && x !== planTypes.basicAdvisor,
    );

    console.log(plans);
    this.setState({ plans });
  }

  schema = {
    planType: Joi.string()
      .required()
      .label('Plan Type'),
  };

  validate() {
    const { error } = Joi.validate(this.state.formdata, this.schema, {
      abortEarly: false,
    });
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  onChange = e => {
    const { name, value } = e.target;
    const formdata = { ...this.state.formdata };
    formdata[name] = value;
    const errors = { ...this.state.errors };
    errors[name] = '';
    this.setState({ formdata, errors });
  };

  onSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });
    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) return;

    const res = await subscriptionService.generateCouponCode(
      this.state.formdata.planType,
    );

    if (!res) {
      this.setState({ loading: false });
      Swal.fire('An error occured', 'Unable to generate coupon code', 'error');
      return;
    }

    this.setState({ newCode: res });
    this.setState({ loading: false });
    toast.success('Coupon code generated successfully');
  };

  render() {
    return (
      <div className="admin-custom">
        <div className="ml-lg-5">
          <h1>Coupons</h1>
          <div className="w-50 my-3 border"></div>
          <form
            onSubmit={this.onSubmit}
            method="POST"
            className="bg-white shadow p-5 rounded font-weight-bold small"
            noValidate
          >
            <h3 className="mb-3">Create Coupon Code</h3>
            <div className="form-row">
              <div className="form-group col-md-6 mb-5">
                <select
                  id="planType"
                  onChange={this.onChange}
                  value={this.state.formdata.planType}
                  name="planType"
                  className={
                    this.state.errors['planType']
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                >
                  <option>select plan</option>
                  {this.state.plans.map(plan => (
                    <option key={plan} value={plan}>
                      {plan.split(/(?=[A-Z])/)[0] +
                        ' ' +
                        plan.split(/(?=[A-Z])/)[1]}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">
                  {this.state.errors['planType']}
                </div>
              </div>

              <div className="form-group col-md-6 px-md-4 mb-5">
                <button type="submit" className="btn btn-primary">
                  {this.state.loading ? (
                    <CircularProgress color="inherit" size={'1rem'} />
                  ) : (
                    ' Generate Code '
                  )}
                </button>
              </div>
            </div>
          </form>

          {this.state.newCode && (
            <div className="my-3 shadow p-3 bg-white">
              <h5>New Coupon Code for {this.state.formdata.planType}</h5>
              <div className="border my-3 p-3">
                <h3>{this.state.newCode}</h3>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Coupons;
