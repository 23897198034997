import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import listingsService from '../../common/services/listingsService';
import { fetchAdminListings } from '../../state/actions/business';
import { getAbbreviation, spacePascalWord } from './../../common/utils/helpers';
import AdminActionModal from '../../common/components/AdminActionModal';
import profileType from './../../common/utils/profileType';
import profileStatus from './../../common/utils/profileStatus';

class BusinessListingTableItem extends Component {
  MySwal = withReactContent(Swal);
  state = {};

  approveListing = async id => {
    Swal.fire(
      'Are you sure?',
      'Do you want to approve listing?',
      'question',
    ).then(async value => {
      if (!value) return;
      const res = await listingsService.approveListing(id);
      if (res) {
        this.props.fetchAdminListings();
        Swal.fire('Listing Approved Successfully', '', 'success');
      } else {
        Swal.fire('Unable to approve listing', '', 'error');
      }
    });
  };

  disapproveListing = async id => {
    Swal.fire(
      'Are you sure?',
      'Do you want to disapprove listing?',
      'question',
    ).then(async value => {
      if (!value) return;
      const res = await listingsService.disapproveListing(id);
      if (res) {
        this.props.fetchAdminListings();
        Swal.fire('Listing Disapproved Successfully', '', 'success');
      } else {
        Swal.fire('Unable to disapprove listing', '', 'error');
      }
    });
  };

  changeListingStatus = async (status, id) => {
    this.MySwal.fire({
      customClass: 'bg-transparent w-lg-30 w-md-30 w-90',
      showConfirmButton: false,
      html: (
        <AdminActionModal
          action={status}
          profileType={profileType.Business}
          profileId={id}
          refreshListings={this.props.fetchAdminListings}
        ></AdminActionModal>
      ),
    });
  };

  feature = async id => {
    Swal.fire(
      'Are you sure?',
      'Do you want to Feature listing?',
      'question',
    ).then(async value => {
      if (!value) return;
      const res = await listingsService.featureListing(id);
      if (res) {
        this.props.fetchAdminListings();
        Swal.fire('Listing Featured Successfully', '', 'success');
      } else {
        Swal.fire('Unable to Faeture listing', '', 'error');
      }
    });
  };

  unfeature = async id => {
    Swal.fire(
      'Are you sure?',
      'Do you want to Un-Feature listing?',
      'question',
    ).then(async value => {
      if (!value) return;
      const res = await listingsService.unfeatureListing(id);
      if (res) {
        this.props.fetchAdminListings();
        Swal.fire('Listing Un-Featured Successfully', '', 'success');
      } else {
        Swal.fire('Unable to Un-Feature listing', '', 'error');
      }
    });
  };

  render() {
    const { listing, number } = this.props;
    return (
      <tr>
        <td>
          <Link to={`/user/businesses/${listing.id}`}>{number}</Link>
        </td>
        <td>
          <Link to={`/user/businesses/${listing.id}`}>{listing.id}</Link>
        </td>
        <td>
          <Link to={`/user/businesses/${listing.id}`}>
            <div>{listing.shortDescription}</div>
          </Link>
        </td>
        <td>
          <span className="mx-2">
            {listing.registeredLocation && listing.registeredLocation}
          </span>
        </td>
        <td>
          {listing.createdAt && new Date(listing.createdAt).toLocaleString()}
        </td>
        <td>
          <span className="font-weight-bold pr-1"></span>₦
          {getAbbreviation(
            listing.askingPrice ||
              listing.capitalRaised ||
              listing.partialSaleAskingPrice,
          )}
        </td>
        <td>{listing.listingType}</td>
        <td>
          <div className="d-flex">
            <span
              className={
                listing.status === profileStatus.Approved
                  ? 'badge badge-success mx-4'
                  : listing.status === profileStatus.Draft ||
                    listing.status === profileStatus.Declined
                  ? 'badge badge-danger mx-4'
                  : listing.status === profileStatus.Pending ||
                    listing.status === profileStatus.ChangesRequired
                  ? 'badge badge-warning mx-4'
                  : 'badge'
              }
            >
              {spacePascalWord(listing.status)}
            </span>
            <button
              className=" button-as-link d-block pr-3"
              id={`${listing.id}`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              {listing.isApproved && (
                <button
                  className="dropdown-item cursor-pointer"
                  onClick={() => this.disapproveListing(listing.id)}
                >
                  Disapprove
                </button>
              )}
              {!listing.isApproved && (
                <button
                  className="dropdown-item cursor-pointer"
                  onClick={() => this.approveListing(listing.id)}
                >
                  Approve
                </button>
              )}
              <button
                className="dropdown-item cursor-pointer"
                onClick={() => this.changeListingStatus('Decline', listing.id)}
              >
                Decline
              </button>
              <button
                className="dropdown-item cursor-pointer"
                onClick={() =>
                  this.changeListingStatus('Request Change', listing.id)
                }
              >
                Request Change
              </button>
              {!listing.isFeatured ? (
                <button
                  className="dropdown-item cursor-pointer"
                  onClick={() => this.feature(listing.id)}
                >
                  Feature
                </button>
              ) : (
                <button
                  className="dropdown-item cursor-pointer"
                  onClick={() => this.unfeature(listing.id)}
                >
                  Un-feature
                </button>
              )}
              <button className="dropdown-item">Hide</button>
              <button className="dropdown-item">Show</button>
              <button className="dropdown-item">Archive</button>
              <button className="dropdown-item">Mark as closed/Sold</button>
            </div>
          </div>
        </td>
        <td>{listing.isFeatured ? 'Yes' : 'No'}</td>
        <td>
          <Link to={`/admin/dashboard/user-listings/${listing.id}`}>
            <div>{listing.firstName}</div>
          </Link>
        </td>
        <td>
          <Link to={`/admin/dashboard/user-listings/${listing.id}`}>
            <div>{listing.lastName}</div>
          </Link>
        </td>

        <td>{listing.officialEmail}</td>
        <td>{listing.phoneNumber}</td>
        <td>{listing.plan}</td>
      </tr>
    );
  }
}

export default connect(null, { fetchAdminListings })(BusinessListingTableItem);
