import React, { Component } from 'react';
import FranchiseListingTableItem from './FranchiseListingTableItem';
import { CircularProgress } from '@material-ui/core';

class FranchiseListingsTable extends Component {
  state = {};
  render() {
    let { loadingFranchiseListings } = this.props;
    return (
      <div className="table-responsive shadow">
        <table className="table">
          <thead className="bg-admin-primary text-white h7">
            <tr>
              <th>Count</th>
              <th>Franchise ID</th>
              <th>Brand Name</th>
              <th>HQ Location</th>
              <th>Date Listed</th>
              <th>Status</th>
              <th>Authorized First Name</th>
              <th>Authorized Last Last Name</th>
              <th>Official Email</th>
              <th>Phone Number</th>
              <th>Designation</th>
              <th>Plan</th>
              <th>Lister First Name</th>
              <th>Lister Last Name</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {loadingFranchiseListings ? (
              <tr>
                <td>
                  <CircularProgress color="primary" size={'1rem'} />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ) : (
              this.props.listings.map((listing, index) => (
                <FranchiseListingTableItem
                  key={listing.id}
                  listing={listing}
                  number={this.props.listings.length - index}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default FranchiseListingsTable;
