import store from '../../state/store';
import jwtDecode from 'jwt-decode';
import baseApiUrl from '../../config';
import http from './httpService';
import userService from '../services/userService';
import {
  LOGIN_USER,
  LOGOUT_USER,
  SET_CURRENT_USER,
  FETCH_USER_NOTIFICATIONS,
  FETCH_USER_PROPOSALS_INBOX,
} from '../../state/actions/types';
import { getCookie, setCookie, deleteCookie } from '../utils/cookie';

const tokenKey = '_compex_token';
const authToken = getCookie(tokenKey) || '';
http.setJwt(authToken);

const signUp = async userDto => {
  store.dispatch({
    type: SET_CURRENT_USER,
    payload: { email: userDto.email },
  });

  const { status } = await http.post(`${baseApiUrl}account/register`, userDto);
  if (status === 200) {
    return true;
  } else {
    return false;
  }
};

const updateCurrentUser = async userDto => {
  const { status } = await http.post(`${baseApiUrl}users/update`, userDto);
  if (status === 200) {
    return true;
  } else {
    return false;
  }
};

const login = async userDto => {
  const { status, data } = await http.post(
    `${baseApiUrl}account/login`,
    userDto,
  );

  return setUserLogin(status, data);
};

const loginUserLocal = async () => {
  const token = getCookie(tokenKey);

  if (token) {
    try {
      const user = getJwtUser(token);
      if (user && user.expireIn) {
        //clear token after expiry time
        const expiryTime = new Date(user.expireIn * 1000);
        if (expiryTime < new Date()) {
          deleteCookie(tokenKey);
          http.setJwt('none');
          return;
        }

        http.setJwt(token);
        setCurrentUser(user);
        store.dispatch({ type: LOGIN_USER });

        // get and set current user context
        userService
          .getUserContext()
          .then(res => {
            if (res.data) {
              setCurrentUser(res.data);
            }
          })
          .catch(err => console.log(err));

        // get and set user notifications
        userService
          .getNotifications()
          .then(res => {
            store.dispatch({
              type: FETCH_USER_NOTIFICATIONS,
              payload: res.data,
            });
          })
          .catch(err => console.log(err));

        userService.getUserProposalsInbox().then(res => {
          store.dispatch({
            type: FETCH_USER_PROPOSALS_INBOX,
            payload: res.data,
          });
        });
      }
    } catch (error) {}
  }
};

const googleLogin = async response => {
  const { status, data } = await http.post(
    `${baseApiUrl}account/google-login`,
    {
      token: response.accessToken,
    },
  );

  return setUserLogin(status, data);
};

const facebookLogin = async response => {
  const { status, data } = await http.post(
    `${baseApiUrl}account/facebook-login`,
    {
      token: response.accessToken,
    },
  );

  return setUserLogin(status, data);
};

const setUserLogin = (status, data) => {
  if (status === 200 && data && data.token) {
    const user = getJwtUser(data.token);
    if (user) {
      http.setJwt(data.token);
      setCookie(tokenKey, data.token);
      store.dispatch({ type: LOGIN_USER });

      // get and set current user context
      userService
        .getUserContext()
        .then(res => {
          if (res.data) {
            setCurrentUser(res.data);
          }
        })
        .catch(err => console.log(err));

      // get and set user notifications
      userService
        .getNotifications()
        .then(res => {
          store.dispatch({ type: FETCH_USER_NOTIFICATIONS, payload: res.data });
        })
        .catch(err => console.log(err));

      userService.getUserProposalsInbox().then(res => {
        store.dispatch({
          type: FETCH_USER_PROPOSALS_INBOX,
          payload: res.data,
        });
      });
    }
    return user;
  } else {
    return null;
  }
};

const setCurrentUser = data => {
  const {
    userId,
    firstName: firstname,
    lastName: surname,
    email,
    phoneNumber,
    roles,
    subscriptions,
    photoUrl,
  } = data;
  const user = {
    userId,
    firstname,
    surname,
    email,
    phoneNumber,
    roles,
    subscriptions,
    photoUrl,
  };
  user.displayName = `${firstname ? firstname : ''} ${surname ? surname : ''}`;

  if (roles && roles.length > 0) {
    user.isAdmin = roles.includes('Admin');
    user.isSuperAdmin = roles.includes('SuperAdmin');
  }

  store.dispatch({
    type: SET_CURRENT_USER,
    payload: user,
  });
};

const logOutUser = () => {
  deleteCookie(tokenKey);
  http.setJwt('none');
  store.dispatch({ type: LOGOUT_USER });
  store.dispatch({ type: SET_CURRENT_USER, payload: {} });
};

const getJwtUser = token => {
  try {
    const result = jwtDecode(token);
    if (result) {
      const claimsPrefix =
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/';
      const user = {
        userId: parseInt(result.unique_name, 10),
        firstname: result[claimsPrefix + 'givenname'],
        surname: result[claimsPrefix + 'surname'],
        email: result[claimsPrefix + 'emailaddress'],
        expireIn: result.exp,
      };
      const roles =
        result['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      user.roles = roles;
      user.isAdmin = roles.includes('Admin');
      user.displayName = `${user.firstname} ${
        user.surname ? user.surname : ''
      }`;

      return user;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const confirmUserEmail = async (email, token) => {
  try {
    const { status } = await http.post(`${baseApiUrl}account/confirm-email`, {
      email,
      token,
    });
    if (status === 200) return true;
  } catch (error) {
    console.log(error);
  }
};

const resendConfirmationEmail = async email => {
  const { status } = await http.post(
    `${baseApiUrl}account/resend-confirmation-email`,
    {
      email,
    },
  );
  if (status === 200) return true;
};

const confirmListingEmail = async (listingId, listingType, token) => {
  try {
    const { status } = await http.post(
      `${baseApiUrl}account/confirm-profile-email`,
      {
        profileId: listingId,
        profileType: listingType,
        token,
      },
    );
    if (status === 200) return true;
  } catch (error) {
    console.log(error);
  }
};

const requestPasswordReset = async email => {
  const { status } = await http.post(
    `${baseApiUrl}account/request-password-reset`,
    {
      email,
    },
  );
  if (status === 200) return true;
};

const resetPassword = async (email, password, token) => {
  const { status } = await http.post(`${baseApiUrl}account/reset-password`, {
    email,
    password,
    token,
  });
  if (status === 200) return true;
};

export default {
  signUp,
  login,
  logOutUser,
  loginUserLocal,
  googleLogin,
  facebookLogin,
  confirmUserEmail,
  resendConfirmationEmail,
  confirmListingEmail,
  requestPasswordReset,
  resetPassword,
  updateCurrentUser,
};
