import React, { Component } from 'react';
import randomstring from 'randomstring';

class CarouselTemp extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    let defaultPerSlide = 3;
    let dataRide = 'carousel';

    const {
      listings,
      itemsPerSlide,
      id,
      controls,
      ride,
      interval,
    } = this.props;
    defaultPerSlide = itemsPerSlide > 0 ? itemsPerSlide : defaultPerSlide;
    dataRide = ride === 'false' ? ride : dataRide;
    const dataInterval = interval ? interval : '5000';

    const { width } = this.state;
    let slides = [];

    if (width > 991) {
      let maxDisplayArray = [];
      slides = [];

      listings &&
        listings.forEach((listing, index) => {
          maxDisplayArray.push(listing);

          if (
            maxDisplayArray.length === defaultPerSlide ||
            index === listings.length - 1
          ) {
            slides.push(
              <div
                className={
                  slides.length === 0
                    ? 'mh-100 h-100 carousel-item active'
                    : 'mh-100 h-100 carousel-item'
                }
                key={randomstring.generate()}
              >
                <div className="mh-100 h-100 d-flex justify-content-around">
                  {maxDisplayArray}
                </div>
              </div>,
            );
            maxDisplayArray = [];
          }
        });
    } else if (width < 992) {
      slides = [];
      listings &&
        listings.forEach((listing, index) => {
          slides.push(
            <div
              className={
                slides.length === 0
                  ? 'mh-100 h-100 carousel-item active'
                  : 'mh-100 h-100 carousel-item'
              }
              key={randomstring.generate()}
            >
              <div className="mh-100 h-100 d-flex justify-content-around px-md-5 mx-md-5">
                {listing}
              </div>
            </div>,
          );
        });
    }

    return (
      <div className="mh-100 h-100">
        <div
          id={id}
          className="mh-100 h-100 carousel slide"
          data-ride={dataRide}
          data-interval={dataInterval}
          data-pause="hover"
        >
          <div className="mh-100 h-100 carousel-inner">{slides}</div>
          {controls && (
            <>
              <a
                className="carousel-control-prev justify-content-start"
                href={'#' + id}
                role="button"
                data-slide="prev"
              >
                <span aria-hidden="true">
                  <i className="fas fa-chevron-left text-footer"></i>
                </span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next justify-content-end"
                href={'#' + id}
                role="button"
                data-slide="next"
              >
                <span aria-hidden="true">
                  <i className="fas fa-chevron-right text-footer"></i>
                </span>
                <span className="sr-only">Next</span>
              </a>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default CarouselTemp;
