import React, { Component } from 'react';
import { startupZoneDomain } from '../../config.js';

class InvestorPaywall extends Component {
  state = {
    selected: 'business',
  };

  addTick(profile) {
    this.setState({
      selected: profile,
    });
  }
  render() {
    return (
      <div className="rounded-1 shadow bg-white p-5 d-flex flex-column custom-paywall">
        <div className="text-center mb-5">
          <p className="font-weight-bold h5 mb-3">
            Create a listing to perform this action
          </p>
          <p className="h7 font-weight-normal">
            Kindly select a category and proceed
          </p>
        </div>
        <div className="d-flex flex-wrap justify-content-center justify-content-md-between mb-5">
          <button
            className="custom-relative  
                      shadow text-center bg-white border-0
                    rounded"
            onClick={() => {
              this.addTick('business');
            }}
          >
            <div
              className={
                'custom-tick' +
                (this.state.selected === 'business' ? ' d-block' : ' d-none')
              }
            >
              <i className="fas fa-check-circle text-primary"></i>
            </div>
            <div className="bg-paywall-business-avatar h5 w-50 mx-auto"></div>
            <p className="text-primary h7 font-weight-bold">Business Seller</p>
          </button>
          <div className="w-100 d-lg-none d-md-none">
            <br />
          </div>
          <button
            className="custom-relative shadow text-center bg-white border-0
                    rounded"
            onClick={() => {
              this.addTick('advisor');
            }}
          >
            <div
              className={
                'custom-tick' +
                (this.state.selected === 'advisor' ? ' d-block' : ' d-none')
              }
            >
              <i className="fas fa-check-circle text-primary"></i>
            </div>
            <div className="bg-paywall-advisor-avatar w-100 mx-auto"></div>
            <p className="text-primary h7 font-weight-bold">
              Professional Advisor
            </p>
          </button>
          <div className="w-100 d-lg-none d-md-none">
            <br />
          </div>
          <button
            className="custom-relative shadow text-center bg-white border-0
                    rounded"
            onClick={() => {
              this.addTick('franchise');
            }}
          >
            <div
              className={
                'custom-tick' +
                (this.state.selected === 'franchise' ? ' d-block' : ' d-none')
              }
            >
              <i className="fas fa-check-circle text-primary"></i>
            </div>
            <div className="bg-paywall-franchise-avatar w-100 mx-auto"></div>
            <p className="text-primary h7 font-weight-bold">Franchise Owner</p>
          </button>

          <div className="w-100 d-lg-none d-md-none">
            <br />
          </div>
          <button
            className="custom-relative shadow text-center bg-white border-0
                    rounded"
            onClick={() => {
              this.addTick('startup');
            }}
          >
            <div
              className={
                'custom-tick' +
                (this.state.selected === 'startup' ? ' d-block' : ' d-none')
              }
            >
              <i className="fas fa-check-circle text-primary"></i>
            </div>
            <div className="bg-paywall-startup-avatar w-100 mx-auto"></div>
            <p className="text-primary h7 font-weight-bold">Startup Owner</p>
          </button>
        </div>
        {this.state.selected === 'business' ? (
          <a
            className="btn btn-primary w-40 mx-auto"
            href="/register-business/plans"
          >
            Proceed
          </a>
        ) : this.state.selected === 'franchise' ? (
          <a
            className="btn btn-primary w-40 mx-auto"
            href="/register-franchise/plans"
          >
            Proceed
          </a>
        ) : this.state.selected === 'advisor' ? (
          <a
            className="btn btn-primary w-40 mx-auto"
            href="/register-advisor/plans"
          >
            Proceed
          </a>
        ) : (
          <a
            className="btn btn-primary w-40 mx-auto"
            href={`${startupZoneDomain}pricing`}
          >
            Proceed
          </a>
        )}
      </div>
    );
  }
}

export default InvestorPaywall;
