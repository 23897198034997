import React, { Component } from 'react';
import Joi from 'joi-browser';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import auth from '../../common/services/authService';
import { CircularProgress } from '@material-ui/core';
import FormAlertMessage from '../../common/components/FormAlertMessage';
import {
  hideNavbar,
  hideFooter,
  showNavbar,
  showFooter,
} from './../../state/actions/layout';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {
        email: '',
      },
      errors: {},
      loading: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.props.hideNavbar();
    this.props.hideFooter();
  }

  schema = {
    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .label('Email'),
  };

  validate() {
    const { error } = Joi.validate(this.state.account, this.schema, {
      abortEarly: false,
    });
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  componentWillUnmount() {
    this.props.showNavbar();
    this.props.showFooter();
  }

  onChange = e => {
    const { name, value } = e.target;
    const account = { ...this.state.account };
    account[name] = value;
    const errors = { ...this.state.errors };
    errors[name] = '';
    this.setState({ account, errors });
  };

  onSubmit = async e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) return;

    try {
      this.setState({ loading: true });
      const result = await auth.requestPasswordReset(this.state.account.email);
      if (result) {
        this.setState({ loading: false });
        this.props.history.replace('/request-password-success');
      } else {
        this.setState({ loading: false });
      }
    } catch (err) {
      this.setState({ loading: false });

      const { response } = err;

      if (response && response.data && response.data.title) {
        let message = response.data.title;
        this.setState({ errorMessage: message });
      }
    }
  };

  render() {
    return (
      <div className="p-3">
        <div className="d-flex justify-content-center fixed-top justify-content-md-between align-items-center px-5 pt-3 mb-3 bg-body">
          <Link to="/" className="nav-link">
            <img
              className="compex-logo"
              src={require('../../assets/img/compex-logo.svg')}
              alt=""
            />
          </Link>
          <div className="d-none d-md-block">
            <span>Know your password?</span>
            <Link to="/login">
              <span className="text-primary font-weight-bold px-2">
                Sign in
              </span>
            </Link>
          </div>
        </div>
        <div className="min-vh-100">
          <div className="text-center mb-5">
            <h3 className="text-primary">Request Password Reset</h3>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-4">
              <form
                onSubmit={this.onSubmit}
                method="POST"
                className="bg-white shadow p-5 rounded font-weight-bold"
                noValidate
              >
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label htmlFor="email">Enter your Email Address</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={this.state.account.email}
                      onChange={this.onChange}
                      className={
                        this.state.errors['email']
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      placeholder="Email Address"
                    />
                    <div className="invalid-feedback">
                      {this.state.errors['email']}
                    </div>
                  </div>
                </div>
                {this.state.errorMessage && (
                  <FormAlertMessage message={this.state.errorMessage} />
                )}
                <div className="row justify-content-center py-3">
                  <div className="col-md-6">
                    <button type="submit" className="btn btn-primary btn-block">
                      {this.state.loading ? (
                        <CircularProgress color="inherit" size={'1rem'} />
                      ) : (
                        'Submit'
                      )}
                    </button>
                  </div>
                </div>
              </form>

              <div className="d-block d-md-none">
                <p className="text-center my-5">
                  Know your Password?{' '}
                  <Link className="text-primary font-weight-bold" to="/login">
                    Sign In
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  hideNavbar,
  hideFooter,
  showFooter,
  showNavbar,
})(ForgotPassword);
