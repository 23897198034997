import React, { Component, Suspense, lazy } from 'react';
import NavBarHome from '../../layout/NavBarHome';
import { connect } from 'react-redux';
import { scrollToRef } from '../../common/utils/helpers';

import {
  hideNavbar,
  hideFooter,
  showNavbar,
  showFooter,
  setHeaders,
  resetHeaders,
} from './../../state/actions/layout';

const HomeSubscribe = lazy(() => import('./HomeSubscribe'));
const HomeConnectSS = lazy(() => import('./HomeConnectSS'));
const HomeConnectBusiness = lazy(() => import('./HomeConnectBusiness'));
const HomeFeatured = lazy(() => import('./HomeFeatured'));
const HomeAsSeenOn = lazy(() => import('./HomeAsSeenOn'));
const HomeOtherProducts = lazy(() => import('./HomeOtherProducts'));
const HomeLanding = lazy(() => import('./HomeLanding'));
const HomePricing = lazy(() => import('./HomePricing'));

export class Home extends Component {
  constructor(props) {
    super(props);
    this.pricing = React.createRef();
  }
  componentDidMount() {
    this.props.hideNavbar();
    this.props.showFooter();
    this.props.setHeaders({
      title: 'Home | CompexAfrica',
      description:
        'Connecting businesses to reliable exit options. CompexAfrica is all about making investments accessible to small and medium enterprises, start-ups, and franchises by connecting them to reliable exit options and providing opportunities for industry advisors to provide services such as: Creating exposure via advertising, Consulting on the best exit options, Sending business proposals to potential clients',
      keywords:
        'business for sale in Nigeria, buying a business, sell my business, sell a business, business brokers, franchise opportunities',
    });
  }

  componentDidUpdate() {
    const location = this.props.location.hash.substring(1);
    if (location) {
      scrollToRef(this[location]);
    }
  }

  componentWillUnmount() {
    this.props.showNavbar();
    this.props.showFooter();
    this.props.resetHeaders();
  }
  render() {
    return (
      <div className="pt-4">
        <Suspense fallback={<div>Loading...</div>}>
          <NavBarHome></NavBarHome>
          <HomeLanding></HomeLanding>
          <HomeFeatured></HomeFeatured>
          <HomeConnectSS></HomeConnectSS>
          <HomeOtherProducts></HomeOtherProducts>
          <HomeConnectBusiness></HomeConnectBusiness>
          <HomeAsSeenOn></HomeAsSeenOn>
          <div
            ref={component => {
              this.pricing = component;
            }}
          >
            <HomePricing history={this.props.history} />
          </div>
          <HomeSubscribe></HomeSubscribe>
        </Suspense>
      </div>
    );
  }
}

export default connect(null, {
  hideNavbar,
  hideFooter,
  showFooter,
  showNavbar,
  setHeaders,
  resetHeaders,
})(Home);
