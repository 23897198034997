import React, { Component } from 'react';
import StartupListingsTable from './StartupListingsTable';
import { connect } from 'react-redux';
import {
  fetchAdminStartups,
  searchAdminStartups,
} from '../../state/actions/startup';
import Pagination from './../../common/components/Pagination';
import { CircularProgress, Switch } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomDatePicker } from './../../common/utils/helpers';
import withSizes from 'react-sizes';

class StartupListings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      startupSearchString: '',
      searchPlaceholder: 'Startup Title',
      status: '',
      byId: false,
      startDate: null,
      endDate: null,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.props.fetchAdminStartups();
  }

  onPageChange = page => {
    const {
      byId,
      startupSearchString,
      status,
      startDate,
      endDate,
    } = this.state;
    if (typeof page === 'number') {
      this.setState({ loading: true });
      this.props.searchAdminStartups(
        startDate,
        endDate,
        startupSearchString,
        status,
        byId,
        page,
      );
    }
  };

  setStartDate(startDate) {
    this.setState({ startDate });
  }

  setEndDate(endDate) {
    this.setState({ endDate });
  }

  setStartupSearchString = searchString => {
    this.setState({ startupSearchString: searchString });
  };

  startupSearch = async e => {
    e.preventDefault();
    const {
      byId,
      startupSearchString,
      status,
      startDate,
      endDate,
    } = this.state;
    if (byId) {
      this.props.searchAdminStartups(
        startDate,
        endDate,
        startupSearchString,
        status,
        byId,
      );
    } else {
      this.props.searchAdminStartups(
        startDate,
        endDate,
        startupSearchString,
        status,
      );
    }
  };

  startupFilter = status => {
    const { byId, startupSearchString, startDate, endDate } = this.state;
    if (byId) {
      this.props.searchAdminStartups(
        startDate,
        endDate,
        startupSearchString,
        status,
        byId,
      );
    } else {
      this.props.searchAdminStartups(
        startDate,
        endDate,
        startupSearchString,
        status,
      );
    }
  };

  handleFilterChange = e => {
    const status = e.target.value;
    this.setState({ status });
    this.startupFilter(status);
  };

  onSwitch = async e => {
    const { checked } = e.target;
    this.setState({
      byId: checked,
      searchPlaceholder: checked ? 'startup ID' : 'startup Title',
    });
  };

  onPreviousPage = () => {
    const {
      byId,
      startupSearchString,
      status,
      startDate,
      endDate,
    } = this.state;
    this.setState({ loading: true });
    this.props.searchAdminStartups(
      startDate,
      endDate,
      startupSearchString,
      status,
      byId,
      this.props.pageNumber - 1,
    );
  };

  onNextPage = () => {
    const {
      byId,
      startupSearchString,
      status,
      startDate,
      endDate,
    } = this.state;
    this.setState({ loading: true });
    this.props.searchAdminStartups(
      startDate,
      endDate,
      startupSearchString,
      status,
      byId,
      this.props.pageNumber + 1,
    );
  };

  render() {
    const {
      adminStartupListings,
      pageNumber,
      pageSize,
      totalCount,
      loadingStartups,
      isMobile,
      isTablet,
    } = this.props;
    const { byId, searchPlaceholder, startDate, endDate } = this.state;
    return (
      <div className="admin-custom">
        <div className="ml-lg-5">
          <h1>Startup Listings Overview</h1>
          <div className="w-50 my-3 border"></div>
          <div className="d-flex flex-wrap mb-5">
            <div className="d-flex flex-column justify-content-around w-100 w-lg-30 w-md-30 text-center bg-white shadow rounded-1 p-4 m-2">
              <p className="h6 mb-4">Total Startups</p>
              <div className="h2 font-weight-bold text-primary">
                {loadingStartups ? (
                  <CircularProgress color="primary" size={'1rem'} />
                ) : (
                  totalCount
                )}
              </div>
            </div>
            <div className="d-block d-lg-none d-md-none w-100"></div>
            <div className="d-flex justify-content-around w-100 w-lg-60 w-md-60 text-center bg-white shadow rounded-1 p-2 m-2">
              <div className="d-flex flex-column justify-content-around">
                <p className="h6 mb-4">From</p>
                <div className="input-group px-2">
                  <DatePicker
                    todayButton="Today"
                    selected={startDate}
                    onChange={date => this.setStartDate(date)}
                    isClearable
                    customInput={<CustomDatePicker ref={this.ref} />}
                    withPortal={isMobile || isTablet}
                  />
                </div>
              </div>
              <div className="d-flex flex-column justify-content-around">
                <p className="h6 mb-4">To</p>
                <div className="input-group">
                  <DatePicker
                    todayButton="Today"
                    selected={endDate}
                    onChange={date => this.setEndDate(date)}
                    isClearable
                    customInput={<CustomDatePicker ref={this.ref} />}
                    withPortal={isMobile || isTablet}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-50 my-3 border"></div>
          <div className=" ml-3">
            <Switch
              color="primary"
              name="filterBy"
              value={byId}
              checked={byId}
              onChange={e => this.onSwitch(e)}
            />
            {byId ? 'By startup ID' : 'By startup Title'}
          </div>

          <div className="d-flex flex-nowrap my-4">
            <div className="form-inline w-70">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder={searchPlaceholder}
                  aria-label={searchPlaceholder}
                  aria-describedby="basic-addon2"
                  onChange={event =>
                    this.setStartupSearchString(event.target.value)
                  }
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline btn-admin-primary"
                    type="button"
                    onClick={this.startupSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>

            <select
              className="browser-default custom-select w-30"
              value={this.state.status}
              onChange={this.handleFilterChange}
            >
              <option value="">See All</option>
              <option value="Approved">Approved</option>
              <option value="Pending">Pending</option>
              <option value="ChangesRequired">Changes Required</option>
              <option value="Draft">Draft</option>
              <option value="Sold">Sold</option>
              <option value="Declined">Declined</option>
              <option value="Deleted">Deleted</option>
            </select>
          </div>
          <div className="d-flex justify-content-between w-lg-60 w-md-60 mb-3">
            <p className="h7 font-weight-normal m-0">Legend: &nbsp;</p>
            <div className="d-flex align-items-center">
              <div className="rounded-circle bg-danger mr-2 status"></div>
              <p className="h7 font-weight-normal m-0">Yet approved</p>
            </div>
            <div className="d-flex align-items-center">
              <div className="rounded-circle bg-warning mr-2 status"></div>
              <p className="h7 font-weight-normal m-0">Approval In progress</p>
            </div>
            <div className="d-flex align-items-center">
              <div className="rounded-circle bg-success mr-2 status"></div>
              <p className="h7 font-weight-normal m-0">Approved</p>
            </div>
          </div>
          <div className="profiles-table">
            <StartupListingsTable
              startups={adminStartupListings}
              loadingStartups={loadingStartups}
            />
            <div className="d-flex justify-content-center my-3">
              <Pagination
                currentPage={pageNumber}
                pageSize={pageSize}
                totalItem={totalCount}
                onPageChange={this.onPageChange}
                onPreviousPage={this.onPreviousPage}
                onNextPage={this.onNextPage}
                hideComponent={loadingStartups ? 'd-none' : ''}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapSizesToProps = sizes => ({
  isMobile: withSizes.isMobile(sizes),
  isTablet: withSizes.isTablet(sizes),
});

export default withSizes(mapSizesToProps)(
  connect(
    state => ({
      loadingStartups: state.startup.loadingStartups,
      adminStartupListings: state.startup.adminStartupListings,
      pageNumber: state.startup.pageNumber,
      pageSize: state.startup.pageSize,
      totalCount: state.startup.totalCount,
    }),
    { fetchAdminStartups, searchAdminStartups },
  )(StartupListings),
);
