import React, { Component } from 'react';
import userService from '../../common/services/userService';
import { Switch } from '@material-ui/core';
import { connect } from 'react-redux';
import FadeIn from 'react-fade-in';
import { CircularProgress } from '@material-ui/core';
import { numberWithCommas } from '../../common/utils/helpers';

class SubscriptionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscription: {},
      renewal: true,
      loading: null,
      done: null,
    };
  }

  async componentDidMount() {
    const id = this.props.match.params['id'];
    if (id) {
      this.setState({
        loading: true,
      });
      const subscription = await userService.getUserSubscription(id);
      if (subscription)
        this.setState({ subscription, renewal: subscription.renewal });
      this.setState({
        loading: false,
        done: true,
      });
    }
  }

  onSwitch = async e => {
    const { checked } = e.target;
    const subscription = { ...this.state.subscription };
    this.setState({ loading: true });
    const res = await userService.changeSubscriptionRenewal(
      subscription.subscriptionId,
      checked,
    );

    if (res) {
      subscription.renewal = checked;
      this.setState({ subscription, renewal: checked, loading: false });
    }
  };

  render() {
    const { subscription, renewal } = this.state;
    return (
      <div className="custom-center">
        {this.state.loading && !this.state.done ? (
          <div
            style={{ minHeight: '60vh' }}
            className="d-flex justify-content-center align-items-center"
          >
            <CircularProgress color="primary" size={'3rem'} />
          </div>
        ) : (
          <FadeIn>
            <div className="ml-lg-5">
              <h1>Invoices and Subscriptions</h1>
              <div className="w-50 my-3 py-3">
                <hr></hr>
              </div>
              {subscription && (
                <div>
                  <div className="mt-lg-5 card shadow">
                    <div className="p-5">
                      <h3 className="mb-4 text-primary">
                        {subscription.listingTitle}
                      </h3>
                      <div className="row mb-3">
                        <div className="col-md-3 col-6">
                          <strong>Current Plan</strong>
                        </div>
                        <div className="col-md-6 col-6">
                          {subscription.planType &&
                            subscription.planType.split(/(?=[A-Z])/)[0]}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-3 col-6">
                          <strong>Plan Price</strong>
                        </div>
                        <div className="col-md-6 col-6">
                          {subscription.planPrice === 0
                            ? 'Free'
                            : `₦ ${numberWithCommas(subscription.planPrice)}`}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-3 col-6">
                          <strong>Start Date</strong>
                        </div>
                        <div className="col-md-6 col-6">
                          {new Date(subscription.planBeginDate).toDateString()}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-3 col-6">
                          <strong>End Date</strong>
                        </div>
                        <div className="col-md-6 col-6">
                          {new Date(subscription.planEndDate).toDateString()}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-3 col-6">
                          <strong>Status</strong>
                        </div>
                        <div className="col-md-6 col-6">
                          {subscription.status}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-3 col-6">
                          <strong>Listing Type</strong>
                        </div>
                        <div className="col-md-6 col-6">
                          {subscription.listingType}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-3 col-6">
                          <strong>Units Assigned</strong>
                        </div>
                        <div className="col-md-6 col-6">
                          {subscription.cycleUnits +
                            subscription.cycleConsumedUnits}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-3 col-6">
                          <strong>Units Consumed</strong>
                        </div>
                        <div className="col-md-6 col-6">
                          {subscription.cycleConsumedUnits}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-3 col-6">
                          <strong>Units Remaining</strong>
                        </div>
                        <div className="col-md-6 col-6">
                          {subscription.cycleUnits}
                        </div>
                      </div>
                      {!subscription.isFree && (
                        <div className="row mb-3">
                          <div className="col-md-3 col-6">
                            <strong>Auto Renew</strong>
                          </div>
                          <div className="col-md-6 col-6">
                            <Switch
                              color="primary"
                              name="renewal"
                              value={renewal}
                              checked={renewal}
                              onChange={e => this.onSwitch(e)}
                              disabled={this.state.loading}
                            />
                            {subscription.renewal ? (
                              this.state.loading ? (
                                <CircularProgress
                                  color="primary"
                                  size={'1rem'}
                                />
                              ) : (
                                'ON'
                              )
                            ) : this.state.loading ? (
                              <CircularProgress color="primary" size={'1rem'} />
                            ) : (
                              'OFF'
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </FadeIn>
        )}
      </div>
    ); //return
  }
}

export default connect(
  state => ({
    subscriptions: state.user.userSubscriptions,
  }),
  {},
)(SubscriptionDetail);
