import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchAdminUserListings } from '../../state/actions/user';
import userService from '../../common/services/userService';
import Swal from 'sweetalert2';

class UserListingTableItem extends Component {
  state = {};

  unsuspendUser = async id => {
    Swal.fire(
      'Are you sure?',
      'Do you want to unsuspend this user?',
      'question',
    ).then(async value => {
      if (!value) return;
      const res = await userService.unsuspendUser(id);
      if (res) {
        this.props.fetchAdminUserListings();
        Swal.fire('User unsuspended successfully', '', 'success');
      } else {
        Swal.fire('Unable to unsuspend user', '', 'error');
      }
    });
  };

  suspendUser = async id => {
    Swal.fire(
      'Are you sure?',
      'Do you want to suspend this user?',
      'question',
    ).then(async value => {
      if (!value) return;
      const res = await userService.suspendUser(id);
      if (res) {
        this.props.fetchAdminUserListings();
        Swal.fire('user suspended successfully', '', 'success');
      } else {
        Swal.fire('Unable to suspend user', '', 'error');
      }
    });
  };

  render() {
    const { listing, number } = this.props;
    return (
      <tr>
        <td>
          <Link to={`/admin/dashboard/users/${listing.id}`}>{number}</Link>
        </td>
        <td>
          <Link to={`/admin/dashboard/users/${listing.id}`}>{listing.id}</Link>
        </td>
        <td>
          <Link to={`/admin/dashboard/users/${listing.id}`}>
            {listing.firstName}
          </Link>
        </td>
        <td>
          <Link to={`/admin/dashboard/users/${listing.id}`}>
            {listing.lastName}
          </Link>
        </td>
        <td>{listing.email}</td>
        <td>{listing.phoneNumber}</td>
        <td>
          {listing.dateRegistered &&
            new Date(listing.dateRegistered).toLocaleString()}
        </td>
        <td>
          <span
            className={
              listing.emailVerified
                ? 'badge badge-success mx-4'
                : 'badge badge-warning mx-4'
            }
          >
            {listing.emailVerified ? 'Verified' : 'Not verified'}
          </span>
        </td>
        <td>
          <div className="d-flex">
            <button
              className="d-block pr-3 border-0 bg-transparent text-primary"
              id={`${listing.id}`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <button
                className="dropdown-item"
                onClick={() => this.suspendUser(listing.id)}
              >
                Suspend
              </button>
              <button
                className="dropdown-item"
                onClick={() => this.unsuspendUser(listing.id)}
              >
                Un-suspend
              </button>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default connect(null, { fetchAdminUserListings })(UserListingTableItem);
