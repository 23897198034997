import React from 'react';
import { Link } from 'react-router-dom';
import SignUpForm from './SignUpForm';

const SignUp = props => {
  return (
    <div className="p-3">
      <div className="d-flex justify-content-center fixed-top justify-content-md-between align-items-center px-5 pt-3 mb-3 bg-body">
        <Link to="/" className="nav-link">
          <img
            className="compex-logo"
            src={require('../../assets/img/compex-logo.svg')}
            alt=""
          />
        </Link>
        <div className="d-none d-md-block">
          <span>Have an account?</span>
          <Link to="/login" className="text-primary font-weight-bold px-2">
            Sign in
          </Link>
        </div>
      </div>
      <div className="min-vh-100">
        <div className="text-center mb-5">
          <h3 className="text-primary">Create a new account</h3>
          <h6>Let's get you started on compex africa</h6>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-7">
            <SignUpForm {...props} />
            <div className="d-block d-md-none">
              <p className="text-center my-5">
                Already have an account?
                <Link
                  className="text-primary font-weight-bold pl-2"
                  to="/login"
                >
                  Sign in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
