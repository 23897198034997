import React, { Component } from 'react';
import Joi from 'joi-browser';
import { connect } from 'react-redux';
import equal from 'fast-deep-equal';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Stepper from 'react-stepper-horizontal';
import listing from '../../common/services/listingsService';
import { editListing } from '../../state/actions/business';
import { Tooltip } from '@material-ui/core';
import Octicon, { Info } from '@primer/octicons-react';
import Select from 'react-select';
import { CircularProgress } from '@material-ui/core';
import { scrollToRef } from '../../common/utils/helpers';

class AddBusinessForm2 extends Component {
  state = {
    formdata: {
      businessExplanation: '',
      howBusinessMakeMoney: '',
      facilityDescription: '',
      howToImprove: '',
      numberOfStaff: '',
      businessStrength: '',
      newOwnerOpportunities: '',
      daysOperations: '',
      marketing: '',
      whySelling: '',
      postSaleSupport: '',
    },
    id: 0,
    files: [],
    errors: {},
    previousData: {},
    businessStrength: null,
    daysOperations: null,
    newOwnerOpportunities: null,
    marketing: null,
    activeStep: 1,
    isSaving: false,
    reApprovalWarning: {},
    reApprovalRequiredFields: [
      'businessExplanation',
      'howBusinessMakeMoney',
      'facilityDescription',
      'howToImprove',
      'whySelling',
    ],
  };

  componentDidMount() {
    const { id } = queryString.parse(this.props.location.search);

    if (id) {
      this.props.editListing(id);
      this.autofillFormFields();
    } else {
      this.props.history.replace('/register-business');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!equal(this.props.formdata, prevProps.formdata)) {
      this.autofillFormFields();
    }
    if (!equal(this.state, prevState)) {
      this.getMultiSelectValue();
    }
  }

  multiSelectOptions = {
    marketingOptions: [
      { value: 'Youtube Advertisement', label: 'Youtube Advertisement' },
      { value: 'Native Advertising', label: 'Native Advertising' },
      { value: 'Partnerships', label: 'Partnerships' },
      { value: 'SEM', label: 'SEM' },
      { value: 'SEO', label: 'SEO' },
      { value: 'Sponsorships', label: 'Sponsorships' },
      { value: 'Twitter', label: 'Twitter' },
      { value: 'Webinars', label: 'Webinars' },
      { value: 'Word of Mouth', label: 'Word of Mouth' },
      {
        value: 'Youtube Channel Management',
        label: 'Youtube Channel Management',
      },
      { value: 'Instagram Advertising', label: 'Instagram Advertising' },
      { value: 'Facebook Advertising', label: 'Facebook Advertising' },
      { value: 'Other Advertising', label: 'Other Advertising' },
    ],

    daysOperationsOptions: [
      { value: 'Bookkeeping', label: 'Bookkeeping' },
      { value: 'Design and Production', label: 'Design and Production' },
      { value: 'Launching New Products', label: 'Launching New Products' },
      { value: 'Managing Inventory', label: 'Managing Inventory' },
      { value: 'SEO', label: 'SEO' },
      { value: 'Reporting', label: 'Reporting' },
      {
        value: 'Supplier Relationship Management',
        label: 'Supplier Relationship Management',
      },
      { value: 'Order Assembly', label: 'Order Assembly' },
      { value: 'Order Fulfillment', label: 'Order Fulfillment' },
      {
        value: 'Product Sourcing and Design',
        label: 'Product Sourcing and Design',
      },
      { value: 'Website Maintenance', label: 'Website Maintenance' },
      { value: 'Programming/Development', label: 'Programming/Development' },
      { value: 'Content marketing', label: 'Content marketing' },
      { value: 'Adwords Maintenance', label: 'Adwords Maintenance' },
      { value: 'Product Photography', label: 'Product Photography' },
    ],

    newOwnerOpportunitiesOptions: [
      {
        value: 'Start Selling into New Markets',
        label: 'Start Selling into New Markets',
      },
      {
        value: 'Capitalise on the Emerging Market',
        label: 'Capitalise on the Emerging Market',
      },
      {
        value: 'Improve Current Marketing Efforts',
        label: 'Improve Current Marketing Efforts',
      },
      {
        value: 'Introduce Further Revenue Streams',
        label: 'Introduce Further Revenue Streams',
      },
      {
        value: 'Improve or Outsource Operations',
        label: 'Improve or Outsource Operations',
      },
      {
        value: 'Advance or Explore Retail/Distribution Partnerships',
        label: 'Advance or Explore Retail/Distribution Partnerships',
      },
      {
        value: 'Supplier Relationship Management',
        label: 'Supplier Relationship Management',
      },
      { value: 'Improve Customer Service', label: 'Improve Customer Service' },
      { value: 'Develop New Features', label: 'Develop New Features' },
      {
        value: 'Increase Basket Size Per Customer',
        label: 'Increase Basket Size Per Customer',
      },
    ],

    businessStrengthOptions: [
      {
        value: 'The Business is Currently Growing',
        label: 'The Business is Currently Growing',
      },
      { value: 'Turn-key Operations', label: 'Turn-key Operations' },
      {
        value: 'Well Established and Unique Brand',
        label: 'Well Established and Unique Brand',
      },
      { value: 'Overall Market Size', label: 'Overall Market Size' },
      {
        value: 'Low Acquisition Cost of New Customers',
        label: 'Low Acquisition Cost of New Customers',
      },
    ],
  };

  autofillFormFields() {
    const { formdata } = this.props;

    if (formdata && formdata.id) {
      const form = { ...this.state.formdata };
      form.businessExplanation = formdata.businessExplanation || '';
      form.howBusinessMakeMoney = formdata.howBusinessMakeMoney || '';
      form.facilityDescription = formdata.facilityDescription || '';
      form.howToImprove = formdata.howToImprove || '';
      form.numberOfStaff = formdata.numberOfStaff || '';
      form.businessStrength = formdata.businessStrength || '';
      form.newOwnerOpportunities = formdata.newOwnerOpportunities || '';
      form.daysOperations = formdata.daysOperations || '';
      form.marketing = formdata.marketing || '';
      form.whySelling = formdata.whySelling || '';
      form.postSaleSupport = `${formdata.postSaleSupport}`;

      if (form.postSaleSupport === 'true') {
        this['yesRadio'].checked = true;
      } else {
        this['noRadio'].checked = true;
      }

      let photoFiles = formdata.files.filter(
        file => file.fileGroup === 'Photos',
      );
      let files = photoFiles.map(file => {
        return {
          name: file.name,
          type: file.type,
          size: file.size,
          base64Url: file.fileUrl,
        };
      });

      this.setState({
        formdata: form,
        files,
        id: formdata.id,
        previousData: formdata,
        activeStep: 3,
      });
    }
  }

  schema = {
    businessExplanation: Joi.string()
      .required()
      .label('Field'),
    howBusinessMakeMoney: Joi.string()
      .required()
      .label('Field'),
    facilityDescription: Joi.string()
      .required()
      .label('Field'),
    howToImprove: Joi.optional(),
    numberOfStaff: Joi.string()
      .required()
      .label('Field'),
    businessStrength: Joi.string()
      .required()
      .label('Field'),
    newOwnerOpportunities: Joi.string()
      .required()
      .label('Field'),
    daysOperations: Joi.string()
      .required()
      .label('Field'),
    marketing: Joi.string()
      .required()
      .label('Field'),
    whySelling: Joi.string()
      .required()
      .label('Field'),
    postSaleSupport: Joi.string()
      .required()
      .label('Field'),
  };

  validate() {
    const { error } = Joi.validate(this.state.formdata, this.schema, {
      abortEarly: false,
    });
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  onChange = e => {
    const { name, value } = e.target;
    const formdata = { ...this.state.formdata };
    formdata[name] = value;
    const errors = { ...this.state.errors };
    errors[name] = '';
    this.setState({ formdata, errors });
  };

  onFocusTextFields = e => {
    const { previousData: business, reApprovalRequiredFields } = this.state;
    if (business.isApproved) {
      const { name } = e.target;
      if (reApprovalRequiredFields.some(x => x === name)) {
        const { reApprovalWarning: oldData } = this.state;
        const reApprovalWarning = { ...oldData };
        reApprovalWarning[name] =
          'Editing this field will require Admin reapproval';
        this.setState({ reApprovalWarning });
      }
    }
  };

  onBlurTextFields = e => {
    const { previousData: business, reApprovalRequiredFields } = this.state;
    if (business.isApproved) {
      const { name, value } = e.target;
      if (reApprovalRequiredFields.some(x => x === name)) {
        if (value === business[name]) {
          const { reApprovalWarning: oldData } = this.state;
          const reApprovalWarning = { ...oldData };
          reApprovalWarning[name] = '';
          this.setState({ reApprovalWarning });
        }
      }
    }
  };

  getMultiSelectValue = () => {
    const fields = [
      'businessStrength',
      'newOwnerOpportunities',
      'daysOperations',
      'marketing',
    ];
    const state = { ...this.state };
    fields.forEach(field => {
      let options = [];
      const value = this.state.formdata[field].split(',');
      if (value[0] === '') {
        return;
      }
      value.forEach(option => {
        options.push({ value: option.trim(), label: option.trim() });
      });
      state[field] = options;
    });
    this.setState(state);
  };

  onMultiSelect = (selectedOptions, name, maxOptions = 1000) => {
    const state = { ...this.state };
    state[name] = selectedOptions;
    this.setState(state);

    //Dynamically limit selectable options
    if (selectedOptions && selectedOptions.length >= maxOptions) {
      this.multiSelectOptions[name + 'Options'].forEach(option => {
        option['disabled'] = 'yes';
      });
    } else {
      this.multiSelectOptions[name + 'Options'].forEach(option => {
        option['disabled'] = 'no';
      });
    }

    //Stringify selected options
    let value = '';
    if (selectedOptions) {
      selectedOptions.forEach((option, index) => {
        value =
          index === 0 ? value + option.value : value + ', ' + option.value;
      });
    }

    //Update state
    const formdata = { ...this.state.formdata };
    formdata[name] = value;
    const errors = { ...this.state.errors };
    errors[name] = '';
    this.setState({ formdata, errors });
  };

  onHandleUpload = async e => {
    const file = e.target.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      Swal.fire('Error', 'Only Jpeg and Png images are allowed', 'error');
    } else {
      if (file && file.size <= 2000000) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = () => {};
        reader.onloadend = () => {
          const base64Url = reader.result;
          if (base64Url) {
            const newFile = {
              name: file.name,
              type: file.type,
              size: file.size,
              base64Url: reader.result,
            };
            const files = [...this.state.files];
            files.push(newFile);
            this.setState({ files });
          }
        };
      } else {
        Swal.fire('Error', 'File is too large', 'error');
      }
    }
  };

  onDeleteFile = file => {
    const files = [...this.state.files];
    const filteredFiles = files.filter(x => x.name !== file.name);
    this.setState({ files: filteredFiles });
  };

  onSubmit = async e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) {
      const firstError = this[Object.keys(errors)[0]];
      scrollToRef(firstError);
      firstError.focus({ preventScroll: true });
      return;
    }

    // post data to server
    const { formdata, previousData } = this.state;

    previousData.businessExplanation = formdata.businessExplanation;
    previousData.howBusinessMakeMoney = formdata.howBusinessMakeMoney;
    previousData.facilityDescription = formdata.facilityDescription;
    previousData.howToImprove = formdata.howToImprove;
    previousData.numberOfStaff = formdata.numberOfStaff;
    previousData.businessStrength = formdata.businessStrength;
    previousData.newOwnerOpportunities = formdata.newOwnerOpportunities;
    previousData.daysOperations = formdata.daysOperations;
    previousData.marketing = formdata.marketing;
    previousData.whySelling = formdata.whySelling;
    previousData.postSaleSupport = formdata.postSaleSupport;
    previousData.businessPhotos = this.state.files;
    let fileGroupCode = [];
    const photos = 1;
    fileGroupCode.push(photos);
    previousData.fileGroupCode = fileGroupCode;

    try {
      this.setState({ isSaving: true });
      const res = await listing.register(previousData);

      if (res) {
        this.setState({ isSaving: false });
        this.props.history.replace('/register-business/3?id=' + res.id);
      } else {
        this.setState({ isSaving: false });
      }
    } catch (error) {
      this.setState({ isSaving: false });
    }
  };

  render() {
    const state = this.state;
    return (
      <div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="d-flex justify-content-center flex-column text-center my-3">
              <h3 className="text-primary font-weight-bold">
                Register a business
              </h3>
              <p className="text-muted">
                Kindly fill out the following information as accurate as
                possible
              </p>
              <div>
                <Stepper
                  steps={[
                    { href: `/register-business/1?id=${this.state.id}` },
                    { href: `/register-business/2?id=${this.state.id}` },
                    { href: `/register-business/3?id=${this.state.id}` },
                    { href: `/register-business/4?id=${this.state.id}` },
                  ]}
                  activeStep={this.state.activeStep}
                  size={45}
                  circleFontSize={22}
                  titleTop={15}
                  defaultBarColor="#002880"
                  completeBarColor="#002880"
                  activeColor="#002880"
                  completeColor="#002880"
                  defaultColor="#555"
                />
              </div>
              <h3 className="font-weight-bold mt-5 mb-2">
                General Information
              </h3>
              <p className="text-muted">
                All fields are required except stated otherwise
              </p>
            </div>
            <form onSubmit={this.onSubmit} method="POST" noValidate>
              <div className="form-row bg-white shadow rounded-2 py-5 px-3">
                <div className="form-group col-md-6 px-md-4">
                  <label
                    htmlFor="businessExplanation"
                    className="d-inline-flex"
                  >
                    Describe highlights of the business{' '}
                    <span className="text-danger text-small">*</span>
                    <Tooltip
                      title="Example: On demand logistics startup, 
                    that acts as an intermediary between merchants and the 
                    prospective buyers who wish to deliver products from local merchants to customers seamlessly. The company has developed a web and mobile app solution to address an estimated $12 billion e-commerce market in Nigeria."
                    >
                      <div>
                        &nbsp;
                        <Octicon icon={Info} />
                      </div>
                    </Tooltip>
                  </label>
                  {this.state.reApprovalWarning['businessExplanation'] && (
                    <div className="text-danger small mb-2">
                      <em>
                        Note:{' '}
                        {this.state.reApprovalWarning['businessExplanation']}
                      </em>
                    </div>
                  )}
                  <textarea
                    id="businessExplanation"
                    name="businessExplanation"
                    onChange={this.onChange}
                    onFocus={this.onFocusTextFields}
                    onBlur={this.onBlurTextFields}
                    value={state.formdata.businessExplanation}
                    rows="3"
                    className={
                      this.state.errors['businessExplanation']
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    ref={elem => {
                      this.businessExplanation = elem;
                    }}
                  />
                  <div className="invalid-feedback">
                    {this.state.errors['businessExplanation']}
                  </div>
                  <em className="text-sm-1 text-danger">
                    Note: Do not include buisness name or contact information
                    here.
                  </em>
                </div>

                <div className="form-group col-md-6 px-md-4">
                  <label
                    htmlFor="howBusinessMakeMoney"
                    className="d-inline-flex"
                  >
                    How does your business make money?{' '}
                    <span className="text-danger text-small">*</span>
                    <Tooltip
                      title="We offer 4 main products: 1) A human resource management solution
                  2) An e-commerce platform for international security brands and security projects
                  3) A closed user group (CUG) service
                  4) A web-based mobile communications platform for sending bulk SMS."
                    >
                      <div>
                        &nbsp;
                        <Octicon icon={Info} />
                      </div>
                    </Tooltip>
                  </label>
                  {this.state.reApprovalWarning['howBusinessMakeMoney'] && (
                    <div className="text-danger small mb-2">
                      <em>
                        Note:{' '}
                        {this.state.reApprovalWarning['howBusinessMakeMoney']}
                      </em>
                    </div>
                  )}
                  <textarea
                    id="howBusinessMakeMoney"
                    name="howBusinessMakeMoney"
                    onChange={this.onChange}
                    onFocus={this.onFocusTextFields}
                    onBlur={this.onBlurTextFields}
                    value={state.formdata.howBusinessMakeMoney}
                    rows="3"
                    className={
                      this.state.errors['howBusinessMakeMoney']
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    ref={elem => {
                      this.howBusinessMakeMoney = elem;
                    }}
                  />
                  <div className="invalid-feedback">
                    {this.state.errors['howBusinessMakeMoney']}
                  </div>
                  <em className="text-sm-1 text-danger">
                    Note: Do not include buisness name or contact information
                    here.
                  </em>
                </div>

                <div className="form-group col-md-6 px-md-4">
                  <label
                    htmlFor="facilityDescription"
                    className="d-inline-flex"
                  >
                    Describe your facility such as build up area{' '}
                    <span className="text-danger text-small">*</span>
                    <Tooltip title="Give details of your facility such as built-up areas, number of floors, rental/lease details">
                      <div>
                        &nbsp;
                        <Octicon icon={Info} />
                      </div>
                    </Tooltip>
                  </label>
                  {this.state.reApprovalWarning['facilityDescription'] && (
                    <div className="text-danger small mb-2">
                      <em>
                        Note:{' '}
                        {this.state.reApprovalWarning['facilityDescription']}
                      </em>
                    </div>
                  )}
                  <textarea
                    id="facilityDescription"
                    name="facilityDescription"
                    onChange={this.onChange}
                    onFocus={this.onFocusTextFields}
                    onBlur={this.onBlurTextFields}
                    value={state.formdata.facilityDescription}
                    rows="3"
                    className={
                      this.state.errors['facilityDescription']
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    ref={elem => {
                      this.facilityDescription = elem;
                    }}
                  />
                  <div className="invalid-feedback">
                    {this.state.errors['facilityDescription']}
                  </div>
                  <em className="text-sm-1 text-danger">
                    Note: Do not include buisness name or contact information
                    here.
                  </em>
                </div>

                <div className="form-group col-md-6 px-md-4">
                  <label htmlFor="howToImprove">
                    How can the future owner improve this business? (optional)
                  </label>
                  {this.state.reApprovalWarning['howToImprove'] && (
                    <div className="text-danger small mb-2">
                      <em>
                        Note: {this.state.reApprovalWarning['howToImprove']}
                      </em>
                    </div>
                  )}
                  <textarea
                    id="howToImprove"
                    name="howToImprove"
                    onChange={this.onChange}
                    onFocus={this.onFocusTextFields}
                    onBlur={this.onBlurTextFields}
                    value={state.formdata.howToImprove}
                    rows="3"
                    className={
                      this.state.errors['howToImprove']
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    ref={elem => {
                      this.howToImprove = elem;
                    }}
                  />
                  <div className="invalid-feedback">
                    {this.state.errors['howToImprove']}
                  </div>
                  <em className="text-sm-1 text-danger">
                    Note: Do not include buisness name or contact information
                    here.
                  </em>
                </div>

                <div className="form-group col-md-6 px-md-4">
                  <label htmlFor="numberOfStaff">
                    How many staff do you employ?{' '}
                    <span className="text-danger text-small">*</span>
                  </label>
                  <select
                    type="text"
                    id="numberOfStaff"
                    name="numberOfStaff"
                    onChange={this.onChange}
                    value={state.formdata.numberOfStaff}
                    className={
                      this.state.errors['numberOfStaff']
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    ref={elem => {
                      this.numberOfStaff = elem;
                    }}
                  >
                    <option value="">Select</option>
                    <option value="1">1</option>
                    <option value="2-10">2-10</option>
                    <option value="11-50">11-50</option>
                    <option value="51-200">51-200</option>
                    <option value="201-500">201-500</option>
                    <option value="501-1000">501-1000</option>
                    <option value="1001-5000">1001-5000</option>
                    <option value="5001-10,000">5001-10,000</option>
                    <option value="10,000+">10,000+</option>
                  </select>
                  <div className="invalid-feedback">
                    {this.state.errors['numberOfStaff']}
                  </div>
                </div>

                <div
                  className="form-group col-md-6 px-md-4"
                  ref={elem => {
                    this.businessStrength = elem;
                  }}
                >
                  <label htmlFor="businessStrength">
                    Business strengths{' '}
                    <span className="text-danger text-small">*</span>
                  </label>
                  <p className="small">
                    <em>Add up to 5 strengths</em>
                  </p>
                  <Select
                    options={this.multiSelectOptions.businessStrengthOptions}
                    isOptionDisabled={option => option.disabled === 'yes'}
                    isMulti
                    id="businessStrength"
                    value={this.state.businessStrength}
                    onChange={selectedOptions => {
                      this.onMultiSelect(
                        selectedOptions,
                        'businessStrength',
                        5,
                      );
                    }}
                    className={
                      this.state.errors['businessStrength'] ? 'is-invalid' : ''
                    }
                  />
                  <div className="invalid-feedback">
                    {this.state.errors['businessStrength']}
                  </div>
                </div>

                <div
                  className="form-group col-md-6 px-md-4"
                  ref={elem => {
                    this.newOwnerOpportunities = elem;
                  }}
                >
                  <label htmlFor="newOwnerOpportunities">
                    Opportunities for a new owner{' '}
                    <span className="text-danger text-small">*</span>
                  </label>
                  <p className="small">
                    <em>Add up to 3 opportunities</em>
                  </p>
                  <Select
                    options={
                      this.multiSelectOptions.newOwnerOpportunitiesOptions
                    }
                    isOptionDisabled={option => option.disabled === 'yes'}
                    isMulti
                    id="newOwnerOpportunities"
                    value={this.state.newOwnerOpportunities}
                    onChange={selectedOptions => {
                      this.onMultiSelect(
                        selectedOptions,
                        'newOwnerOpportunities',
                        3,
                      );
                    }}
                    className={
                      this.state.errors['newOwnerOpportunities']
                        ? 'is-invalid'
                        : ''
                    }
                  />
                  <div className="invalid-feedback">
                    {this.state.errors['newOwnerOpportunities']}
                  </div>
                </div>

                <div
                  className="form-group col-md-6 px-md-4"
                  ref={elem => {
                    this.daysOperations = elem;
                  }}
                >
                  <label htmlFor="daysOperations">
                    Key day-to-day operations{' '}
                    <span className="text-danger text-small">*</span>
                  </label>
                  <p className="small">
                    <em>Add up to 3 key operations</em>
                  </p>
                  <Select
                    options={this.multiSelectOptions.daysOperationsOptions}
                    isOptionDisabled={option => option.disabled === 'yes'}
                    isMulti
                    id="daysOperations"
                    value={this.state.daysOperations}
                    onChange={selectedOptions => {
                      this.onMultiSelect(selectedOptions, 'daysOperations', 3);
                    }}
                    className={
                      this.state.errors['daysOperations'] ? 'is-invalid' : ''
                    }
                  />
                  <div className="invalid-feedback">
                    {this.state.errors['daysOperations']}
                  </div>
                </div>

                <div
                  className="form-group col-md-6 px-md-4"
                  ref={elem => {
                    this.marketing = elem;
                  }}
                >
                  <label htmlFor="marketing">
                    Marketing <span className="text-danger text-small">*</span>
                  </label>
                  <p className="small">
                    <em>Add up to 3 key marketing tactics</em>
                  </p>
                  <Select
                    options={this.multiSelectOptions.marketingOptions}
                    isOptionDisabled={option => option.disabled === 'yes'}
                    isMulti
                    id="marketing"
                    value={this.state.marketing}
                    onChange={selectedOptions => {
                      this.onMultiSelect(selectedOptions, 'marketing', 3);
                    }}
                    className={
                      this.state.errors['marketing'] ? 'is-invalid' : ''
                    }
                  />
                  <div className="invalid-feedback">
                    {this.state.errors['marketing']}
                  </div>
                </div>

                <div className="form-group col-md-6 px-md-4">
                  <label htmlFor="whySelling">
                    Why are you selling this business{' '}
                    <span className="text-danger text-small">*</span>
                  </label>
                  {this.state.reApprovalWarning['whySelling'] && (
                    <div className="text-danger small mb-2">
                      <em>
                        Note: {this.state.reApprovalWarning['whySelling']}
                      </em>
                    </div>
                  )}
                  <textarea
                    id="whySelling"
                    name="whySelling"
                    onChange={this.onChange}
                    onFocus={this.onFocusTextFields}
                    onBlur={this.onBlurTextFields}
                    value={state.formdata.whySelling}
                    rows="3"
                    className={
                      this.state.errors['whySelling']
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    ref={elem => {
                      this.whySelling = elem;
                    }}
                  />
                  <div className="invalid-feedback">
                    {this.state.errors['whySelling']}
                  </div>
                  <em className="text-sm-1 text-danger">
                    Note: Do not include buisness name or contact information
                    here.
                  </em>
                </div>

                <div
                  className="form-group col-md-6 px-md-4"
                  ref={elem => {
                    this.postSaleSupport = elem;
                  }}
                >
                  <label htmlFor="listingType" className="d-block">
                    Will you or a staff member provide post sale support?{' '}
                    <span className="text-danger text-small">*</span>
                  </label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="yes"
                      value="true"
                      name="postSaleSupport"
                      onChange={this.onChange}
                      ref={radio => {
                        this.yesRadio = radio;
                      }}
                    />
                    <label className="form-check-label" htmlFor="yes">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="no"
                      value="false"
                      name="postSaleSupport"
                      onChange={this.onChange}
                      ref={radio => {
                        this.noRadio = radio;
                      }}
                    />
                    <label className="form-check-label" htmlFor="no">
                      No
                    </label>
                  </div>
                  <div className="invalid-feedback">
                    {this.state.errors['postSaleSupport']}
                  </div>
                </div>

                <div className="form-group col-md-6 px-md-4">
                  <h4>
                    Upload photos{' '}
                    <span className="text-sm-1">
                      (Max file size 2MB) optional
                    </span>
                  </h4>
                  <label className="small" htmlFor="">
                    Kindly provide quality images of the business. <br />
                    Please ensure that the images do not reveal the identity of
                    the business
                  </label>
                  <div className="upload-btn-wrapper">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-lg"
                    >
                      Upload images
                    </button>
                    <input
                      type="file"
                      name="file"
                      onChange={this.onHandleUpload}
                    />
                  </div>
                  <div>
                    {this.state.files.map(file => (
                      <div className="mb-2" key={file.name}>
                        {file.type === 'application/pdf' ? (
                          <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                        ) : (
                          <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                        )}
                        <span className="small pr-3">
                          <a
                            href={file.base64Url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {file.name}
                          </a>
                        </span>
                        <i
                          className="far fa-times-circle text-danger cursor-pointer"
                          onClick={() => this.onDeleteFile(file)}
                        ></i>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-around align-items-around w-100 py-5">
                <Link
                  to={`/register-business?id=${this.state.id}`}
                  className="btn btn-lg btn-outline-primary text-primary bg-white w-lg-30 w-sm-60 w-xs-60 mb-3 mb-lg-0 mb-xl-0"
                >
                  Back
                </Link>
                <button
                  type="submit"
                  className="btn btn-primary btn-lg w-lg-30 w-sm-60 w-xs-60"
                >
                  {this.state.isSaving ? (
                    <CircularProgress color="inherit" size={'1rem'} />
                  ) : (
                    'Save and continue'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    formdata: state.business.formdata,
  }),
  { editListing },
)(AddBusinessForm2);
