import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';
import startupService from '../../common/services/startupService';
import { fetchAdminStartups } from '../../state/actions/startup';
import { getAbbreviation } from './../../common/utils/helpers';
import profileStatus from './../../common/utils/profileStatus';
import { startupZoneDomain } from '../../config';

class StartupListingsTableItem extends Component {
  MySwal = withReactContent(Swal);
  state = {};

  approveStartup = async id => {
    Swal.fire({
      title: `Are you sure?
      Do you want to approve listing?
      `,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await startupService.approveStartup(id);
          if (!response) {
            throw new Error(response.statusText);
          }
          return response;
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
      if (result.value === true) {
        this.props.fetchAdminStartups();
        Swal.fire('Listing Approved Successfully', '', 'success');
      }
    });
  };

  disapproveStartup = async id => {
    Swal.fire({
      title: `Are you sure?
      Do you want to disapprove listing?
      `,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await startupService.disapproveStartup(id);
          if (!response) {
            throw new Error(response.statusText);
          }
          return response;
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
      if (result.value === true) {
        this.props.fetchAdminStartups();
        Swal.fire('Listing Disapproved Successfully', '', 'success');
      }
    });
  };

  render() {
    const { startup, number } = this.props;
    return (
      <tr>
        <td>
          <a
            href={`${startupZoneDomain}startups/${startup.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {number}
          </a>
        </td>
        <td>
          <a
            href={`${startupZoneDomain}startups/${startup.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {startup.id}
          </a>
        </td>
        <td>
          <a
            href={`${startupZoneDomain}startups/${startup.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>{startup.startupName}</div>
          </a>
        </td>
        <td>
          <span className="mx-2">
            {startup.locationState + ', ' + startup.locationCountry}
          </span>
        </td>
        <td>
          {startup.createdAt && new Date(startup.createdAt).toLocaleString()}
        </td>
        <td>
          <span className="font-weight-bold pr-1"></span>₦
          {getAbbreviation(startup.investmentNeeded)}
        </td>
        <td>
          <div className="d-flex">
            <span
              className={
                startup.isApproved
                  ? 'badge badge-success mx-4'
                  : startup.status === profileStatus.Pending ||
                    startup.status === profileStatus.ChangesRequired
                  ? 'badge badge-warning mx-4'
                  : 'badge'
              }
            >
              {startup.isApproved ? 'Approved' : 'Pending'}
            </span>
            <button
              className=" button-as-link d-block pr-3"
              id={`${startup.id}`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              {startup.isApproved && (
                <button
                  className="dropdown-item cursor-pointer"
                  onClick={() => this.disapproveStartup(startup.id)}
                >
                  Disapprove
                </button>
              )}
              {!startup.isApproved && (
                <button
                  className="dropdown-item cursor-pointer"
                  onClick={() => this.approveStartup(startup.id)}
                >
                  Approve
                </button>
              )}

              <button className="dropdown-item">Hide</button>
              <button className="dropdown-item">Show</button>
              <button className="dropdown-item">Archive</button>
              <button className="dropdown-item">Mark as closed/Sold</button>
            </div>
          </div>
        </td>
        <td>
          <a
            href={`${startupZoneDomain}startups/${startup.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>{startup.email}</div>
          </a>
        </td>
        <td>
          <a
            href={`${startupZoneDomain}startups/${startup.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>{startup.phoneNumber}</div>
          </a>
        </td>
        <td>{startup.industry}</td>

        <td>{startup.plan}</td>
        <td>
          <a
            href={`${startupZoneDomain}startups/${startup.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>{startup.firstName}</div>
          </a>
        </td>
        <td>
          <a
            href={`${startupZoneDomain}startups/${startup.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>{startup.lastName}</div>
          </a>
        </td>
      </tr>
    );
  }
}

export default connect(null, { fetchAdminStartups })(StartupListingsTableItem);
