import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import Swal from 'sweetalert2';
import listingsService from '../../common/services/listingsService';
import franchiseService from '../../common/services/franchiseService';
import { getAbbreviation, spacePascalWord } from './../../common/utils/helpers';
import profileStatus from './../../common/utils/profileStatus';
import businessSectors from '../../common/utils/businessSectors';

class UserBusinessFranchiseItem extends Component {
  state = {};

  onSwitch = e => {
    let { availability } = { ...this.state };
    availability = e.target.checked;
    this.setState({ availability });
  };

  deleteBusiness = async id => {
    Swal.fire(
      'Are you sure?',
      'Do you want to delete listing?',
      'warning',
    ).then(async ok => {
      if (ok.value) {
        const res = await listingsService.deleteListing(id);
        if (res) {
          Swal.fire('Listing deleted Successfully', '', 'success').then(() => {
            this.props.refreshListings();
          });
        } else {
          Swal.fire('Unable to delete listing', '', 'error');
        }
      }
    });
  };

  deleteFranchise = async id => {
    Swal.fire(
      'Are you sure?',
      'Do you want to delete listing?',
      'warning',
    ).then(async ok => {
      if (!ok.value) return;
      const res = await franchiseService.deleteFranchise(id);
      if (res) {
        Swal.fire('Listing deleted Successfully', '', 'success').then(() => {
          this.props.refreshListings();
        });
      } else {
        Swal.fire('Unable to delete listing', '', 'error');
      }
    });
  };

  render() {
    const { listing, whichListing } = this.props;
    const imgUrl =
      listing.photos && listing.photos.length > 0
        ? listing.photos[0].fileUrl
        : listing.sector && businessSectors[listing.sector.replace(/[- ]/g, '')]
        ? businessSectors[listing.sector.replace(/[- ]/g, '')].defaultImage
        : businessSectors.default.defaultImage;
    const bgImgStyle = {
      background:
        'linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,.1)), url("' +
        imgUrl +
        '")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      height: '120px',
    };

    return (
      <React.Fragment>
        {whichListing === 'business' ? (
          <div className="listing-item">
            <div className="card rounded-2 mb-5 mx-2 listing-item-shadow">
              <div
                style={bgImgStyle}
                className="rounded-t-2 pt-2 d-flex justify-content-end"
              >
                <div className="dropdown">
                  <button
                    className="button-as-link d-block pr-3 text-white"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="h4 font-weight-bold fas fa-ellipsis-v"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Link
                      to={`/register-business/1?id=${listing.id}`}
                      className="dropdown-item h7"
                    >
                      Edit
                    </Link>
                    <button
                      className="dropdown-item h7"
                      onClick={() => this.deleteBusiness(listing.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <p className="card-title h5 text-primary">
                  <Truncate lines={2} ellipsis={<span>...</span>}>
                    {listing.shortDescription}
                  </Truncate>
                </p>
                <div className="d-flex justify-content-between">
                  <div>
                    <i className="fas fa-map-marker-alt text-primary"></i>&nbsp;
                    {listing.registeredLocation}
                  </div>
                  <div className="font-weight-bold">
                    ₦
                    {getAbbreviation(
                      listing.askingPrice ||
                        listing.capitalRaised ||
                        listing.partialSaleAskingPrice,
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2 mb-3">
                  <div className="d-flex align-items-center">
                    <p className="font-weight-bold m-0 text-uppercase">
                      <span
                        className={
                          listing.status === profileStatus.Approved
                            ? 'text-success'
                            : listing.status === profileStatus.Pending ||
                              listing.status === profileStatus.ChangesRequired
                            ? 'text-warning'
                            : listing.status === profileStatus.Draft ||
                              listing.status === profileStatus.Declined
                            ? 'text-danger'
                            : ''
                        }
                      >
                        {spacePascalWord(listing.status)}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2 mb-3">
                  <div>
                    <i className="fas fa-store-alt text-success"></i>&nbsp;
                    {listing.listingType}
                  </div>
                </div>
                <div className="d-flex flex-row-reverse">
                  <Link
                    to={`/user/businesses/${listing.id}`}
                    className="btn btn-primary font-weight-bold py-1 px-2"
                  >
                    View
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : whichListing === 'franchise' ? (
          <div className="listing-item">
            <div className="card rounded-2 mb-5 mx-2 listing-item-shadow">
              <div
                style={bgImgStyle}
                className="rounded-t-2 pt-2 d-flex justify-content-end"
              >
                <div className="dropdown">
                  <button
                    className="button-as-link d-block pr-3 text-white"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="h4 font-weight-bold fas fa-ellipsis-v"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Link
                      to={`/register-franchise/1?id=${listing.id}`}
                      className="dropdown-item h7"
                    >
                      Edit
                    </Link>
                    <button
                      className="dropdown-item h7"
                      onClick={() => this.deleteFranchise(listing.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <p className="card-title h5 text-primary">
                  <Truncate lines={2} ellipsis={<span>...</span>}>
                    {listing.brandName}
                  </Truncate>
                </p>
                <div className="d-flex justify-content-between">
                  <div className="">
                    <span>Est. </span>
                    {listing.startYear}
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-2 mb-2">
                  <div className="min-w-rem-6">
                    EBITDA Margin:{' '}
                    <span className="font-weight-bold">{listing.ebitda} %</span>
                  </div>
                  <div className="text-right ml-1">
                    <i className="fas fa-map-marker-alt text-primary"></i>&nbsp;
                    <Truncate lines={2} ellipsis={<span>...</span>}>
                      {listing.registeredLocation}
                    </Truncate>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-3">
                  <div className="min-w-rem-6">
                    Exp. Monthly Sales: <br />
                    <span className="font-weight-bold">
                      ₦{getAbbreviation(listing.averageMonthlySales)}
                    </span>
                  </div>
                  <div className="text-right ml-1">
                    Inv. Required: <br />
                    <span className="font-weight-bold">
                      ₦{getAbbreviation(listing.investmentMin)}
                      {' - '}₦{getAbbreviation(listing.investmentMax)}
                    </span>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center mt-2 mb-3">
                  <div className="d-flex align-items-center">
                    <p className="font-weight-bold m-0 text-uppercase">
                      <span
                        className={
                          listing.status === profileStatus.Approved
                            ? 'text-success'
                            : listing.status === profileStatus.Pending ||
                              listing.status === profileStatus.ChangesRequired
                            ? 'text-warning'
                            : listing.status === profileStatus.Draft ||
                              listing.status === profileStatus.Declined
                            ? 'text-danger'
                            : ''
                        }
                      >
                        {spacePascalWord(listing.status)}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row-reverse">
                  <Link
                    to={`/user/franchises/${listing.id}`}
                    className="btn btn-primary font-weight-bold py-1 px-2"
                  >
                    View
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default UserBusinessFranchiseItem;
