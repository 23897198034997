import http from './httpService';
import baseApiUrl from '../../config';

const getFranchises = (pageNumber = 1) => {
  return http.get(`${baseApiUrl}franchises?pageNumber=${pageNumber}`);
};

const getFranchise = (id, edit) => {
  const queryString = edit ? '?edit=true' : '';
  return http.get(`${baseApiUrl}franchises/${id}${queryString}`);
};

const addFranchise = async franchiseDto => {
  const { data } = await http.post(
    `${baseApiUrl}franchises/register`,
    franchiseDto,
  );

  return data;
};

const deleteFranchise = async id => {
  try {
    const { status } = await http.post(`${baseApiUrl}franchises/${id}/delete`);
    if (status === 200) {
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

const getAdminFranchiseListings = (pageNumber = 1) => {
  try {
    return http.get(
      `${baseApiUrl}franchises?all=true&pageNumber=${pageNumber}`,
    );
  } catch (error) {
    console.error(error);
  }
};

const searchAdminFranchiseListings = (
  startDate,
  endDate,
  searchQuery,
  status,
  byId = false,
  pageNumber = 1,
) => {
  try {
    startDate = startDate && startDate.toISOString();
    endDate = endDate && endDate.toISOString();
    if (startDate && endDate)
      return http.get(
        `${baseApiUrl}franchises?all=true&startDate=${startDate}&endDate=${endDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else if (startDate)
      return http.get(
        `${baseApiUrl}franchises?all=true&startDate=${startDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else if (endDate)
      return http.get(
        `${baseApiUrl}franchises?all=true&endDate=${endDate}&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
    else
      return http.get(
        `${baseApiUrl}franchises?all=true&byId=${byId}&query=${searchQuery}&status=${status}&pageNumber=${pageNumber}`,
      );
  } catch (error) {
    console.error(error);
  }
};

const approveFranchiseListing = async id => {
  try {
    const { status } = await http.post(`${baseApiUrl}franchises/${id}/approve`);
    if (status === 200) return true;
  } catch (error) {
    console.error(error);
  }
};

const disapproveFranchiseListing = async id => {
  try {
    const { status } = await http.post(
      `${baseApiUrl}franchises/${id}/disapprove`,
    );
    if (status === 200) return true;
  } catch (error) {
    console.error(error);
  }
};

const declineFranchiseListing = async dto => {
  const { status } = await http.post(
    `${baseApiUrl}franchises/${dto.profileId}/decline`,
    {
      reason: dto.reason,
    },
  );
  if (status === 200) return true;
};

const requestFranchiseListingChange = async dto => {
  const { status } = await http.post(
    `${baseApiUrl}franchises/${dto.profileId}/request-change`,
    {
      reason: dto.reason,
    },
  );
  if (status === 200) return true;
};

export default {
  getFranchises,
  getFranchise,
  addFranchise,
  getAdminFranchiseListings,
  searchAdminFranchiseListings,
  approveFranchiseListing,
  disapproveFranchiseListing,
  deleteFranchise,
  declineFranchiseListing,
  requestFranchiseListingChange,
};
