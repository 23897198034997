import React, { Component } from 'react';
import message from '../../assets/img/mail.svg';
import { connect } from 'react-redux';
import userService from '../services/userService';
import { fetchUserProposalsInbox } from '../../state/actions/user';

class ProposalNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inbox: [],
    };
  }
  componentDidMount() {
    let inbox = this.props.userProposals;
    this.setState({
      inbox: inbox,
    });
  }
  viewNotification = (link, id) => {
    userService.readProposal(id).then(() => {
      this.props.fetchUserProposalsInbox();
      this.props.history.push(link);
    });
  };

  markAllRead = () => {
    userService.markAllProposalRead().then(() => {
      this.props.fetchUserProposalsInbox();
    });
  };

  isReadClass = (classes, status) => {
    return status ? classes + ' bg-white' : classes + ' bg-unread';
  };

  render() {
    let userProposals = this.props.userProposals;
    let unreadCount = 0;
    let notificationViews = [];

    if (userProposals && userProposals.length > 0) {
      unreadCount = userProposals.filter(proposal => !proposal.isRead).length;
      userProposals = userProposals.filter(proposal => !proposal.isRead);

      userProposals.forEach(proposal => {
        // const data = JSON.parse(proposal.data);
        const link = `/user/inbox/proposals/${proposal.id}`;
        let senderDes = '';
        let senderAdvisor = proposal && proposal['proposals'][0].advisor;
        let senderInvestor = proposal && proposal['proposals'][0].investor;
        let senderBusiness = proposal && proposal['proposals'][0].business;
        let senderFranchise = proposal && proposal['proposals'][0].franchise;
        if (senderAdvisor) {
          senderDes = senderAdvisor.whoYouAre;
        }
        if (senderInvestor) {
          senderDes = senderInvestor.whoYouAre;
        }
        if (senderBusiness) {
          senderDes = senderBusiness.businessName;
        }
        if (senderFranchise) {
          senderDes = senderFranchise.brandName;
        }
        let notificationView = {
          link,
          date: new Date(proposal.created).toDateString(),
          id: proposal.id,
          listingId: proposal.id,
          listingType: 'inbox',
          isRead: proposal.isRead,
        };

        notificationView.display = (
          <span>
            Proposal From: {senderDes}
            {' - '}
            <strong> {proposal.subject} </strong>
          </span>
        );

        notificationViews.push(notificationView);
      });

      const userProposalsClone = [...notificationViews];
      notificationViews.length = 0;
      notificationViews = userProposalsClone.slice(0, 6);
    }

    return (
      <React.Fragment>
        <div
          className="cursor-pointer nav-link"
          href="#"
          id="navbarDropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          role="button"
        >
          <div className="position-relative max-w-rem-2a">
            <img
              src={message}
              alt="..."
              className="img-fluid p-1"
              width="30"
            ></img>
            {unreadCount > 0 && (
              <div
                className="counter-wrapper position-absolute"
                style={{ top: '-2px', right: '0px' }}
              >
                <span
                  className="badge badge-danger counter"
                  style={{ fontSize: '.7rem' }}
                >
                  {unreadCount}
                </span>
              </div>
            )}
          </div>
        </div>
        <div
          className="dropdown-menu bg-light notifications"
          style={{ minWidth: '20rem' }}
          aria-labelledby="navbarDropdown"
        >
          <div className="d-flex justify-content-between bg-white p-2 border-bottom mb-2">
            <div>Proposal Inbox</div>
            <div onClick={this.markAllRead} className="btn btn-link p-0 m-0">
              Mark all as read
            </div>
          </div>
          {notificationViews.map(view => (
            <div
              onClick={() => this.viewNotification(view.link, view.id)}
              className={this.isReadClass(
                'mx-2 mb-2 border rounded p-2 cursor-pointer notification ',
                view.isRead,
              )}
              key={view.id}
            >
              <div>{view.display}</div>
              <div className="text-right">
                <em
                  className="text-sm-1 text-muted"
                  style={{ fontSize: '.6rem' }}
                >
                  {view.date}
                </em>
              </div>
            </div>
          ))}
          {notificationViews.length === 0 && (
            <div className="mx-2 mb-2 border rounded p-2 text-danger">
              No proposals at the moment
            </div>
          )}
          <div className="d-flex justify-content-between bg-white p-2 border-top">
            <div className="btn btn-link p-0 m-0">See all</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    userProposals: state.user.userProposalsInbox,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, { fetchUserProposalsInbox })(
  ProposalNotification,
);
