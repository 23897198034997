import React, { Component } from 'react';
import userService from '../../common/services/userService';
import { CircularProgress } from '@material-ui/core';

class UserSubscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: [],
      loading: true,
      done: undefined,
    };
  }

  async componentDidMount() {
    let subscriptions = await userService.getUserSubscriptions();
    this.setState({ subscriptions, loading: false });
    setTimeout(() => {
      this.setState({ done: true });
    }, 1500);
  }

  gotoSubsciptionDetails = subscription => {
    console.log(this.props.history);
    this.props.history.push(
      '/user/subscriptions/' + subscription.subscriptionId,
    );
  };

  render() {
    const { subscriptions } = this.state;

    return (
      <div>
        <div className="ml-lg-5">
          <h1>Invoices and Subscriptions</h1>
          <div className="w-50 my-3 py-3">
            <hr></hr>
          </div>
          <div className="table-responsive shadow">
            <table className="table table-hover">
              <thead className="bg-admin-primary text-white h7">
                <tr>
                  <th className="py-3">#</th>
                  <th>Title of Listing</th>
                  <th>Current Plan</th>
                  <th>Start Date</th>
                  <th>Renewal Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {this.state.loading && (
                  <tr>
                    <td>
                      <CircularProgress color="primary" size={'1rem'} />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
                {subscriptions &&
                  subscriptions.length > 0 &&
                  subscriptions.map((subscription, index) => (
                    <tr
                      key={index}
                      className="cursor-pointer"
                      onClick={() => this.gotoSubsciptionDetails(subscription)}
                    >
                      <td>{index + 1}</td>
                      <td>{subscription.listingTitle}</td>
                      <td>
                        {subscription.planType &&
                          subscription.planType.split(/(?=[A-Z])/)[0]}
                      </td>
                      <td>
                        {new Date(subscription.planBeginDate).toDateString()}
                      </td>
                      <td>
                        {new Date(subscription.planEndDate).toDateString()}
                      </td>
                      <td>{subscription.status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {this.state.done &&
            (!subscriptions || subscriptions.length === 0) && (
              <div className="text-danger ml-5 py-5">
                You don't have any subscription
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default UserSubscriptions;
