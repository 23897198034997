import http from './httpService';
import baseApiUrl from '../../config';

const searchListings = (listingType, searchData) => {
  const { text, type, sector, country, region } = searchData;
  let query = '';
  let resourceType = '';
  if (listingType === 'business') resourceType = 'businesses';
  if (listingType === 'investor') resourceType = 'investors';
  if (listingType === 'advisor') resourceType = 'advisors';
  if (listingType === 'franchise') resourceType = 'franchises';
  if (text) query += `${text}`;
  if (type) query += `&type=${type}`;
  if (sector) query += `&sector=${sector}`;
  if (country) query += `&country=${country}`;
  if (region) query += `&region=${region}`;

  return http.get(`${baseApiUrl}${resourceType}?query=${query}`);
};

const markNotificationRead = id => {
  return http.post(`${baseApiUrl}notifications/${id}/read`);
};

const markAllNotificationsRead = () => {
  return http.post(`${baseApiUrl}notifications/readall`);
};

export default {
  searchListings,
  markNotificationRead,
  markAllNotificationsRead,
};
