import React, { Component } from 'react';

class FranchisePaywall extends Component {
  state = {
    selected: 'investor',
  };

  addTick(profile) {
    this.setState({
      selected: profile,
    });
  }
  render() {
    return (
      <div className="rounded-1 shadow bg-white p-5 d-flex flex-column custom-paywall">
        <div className="text-center mb-5">
          <p className="font-weight-bold h5 mb-3">
            Create a listing to perform this action
          </p>
          <p className="h7 font-weight-normal">
            Kindly select a category and proceed
          </p>
        </div>
        <div className="d-flex flex-wrap justify-content-around mb-5">
          <button
            className="custom-relative w-md-30 w-lg-30
                    w-100 mb-5 p-4 shadow text-center bg-white border-0
                    rounded"
            onClick={() => {
              this.addTick('investor');
            }}
          >
            <div
              className={
                'custom-tick' +
                (this.state.selected === 'investor' ? ' d-block' : ' d-none')
              }
            >
              <i className="fas fa-check-circle text-primary"></i>
            </div>
            <div className="bg-investor-avatar mw-100 mx-auto"></div>
            <p className="text-primary h-7 font-weight-bold">Investor/Buyer</p>
          </button>
          <div className="w-100 d-lg-none d-md-none"></div>
          <button
            className="custom-relative w-md-30 w-lg-30
                    w-100 mb-5 p-4 shadow text-center bg-white border-0
                    rounded"
            onClick={() => {
              this.addTick('advisor');
            }}
          >
            <div
              className={
                'custom-tick' +
                (this.state.selected === 'advisor' ? ' d-block' : ' d-none')
              }
            >
              <i className="fas fa-check-circle text-primary"></i>
            </div>
            <div className="bg-advisor-avatar mw-100 mx-auto"></div>
            <p className="text-primary h-7 font-weight-bold">
              Professional Advisor
            </p>
          </button>
          <div className="w-100 d-lg-none d-md-none"></div>
        </div>
        {this.state.selected === 'investor' ? (
          <a
            className="btn btn-primary w-40 mx-auto"
            href="/register-investor/plans"
          >
            Proceed
          </a>
        ) : (
          <a
            className="btn btn-primary w-40 mx-auto"
            href="/register-advisor/plans"
          >
            Proceed
          </a>
        )}
      </div>
    );
  }
}

export default FranchisePaywall;
