import React, { Component } from 'react';
import ContactBttn from '../../common/components/ContactBttn';
import { connect } from 'react-redux';
import { fetchFranchiseDetails } from '../../state/actions/franchise';
import { fetchBusinessListings } from '../../state/actions/business';
import franchiseService from '../../common/services/franchiseService';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import CarouselTemp from '../../common/components/CarouselTemp';
import BusinessFranchiseListingItem from '../../common/components/BusinessFranchiseListingItem';
import randomstring from 'randomstring';
import { spacePascalWord, camelPad } from '../../common/utils/helpers';
import FadeIn from 'react-fade-in';
import { CircularProgress } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import profileStatus from './../../common/utils/profileStatus';

class FranchiseListingItemPage extends Component {
  state = {
    loading: null,
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.setState({
        loading: true,
      });
      this.props.fetchFranchiseDetails(id);
    }
    this.props.fetchBusinessListings();
  }

  componentDidUpdate(prevProps) {
    if (this.props.franchise !== prevProps.franchise) {
      this.setState({
        loading: false,
      });
    }
  }

  deleteFranchise = async id => {
    Swal.fire(
      'Are you sure?',
      'Do you want to delete listing?',
      'warning',
    ).then(async ok => {
      if (!ok.value) return;
      const res = await franchiseService.deleteFranchise(id);
      if (res) {
        Swal.fire('Listing deleted Successfully', '', 'success').then(() => {
          this.props.history.replace('/user/franchises');
        });
      } else {
        Swal.fire('Unable to delete listing', '', 'error');
      }
    });
  };

  render() {
    const { franchise, businessListings } = this.props;
    let franchisePhotos = [];

    if (franchise.photos && franchise.photos.length > 0) {
      franchise.photos.forEach((imgObj, index) => {
        franchisePhotos.push(
          <div className="vh-center-parent" key={randomstring.generate()}>
            <img
              className="vh-center"
              src={imgObj.fileUrl}
              alt={'Slide' + index}
            />
          </div>,
        );
      });
    }

    let recommendedBusinesses = [];
    if (businessListings && businessListings.length > 0) {
      for (let index = 0; index < businessListings.length; index++) {
        recommendedBusinesses.push(
          <div className="d-block img-fluid">
            <BusinessFranchiseListingItem
              listing={businessListings[index]}
              key={randomstring.generate()}
              whichListingType="business"
            />
            {businessListings[index + 1] && (
              <BusinessFranchiseListingItem
                listing={businessListings[index + 1]}
                key={randomstring.generate()}
                whichListingType="business"
              />
            )}
            {businessListings[index + 2] && (
              <BusinessFranchiseListingItem
                listing={businessListings[index + 2]}
                key={randomstring.generate()}
                whichListingType="business"
              />
            )}
          </div>,
        );
      }
    }

    return (
      <div className="custom-center">
        {this.state.loading ? (
          <div
            style={{ minHeight: '60vh' }}
            className="d-flex justify-content-center align-items-center"
          >
            <CircularProgress color="primary" size={'3rem'} />
          </div>
        ) : (
          franchise && (
            <FadeIn>
              <div>
                {(franchise.isOwner || this.props.currentUser.isAdmin) && (
                  <div className="w-lg-94 w-xl-94 w-xs-100 w-sm-100 w-md-100 rounded bg-white shadow-lg d-flex flex-column p-4 pl-5 mb-5 h-rem-lg-details-7">
                    <p className="h8 w-80 text-details min-h-rem-lg-2">
                      Hi, {franchise.firstName && franchise.firstName}{' '}
                      {franchise.lastName && franchise.lastName}, welcome to
                      Compex Africa. You have created a franchise profile. You
                      can edit your preference using the buttons below.
                    </p>
                    <div className="d-flex justify-content-between flex-wrap w-80">
                      <div
                        className={
                          franchise.status === profileStatus.Approved
                            ? 'text-success mb-3'
                            : franchise.status === profileStatus.Pending ||
                              franchise.status === profileStatus.ChangesRequired
                            ? 'text-warning mb-3'
                            : franchise.status === profileStatus.Draft ||
                              franchise.status === profileStatus.Declined
                            ? 'text-danger mb-3'
                            : ' mb-3'
                        }
                      >
                        <span>
                          <i className="fas fa-circle"></i>
                        </span>
                        <span className="small mx-2">
                          {spacePascalWord(franchise.status)}
                        </span>
                        {!franchise.subscription && (
                          <>
                            <i className="fas fa-square text-muted mr-2"></i>
                            <span className="text-muted">No plan</span>
                          </>
                        )}
                        {franchise.subscription && (
                          <>
                            <i className="fas fa-square text-success mr-2"></i>

                            <Link
                              to={`/user/subscriptions/${franchise.subscription.subscriptionId}`}
                            >
                              {franchise.subscription.planType &&
                                spacePascalWord(
                                  franchise.subscription.planType,
                                  true,
                                ) + ' plan'}
                            </Link>
                          </>
                        )}
                      </div>

                      {!franchise.subscription && (
                        <>
                          <div className="w-100"></div>

                          <div>
                            <Link
                              to={`/register-franchise?id=${franchise.id}`}
                              className="mx-3"
                            >
                              Select plan
                            </Link>
                          </div>
                        </>
                      )}

                      {franchise.subscription && (
                        <>
                          <div className="w-100"></div>

                          <Link
                            to={`/user/franchises/${franchise.id}/upgrade`}
                            className="btn btn-outline-primary h8 font-weight-bold min-w-20"
                          >
                            Change or renew plan
                          </Link>
                        </>
                      )}

                      <Link
                        className="btn btn-outline-primary h8 font-weight-bold min-w-20"
                        to={`/register-franchise?id=${franchise.id}`}
                      >
                        {franchise.status === profileStatus.Draft
                          ? 'Complete profile'
                          : 'Edit'}
                      </Link>
                      <button
                        className="btn btn-outline-primary h8 font-weight-bold min-w-20"
                        onClick={() => this.deleteFranchise(franchise.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                )}
                <div className="d-flex flex-wrap flex-md-nowrap flex-xl-nowrap">
                  <div className="w-100 w-lg-60 min-h-rem-lg-82 d-flex flex-column mb-5 mt-4 mrp-md-9a">
                    <div className="d-flex">
                      <div className="w-80">
                        <p className="h1z font-weight-bold text-primary">
                          {franchise.brandName && franchise.brandName}
                        </p>
                        <p className="h8a">
                          {'Established in '}{' '}
                          {franchise.startYear && franchise.startYear}
                          {'. '}
                          {franchise.outletsNumber && franchise.outletsNumber}
                          {' franchises Currently.'} {' Headquaters in '}{' '}
                          {franchise.headquartersLocation &&
                            franchise.headquartersLocation}
                          {'.'}
                        </p>
                      </div>
                    </div>
                    <div
                      className="w-100 rounded-2 bg-darkdash position-relative shadow"
                      style={{ paddingBottom: '62%' }}
                    >
                      <div
                        className="position-absolute custom-carousel"
                        style={{ top: '0', bottom: '0', left: '0', right: '0' }}
                      >
                        <CarouselTemp
                          listings={franchisePhotos}
                          itemsPerSlide={1}
                          id={'listingPhotos'}
                          controls={true}
                        ></CarouselTemp>
                      </div>
                    </div>
                    <div className="mb-4 mt-4">
                      <p className="h7 font-weight-bold">All formats</p>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <td className="h8a">Format</td>
                              <td className="h8a">Investment</td>
                              <td className="h8a">Brand fee</td>
                              <td className="h8a">Space</td>
                              <td className="h8a">Staff</td>
                              <td className="h8a">Exp monthly sales</td>
                              <td className="h8a">Profit Margin (Ebitda)</td>
                              <td className="h8a">Royalty</td>
                            </tr>
                          </thead>
                          <tbody>
                            {franchise.salesFormats &&
                              franchise.salesFormats.map(salesFormat => (
                                <tr key={randomstring.generate()}>
                                  <td className="h8a">
                                    {salesFormat.formatName}
                                  </td>
                                  <td className="h8a">
                                    <NumberFormat
                                      value={salesFormat.investmentMin}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'₦'}
                                    />
                                    {' - '}
                                    <NumberFormat
                                      value={salesFormat.investmentMax}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'₦'}
                                    />
                                  </td>
                                  <td className="h8a">
                                    <NumberFormat
                                      value={salesFormat.brandFee}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'₦'}
                                    />
                                  </td>
                                  <td className="h8a">
                                    {salesFormat.spaceMin}
                                    {' - '}
                                    {salesFormat.spaceMin}
                                    {'sq Feet'}
                                  </td>
                                  <td className="h8a">
                                    {salesFormat.averageStaffNum}
                                  </td>
                                  <td className="h8a">
                                    <NumberFormat
                                      value={salesFormat.averageMonthlySales}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'₦'}
                                    />
                                  </td>
                                  <td className="h8a">
                                    {salesFormat.averageEbitda} {'%'}
                                  </td>
                                  <td className="h8a">
                                    {salesFormat.royaltyDetails}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="mb-2">
                      <p className="h7 font-weight-bold">
                        About {franchise.brandName && franchise.brandName}
                      </p>
                      <p className="h8a font-weight-normal">
                        {franchise.shortDescription}
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="h7 font-weight-bold">
                        Products and services
                      </p>
                      <p className="h8a font-weight-normal">
                        {franchise.productsServices}
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="h7 font-weight-bold">Preferred Locations</p>
                      <p className="h8a font-weight-normal">
                        {franchise.expandLocations}
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="h7 font-weight-bold">
                        How to get {franchise.brandName && franchise.brandName}{' '}
                        Franchise
                      </p>
                      <p className="h8a font-weight-normal">
                        {franchise.dealerProcedure}
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="h7 font-weight-bold">
                        What are some of the things we look for in a franchise
                      </p>
                      <p className="h8a font-weight-normal">
                        {franchise.expectation}
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="h7 font-weight-bold">
                        Support you can expect from the company
                      </p>
                      <p className="h8a font-weight-normal">
                        {franchise.supportKind}
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="h7 font-weight-bold">Name</p>
                      <p className="h8a font-weight-normal">
                        {franchise.firstName
                          ? `${franchise.firstName} ${franchise.lastName}`
                          : 'Hidden until contacted'}
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="h7 font-weight-bold">Phone</p>
                      <p className="h8a font-weight-normal">
                        {franchise.phoneNumber
                          ? franchise.phoneNumber
                          : 'Hidden until contacted'}
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="h7 font-weight-bold">Email</p>
                      <p className="h8a font-weight-normal">
                        {franchise.email
                          ? franchise.email
                          : 'Hidden until contacted'}
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="h7 font-weight-bold">Company</p>
                      <p className="h8a font-weight-normal">
                        {franchise.brandName}
                      </p>
                    </div>
                    <div className="d-flex flex-wrap">
                      <p className="h7 font-weight-bold w-100 mb-3">
                        Documents&nbsp;
                        {!franchise.canViewContacts && (
                          <span className="font-weight-normal px-2">
                            <i className="fas fa-lock"></i>
                            <span className="px-2">Hidden until contacted</span>
                          </span>
                        )}
                      </p>
                      {franchise.canViewContacts && (
                        <React.Fragment>
                          {franchise.documents && franchise.documents.length ? (
                            franchise.documents.map(document => (
                              <div
                                key={randomstring.generate()}
                                className="w-50"
                              >
                                <div className="my-2">
                                  {document.category
                                    ? document.category
                                    : camelPad(document.fileGroup)}
                                </div>
                                <div className="w-md-50 pr-2 d-flex mb-2">
                                  {document.type === 'application/pdf' ? (
                                    <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                                  ) : document.type ===
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                                    <i className="far fa-file-word fa-2x pr-2 text-info"></i>
                                  ) : document.type ===
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
                                    <i className="far fa-file-excel fa-2x pr-2 text-success"></i>
                                  ) : (
                                    <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                                  )}
                                  &nbsp;
                                  <a
                                    href={document.fileUrl}
                                    className="h7 font-weight-normal"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {document.name}
                                  </a>
                                </div>
                                <div className="d-block d-lg-none d-md-none w-100"></div>
                              </div>
                            ))
                          ) : (
                            <p className="text-danger">
                              No document was uploaded
                            </p>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="w-100 d-block d-lg-none d-md-none"></div>
                  <div className="d-none d-lg-block ml-3">
                    {!franchise.isOwner && (
                      <div className="mb-4 d-none d-md-block d-lg-block">
                        <ContactBttn
                          sendTo={'franchise'}
                          franchiseId={franchise.id}
                          history={this.props.history}
                          disable={franchise.isOwner || franchise.hasContacted}
                          canContact={franchise.canContact}
                        ></ContactBttn>
                      </div>
                    )}
                    <div className="min-w-rem-lg-22 min-h-rem-lg-82 w-100 d-md-flex flex-column d-none d-lg-block d-sm-block shadow-lg bg-white rounded position-relative p-4">
                      <p className="h4a text-center font-weight-bold mb-4">
                        Recommended Businesses
                      </p>
                      <CarouselTemp
                        itemsPerSlide={1}
                        id={'recommendedBusinesses'}
                        listings={recommendedBusinesses}
                        ride={'false'}
                      ></CarouselTemp>
                      <div className="border-top mt-3 d-flex justify-content-around py-4 position-absolute position-left-0 position-bottom-0 w-100">
                        <div>
                          <i className="fas fa-bookmark"></i>&nbsp;Bookmark
                        </div>
                        <div className="border-right"></div>
                        <div>
                          <i className="fas fa-share"></i>&nbsp;Share
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!franchise.isOwner && (
                  <div className="w-md-25 w-50 mb-3a">
                    <ContactBttn
                      sendTo={'franchise'}
                      franchiseId={franchise.id}
                      history={this.props.history}
                      disable={franchise.isOwner || franchise.hasContacted}
                      canContact={franchise.canContact}
                    ></ContactBttn>
                  </div>
                )}
              </div>
            </FadeIn>
          )
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    franchise: state.franchise.franchiseDetails,
    currentUser: state.user.currentUser,
    businessListings: state.business.businessListings,
  }),
  { fetchFranchiseDetails, fetchBusinessListings },
)(FranchiseListingItemPage);
