import React, { Component } from 'react';
import InvestorListingTableItem from './InvestorListingTableItem';
import { CircularProgress } from '@material-ui/core';

class InvestorListingsTable extends Component {
  render() {
    let { loadingInvestorListings } = this.props;
    return (
      <div className="table-responsive shadow">
        <table className="table">
          <thead className="bg-admin-primary text-white h7">
            <tr>
              <th>Count</th>
              <th>Investor ID</th>
              <th>Investor First Name</th>
              <th>Investor Last Name</th>
              <th>Official Email</th>
              <th>Phone Number</th>
              <th>Investor Type</th>
              <th>Plan</th>
              <th>Date Registered</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {loadingInvestorListings ? (
              <tr>
                <td>
                  <CircularProgress color="primary" size={'1rem'} />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ) : (
              this.props.listings.map((listing, index) => (
                <InvestorListingTableItem
                  key={listing.id}
                  listing={listing}
                  number={this.props.listings.length - index}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default InvestorListingsTable;
