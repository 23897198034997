import React, { useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import authService from '../services/authService';
import { facebookAppId } from '../../config';
import { CircularProgress } from '@material-ui/core';

const FacebookLoginButton = props => {
  const [isLoading, setIsLoading] = useState(false);

  const responseFacebook = async response => {
    console.log(response);

    if (!response.accessToken) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const res = await authService.facebookLogin(response);
      if (res) {
        setIsLoading(false);
        const { state } = props.location;
        const redirectPath = state ? state.from.pathname : '/user/dashboard';
        props.history.replace(redirectPath);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <FacebookLogin
      appId={facebookAppId}
      autoLoad={false}
      callback={responseFacebook}
      render={renderProps => (
        <div
          className="btn border border-thick min-w-rem-13"
          onClick={renderProps.onClick}
        >
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="bg-facebook-icon"
              style={{ maxWidth: '1.5rem' }}
            ></div>
            {isLoading ? (
              <span className="px-3 min-w-90">
                <CircularProgress color="primary" size={'1.3rem'} />
              </span>
            ) : (
              <span
                className="text-muted pl-3"
                style={{ fontFamily: 'Roboto', fontWeight: 500 }}
              >
                {props.displayText}
              </span>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default FacebookLoginButton;
