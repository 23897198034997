import React, { Component } from 'react';
import Joi from 'joi-browser';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import equal from 'fast-deep-equal';
import queryString from 'query-string';
import Swal from 'sweetalert2';
import Stepper from 'react-stepper-horizontal';
import listingService from '../../common/services/listingsService';
import { editListing } from '../../state/actions/business';
import planTypes from '../../common/utils/planTypes';
import withReactContent from 'sweetalert2-react-content';
import SubscriptionModal from '../../common/components/SubscriptionModal';
import subscriptionService from '../../common/services/subscriptionService';
import { CircularProgress } from '@material-ui/core';
import { scrollToRef } from '../../common/utils/helpers';
import profileStatus from './../../common/utils/profileStatus';
import BusinessPlans from './BusinessPlans';
import profileType from './../../common/utils/profileType';

class AddBusinessForm4 extends Component {
  state = {
    formdata: {
      TCAgreement: '',
      TEMAgreement: '',
      AnotherAgreement: '',
      couponCode: '',
    },
    steps: [{}, {}, {}, {}],
    errors: {},
    previousData: {},
    id: '',
    formError: false,
    selectedPlan: '',
    hasSubscribed: false,
    subscriptionPlans: [],
    isValidCode: '',
    isSubmitting: false,
    isValidating: false,
  };

  async componentDidMount() {
    const { id } = queryString.parse(this.props.location.search);

    if (id) {
      this.props.editListing(id);
      this.autofillFormFields();
    } else {
      this.props.history.replace('/register-business');
    }

    const subscriptionPlans = await subscriptionService.getSubscriptionPlans();
    this.setState({ subscriptionPlans });
  }

  componentDidUpdate(prevProps) {
    if (!equal(this.props.formdata, prevProps.formdata)) {
      this.autofillFormFields();
    }
  }

  autofillFormFields() {
    const { formdata } = this.props;

    if (formdata && formdata.id) {
      const { subscriptions } = formdata;
      let subscriptionPlan = '';
      let hasSubscribed = false;

      if (subscriptions && subscriptions.length > 0) {
        subscriptionPlan = subscriptions[0].planType;
        hasSubscribed = true;
      }

      const form = { ...this.state.formdata };
      if (formdata.acceptAgreements) {
        form.TCAgreement = 'true';
        form.TEMAgreement = 'true';
        form.AnotherAgreement = 'true';

        this['TCAgreement'].checked = true;
        this['TEMAgreement'].checked = true;
        this['AnotherAgreement'].checked = true;
      }

      this.setState({
        formdata: form,
        id: formdata.id,
        selectedPlan: subscriptionPlan || this.props.selectedPlan,
        hasSubscribed,
        previousData: formdata,
      });
    }
  }

  schema = {
    TCAgreement: Joi.string()
      .required()
      .valid('true')
      .label('Agreement'),
    TEMAgreement: Joi.string()
      .required()
      .valid('true')
      .label('Agreement'),
    AnotherAgreement: Joi.string()
      .required()
      .valid('true')
      .label('Agreement'),
    couponCode: Joi.optional(),
  };

  validate() {
    const { error } = Joi.validate(this.state.formdata, this.schema, {
      abortEarly: false,
    });
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  onChange = e => {
    const { name, value } = e.target;
    const formdata = { ...this.state.formdata };
    formdata[name] = value;
    const errors = { ...this.state.errors };
    errors[name] = '';

    // reset selectedPlan if coupon code is changing
    let selectedPlan = this.state.selectedPlan;
    if (formdata[name] === 'couponCode') selectedPlan = '';

    this.setState({ formdata, selectedPlan, errors });
  };

  onAgreementChange = e => {
    const { name } = e.target;
    const formdata = { ...this.state.formdata };
    formdata[name] = e.target.checked ? 'true' : 'false';
    const errors = { ...this.state.errors };
    errors[name] = '';
    this.setState({ formdata, errors });
  };

  validateCouponCode = async e => {
    const { couponCode } = this.state.formdata;

    if (couponCode) {
      // validate coupon code
      this.setState({ isValidating: true });
      const res = await subscriptionService.validateCouponCode(
        couponCode,
        'Business',
      );
      if (res) {
        this.setState({
          selectedPlan: planTypes.coupon,
          isValidCode: true,
          isValidating: false,
        });
      } else {
        this.setState({
          selectedPlan: '',
          isValidCode: false,
          isValidating: false,
        });
      }
    }
  };

  onSelectPlan = plan => {
    if (
      this.state.previousData.status &&
      this.state.previousData.status !== profileStatus.Draft
    ) {
      this.upgradePlan(plan);
    } else {
      this.setState({ selectedPlan: plan });
    }
  };

  upgradePlan = plan => {
    const { id, formdata } = this.state;
    this.promptPayment(plan, id, formdata.workEmail, true);
  };

  MySwal = withReactContent(Swal);

  promptPayment = (plan, businessId, workEmail, isUpgrading = false) => {
    const plans = [...this.state.subscriptionPlans];
    const selectedPlan = plans.find(x => x.planType === plan);

    const { email, phoneNumber } = this.props.currentUser;
    const successMessage = isUpgrading
      ? 'Subscription was successful and your changes was saved'
      : email !== workEmail
      ? `A verification link has been sent to ${workEmail}. You must confirm your email before your listing will be approved.`
      : 'Your request has been sent for approval';

    if (email && selectedPlan && businessId) {
      this.MySwal.fire({
        customClass: 'bg-transparent w-lg-30 w-md-30 w-90',
        showConfirmButton: false,
        html: (
          <SubscriptionModal
            planId={selectedPlan.planId}
            amount={selectedPlan.amount}
            userEmail={email}
            userPhone={phoneNumber}
            listingId={businessId}
            listingType={profileType.Business}
            redirectTo="listingsuccessful"
            successMessage={successMessage}
            history={this.props.history}
          ></SubscriptionModal>
        ),
      });
    }
  };

  onSubmit = async e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) {
      this.setState({ formError: true });
      const firstError = this[Object.keys(errors)[0]];
      scrollToRef(firstError);
      firstError.focus({ preventScroll: true });
      return;
    } else {
      this.setState({ formError: false });
    }

    if (!this.state.selectedPlan) {
      Swal.fire(
        'Error',
        'You must select a plan or enter a valid subscription code before submitting',
        'error',
      );
      return;
    }

    // post data to server
    const { previousData, hasSubscribed, selectedPlan } = this.state;
    previousData.acceptAgreements = true;
    previousData.subscriptionPlan = selectedPlan;
    previousData.couponCode = this.state.formdata.couponCode;

    try {
      this.setState({ isSubmitting: true });
      const res = await listingService.register(previousData);

      if (res && hasSubscribed) {
        this.setState({ isSubmitting: false });
        Swal.fire(
          'Changes submitted successfully',
          'Your changes has been submitted successfully',
          'success',
        ).then(() => {
          this.props.history.replace(`/user/businesses/${res.id}`);
        });
      } else if (
        res &&
        (selectedPlan === planTypes.activeBusiness ||
          selectedPlan === planTypes.premiumBusiness ||
          selectedPlan === planTypes.professionalBusiness)
      ) {
        this.setState({ isSubmitting: false });
        // redirect to payment platform
        this.promptPayment(selectedPlan, res.id, previousData.workEmail);
      } else if (
        res &&
        (selectedPlan === planTypes.coupon ||
          selectedPlan === planTypes.basicBusiness)
      ) {
        this.setState({ isSubmitting: false });
        const message =
          this.props.currentUser.email !== previousData.workEmail
            ? `A verification link has been sent to ${previousData.workEmail}. You must confirm your email before your listing will be approved.`
            : 'Your request has been sent for approval';

        Swal.fire(
          'Business listing registered successfully',
          message,
          'success',
        ).then(() => {
          this.props.history.replace(`/user/businesses/${res.id}`);
        });
      } else {
        this.setState({ isSubmitting: false });
        Swal.fire('An error occured', '', 'error');
      }
    } catch (error) {
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    const { hasSubscribed, previousData } = this.state;
    return (
      <div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="d-flex justify-content-center flex-column text-center my-3">
              <h3 className="text-primary font-weight-bold">
                Register a business
              </h3>
              <p className="text-muted">
                Kindly fill out the following information as accurate as
                possible
              </p>
              <div>
                <Stepper
                  steps={[
                    { href: `/register-business/1?id=${this.state.id}` },
                    { href: `/register-business/2?id=${this.state.id}` },
                    { href: `/register-business/3?id=${this.state.id}` },
                    { href: `/register-business/4?id=${this.state.id}` },
                  ]}
                  activeStep={3}
                  size={45}
                  circleFontSize={22}
                  titleTop={15}
                  defaultBarColor="#002880"
                  completeBarColor="#002880"
                  activeColor="#002880"
                  completeColor="#002880"
                  defaultColor="#555"
                />
              </div>
              <h3 className="font-weight-bold mt-5 mb-2">Agreements</h3>
            </div>
            <form onSubmit={this.onSubmit} method="POST" noValidate>
              <div className="form-row bg-white shadow rounded-2 py-5 px-3">
                <div className="col-12">
                  <p className="ml-4 mb-4">
                    Kindly tick the boxes below to indicate your agreement to
                    the following terms
                  </p>
                </div>
                <div className="row w-100">
                  <div className="col-1">
                    <div className="text-right">
                      <input
                        type="checkbox"
                        name="TCAgreement"
                        onChange={this.onAgreementChange}
                        value="true"
                        ref={elem => {
                          this.TCAgreement = elem;
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-11">
                    <p>
                      I agree to the privacy policy of this platform.
                      <br />
                      <a
                        className="text-primary font-weight-bold"
                        target="_blank"
                        href="/privacy"
                      >
                        View privacy policy.
                      </a>
                    </p>
                  </div>
                </div>

                <div className="row w-100">
                  <div className="col-1">
                    <div className="text-right">
                      <input
                        type="checkbox"
                        name="TEMAgreement"
                        onChange={this.onAgreementChange}
                        value="true"
                        ref={elem => {
                          this.TEMAgreement = elem;
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-11">
                    <p>
                      I agree to the refund policy of this platform.
                      <br />
                      <a
                        className="text-primary font-weight-bold"
                        target="_blank"
                        href="/refunds"
                      >
                        View refund policy.
                      </a>
                    </p>
                  </div>
                </div>

                <div className="row w-100">
                  <div className="col-1">
                    <div className="text-right">
                      <input
                        type="checkbox"
                        name="AnotherAgreement"
                        onChange={this.onAgreementChange}
                        value="true"
                        ref={elem => {
                          this.AnotherAgreement = elem;
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-11">
                    <p>
                      I agree to the terms and conditions of this platform
                      <br />
                      <a
                        className="text-primary font-weight-bold"
                        target="_blank"
                        href="/terms"
                      >
                        View terms and conditions.
                      </a>
                    </p>
                  </div>
                </div>

                {this.state.formError && (
                  <div className="row">
                    <div className="col">
                      <div className="text-danger mx-5 small">
                        You must accept all agreements
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="fieldset mt-5 border-top-2 border-bottom-2">
                <div className="py-4">
                  <h4 className="text-center text-muted font-weight-bold">
                    Choose a plan
                  </h4>
                </div>
                <BusinessPlans
                  currentPlan={this.state.selectedPlan}
                  planChange={this.onSelectPlan}
                  isDraft={
                    previousData.status &&
                    previousData.status === profileStatus.Draft
                  }
                  isUpgrading={
                    previousData.status &&
                    previousData.status !== profileStatus.Draft
                  }
                  {...this.props}
                />

                <div className="mx-3 mb-5">
                  <p>Have a subscription code?</p>
                  <div className="form-row">
                    <div className="col-md-10 mb-3">
                      <input
                        type="text"
                        name="couponCode"
                        onChange={this.onChange}
                        value={this.state.formdata.couponCode}
                        id="couponCode"
                        className="form-control form-control-sm"
                        placeholder="Enter subscription code"
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn btn-sm btn-primary w-100"
                        type="button"
                        disabled={hasSubscribed}
                        onClick={
                          !hasSubscribed ? this.validateCouponCode : void 0
                        }
                      >
                        {this.state.isValidating ? (
                          <CircularProgress color="inherit" size={'1rem'} />
                        ) : (
                          'Validate'
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="py-2">
                    {this.state.isValidCode && (
                      <span>
                        <i className="fa fa-check-circle fa-2x text-success"></i>
                        <span className="px-3">Valid Code.</span>
                      </span>
                    )}
                    {this.state.isValidCode === false && (
                      <span>
                        <i className="fa fa-times-circle fa-2x text-danger"></i>
                        <span className="px-3">
                          code not valid or not usable on this form.
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex flex-wrap justify-content-around align-items-around w-100 py-5">
                <Link
                  to={`/register-business/3?id=${this.state.id}`}
                  className="btn btn-lg btn-outline-primary text-primary bg-white w-lg-30 w-sm-60 w-xs-60 mb-3 mb-lg-0 mb-xl-0"
                >
                  Back
                </Link>
                <button
                  type="submit"
                  className="btn btn-primary btn-lg w-lg-30 w-sm-60 w-xs-60"
                >
                  {this.state.isSubmitting ? (
                    <CircularProgress color="inherit" size={'1rem'} />
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    formdata: state.business.formdata,
    currentUser: state.user.currentUser,
    selectedPlan: state.business.selectedPlan,
  }),
  { editListing },
)(AddBusinessForm4);
