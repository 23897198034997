import React, { Component } from 'react';
import BusinessPlans from './BusinessPlans';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SubscriptionModal from './../../common/components/SubscriptionModal';
import subscriptionService from '../../common/services/subscriptionService';
import { fetchListingDetails } from '../../state/actions/business';
import { spacePascalWord } from './../../common/utils/helpers';
import profileType from './../../common/utils/profileType';

class UpgradeBusinessPlan extends Component {
  MySwal = withReactContent(Swal);
  state = {
    id: '',
    subscriptionPlans: {},
  };

  async componentDidMount() {
    const { id } = this.props.match.params;
    const { business } = this.props;

    if (!business || !business.id) this.props.fetchListingDetails(id);

    const subscriptionPlans = await subscriptionService.getSubscriptionPlans();

    this.setState({
      id,
      subscriptionPlans,
    });
  }

  planChange = plan => {
    const { id, isOwner } = this.props.business;
    if (!isOwner) this.props.history.push(`/businesses/${id}`);

    this.promptPayment(plan);
  };

  promptPayment = plan => {
    const plans = [...this.state.subscriptionPlans];
    const selectedPlan = plans.find(x => x.planType === plan);

    const { email, phoneNumber } = this.props.currentUser;
    const { id: businessId, email: workEmail } = this.props.business;
    if (email && selectedPlan && businessId) {
      this.MySwal.fire({
        customClass: 'bg-transparent w-lg-30 w-md-30 w-90',
        showConfirmButton: false,
        html: (
          <SubscriptionModal
            planId={selectedPlan.planId}
            amount={selectedPlan.amount}
            userEmail={email}
            listingEmail={workEmail}
            userPhone={phoneNumber}
            listingId={businessId}
            listingType={profileType.Business}
            redirectTo={`user/businesses/${businessId}`}
            successMessage={`Subscription to ${spacePascalWord(
              plan,
            )} plan was successful`}
            history={this.props.history}
          ></SubscriptionModal>
        ),
      });
    }
  };

  render() {
    const { business } = this.props;
    const currentPlan =
      business && business.subscription && business.subscription.planType;

    return (
      <div className="scale-down-lg-7">
        <h3 className="text-center">Upgrade/change Business plan</h3>
        <BusinessPlans
          currentPlan={currentPlan}
          planChange={this.planChange}
          isUpgrading={true}
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    business: state.business.listingDetails,
    currentUser: state.user.currentUser,
  }),
  { fetchListingDetails },
)(UpgradeBusinessPlan);
