import React from 'react';
import NavBarMobile from '../common/components/NavBarMobile';
import NavBarHomeDesktop from '../common/components/NavBarHomeDesktop';

const NavBarHome = () => {
  return (
    <div>
      <NavBarMobile></NavBarMobile>
      <NavBarHomeDesktop></NavBarHomeDesktop>
    </div>
  );
};

export default NavBarHome;
