import {
  FETCH_INVESTOR_LISTINGS,
  FETCH_INVESTOR_DETAILS,
  FETCH_ADMIN_INVESTOR_LISTINGS,
  FETCH_ADMIN_INVESTOR_LISTINGS_STARTED,
  EDITINVESTOR,
  SELECT_PLAN,
  RESETFORMS,
} from './types';
import investorService from '../../common/services/investorsService';

export const fetchInvestorListings = (
  queryString = '',
  pageNumber = 1,
) => dispatch => {
  investorService.getInvestors(queryString, pageNumber).then(
    res => {
      dispatch({
        type: FETCH_INVESTOR_LISTINGS,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_INVESTOR_LISTINGS,
        payload: { data: [] },
      });
    },
  );
};

export const fetchInvestorDetails = id => dispatch => {
  investorService.getInvestor(id).then(
    res => {
      dispatch({
        type: FETCH_INVESTOR_DETAILS,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_INVESTOR_DETAILS,
        payload: {},
      });
    },
  );
};

export const editInvestor = id => dispatch => {
  investorService.getInvestor(id, true).then(
    res => {
      dispatch({
        type: EDITINVESTOR,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: EDITINVESTOR,
        payload: {},
      });
    },
  );
};

export const fetchAdminInvestorListings = (pageNumber = 1) => dispatch => {
  dispatch({ type: FETCH_ADMIN_INVESTOR_LISTINGS_STARTED });
  investorService.getAdminInvestorListings(pageNumber).then(
    res => {
      dispatch({
        type: FETCH_ADMIN_INVESTOR_LISTINGS,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_ADMIN_INVESTOR_LISTINGS,
        payload: { data: [] },
      });
    },
  );
};

export const searchAdminInvestorListings = (
  startDate,
  endDate,
  searchQuery,
  status,
  byId,
  pageNumber = 1,
) => async dispatch => {
  dispatch({ type: FETCH_ADMIN_INVESTOR_LISTINGS_STARTED });
  await investorService
    .searchAdminInvestorListings(
      startDate,
      endDate,
      searchQuery,
      status,
      byId,
      pageNumber,
    )
    .then(
      res => {
        dispatch({
          type: FETCH_ADMIN_INVESTOR_LISTINGS,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: FETCH_ADMIN_INVESTOR_LISTINGS,
          payload: { data: [] },
        });
      },
    );
};

export const selectPlan = plan => dispatch => {
  dispatch({
    type: SELECT_PLAN,
    payload: plan,
  });
};

export const resetForm = () => dispatch => {
  dispatch({ type: RESETFORMS });
};
