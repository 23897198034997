export default {
  basicBusiness: 'BasicBusiness',
  activeBusiness: 'ActiveBusiness',
  premiumBusiness: 'PremiumBusiness',
  professionalBusiness: 'ProfessionalBusiness',
  basicInvestor: 'BasicInvestor',
  activeInvestor: 'ActiveInvestor',
  premiumInvestor: 'PremiumInvestor',
  professionalInvestor: 'ProfessionalInvestor',
  basicAdvisor: 'BasicAdvisor',
  activeAdvisor: 'ActiveAdvisor',
  premiumAdvisor: 'PremiumAdvisor',
  professionalAdvisor: 'ProfessionalAdvisor',
  basicFranchise: 'BasicFranchise',
  premiumFranchise: 'PremiumFranchise',
  professionalFranchise: 'ProfessionalFranchise',
  monthlyStartup: 'MonthlyStartup',
  quarterlyStartup: 'QuarterlyStartup',
  yearlyStartup: 'YearlyStartup',
  coupon: 'Coupon',
};
