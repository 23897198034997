import React from 'react';
import auth from '../services/authService';
import { useHistory } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import tempAvatar from '../../assets/img/user.svg';
import { startupZoneDomain } from '../../config';

const NavBarHomeDesktop = props => {
  const history = useHistory();

  const logout = () => {
    auth.logOutUser();
    history.replace('/');
  };

  return (
    <div className="d-none d-xl-block d-lg-block custom-nav">
      <nav className="navbar navbar-expand-lg navbar-light fixed-top px-md-5 pt-3 pb-0 bg-landing">
        <div className="container">
          <Link to="/" className="navbar-brand mr-md-0 position-relative">
            <img
              className="compex-logo"
              src={require('../../assets/img/compex-logo.svg')}
              alt=""
            />
            <p
              className="m-0 position-absolute h10"
              style={{
                top: '47%',
                right: '-12.5%',
              }}
            >
              BETA
            </p>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item my-auto mx-2 nav-drop">
                <NavLink to="/businesses" className="nav-link text-primary h6">
                  Businesses for sale
                  <span className="sr-only">(current)</span>
                </NavLink>
                <div className="bg-footer position-absolute d-none nav-drop-item">
                  <NavLink to="/businesses" className="nav-link text-white">
                    Businesses for sale
                  </NavLink>
                  <NavLink
                    to="/register-business/plans"
                    className="nav-link text-white"
                  >
                    Register Business
                  </NavLink>
                  <NavLink
                    to="/covid-intervention"
                    className="nav-link text-white d-none"
                  >
                    COVID-19 Intervention
                  </NavLink>
                </div>
              </li>
              <li className="nav-item my-auto mx-2 nav-drop">
                <NavLink to="/investors" className="nav-link text-primary  h6">
                  Investors <span className="sr-only">(current)</span>
                </NavLink>
                <div className="bg-footer position-absolute d-none nav-drop-item">
                  <Link
                    className="nav-link text-white"
                    to={{
                      pathname: '/lenders',
                    }}
                  >
                    Lenders
                  </Link>
                  <NavLink to="/investors" className="nav-link text-white">
                    Investors
                  </NavLink>
                  <NavLink
                    to="/register-investor/plans"
                    className="nav-link text-white"
                  >
                    Register Investor
                  </NavLink>
                </div>
              </li>
              <li className="nav-item my-auto mx-2 nav-drop">
                <NavLink to="/advisors" className="nav-link text-primary  h6">
                  Advisors
                </NavLink>
                <div className="bg-footer position-absolute d-none nav-drop-item">
                  <NavLink to="/advisors" className="nav-link text-white">
                    Advisors
                  </NavLink>
                  <NavLink
                    to="/register-advisor/plans"
                    className="nav-link text-white"
                  >
                    Register Advisor
                  </NavLink>
                </div>
              </li>
              <li className="nav-item my-auto mx-2 nav-drop">
                <NavLink to="/franchises" className="nav-link text-primary  h6">
                  Franchises
                </NavLink>
                <div className="bg-footer position-absolute d-none nav-drop-item">
                  <NavLink to="/franchises" className="nav-link text-white">
                    Franchises
                  </NavLink>
                  <NavLink
                    to="/register-franchise/plans"
                    className="nav-link text-white"
                  >
                    Register Franchise
                  </NavLink>
                </div>
              </li>
              <li className="nav-item my-auto mx-2">
                <a
                  href={`${startupZoneDomain}`}
                  className="nav-link text-primary  h6"
                >
                  Startup Zone
                </a>
              </li>
              <li className="nav-item my-auto mx-2">
                <NavLink to="/#pricing" className="nav-link text-primary  h6">
                  Pricing
                </NavLink>
              </li>
              {props.isLoggedIn ? (
                <>
                  <li className="nav-item dropdown mx-2 text-center">
                    <div
                      className="cursor-pointer"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          props.currentUser.photoUrl
                            ? props.currentUser.photoUrl
                            : tempAvatar
                        }
                        alt="..."
                        style={{
                          minHeight: '3.2rem',
                          minWidth: '3.2rem',
                          maxHeight: '3.2rem',
                          maxWidth: '3.2rem',
                        }}
                        className="img-fluid rounded-circle p-1"
                      ></img>
                    </div>
                    <div
                      className="dropdown-menu min-w-rem-7"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link to="/user/dashboard" className="dropdown-item">
                        Profile
                      </Link>
                      <button
                        className=" dropdown-item cursor-pointer"
                        onClick={logout}
                      >
                        Sign out
                      </button>
                    </div>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item my-auto mx-2 nav-drop">
                    <NavLink
                      to="/login"
                      className="nav-link text-primary h6 mb-0"
                    >
                      <button className="btn btn-outline-primary">
                        Sign In
                      </button>
                    </NavLink>
                  </li>
                  <li className="nav-item my-auto mx-2 nav-drop">
                    <NavLink
                      to="/signup"
                      className="nav-link text-primary h6 mb-0"
                    >
                      <button className="btn btn-primary">Sign Up</button>
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default connect(state => ({
  isLoggedIn: state.user.isLoggedIn,
  currentUser: state.user.currentUser,
}))(NavBarHomeDesktop);
