const profileType = {
  Business: 'Business',
  Franchise: 'Franchise',
  Investor: 'Investor',
  Advisor: 'Advisor',
};

Object.freeze(profileType);

export default profileType;
